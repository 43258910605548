import React, { useRef, useEffect } from "react"
import styled from "styled-components"
import { size } from "../../../Constants/sizesAndSpacing"
import { breakpoints } from "../../../Constants/responsive"
import { ListingDescription } from "../ListingCard/listingCard"
import { IServerListing } from "../../../mockListings"
import SmallHeader from "./Header/smallHeader"
import { colors } from "../../../Constants/colors"
import moment from "moment"
import Footer from "../../Footer/footer"
import ShowInterest from "./showInterest"
import Axios from "axios"
import { baseURL } from "../../../Constants/serverConfig"

const MainWrapper = styled.div`
  width: 100%;
  background: white;
  z-index: 100;

  @media (min-width: ${breakpoints.mobile + "px"}) {
    width: ${size.extendedListingDescriptionWidth + "px"};
  }
`

const ListingStyle = styled.div`
  font-weight: 600;
  margin-top: 2%;
  font-size: 1.2em;
`

const DescriptionHeading = styled.div`
  width: 100%;
  height: auto;
  font-size: 1.2em;
  font-weight: 600;
  border-bottom: 1px solid ${colors.lightgrey};
  padding-left: 1em;
  padding-bottom: 0.5em;
  margin-top: 0.5em;
  margin-bottom: 1em;
`

const ShowInterestButton = styled.div`
  margin: 2%;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-right: 1em;
  padding-left: 1em;
  background: ${colors.primary};
  border-radius: 0.2em;
  cursor: pointer;
  color: ${colors.white};
  font-size: 1em;
  text-align: center;
  font-weight: 600;
`

const TextIcon = styled.i`
  color: ${colors.secondary};
  margin-right: 2%;
`

const NavHeaderWrapper = styled.div`
  z-index: 1030;
  position: sticky;
  position: -webkit-sticky;
  background: ${colors.white};
  display: inline;
  height: ${size.NavHeaderWrapperHeight + "px"};
`

const DescriptionWrapper = styled.div`
  overflow-x: hidden;
  overflow-y: scroll;
  height: calc(
    100% - ${size.headerHeight + "px"} - ${size.NavHeaderWrapperHeight + "px"}
  );

  ::-webkit-scrollbar {
    width: 1.25em;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${colors.midprimary};
    border-radius: 1.25em;
    border: 6px solid transparent;
    background-clip: content-box;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: ${colors.primary};
  }
`

const SectionWrapper = styled.div`
  margin-left: 5%;
  margin-right: 5%;
`

const HorizontalNavBarItems = styled.div`
  cursor: pointer;
  font-weight: 600;
  padding-bottom: 0.5em;
  border-bottom: 5px solid ${colors.white};
  &:hover {
    color: ${colors.primary};
    border-bottom: 5px solid ${colors.primary};
  }
`

const HorizontalNavBar = styled.div`
  display: flex;
  justify-content: space-around;;
  align-items: center;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
`

const ShowInterestText = styled.p`
  font-size: 0.5em;
  margin: auto;
  padding-top: 1em;
  padding-bottom: 1em;
  text-align: left;
  opacity: 0.9;
  color: ${colors.secondary};
  @media only screen and (min-width: ${breakpoints.mobile + "px"}) {
    font-size: 0.5em;
  }
  @media only screen and (min-width: ${breakpoints.tablet + "px"}) {
    font-size: 0.6em;
  }
  @media only screen and (min-width: ${breakpoints.desktop + "px"}) {
    font-size: 0.7em;
  }
`

const ExtendedListingDescription: React.FC<IServerListing> = (
  props: IServerListing
) => {
  const showInterestRef: React.MutableRefObject<HTMLDivElement | null> = useRef(
    null
  )
  const showOverview: React.MutableRefObject<HTMLDivElement | null> = useRef(
    null
  )

  const showFactsFeatures: React.MutableRefObject<HTMLDivElement | null> = useRef(
    null
  )
  const handleNavBar: React.MutableRefObject<HTMLInputElement | null> = useRef(
    null
  )

  const overView: React.MutableRefObject<HTMLInputElement | null> = useRef(null)
  const showInterest: React.MutableRefObject<HTMLInputElement | null> = useRef(
    null
  )
  const scrollTo = (ref: React.MutableRefObject<HTMLDivElement | null>) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "start" })
    }
  }

  // useEffect(() => {
  //   Axios.post(baseURL + "/viewpost", {
  //     postid: props.postID,
  //   }).then(res => {
  //     console.log(res)
  //   })
  // }, [])

  var propType = [
    "Apartment",
    "Farm House",
    "House/Villa",
    "PG/Roommate",
    "Other",
  ]
  var furnish = ["Unfurnished", "Semi-Furnished", "Furnished"]
  var leaseDur = ["Not Specified", "1 Month", "6 Months", "1 Year", "Flexible"]

  const numberFormat = (value: number) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    })
      .format(value)
      .replace(/\D00(?=\D*$)/, "")

  return (
    <>
      <MainWrapper id="mainWrapper">
        <NavHeaderWrapper id="headerItem">
          <SmallHeader toggle={() => {}} props={props}></SmallHeader>
          <ListingStyle>
            <ListingDescription {...props}></ListingDescription>
          </ListingStyle>
          <ShowInterestButton
            onClick={() => {
              scrollTo(showInterestRef)
            }}
          >
            Show Interest
          </ShowInterestButton>

          <HorizontalNavBar className="navItems" ref={handleNavBar}>
            <HorizontalNavBarItems
              ref={overView}
              onClick={() => {
                scrollTo(showOverview)
              }}
            >
              Overview
            </HorizontalNavBarItems>

            <HorizontalNavBarItems
              onClick={() => {
                scrollTo(showFactsFeatures)
              }}
            >
              Facts and Features
            </HorizontalNavBarItems>

            <HorizontalNavBarItems
              ref={showInterest}
              onClick={() => {
                scrollTo(showInterestRef)
              }}
            >
              Show Interest
            </HorizontalNavBarItems>
          </HorizontalNavBar>
        </NavHeaderWrapper>

        <DescriptionWrapper>
          <SectionWrapper>
            <div ref={showOverview} />
            <h2>Overview</h2>
            <div>
              <TextIcon className="fa fa-tag" />
              {props.hasOwnProperty("createdDate") && (
                <> {moment(props.createdDate.toString()).fromNow()}</>
              )}

              <p style={{ marginBottom: "1%" }} />

              {props.hasOwnProperty("views") && props.views > 0 && (
                <>
                  <TextIcon className="fa fa-eye" />
                  <strong>Viewed by </strong> {props.views} users
                  <p style={{ marginBottom: "1%" }} />
                </>
              )}

              {props.hasOwnProperty("aboutTheProperty") &&
                props.aboutTheProperty.length > 1 && (
                  <div>
                    <strong>Property Description </strong>
                    <br />

                    <p style={{ marginBottom: "1%" }} />
                    {props.aboutTheProperty}
                  </div>
                )}

              <p style={{ marginBottom: "1%" }} />

              {props.hasOwnProperty("leaseSummary") &&
                props.leaseSummary.length > 1 && (
                  <div>
                    <strong>Lease Summary</strong>
                    <br />
                    <p style={{ marginBottom: "1%" }} />
                    {props.leaseSummary}
                  </div>
                )}
            </div>
          </SectionWrapper>

          <div ref={showFactsFeatures} />

          <SectionWrapper>
            <h2>Facts and Features</h2>

            {props.hasOwnProperty("securityDeposit") && (
              <>
                <strong style={{ marginRight: "2%" }}>Security Deposit</strong>{" "}
                {numberFormat(props.securityDeposit)}
                <br />
                <div style={{ marginBottom: "2%" }} />
              </>
            )}

            {props.hasOwnProperty("yearBuilt") && props.yearBuilt.length > 3 && (
              <>
                {" "}
                <strong style={{ marginRight: "2%" }}>Year Built </strong>{" "}
                {props.yearBuilt}
                <br />
                <div style={{ marginBottom: "2%" }} />
              </>
            )}

            {props.hasOwnProperty("leaseDuration") && (
              <>
                {" "}
                <strong style={{ marginRight: "2%" }}>
                  Lease Duration{" "}
                </strong>{" "}
                {leaseDur[Number(props.leaseDuration)]}
                <br />
                <div style={{ marginBottom: "2%" }} />
              </>
            )}

            {props.hasOwnProperty("propertyType") && (
              <>
                {" "}
                <strong style={{ marginRight: "2%" }}>
                  Property Type{" "}
                </strong>{" "}
                {propType[Number(props.propertyType)]}
                <br />
                <div style={{ marginBottom: "2%" }} />
              </>
            )}

            {props.hasOwnProperty("furnishing") && (
              <>
                <strong style={{ marginRight: "2%" }}>Furnishing </strong>{" "}
                {furnish[Number(props.furnishing)]}
                <br />
                <div style={{ marginBottom: "2%" }} />
              </>
            )}

            {props.hasOwnProperty("amenities") && props.amenities.length > 0 && (
              <>
                <strong style={{ marginRight: "2%" }}>Amenities </strong>{" "}
                {props.amenities.join(", ")}
                <br />
                <div style={{ marginBottom: "2%" }} />
              </>
            )}

            {props.hasOwnProperty("amenities2") &&
              props.amenities2.length > 1 && (
                <div>
                  <strong>Additional amenities</strong>
                  <br />
                  <p style={{ marginBottom: "1%" }} />
                  {props.amenities2}
                </div>
              )}
          </SectionWrapper>

          <div ref={showInterestRef} />
          <SectionWrapper>
          {/* <div id="#showInterest"> */}
            <h2>Show Interest</h2>
          {/* </div> */}
          <ShowInterest postID={props.postID}></ShowInterest>
          <ShowInterestText>
            You agree to EkThikana's Terms and Conditions, Privacy Policy, and Cookie Policy. By choosing to ShowInterest, you also agree that EkThikana,
            landlords, and property managers may call or text you about any inquiries you submit through our services, which may involve use of automated means
            and prerecorded/artificial voices.
          </ShowInterestText>
        </SectionWrapper>
        <Footer></Footer>
        </DescriptionWrapper>
      </MainWrapper>
    </>
  )
}

export default React.memo(ExtendedListingDescription)
