import React from "react"
import styled from "styled-components"
import { IServerListing, mockImages } from "../../../mockListings"
import { spacing, size } from "../../../Constants/sizesAndSpacing"
import { colors } from "../../../Constants/colors"
import ListingImage from "./listingImage"
import useModal from "../../../Hooks/useModal"
import Modal from "../../Modal/modal"
import ExtendedListingCard from "../Extended Listing Card/extendedListingCard"
import { breakpoints } from "../../../Constants/responsive"
import { useBookmarks } from "../../../Hooks/useBookmarks"
import { Link, useHistory, useRouteMatch } from "react-router-dom"


const ListingCardWrapper = styled.div`
  flex: 1 0 ${size.listingMinWidth + 35 + "px"};
  margin: ${spacing.listingMargin / 2 + "px"};
  box-shadow: 0 0 2.5px ${colors.shadow};
  font-size: 1em;
  box-sizing: border-box;
  border-left: 3px solid ${colors.primary};
  position: relative;
  @media (min-width: ${breakpoints.mobile + "px"}) {
    flex: 1 0 ${size.listingMinWidth + "px"};
  }
`

const BookmarkButtonWrapper = styled.div<{ active: boolean }>`
  position: absolute;
  z-index: 10;
  top: 15px;
  right: 15px;
  height: 25px;
  width: 25px;
  overflow: hidden;
  border-radius: 50%;
  cursor: pointer;
  background: url("/icon.png");
  background-position: center;
  background-size: cover;
  filter: ${props => (props.active ? "none" : "grayscale(100%)")};
`

const ListingFlex = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-content: center;
  align-items: baseline;
`

const ListingLineSpacing = styled.div`
  height: ${spacing.listingCardLineSpacing + "em"};
`

const ListingDetailsWrapper = styled.div`
  width: 100%;
  padding: 0 1em 0 1em;
  box-sizing: border-box;
`

const ListingmonthlyRent = styled.div`
  font-size: 1.3em;
  font-weight: 600;
  margin-right: 1em;
`

const ListingParameters = styled.div`
  font-size: 0.875em;
  font-weight: 500;
`

const ListingParametersSpan = styled.span`
  margin-right: 0.5em;
`

const ListingParametersIcon = styled.span`
  color: rgba(0, 0, 0, 1);
  font-weight: 500;
`

const ListingAddress = styled.div`
  max-width: 90%;
  overflow: hidden;
  white-space: nowrap;
  font-size: 0.875em;
`

const ListingShortAddress = styled.div`
  overflow: hidden;
  white-space: nowrap;
  font-size: 0.75em;
`

export const ListingDescription: React.FC<IServerListing> = (
  props: IServerListing
) => {
  const numberFormat = (value: number) =>
    new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    })
      .format(value)
      .replace(/\D00(?=\D*$)/, "")

  return (
    <ListingDetailsWrapper>
      <ListingFlex>
        <ListingmonthlyRent>
          {numberFormat(props.monthlyRent)}
        </ListingmonthlyRent>
        <ListingParameters>
          <ListingParametersSpan>
            {props.bedrooms > 0 ? (
              <span>
                {props.bedrooms}{" "}
                <ListingParametersIcon> bd</ListingParametersIcon>
              </span>
            ) : (
              <span>Studio</span>
            )}
          </ListingParametersSpan>
          <ListingParametersSpan>
            {props.bathrooms} <ListingParametersIcon>ba</ListingParametersIcon>
          </ListingParametersSpan>
          <ListingParametersSpan>
            {props.squareFeet > 0 ? (
              <span>
                {props.squareFeet}{" "}
                <ListingParametersIcon> sqft</ListingParametersIcon>
              </span>
            ) : (
              <span style={{display: "none"}}> hidden</span>
            )}
          </ListingParametersSpan>
          {/* <ListingParametersSpan>
            {props.squareFeet}{" "}
            <ListingParametersIcon>sqft</ListingParametersIcon>
          </ListingParametersSpan> */}
        </ListingParameters>
      </ListingFlex>
      <ListingLineSpacing></ListingLineSpacing>
      <ListingLineSpacing></ListingLineSpacing>
      <ListingAddress>
        {props.address && props.address.slice(0, 35)}{" "}
        {props.address && props.address.length > 34 && "..."}
      </ListingAddress>
      <ListingLineSpacing></ListingLineSpacing>
      <ListingShortAddress>{props.city}</ListingShortAddress>
      <ListingLineSpacing></ListingLineSpacing>
      <ListingLineSpacing></ListingLineSpacing>
    </ListingDetailsWrapper>
  )
}

const ListingCard: React.FC<IServerListing> = (props: IServerListing) => {
  const [open, toggle] = useModal()
  const imgCode = Math.floor(Math.random() * 3)
  const bookmark = useBookmarks()
  const history = useHistory()
  // use postId in future
  const isBookmarked = bookmark.isBookmarked(props.postID)

  const match = useRouteMatch("/savedThikanas")
  const redirect = match && match.isExact ? false : true

  return (
    <>
      <ListingCardWrapper>
        <BookmarkButtonWrapper
          onClick={e => {
            e.stopPropagation()
            if (isBookmarked) {
              bookmark.removeBookmark(props.postID)
            } else bookmark.addBookmark(props)
          }}
          active={isBookmarked}
        ></BookmarkButtonWrapper>
        <div
          onClick={() => {
            if (redirect) history.push("/app/" + props.postID)
            toggle()
          }}
        >
          <ListingImage
            src={
              (props.contentIDs &&
                props.contentIDs.length > 0 &&
                props.contentIDs[0]) ||
              mockImages[imgCode]
            }
          ></ListingImage>
          <ListingLineSpacing></ListingLineSpacing>
          <ListingDescription {...props}></ListingDescription>
        </div>
      </ListingCardWrapper>

      {open && (
        <Modal toggle={toggle} open={open} isLink={redirect}>
          <ExtendedListingCard
            toggle={() => {
              // if (!isBookmarked) history.push("/app")
              toggle()
            }}
            {...props}
          ></ExtendedListingCard>
        </Modal>
      )}
    </>
  )
}

export default ListingCard
