import React from "react";
import styled from "styled-components";
import SwipeableViewWithControls from "./swipeableViewWithControls";
import ListingImage from "../../ListingCard/listingImage";
import { breakpoints } from "../../../../Constants/responsive";
import { colors } from "../../../../Constants/colors";

const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background: ${colors.shadow};
`;

const CloseButton = styled.div`
  position: absolute;
  top: 1em;
  left: 1em;
  width: 2em;
  height: 2em;
  z-index: 1000000;
  cursor: pointer;
  display: block;
  font-size: 1.35em;

  @media (min-width: ${breakpoints.tablet + "px"}) {
    display: none;
  }
`;

const PhotosLabel = styled.div`
  position: absolute;
  top: 1em;
  font-size: 1.3em;
  left: 2.5em;
  z-index: 1000000;
  display: block;
  font-weight: 400;

  @media (min-width: ${breakpoints.tablet + "px"}) {
    left: 1em;
  }
`;

interface IProps {
  images: string[];
  index: number;
  toggle: () => void;
}

const FullScreenImageViewer: React.FC<IProps> = (props: IProps) => {
  return (
    <MainWrapper>
      <CloseButton onClick={props.toggle}>
        <i className="fas fa-chevron-left"></i>
      </CloseButton>
      <PhotosLabel>Photos</PhotosLabel>
      <SwipeableViewWithControls autoPlay={false} index={props.index}>
        {props.images.map((src, i) => (
          <ListingImage key={i} src={src}></ListingImage>
        ))}
      </SwipeableViewWithControls>
    </MainWrapper>
  );
};

export default FullScreenImageViewer;
