import React from "react"
import styled from "styled-components"
import { useFilter, IFilters } from "../../Hooks/useFilter"
import "./secondHeader/radioButton.css"
// import RentSellFilter from "./secondHeader/rentSellFilter";
import PricingFilter from "./secondHeader/pricingFilter"
import BedBathFilter from "./secondHeader/bedBathFilter"
import HomeTypeFilter from "./secondHeader/homeTypeFilter"
import MoreFilter from "./secondHeader/moreFilter"
import { breakpoints } from "../../Constants/responsive"
import SearchBar from "./searchBar/searchBar"
import { colors } from "../../Constants/colors"
import { size } from "../../Constants/sizesAndSpacing"
import { IMapConfig } from "../Listings/ListingMap/listingMap"
import { useHistory } from "react-router-dom"
import { useAuth } from "../../Hooks/useAuth"
import { track } from "../../Utils/analytics"
import useGAEventTracker from "../../Hooks/useGAEventsTracker"

const HeaderBar = styled.div`
  width: 100%;
  background: ${colors.white};
  display: flex;
  justify-content: left;
  align-items: center;
  height: ${size.headerHeight + "px"};
  border-bottom: 0.5px solid ${colors.shadow};
  box-sizing: border-box;
  padding: 0 10px;
  font-size: 14px;
  font-weight: 600;

  @media (min-width: ${breakpoints.mobile + "px"}) {
    font-size: 15px;
  }

  @media (min-width: ${breakpoints.tablet + "px"}) {
    font-size: 16px;
  }
`

const Collapsable = styled.div`
  height: 100%;
  align-items: center;
  // margin-right: 0.625em;
  flex-grow: 1;
  // max-width: 22em;
  display: none;
  // margin-left: 5px;

  @media (min-width: ${breakpoints.mobile + "px"}) {
    display: flex;
  }
`

const SearchWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 0.625em;
  flex-grow: 1;
  max-width: 22em;
`

const MapModeSwitcher = styled.div<{ active: boolean }>`
  display: flex;
  flex-grow: 1;
  max-width: 7em;
  align-items: center;
  justify-content: center;
  height: 60%;
  border-radius: 3px;
  cursor: pointer;
  box-sizing: border-box;
  font-size: 0.9em;
  font-weight: 500;
  color: ${colors.white};

  background: ${colors.primary};

  @media (min-width: ${breakpoints.tablet + "px"}) {
    display: none;
  }
`

const AddListing = styled.div`
  display: flex;
  flex-grow: 1;
  max-width: 8em;
  align-items: center;
  justify-content: center;
  height: 60%;
  border-radius: 3px;
  cursor: pointer;
  box-sizing: border-box;
  font-size: 0.9em;
  font-weight: 500;
  color: ${colors.white};
  // margin-right: -4em;
  margin-left: auto;
  background: ${colors.primary};
`

const HeaderWrapper = styled.div`
  display: block;
  width: 100%;
  z-index: 100000;
`

interface IProps {
  mapStartConfig: IMapConfig
  setMapStartConfig: React.Dispatch<React.SetStateAction<IMapConfig>>
}

const SecondHeader: React.FC<IProps> = (props: IProps) => {
  const filterHook = useFilter()
  const { checkSignin } = useAuth()
  const history = useHistory()

  const filter = filterHook.filters as IFilters

  const GAEventsTracker = useGAEventTracker("Button pressed")

  return (
    <HeaderWrapper>
      <HeaderBar>
        <SearchWrapper>
          <SearchBar
            mapStartConfig={props.mapStartConfig}
            setMapStartConfig={props.setMapStartConfig}
          ></SearchBar>
        </SearchWrapper>
        {/* <RentSellFilter filter={filter}></RentSellFilter> */}
        <PricingFilter filter={filter}></PricingFilter>

        <BedBathFilter filter={filter}></BedBathFilter>
        <HomeTypeFilter filter={filter}></HomeTypeFilter>

        <MoreFilter filter={filter}></MoreFilter>
        <MapModeSwitcher
          active={filterHook.viewMapMode.value}
          onClick={() => {
            filterHook.viewMapMode.set(!filterHook.viewMapMode.value)
          }}
        >
          View {filterHook.viewMapMode.value ? "List" : "Map"}
        </MapModeSwitcher>
        <Collapsable>
          <AddListing
            onClick={() => {
              checkSignin() && history.push("/mylistings")
              GAEventsTracker("Add listing button pressed")
              {/* track("creation", "add listing button pressed") */}
            }}
          >
            <i
              style={{
                marginRight: "5px",
                fontSize: "0.875em",
                transform: "translateY(1px)",
              }}
              className="fa fa-plus"
            ></i>
            Add Listing
          </AddListing>
        </Collapsable>
      </HeaderBar>
    </HeaderWrapper>
  )
}

export default SecondHeader
