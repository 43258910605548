export const getStaticListing = {
  url:
    "https://1thikana.in:8443/thikana/getposts/28.47631165896465/76.97287559509277/999999/0/0",
}

// export const baseURL = "http://65.0.1.89:9090/thikana"
export const baseURL = "https://1thikana.in:8443/thikana"

export const clientURL = "ekthikana.com"

export const s3MediaURL = "https://thikanamedia.s3.ap-south-1.amazonaws.com/"

export const googleClientId = "502915175146-vh7gebbeau15r43bsha2b7b5rv8i2ci5.apps.googleusercontent.com"
export const facebookClientId = "888675624960341"

export const REACT_APP_GA_TRACKING_CODE="UA-191154979-1"
