import React, { useState } from "react"
import { ImCheckboxUnchecked, ImCheckboxChecked } from "react-icons/im"
import styled from "styled-components"
import FilterButton from "./filterButton"
import { IFilters } from "../../../Hooks/useFilter"
import { colors } from "../../../Constants/colors"

const HomeTypeLabel = styled.div`
  font-size: 1em;
  margin-top: 2%;
  margin-bottom: 2%;
`

const HomeTypeList = styled.ul`
  font-weight: 500;
  padding-left: 0;
  font-size: 1em;
  align-content: center;
  min-width: 300px;
  li {
    list-style: none;
    color: ${colors.primary};
    display: flex;
    flex-wrap: nowrap;
    input:checked {
      display: inline;
      color: ${colors.primary};
    }
  }
`

const DoneButton = styled.button`
  font-size: 0.9em;
  padding: 0.5em;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  background: ${colors.primary};
  border: 2px solid ${colors.primary};
  text-align: center;
  border-radius: 2px;
  cursor: pointer;
  color: ${colors.white};
  font-weight: 600;
`

const ResetButton = styled.button`
  font-size: 0.9em;
  padding: 0.5em;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2%;
  width: 100%;
  background: ${colors.white};
  border: 2px solid ${colors.primary};
  text-align: center;
  border-radius: 2px;
  cursor: pointer;
  color: ${colors.primary};
  font-weight: 600;

  &:hover {
    background: ${colors.lightprimary};
  }
`

interface IProps {
  filter: IFilters
}

interface IHomeTypeProps {
  filter: IFilters
  closeDropdown?: () => void
}

const hometype = [
  "Apartments ",
  "Farm House ",
  "House/Villa ",
  "PG/Roommate ",
  "Other ",
]
const HomeType = (props: IHomeTypeProps) => {
  const filter = props.filter
  const settingCheck = (index: string) => {
    setCheck((check: any) => {
      if (check.some((item: string) => item === index)) {
        return (check = check.filter((item: string) => item !== index))
      } else {
        return (check = [...check, index])
      }
    })
  }
  const [check, setCheck] = useState([...filter.hometype.value])

  return (
    <>
      <HomeTypeLabel>Home Type</HomeTypeLabel>
      <HomeTypeList>
        <div
          style={{
            paddingBottom: "0.8em",
            cursor: "pointer",
          }}
          onClick={() => settingCheck("0")}
        >
          {check.some(item => item === "0") ? (
            <ImCheckboxChecked
              style={{
                fontSize: "1.2em",
                marginRight: "0.8em",
                color: "#f46029",
                cursor: "pointer",
              }}
            >
              {" "}
            </ImCheckboxChecked>
          ) : (
            <ImCheckboxUnchecked
              style={{
                fontSize: "1.2em",
                marginRight: "0.8em",
                color: "#f46029",
                cursor: "pointer",
              }}
            ></ImCheckboxUnchecked>
          )}
          Apartments
        </div>
        <div
          style={{
            paddingBottom: "0.8em",
            cursor: "pointer",
          }}
          onClick={() => settingCheck("1")}
        >
          {check.some(item => item === "1") ? (
            <ImCheckboxChecked
              style={{
                fontSize: "1.2em",
                marginRight: "0.8em",
                color: "#f46029",
                cursor: "pointer",
              }}
            >
              {" "}
            </ImCheckboxChecked>
          ) : (
            <ImCheckboxUnchecked
              style={{
                fontSize: "1.2em",
                marginRight: "0.8em",
                color: "#f46029",
                cursor: "pointer",
              }}
            ></ImCheckboxUnchecked>
          )}
          Farm House
        </div>
        <div
          style={{
            paddingBottom: "0.8em",
            cursor: "pointer",
          }}
          onClick={() => settingCheck("2")}
        >
          {check.some(item => item === "2") ? (
            <ImCheckboxChecked
              style={{
                fontSize: "1.2em",
                marginRight: "0.8em",
                color: "#f46029",
                cursor: "pointer",
              }}
            >
              {" "}
            </ImCheckboxChecked>
          ) : (
            <ImCheckboxUnchecked
              style={{
                fontSize: "1.2em",
                marginRight: "0.8em",
                color: "#f46029",
                cursor: "pointer",
              }}
            ></ImCheckboxUnchecked>
          )}
          House/Villa
        </div>
        <div
          style={{
            paddingBottom: "0.8em",
            cursor: "pointer",
          }}
          onClick={() => settingCheck("3")}
        >
          {check.some(item => item === "3") ? (
            <ImCheckboxChecked
              style={{
                fontSize: "1.2em",
                marginRight: "0.8em",
                color: "#f46029",
                cursor: "pointer",
              }}
            >
              {" "}
            </ImCheckboxChecked>
          ) : (
            <ImCheckboxUnchecked
              style={{
                fontSize: "1.2em",
                marginRight: "0.8em",
                color: "#f46029",
                cursor: "pointer",
              }}
            ></ImCheckboxUnchecked>
          )}
          PG/Roommate
        </div>
        <div
          style={{
            paddingBottom: "0.8em",
            cursor: "pointer",
          }}
          onClick={() => settingCheck("4")}
        >
          {check.some(item => item === "4") ? (
            <ImCheckboxChecked
              style={{
                fontSize: "1.2em",
                marginRight: "0.8em",
                color: "#f46029",
                cursor: "pointer",
              }}
            >
              {" "}
            </ImCheckboxChecked>
          ) : (
            <ImCheckboxUnchecked
              style={{
                fontSize: "1.2em",
                marginRight: "0.8em",
                color: "#f46029",
                cursor: "pointer",
              }}
            ></ImCheckboxUnchecked>
          )}
          Other
        </div>
      </HomeTypeList>
      <DoneButton
        onClick={() => {
          if (check.length >= 1) {
            filter.hometype.set([...check])
          }
          props.closeDropdown && props.closeDropdown()
        }}
      >
        Done
      </DoneButton>
      <ResetButton
        onClick={() => {
          filter.reset()
          props.closeDropdown && props.closeDropdown()
        }}
      >
        Reset
      </ResetButton>
    </>
  )
}

const HomeTypeFilter: React.FC<IProps> = (props: IProps) => {
  const filter = props.filter
  const filterType = hometype[Number(filter.hometype.value[0])]
  return (
    <FilterButton collapsable={true}>
      {
        <span>
          {filter.hometype.value.length > 0
            ? filterType.slice(0, 15) + "..."
            : "Home Type"}
        </span>
      }
      <HomeType filter={filter}></HomeType>
    </FilterButton>
  )
}

export default HomeTypeFilter
