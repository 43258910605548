import React from "react";
import styled from "styled-components";

const Img = styled.img`
  width: 100%;
  height: auto;
`;

const Logo = () => {
  return (
    <>
        <Img src="/logo.png" alt="EkThikana"></Img>
    </>
  );
};

export default Logo;
