import React from "react";
import styled from "styled-components";
import { colors } from "../../Constants/colors";
import { breakpoints } from "../../Constants/responsive";
import { size } from "../../Constants/sizesAndSpacing";
import Footer from  "../Footer/footer";

const Wrapper = styled.div`
  height: calc(100% - ${size.headerHeight + "px"});
  overflow-y: scroll;
`;
const Canvas = styled.div`
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 1%;
  margin-bottom: 1%;
`;

const MainHeading = styled.h1`
  color: ${colors.secondary};

  @media only screen and (min-width: ${breakpoints.mobile + "px"}) {
    font-size: 3em;

  }
  @media only screen and (min-width: ${breakpoints.tablet + "px"}) {
    font-size: 3.5em;
  }

  @media only screen and (min-width: ${breakpoints.desktop + "px"}) {
    font-size: 4em;
  }
`;

const TextHeading = styled.h2`
  margin-top: 0.5%;
  margin-bottom: 0.5%;
  color: ${colors.secondary};
  font-weight: 600;

  @media only screen and (min-width: ${breakpoints.mobile + "px"}) {
    font-size: 1.5em;

  }
  @media only screen and (min-width: ${breakpoints.tablet + "px"}) {
    font-size: 1.725em;
  }

  @media only screen and (min-width: ${breakpoints.desktop + "px"}) {
    font-size: 2em;
  }
`;

const TextBody = styled.p`
  margin-top: 1%;
  margin-bottom: 1%;
  color: ${colors.secondary};
  text-align: justify;

  @media only screen and (min-width: ${breakpoints.mobile + "px"}) {
    font-size: 0.875em;

  }
  @media only screen and (min-width: ${breakpoints.tablet + "px"}) {
    font-size: 1em;
  }

  @media only screen and (min-width: ${breakpoints.desktop + "px"}) {
    font-size: 1.2em;
  }
`;

const SpacingBlock = styled.div`
  padding: 2%;
`;

const CookieScreen = () => {
  return (
    <Wrapper>
        <Canvas>
          <MainHeading> Cookie Policy </MainHeading>
                      <TextBody><strong>Effective Date:</strong> January 1, 2021</TextBody>
                      <SpacingBlock></SpacingBlock>
                      <TextHeading>Introduction</TextHeading>
                      <TextBody>
                          This Cookie Policy (<strong>“Cookie Policy”</strong>) explains that We believe in being open and clear about how We Use Your Information.
                          In the spirit of transparency, this Cookie Policy provides detailed Information about how and when We Use Cookies on our Websites.
                          This Cookie Policy applies to any Techlipi product or Services that links to this Policy or incorporates it by reference.
                      </TextBody>
                      <SpacingBlock></SpacingBlock>
                      <TextHeading>What is a Cookie?</TextHeading>
                      <TextBody>
                            Cookies are small pieces of text stored by a website You visit in Your browser and subsequently sent by Your web browser in every request
                            to the Site. A Cookie file is stored in your web browser and allows the Site or a Third-Party to recognize You and make your next visit easier
                            and the Site more useful to you. Essentially, Cookies are a User’s identification card for the EkThikana servers. Cookies allow the Company to
                            serve you better and more efficiently, and to personalize your experience on our Site. Web beacons, tags and scripts may be used in the Site or
                            in emails to help us to deliver Cookies, count visits, understand usage and campaign effectiveness and determine whether an email has been opened
                            and acted upon. We may analyse or perform analysis based on the use of these technologies and generate reports on an individual and aggregated basis.
                      </TextBody>
                      <SpacingBlock></SpacingBlock>
                      <TextHeading>Does EkThikana uses Cookies?</TextHeading>
                      <TextBody>
                            The Company uses Cookies, tags and other technologies when You use EkThikana. Cookies are used to ensure everyone has their best possible experience.
                            Cookies also help us keep your account safe. By continuing to visit or use our Services, You are agreeing to the use of Cookies and similar technologies
                            for the purposes We describe in the Cookie Policy. If you prefer not to receive Cookies or web beacons, then you should stop using our Site, or consult
                            your browsing and Third Party Cookie settings as described below.
                        </TextBody>
                        <SpacingBlock></SpacingBlock>
                        <TextHeading>What are Cookies used for?</TextHeading>
                        <TextBody>
                              When You visit our Websites, We may place a number of Cookies in your browser. These are known as First Party Cookies and are required to enable to hold
                              session information as you navigate from page to page within the Site. For example, We use Cookies on our Site to understand visitor and user preferences,
                              improve their experience, and track and analyse usage, navigational and other statistical information. Additionally, Cookies allow Us to bring You
                              Advertising both on and off Site, and bring customized features to You. You can control the use of Cookies at the individual browser level. If You elect
                              not to activate the Cookie or to later disable Cookies, You may still visit EkThikana, but your ability to use some features or areas of EkThikana may be
                              limited.
                          </TextBody>
                          <SpacingBlock></SpacingBlock>
                          <TextHeading>What are the different Cookies?</TextHeading>
                      <TextBody>
                        <ol>
                          <li>
                            <strong>Essential Cookies: </strong>Essential cookies (First Party Cookies) are sometimes called <i>“strictly necessary”</i> as without them We cannot provide many
                            Services that You need on the Site. For example, essential Cookies help remember Your preferences as You move around the Site. Essential Cookies also keep You
                            logged in into a Site. Without them the login functionality would not work.
                          </li>
                          <br></br>
                          <li>
                            <strong>Functionality or Preference Cookies: </strong> During your visit to EkThikana, Cookies are used to remember Information You have entered or choices
                            You make (such as your username, language or your region) on EkThikana. They also store your preferences when personalizing the Site to optimize your use of
                            EkThikana. These preferences are remembered, through the use of the persistent Cookies, and the next time you visit EkThikana You will not have to set them again.
                          </li>
                          <br></br>
                          <li>
                            <strong>Targeting or Advertising Cookies: </strong> These Cookies are placed by Third Party Advertising platforms or networks or EkThikana in order to, deliver
                            Advertisements and track Advertising performance, enable Advertising networks to deliver Advertisements that may be relevant to You based upon your activities
                            (this is sometimes called <i>“behavioural” “tracking”</i> or <i>“targeted”</i> Advertising) on EkThikana. They may subsequently use Information about Your
                            visit to target You with Advertising that You may be interested in, on EkThikana or other websites. For example, these Cookies remember which browsers have
                            visited the Sites.
                          </li>
                          <br></br>
                          <li>
                            <strong>Analytics Cookies: </strong> These Cookies track Information about visits to EkThikana and partner websites so that We can make improvements and
                            report our performance. For example: analyse visitor and User behaviour so as to provide more relevant Content or suggest certain activities. They collect
                            Information about how visitors use the Site, which Site the User came from, the number of each User’s visits and how long a User stays on EkThikana. We might
                            also use analytics Cookies to test new ads, pages, or features to see how Users react to them.
                          </li>
                        </ol>
                      </TextBody>
                      <SpacingBlock></SpacingBlock>
        </Canvas>
        <Footer></Footer>
    </Wrapper>

  );
};

export default CookieScreen;
