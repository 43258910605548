import React, { useState } from "react";
import styled from "styled-components";
import { breakpoints } from "../../../../Constants/responsive";
import ListingImage from "../../ListingCard/listingImage";
import SwipeableViewWithControls from "./swipeableViewWithControls";
import useModal from "../../../../Hooks/useModal";
import Modal from "../../../Modal/modal";
import FullScreenImageViewer from "./fullScreenImageViewer";

const MainWrapper = styled.div`
  width: 100%;
 
  @media (min-width: ${breakpoints.mobile + "px"}) {
    display: none;
  }
`;

const ImgWrapper = styled.div`
  width: 100%;
`;

interface IProps {
  images: string[];
}

const MobileImageViewer: React.FC<IProps> = (props: IProps) => {
  const [open, toggle] = useModal();
  const [tappedOn, changeTappedOn] = useState(0);

  return (
    <>
      <MainWrapper>
        <SwipeableViewWithControls>
          {props.images.map((src, i) => {
            return (
              <ImgWrapper
                key={i}
                onClick={() => {
                  changeTappedOn(i);
                  toggle();
                }}
              >
                <ListingImage paddingTop={50} src={src}></ListingImage>
              </ImgWrapper>
            );
          })}
        </SwipeableViewWithControls>
      </MainWrapper>
      {open && (
        <Modal open={open} toggle={toggle} zIndex={10000}>
          <FullScreenImageViewer
            toggle={toggle}
            images={props.images}
            index={tappedOn}
          ></FullScreenImageViewer>
        </Modal>
      )}
    </>
  );
};

export default MobileImageViewer;
