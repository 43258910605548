import React from "react";
import styled from "styled-components";
import { colors } from "../../Constants/colors";
import { breakpoints } from "../../Constants/responsive";
import { size } from "../../Constants/sizesAndSpacing";
import Footer from  "../Footer/footer";
import { NavLink } from "react-router-dom";

const Wrapper = styled.div`
  height: calc(100% - ${size.headerHeight + "px"});
  overflow-y: scroll;
`;
const Canvas = styled.div`
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 1%;
  margin-bottom: 1%;
`;

const MainHeading = styled.h1`
  color: ${colors.secondary};

  @media only screen and (min-width: ${breakpoints.mobile + "px"}) {
    font-size: 3em;

  }
  @media only screen and (min-width: ${breakpoints.tablet + "px"}) {
    font-size: 3.5em;
  }

  @media only screen and (min-width: ${breakpoints.desktop + "px"}) {
    font-size: 4em;
  }
`;

const TextHeading = styled.h2`
  margin-top: 0.5%;
  margin-bottom: 0.5%;
  color: ${colors.secondary};
  font-weight: 600;

  @media only screen and (min-width: ${breakpoints.mobile + "px"}) {
    font-size: 1.5em;

  }
  @media only screen and (min-width: ${breakpoints.tablet + "px"}) {
    font-size: 1.725em;
  }

  @media only screen and (min-width: ${breakpoints.desktop + "px"}) {
    font-size: 2em;
  }
`;

const TextBody = styled.p`
  margin-top: 1%;
  margin-bottom: 1%;
  color: ${colors.secondary};
  text-align: justify;

  @media only screen and (min-width: ${breakpoints.mobile + "px"}) {
    font-size: 0.875em;

  }
  @media only screen and (min-width: ${breakpoints.tablet + "px"}) {
    font-size: 1em;
  }

  @media only screen and (min-width: ${breakpoints.desktop + "px"}) {
    font-size: 1.2em;
  }
`;

const SpacingBlock = styled.div`
  padding: 2%;
`;

const TermsScreen = () => {
  return (
    <Wrapper>
      <Canvas>
          <MainHeading> Terms and Conditions </MainHeading>
          <TextBody><strong>Updated:</strong> January 1, 2021</TextBody>
          <SpacingBlock></SpacingBlock>
          <TextBody>
              Techlipi LLP is a Limited Liability Partnership, incorporated under the extant provisions of the laws of India having LLPIN AAU-8708 (<strong>“Techlipi”</strong>). EkThikana is the flagship brand of Techlipi (<strong>“EkThikana”</strong>), having its registered office at House No.766, Sector 4, Gurugram - 122006, Haryana. <strong>EkThikana</strong>, as used herein, shall be construed as a collective reference to the Site <NavLink to="/homepage" style={{color: "blue"}}>www.ekthikana.com</NavLink>, along with its sub-pages, and all other Services.
          </TextBody>
          <SpacingBlock></SpacingBlock>
          <TextBody>
            <TextHeading>Acceptance of Terms and Conditions</TextHeading>
            <ol type="i">
              <li>
                Thank you for choosing EkThikana. These Terms and Conditions, set out hereunder, are intended to make the User aware of their legal rights and responsibilities with respect to their access to, and Use of, the EkThikana.
              </li>
              <br></br>
              <li>
                The User is advised to carefully read these Terms and Conditions. By accessing or using EkThikana, the User agrees to these Terms and Conditions, thereby concluding a legally binding contract.
              </li>
              <br></br>
              <li>
                The User may not be able to Use EkThikana and/or any Services if they does not accept these terms and conditions and/or is unable to be bound by the same. In order to avail of the Services, the User must first agree to these Terms and Conditions, by:
              </li>
              <br></br>
              <ul style={{listStyleType: "disc"}}>
                <li>
                  Clicking to accept and agree to the Terms and Conditions, where it is made available by the Company to its Users in the user interface for any particular Service(s); and/or
                </li>
                <br></br>
                <li>
                  By actually using the Services
                </li>
                <br></br>
              </ul>
              <li>
                The Company may vary, amend, change and/or update the Terms and Conditions, from time to time, at its sole discretion. The User shall be responsible for checking the Terms and Conditions from time to time, and to ensure continued compliance thereof. The User’s use of EkThikana after any such change(s) shall be deemed as express acceptance of the changed / updated terms and conditions.
              </li>
              <br></br>
              <li>
                The Company may vary, amend, change and/or update the Terms and Conditions, from time to time, at its sole discretion. The User shall be responsible for checking the Terms and Conditions from time to time, and to ensure continued compliance thereof. The User’s use of EkThikana after any such change(s) shall be deemed as express acceptance of the changed / updated terms and conditions.
              </li>
              <br></br>
              <li>
                The Company may provide a translation of the English version of the Terms and Conditions. The User understands and agrees that any translation of these Terms and Conditions into any other language is solely for the convenience of the User(s), and the English version only shall govern the relationship between the User and the Company. Further, in case of any inconsistencies between the English version and any of the translated version(s), the English version shall prevail.
              </li>
            </ol>
          </TextBody>
          <SpacingBlock></SpacingBlock>
          <TextBody>
            <TextHeading>Definitions</TextHeading>
            <br></br>
            Words and phrases used in this document shall have the meaning assigned to them under this Clause:
            <ol type="i">
              <li>
                <strong>Account</strong> shall mean and include the account created on EkThikana by a User, in accordance with the terms of the Agreement, registered with and approved by the Company;
              </li>
              <br></br>
              <li>
                <strong>Advertiser</strong> shall mean and include any such Person who pays revenue to the Company for hosting their Advertisement;
              </li>
              <br></br>
              <li>
                <strong>Advertisement</strong> shall mean and include any/all advertisements, promotions, offers, etc. which may be hosted by the Company on EkThikana from any Third-Party Service Provider;
              </li>
              <br></br>
              <li>
                <strong>Agreement </strong> shall mean and include the Terms and Conditions, the <NavLink to="/privacy-policy" style={{color: "blue"}}>Privacy Policy</NavLink>, the <NavLink to="/listing-conditions" style={{color: "blue"}}>Listing Conditions</NavLink>, and/or any other such terms and conditions that may be mutually agreed upon between the Company and the User in relation to the use of, and access to, EkThikana;
              </li>
              <br></br>
              <li>
                <strong>Applicable Law </strong> shall mean and include the relevant laws, rules, regulations, notifications, ordinances, byelaws, guidelines, directions, policies, directives, memorandums, etc. that are issued by the concerned department, tribunal, other Government Authority, court(s), state/central government(s) in the Republic of India;
              </li>
              <br></br>
              <li>
                <strong>Company </strong> shall mean and include Techlipi LLP, as well as, its partners, associates, affiliates, subsidiaries, successors, assignees and/or associates;
              </li>
              <br></br>
              <li>
                <strong>Confidential Information </strong> shall mean and include without limitation, all non-public information, including financial, commercial, scientific and/proprietary information shared/furnished or any other non-public information to which the User gains access or which is disclosed or made available by EkThikana to it, directly or indirectly, whether in writing, oral, graphic, visual or any other tangible, intangible or electronic form, or any other medium or format, or any Information which due to the nature of the Information or the circumstances surrounding disclosure ought to be treated as confidential. Confidential Information for the purposes of this Agreement, shall include, without any limitation, the following:
              </li>
              <br></br>
              <ul style={{listStyleType: "disc"}}>
                <li>
                  Any marketing strategies, plans, financial information, or projections, operations, sales estimates, business plans and performance results business activities of the Company (past, present or future/prospective), its affiliates, subsidiaries and affiliated companies;
                </li>
                <br></br>
                <li>
                  Any specifications, strategies, Data, designs, drawings, blueprints, sketches, drafts, schemes, software, models, prototypes, proposal, outline, plot, financial information, technologies, technical and business strategies;
                </li>
                <br></br>
                <li>
                  Intellectual property rights, intentions, concepts, prospects, structures, processes, ideas, trade secrets and know how, designs, market information, sales statistics and other commercial information owned by/assigned/licensed to the Company;
                </li>
                <br></br>
                <li>
                  Plans for products or Services, and customer or supplier lists;
                </li>
                <br></br>
                <li>
                  Any economic, scientific or technical information, invention, design, process, procedure, formula, improvement, technology or method;
                </li>
                <br></br>
                <li>
                  Any concepts, reports, Data, know-how, works-in-progress, designs, development tools, specifications, computer software, source code, object code, flow charts, databases, inventions, Information and trade secrets;
                </li>
                <br></br>
                <li>
                  Any other Information of or other affairs of the Company or any Third-Party, memorandum, drafts of agreements or other materials prepared in, whether furnished before or after the date of this Agreement; and
                </li>
                <br></br>
                <li>
                  Any other Information that should reasonably be recognized as Confidential Information of the Company.
                </li>
                <br></br>
              </ul>
              <li>
                <strong>Content </strong> shall include (but not limited to) reviews, images, photos, audios, videos, location data, and any / all forms of Information or Data that is generated/hosted/created/displayed on EkThikana. The Content shall further be categorized as follows:
              </li>
              <br></br>
              <ul style={{listStyleType: "disc"}}>
                <li>
                  <strong>EkThikana Content</strong> means the Content that is created by the Company and made available in connection with the Services including, but not limited to, visual interfaces, interactive features, graphics, design, compilation, computer code, products, software, aggregate ratings, reports and other usage related Data, in connection with the activities associated with the Users’ Accounts, and all other elements and components of service, excluding the User Content and Third-Party Content.
                </li>
                <br></br>
                <li>
                  <strong>Third-Party Content </strong> means Content that comes from parties other than the Company or its Users and is available for their service.
                </li>
                <br></br>
                <li>
                  <strong>User Content </strong> means the Content that the User uploads, shares or transmits to, through or in connection with the Services, such as profile information, photos, videos, reviews, comments, feedback and/or any other material which is publicly displayed by the User.
                </li>
                <br></br>
              </ul>
      <li>
                <strong>“Cookie” </strong> is a text file that is stored in Your computer and enables us to recognize when You use EkThikana, store User settings and preferences, enhance User experience by delivering Content, advertise Content specific to User interests, perform research and analytics, track User use of the Company’s Services, and to assist with security and administrative functions;
              </li>
              <br></br>
      <li>
                <strong>Data </strong> shall have the same meaning as assigned to it in Section 2(o) of the Information and Technology Act;
              </li>
              <br></br>
              <li>
                <strong>EkThikana </strong> as used herein shall be construed as a collective reference to the Site <NavLink to="/homepage" style={{color: "blue"}}>www.ekthikana.com</NavLink>, along with its subpages and all other Services provided by the Company;
              </li>
              <br></br>
              <li>
                <strong>Estate Agent </strong> shall have the same meaning assigned to ‘Real Estate Agent’ under the RERA Act and any rules, regulations, guidelines, etc. made thereunder, and and/all amendments made thereto;
              </li>
              <br></br>
              <li>
                <strong>General User </strong> shall mean and include any User who accesses EkThikana, without the need or a requirement to create an Account;
              </li>
              <br></br>
              <li>
                <strong>Government Authority </strong> shall mean and include any government, statutory, regulatory, administrative, judicial, or quasi-judicial body or organization, or any government authority, department, or instrumentality, exercising executive, legislative, judicial, regulatory or administrative functions, having jurisdiction over the Agreement and the Services contemplated under the Agreement;
              </li>
              <br></br>
      <li>
                <strong>Information </strong> shall have the meaning assigned to it as per Section 2(v) of the Information Technology Act;
              </li>
              <br></br>
              <li>
                <strong>Information Technology Act </strong> shall mean and include the Information Technology Act, 2000, as amended from time to time, along with the rules, regulations, byelaws, guidelines, circulars, etc. made thereunder;
              </li>
              <br></br>
              <li>
                <strong>Intellectual Property </strong> shall mean and include patents, inventions, know how, trade secrets, trademarks, service marks, designs, tools, devices, models, methods, procedures, processes, systems, principles, algorithms, works of authorship, flowcharts, drawings, and other confidential and proprietary information, Data, documents, instruction manuals, records, memoranda, notes, user guides, ideas, concepts, Information, materials, discoveries, developments, and other copyrightable works, and techniques in either printed or machine-readable form, whether or not copyrightable or patentable, each of which is not in the public domain or would by its very nature fall within public domain;
              </li>
              <br></br>
              <li>
                <strong>Intellectual Property Rights </strong> shall mean and include:
              </li>
              <br></br>
              <ul style={{listStyleType: "disc"}}>
                <li>
                  All right, title, and interest under, including but not limited to, patent, trademark, copyright under the Patents Act, 1970, Trademarks Act, 1999 and Copyright Act, 1957 respectively; any statute or under common law including patent rights; copyrights including moral rights; and any similar rights in respect of Intellectual Property, anywhere in the world, whether negotiable or not;
                </li>
                <br></br>
                <li>
                  Any licenses, permissions and grants in connection therewith;
                </li>
                <br></br>
                <li>
                  Applications for any of the foregoing and the right to apply for them in any part of the world;
                </li>
                <br></br>
                <li>
                  Right to obtain and hold appropriate registrations in Intellectual Property;
                </li>
                <br></br>
                <li>
                  All extensions and renewals thereof;
                </li>
                <br></br>
                <li>
                  Causes of action in the past, present or future, related thereto including the rights to damages and profits, due or accrued, arising out of past, present or future infringements or violations thereof and the right to sue for and recover the same;
                </li>
                <br></br>
                <li>
                  Any Confidential Information.
                </li>
                <br></br>
              </ul>
              <li>
                <strong>Intermediary </strong> under this Agreement, shall mean the Company, in relation to hosting or transmitting any User or Third-Party Content, and shall have the meaning assigned under Section 2(w) of the Information Technology Act, 2000;
              </li>
              <br></br>
              <li>
                <strong>Listing </strong> under this Agreement, shall mean the listing of a property by a Subscriber on EkThikana;
              </li>
              <br></br>
              <li>
                <strong>Listing Conditions </strong> under this Agreement shall mean the terms and conditions relating to Listing as available at <NavLink to="/listing-conditions" style={{color: "blue"}}>www.ekthikana.com/listing-conditions</NavLink> and shall be read in addition to, and in conjunction with, these Terms and Conditions;
              </li>
              <br></br>
              <li>
                <strong>Listing Form </strong> shall mean the form required to be filled out by a Subscriber while posting any property on EkThikana, and include details such as property location (latitude and longitude), address, city, state, property type, property description, bedrooms, bathrooms, amenities, year of built, square footage, furnishing, lease duration, lease summary, security deposit, monthly rent, date of availability, property photographs, etc.;
              </li>
              <br></br>
              <li>
                <strong>Person </strong> shall mean and include any natural person, corporation, trust, association, joint venture, government entity and/or any other entity and in case of a natural person shall include his legal representatives, administrators, executors and heirs and in case of a trust shall include the trustee or the trustees for the time being;
              </li>
              <br></br>
              <li>
                <strong>Personal Information </strong> shall mean and include details of the User such as name, email address, phone number, profile picture, age, biometric information, financial information such as bank account, debit card/credit card details and such other sensitive Information of the User as would be covered under Rule 2(i) of the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011;
              </li>
              <br></br>
              <li>
                <strong>Privacy Policy </strong> shall mean the privacy policy of EkThikana as available at <NavLink to="/privacy-policy" style={{color: "blue"}}>www.ekthikana.com/privacy-policy</NavLink>;
              </li>
              <br></br>
              <li>
                <strong>RERA </strong> shall mean the Real Estate Regulatory Authority constituted under the RERA Act;
              </li>
              <br></br>
              <li>
                <strong>RERA Act </strong> shall mean and include the Real Estate (Regulation & Development) Act, 2016, as amended from time to time, along with the rules, regulations, byelaws, guidelines, circulars, etc. made thereunder;
              </li>
              <br></br>
              <li>
                <strong>Service </strong> shall mean any and all services provided by the Company, within the territory of India to the User, and includes, without limitation, the following:
              </li>
              <br></br>
              <ul style={{listStyleType: "disc"}}>
                <li>
                  connecting tenants and owners to find each other without paying any brokerage,
                </li>
                <br></br>
                <li>
                  services to the Users who wish to post their Listings for the purposes of renting their property,
                </li>
                <br></br>
                <li>
                  services to the Users who wish to secure/buy a property through the Site,
                </li>
                <br></br>
                <li>
                  services to the Users pertaining to paperwork and documentation processing, relating to lease agreement registration, bank franking, police verification and society approvals with regard to the rental property listed on its website,
                </li>
                <br></br>
                <li>
                  services to those who wish to purchase Advertisement space of their products or services on the Site,
                </li>
                <br></br>
                <li>
                  services to the Users who wish to receive Advertisements and promotional messages on the Site and through e-mails and text messages,
                </li>
                <br></br>
                <li>
                  services to the Users allowing them to pay rental money to the other Users through the Site using Third Party payment gateways, and
                </li>
                <br></br>
                <li>
                  connecting the Users to various Third-Party Service Providers for the provision of other related services
                </li>
                <br></br>
              </ul>
              <li>
                <strong>Site </strong>shall mean and include the website owned and operated by the Company, at the URL <NavLink to="/homepage" style={{color: "blue"}}>www.ekthikana.com</NavLink> and its sub-pages;
              </li>
              <br></br>
              <li>
                <strong>Subscriber </strong> shall mean and include any User who creates and maintains an Account on EkThikana, on a paid or unpaid basis;
              </li>
              <br></br>
              <li>
                <strong>Terms and Conditions </strong> shall mean and include the present Terms and Conditions, along with any amendment and/or modification made thereto. The Terms and Conditions shall govern the access or use by any User, of EkThikana and the Content and Services made available by the Company;
              </li>
              <br></br>
              <li>
                <strong>Third Party </strong> shall mean and include any Person except the Users of EkThikana and the Company;
              </li>
              <br></br>
              <li>
                <strong>Third-Party Service </strong> shall mean and include any Service offered or provided on EkThikana by any Third Party;
              </li>
              <br></br>
              <li>
                <strong>Third-Party Service Providers </strong> shall mean and include any service provider with whom the Company has an agreement to provide Third Party Content and Services on EkThikana, in addition to the Content and Services made available by the Company to the User;
              </li>
              <br></br>
              <li>
                <strong>Unauthorized User </strong> shall mean and include any Person who is not competent to enter into contract, in accordance with Section 11 of the Indian Contract Act, 1872;
              </li>
              <br></br>
              <li>
                <strong>Use </strong> in relation to Content, shall mean and include the use, copy, display, distribution, modification, translation, reformatting, incorporation into Advertisement and other works, analysis, promotion, commercialization, and creation of derivative works;
              </li>
              <br></br>
              <li>
                <strong>User </strong> refers to any Person accessing or using EkThikana who may either be a General User or a Subscriber;
              </li>
              <br></br>
              <li>
                <strong>User Information </strong> shall mean and include Personal Information, User Content and/or any Data, detail, Information in relation to the User, made available to the Company by the User, either through EkThikana, by acceptance, by any form of communication, or otherwise, as agreeable to the Company and the User.
              </li>
              <br></br>
            </ol>
            </TextBody>
            <SpacingBlock></SpacingBlock>
            <TextBody>
              <TextHeading>Interpretation</TextHeading>
              Unless the context otherwise requires or a contrary indication appears:
              <ol type="i">
                <li>
                  Reference to any legislation or law shall include references to any such legislation or law as it may from time to time be amended, supplemented or re-enacted, and any reference to a statutory provision shall include any subordinate legislation made from time to time under that provision, including, rules, regulations, byelaws, circulars, guidelines, etc.
                </li>
                <br></br>
                <li>
                  Headings to sections and clauses are for ease of reference only and shall be ignored in the construction of the relevant Sections and Clauses.
                </li>
                <br></br>
                <li>
                  The singular includes the plural and vice versa and words importing a particular gender include all genders.
                </li>
                <br></br>
                <li>
                  The words <i>‘other’</i>, <i>‘otherwise’</i> and <i>‘whatsoever’</i> will not be construed ejusdem generis or be construed as any limitation upon the generality of any preceding words or matters specifically referred to.
                </li>
                <br></br>
              </ol>
          </TextBody>
          <SpacingBlock></SpacingBlock>
          <TextBody>
            <TextHeading>Use and Access of the EkThikana</TextHeading>
            <ol type="i">
              <li>
                Any User by accessing or using any of the Services offered or proposed to be offered by the Company, agrees to be bound by the Agreement, as may be amended or modified from time to time by the Company.
              </li>
              <br></br>
              <li>
                Use or access by any Unauthorized User will be deemed Prohibited Conduct and the Company will not be responsible for any claims/losses/liabilities to such Unauthorized User, and/or any other Person, User or Third Party, on account of such Use or access by the Unauthorized User.
              </li>
              <br></br>
              <li>
                In order to fully Use and access all of the features and Services offered by the Company, a User must create and maintain an Account by submitting the requisite Personal Information to the Company.
              </li>
              <br></br>
              <li>
                The Company shall have the sole discretion and shall be entitled to modify the Terms and Conditions in relation to Use and access to EkThikana. The Company may also notify the User of any such change / modification by way of sending an e-mail/SMS to the User’s registered e-mailing address/phone number and/or by posting notification in this regard on the User’s Account. If the User fails to indicate their acceptance to the same, within a period of 14 (Fourteen) day, it shall be deemed that the User has accepted the amended/modified Terms and Conditions.
              </li>
              <br></br>
              <li>
                Some of the Services being provided by EkThikana may be subject to specific rules, regulations, conditions and terms of use of service set down in that respect, which are also binding on the User and must be read along with the Terms and Conditions. In case of any inconsistencies / repugnancies of the Terms and Conditions, the latter shall prevail.
              </li>
              <br></br>
              <li>
                The Company, at its sole and absolute discretion, reserves the right to:
              </li>
              <br></br>
              <ul style={{listStyleType: "disc"}}>
                <li>
                  Restrict, suspend, or terminate any User’s access to all or any part of EkThikana;
                </li>
                <br></br>
                <li>
                  Change, suspend, or discontinue all or any part of the Services;
                </li>
                <br></br>
                <li>
                  Reject, move, or remove any material that may be submitted by a User;
                </li>
                <br></br>
                <li>
                  Modify or delete any Content or Services that are available on EkThikana;
                </li>
                <br></br>
                <li>
                  Deactivate or delete an Account and all related Information and files on that Account;
                </li>
                <br></br>
                <li>
                  Establish and regulate the general practices and limits of Users concerning the Use of and access to EkThikana;
                </li>
                <br></br>
                <li>
                  Forward any complaint or information, made available to the Company, which in its opinion would amount to a  violation/breach, or suspected violation/breach, of any Applicable Law by a User to the concerned Government Authority, within whose jurisdiction such violation/breach would fall.
                </li>
                <br></br>
              </ul>
              <li>
                Nothing contained in the Agreement shall limit the Company’s duty to comply with governmental, court, and law-enforcement requests or requirements relating to the User’s Use of EkThikana. The Company shall not be held responsible for any Content contributed by Users on the EkThikana, and for any loss/ damage on account of the same.
              </li>
              <br></br>
              <li>
                The Company reserves the right to charge its Users a fee, in respect of Use or access to any Services, at any time during the subsistence of the Agreement.
              </li>
              <br></br>
              <li>
                The Company, may from time to time, conduct and/or organize surveys, promotions and offers on EkThikana, on such terms and conditions, as it may deem fit.
              </li>
              <br></br>
              <li>
                The User consents to receiving notifications / communications such as updates, announcements, administrative messages and Advertisements from the Company.
              </li>
              <br></br>
            </ol>
        </TextBody>
        <SpacingBlock></SpacingBlock>
        <TextBody>
          <TextHeading>Representations and Warranties of User(s)</TextHeading>
          <ol type="i">
            <li>
              The User warrants that he/she is above of age of eighteen (18) years, and is competent to enter into the Agreement, in accordance with Section 11 of the Indian Contract Act, 1872. In the event that it is brought to the notice of the Company that a User is an Unauthorized User, the Company is empowered to block access to and Use of EkThikana by such Unauthorized User. The Company may, at its discretion, deactivate any Account created by an Unauthorized User and delete any Content generated by, or related to, such an Account.
            </li>
            <br></br>
            <li>
              The User represents and warrants that is shall abide by the Agreement and Applicable Laws, as may be amended from time to time, failing which, the Company may, at its sole and absolute discretion, take necessary remedial action against the User, including but not limited to:
            </li>
            <br></br>
            <ul style={{listStyleType: "disc"}}>
              <li>
                restricting, suspending, or terminating a User’s access to or use of EkThikana; and/or
              </li>
              <br></br>
              <li>
                deactivating or deleting the Account and all related Information and Content associated with such Account; and/or
              </li>
              <br></br>
              <li>
                reporting any violation of any Applicable Law by the User to the Government Authority concerned.
              </li>
              <br></br>
            </ul>
            <li>
              The User represents and warrants that it shall not attempt to gain unauthorized access to their Account, or the servers or networks connected to EkThikana by any means other than the user interface provided by the Company, including but not limited to, by circumventing or modifying, attempting to circumvent or modify, or encouraging or assisting any other Person to circumvent or modify, any security, technology, device, or software that underlies or is part of the Services.
            </li>
            <br></br>
            <li>
              User represents and warrants that they owns all Intellectual Property Rights in the User’s Content and that no part of the User’s Content infringes any Third-Party rights. Users further confirm and undertake to not display or use the names, logos, marks, labels, trademarks, copyrights or any other Intellectual and proprietary rights of any Third Party on EkThikana.
            </li>
            <br></br>
            <li>
              The User represents and warrants that any material, Information and Data accessed, downloaded or otherwise obtained through the Services, is done at the User’s own discretion and risk. The User will be solely responsible for any potential or actual damage to the User’s computer system,  devices or loss of Data that results from the User’s download of any such material, and the Company shall not be liable for the same.
            </li>
            <br></br>
            <li>
              The User represents and warrants that the User assumes certain risks prior to, while, or after interacting with other Users, Third-Party Service Providers, or any other Person, on and through the Site, and the User shall be solely responsible for such User’s personal assessment of specific requirements with regard to interaction with other Users/ Persons/ Third-Party Service Providers.
            </li>
            <br></br>
            <li>
              The User represents and warrants that its Use of EkThikana is in compliance with Applicable Laws.
            </li>
            <br></br>
            <li>
              The User represents and warrants that it shall neither make nor permit nor authorize the making of any public announcement, including press statements, or statements on the internet or on any other form of media and/or any disclosure of any nature whatsoever to any Person concerning the Agreement, without the prior written permission of the Company.
            </li>
            <br></br>
            <li>
              The User represents and warrants that it will provide accurate details of their Personal Information, when so requested by the Company, so as to ensure that they can receive communications from the Company by way of e-mails/SMSs, on their registered email address or telephone number.
            </li>
            <br></br>
            <li>
              The User represents and warrants that it will add the Company to their <i>‘safe senders’ </i> list. The Company shall not be held liable if any e-mail/SMS remains unread by a User as a result of such e-mail getting delivered to the User’s junk or spam folder.
            </li>
            <br></br>
            <li>
              The User represents and warrants to grant access to the Company to collect information about their IP Address, physical location (by clicking to allow) and such other Data as may be collected by the Company, for the purposes of its research and development, such as pages visited on its Site, time of visit, type of web browser or operating system, etc. which shall be stored locally on the Company’s servers.
            </li>
            <br></br>
            <li>
              The User represents and warrants that in order to avail of certain features/Services on EkThikana, they will be required  to furnish certain Personal Information to the Company. The User represents and warrants that any Personal Information furnished by it to the Company is accurate, up to date and genuine. In the event of any change in particulars provided, the User represents and warrants that it shall notify the Company and/or review, modify and update their Information.
            </li>
            <br></br>
            <li>
              The User represents and warrants that in case the User signs up using Google or any social media platform, the Company shall capture the name, e-mail address, phone number and profile picture, as may be available and retrieved by the Company through Google, Facebook or any other social media platform.
            </li>
            <br></br>
          </ol>
        </TextBody>
        <SpacingBlock></SpacingBlock>
        <TextBody>
          <TextHeading>Representations and Warranties of Subscriber(s)</TextHeading>
          In addition to the representations and warranties of a User, contained in Clause Representations and Warranties of User(s) hereinabove, every Subscriber also represents and warrants as under:
          <ol type="i">
            <li>
              The Subscriber represents and warrants that it shall neither create nor operate more than 1 (One) Account on EkThikana. The operation of multiple Accounts is prohibited, and the Company may take appropriate steps, as it may deem fit, for deactivation/deletion of any additional Accounts, in addition to any penalty/legal action that may be taken against the Subscriber.
            </li>
            <br></br>
            <li>
              The Subscriber represents and warrants that it may only post a Listing on EkThikana, if the Subscriber is the owner, or the power-of-attorney holder on behalf of the owner, or has acquired/received all necessary rights and authorizations from the owner/ power-of-attorney holder in writing, of such property. The Subscriber further represents and warrants that in the event the property in question is under joint ownership or has been licensed/assigned to any other Person by the lawful owner, the Subscriber shall obtain the consent, in writing, of all owners/stakeholders/licensees/assignees of the property, as the case may be, before posting/publishing the Listing.
            </li>
            <br></br>
            <li>
              The Subscriber represents and warrants that it is the owner of, or licensee/assignee of any Content including from the photographer and/or copyright owner of any photographs, to publish and advertise any Listing of a property(s) on EkThikana.
            </li>
            <br></br>
            <li>
              The Subscriber represents and warrants that it shall not reveal any password assigned by the Company, to a Subscriber’s Account, to anyone else.  Subscribers shall not use anyone else’s password and shall be responsible for maintaining the confidentiality of their Accounts and passwords. The Subscribers agree to immediately notify the Company of any unauthorized use of their passwords or Accounts or any other breach of security.
            </li>
            <br></br>
            <li>
              The Subscriber represents and warrants that they shall exit/log-out of their Account at the end of each session. The Company shall not be responsible for any loss or damage that may result if the User fails to comply with these requirements.
            </li>
            <br></br>
            <li>
              The Subscriber represents and warrants that they shall be allowed to post Listing in relation to any property by filling out the complete details required under the Listing Form. The Subscriber agrees that the Company shall store such Information on its Platform and Site, while the Subscriber posts any Listing for property.
            </li>
            <br></br>
            <li>
              The Subscriber represents and warrants that they shall be solely responsible for the correctness, veracity and accuracy of the Listing posted by it and the Company shall not be held responsible for any inaccuracy in any such Listing, including details of property, area, photographs, etc.
            </li>
            <br></br>
            <li>
              The Subscriber further represents and warrants that it will adhere to the <NavLink to="/listing-conditions" style={{color: "blue"}}>Listing Conditions</NavLink>.
            </li>
            <br></br>
          </ol>
        </TextBody>
        <SpacingBlock></SpacingBlock>
        <TextBody>
          <TextHeading>Use of Information</TextHeading>
          <ol type="i">
            <li>
              The Company undertakes that the procurement, storage, usage and dissemination of all Information including User Information and/or Content, as the case may be, pursuant to this Agreement, shall at all times, including upon expiration of the Services or termination of the Agreement with the User, be in accordance with the Information Technology Act, the rules made thereunder and any other Applicable Laws.
            </li>
            <br></br>
            <li>
              The User hereby irrevocably and unequivocally authorizes the Company to utilize User Information for the purposes including those set out below:
            </li>
            <br></br>
            <ul style={{listStyleType: "disc"}}>
              <li>
                providing Services in accordance with the Agreement;
              </li>
              <br></br>
              <li>
                creating and maintaining a customer relationship management system, member directories, and invitation lists for events;
              </li>
              <br></br>
              <li>
                contacting a Third-Party Service Provider and/ or facilitating or enabling the services of a Third-Party Service Provider for the User, in accordance with the arrangement between the Company and such Third-Party Service Provider;
              </li>
              <br></br>
              <li>
                conducting internal studies, consumer research, surveys and preparing reports in connection with the Services;
              </li>
              <br></br>
              <li>
                developing, testing, improving, and demonstrating the Services;
              </li>
              <br></br>
              <li>
                authenticating Use, detecting potential fraudulent use, and otherwise maintaining the security of  EkThikana;
              </li>
              <br></br>
              <li>
                anonymizing and aggregating information for analytics and reporting;
              </li>
              <br></br>
              <li>
                advertising, marketing, and selling the Services, including linking together or merging User Information with other Use Information so that the Company may better understand the needs of the User;
              </li>
              <br></br>
              <li>
                disclosing User Information to its directors, officers, employees, advisors, auditors, counsel, or its authorized representatives on a need-to-know basis for provision of the Services;
              </li>
              <br></br>
              <li>
                disclosing User Information to third parties (including law enforcement agencies and the User’s building management personnel) where the Company has reasonable cause to believe that the User is guilty of any Prohibited Conduct;
              </li>
              <br></br>
              <li>
                sending alerts, contact details, promotional messages and promotional calls whether by the Company itself or through its partners/ vendors and sub-partners/ sub-vendors;
              </li>
              <br></br>
              <li>
                short-term transient use; and/or
              </li>
              <br></br>
              <li>
                for any other purpose with the consent of the User.
              </li>
              <br></br>
            </ul>
            <li>
              The Company shall share User Information in accordance with the Terms and Conditions, as well as the <NavLink to="/privacy-policy" style={{color: "blue"}}>Privacy Policy</NavLink>. If the User Information is shared on the Site publicly by the User, it can be viewed by anyone. Such de-identified Information shall be shared by the Company with any Persons, entities, third parties, etc., without any restriction.
            </li>
            <br></br>
            <li>
              The Company may retain the Information, and continue to Use the same, as may be required for business operations, for archival purposes, and/or to satisfy legal requirements. However, the same shall be retained only so long as it is necessary, unless:
            </li>
            <br></br>
            <ul style={{listStyleType: "disc"}}>
              <li>
                the relevant purposes for the use of Information described hereinabove are no longer applicable;
              </li>
              <br></br>
              <li>
                the Company is no longer required by Applicable Law, regulations, contractual obligations or legitimate business purposes to retain Information; and
              </li>
              <br></br>
              <li>
                the retention of Personal Information is not required for the establishment, exercise or defense of any legal claim.
              </li>
              <br></br>
            </ul>
            <li>
              The Site may serve as a platform for relevant and interested Third-Party Service Providers for the purposes of advertising or promoting their services in relation to the Services provided by the Company. The Company shall have the unequivocal consent of the User to share User Information, in whole or in part, with the Third-Party Service Provider, without intimation to the User, in accordance with the terms and conditions separately agreed to between the Company and such Third-Party Service Provider.
            </li>
            <br></br>
            <li>
              The Third-Party Service Providers may use Cookies or similar technologies to collect Information about the User’s pattern of availing the Services, in order to inform, optimize, and provide Advertisements based on the User’s visits on the Site and general browsing pattern. The User may, therefore, receive tailored in-application Advertisements. The Company does not guarantee the accuracy, authenticity, veracity, integrity or quality of any Content provided by such Third-Party Service Providers. Further, the Users interactions with such Third-Party Service Providers found on or through the Services provided by the Company on the Site, including payment and delivery of goods or services, and any other terms, conditions, warranties or representations associated with such dealings, are solely between the Users and the Third-Party Service Providers. In no event shall the Company be liable for any damages arising out of any interaction between the User and such Third-Party Service Provider.
            </li>
            <br></br>
          </ol>
        </TextBody>
        <SpacingBlock></SpacingBlock>
        <TextBody>
          <TextHeading>Intellectual Property Rights</TextHeading>
          <ol type="i">
            <li>
              The Services provided by the Company include a combination of Content created by the Company, its partners, affiliates, licensors, and associates, as well as Third Parties and/or Users of the Services. The Company is the sole and exclusive copyright owner of the Contents of its own Services and any other Content on EkThikana.
            </li>
            <br></br>
            <li>
              The Intellectual Property Rights in all software underlying EkThikana, and the material published on EkThikana, including (but not limited to) software, Advertisements, written Content, photographs, graphics, images, illustrations, marks, logos, audio or video clippings, flash animation and trademarks, are owned by the Company.
            </li>
            <br></br>
            <li>
              In the event the User has contributed any Content to EkThikana, in any manner whatsoever, including Listings, Personal Information, etc., all Intellectual Property Rights to the same shall become the absolute property of the Company, and the User shall have no right or claim over the same, in any manner whatsoever. The User hereby grants to the Company and its affiliates, partners, licensors, associates, and successors a worldwide, irrevocable, royalty-free, non-exclusive, sub-licensable license, to Use, reproduce, create derivative works of, distribute, publicly perform, publicly display, transfer, transmit, and/or publish Users’ Content for any of the following purposes:
            </li>
            <br></br>
            <ul style={{listStyleType: "disc"}}>
              <li>
                displaying the User’s Content on EkThikana;
              </li>
              <br></br>
              <li>
                distributing the User’s Content, either electronically or through other media, to other Users seeking to download or otherwise acquire it;
              </li>
              <br></br>
              <li>
                distributing the User’s Content, either electronically or through other media, to any other associated companies or selected Third-Parties;
              </li>
              <br></br>
              <li>
                storing the User’s Content in a remote database accessible by end users, for a charge;
              </li>
              <br></br>
              <li>
                retaining the User’s Content in accordance with the Agreement, notwithstanding any termination of the Agreement or suspension of the Service to the User.
              </li>
              <br></br>
            </ul>
            This license shall apply to the distribution and the storage of the User’s Content in any form, medium, or technology.
            <br></br>
            <li>
              In the event the User, during the term of the Agreement or at any time, thereafter, uses such Intellectual Property on any other website or related activity, the same shall be considered as an infringement of the Intellectual Property Rights of the Company, and the Company shall have the right to take recourse to whatever legal/remedial action is required, in the given facts and circumstances, at the costs and peril of the User.
            </li>
            <br></br>
            <li>
              Users are solely responsible for all materials (whether publicly posted or privately transmitted) that they upload, post, e-mail, transmit, or otherwise generate and/or make available on EkThikana. Each User represents that they owns all Intellectual Property Rights in the Content generated/created/displayed by it and that no part of the User’s Content infringes any Third-Party Rights. Users further confirm and undertake to not display or use the names, logos, marks, labels, trademarks, copyrights or any other Intellectual and proprietary rights of any Third Party on EkThikana.
            </li>
            <br></br>
            <li>
              The User shall not modify, publish, transmit, participate in the transfer or sale of, reproduce, create derivative works of, distribute, publicly perform, publicly display, or in any way exploit and/or utilize any of the materials, Information or Content on EkThikana, that does not belong to the User, either in whole or in part, without the express written license from the Company and/or the respective Third Party, to whom such material, Information and/or Content is related to.
            </li>
            <br></br>
            <li>
              The Company does not actively and/or knowingly display or host any Content/Information, and the Company is an Intermediary, in the context of the User’s/Third Party’s Content available on EkThikana.
            </li>
            <br></br>
            <li>
              All names, logos, marks, labels, trademarks, copyrights or any other Intellectual and/and proprietary rights belonging to any Person (including a User), entity or Third Party, are recognized as proprietary to the respective owners and any claims, controversies or issues against these names, logos, marks, labels, trademarks, copyrights or Intellectual and proprietary rights must be directly addressed to the respective parties under notice to the Company.
            </li>
            <br></br>
            <li>
              The User agrees to indemnify and hold harmless the Company, its directors, employees, affiliates and assigns against all costs, damages, loss and harm including towards litigation costs and counsel fees, in respect of any Third Party claims that may be initiated, including for the infringement of Intellectual Property Rights arising out of such display or use of the names, logos, marks, labels, trademarks, copyrights or any other Intellectual and proprietary rights on the Company, by such User or through the User’s commissions or omissions.
            </li>
            <br></br>
            <li>
              The Company reserves the right, at any time and without any prior notice, to remove, block, and/or disable access to any Content, which in its reasonable opinion is objectionable, in violation of the Agreement, Applicable Laws and/or otherwise harmful.
            </li>
            <br></br>
          </ol>
        </TextBody>
        <SpacingBlock></SpacingBlock>
        <TextBody>
          <TextHeading>Advertising</TextHeading>
          <ol type="i">
            <li>
              Some of the Services available on EkThikana are supported by advertising revenue, and the Company may display Advertisements, promotions, offers, etc. on EkThikana, in consideration for the Company granting access to, and use of, its Services to the User.
            </li>
            <br></br>
            <li>
              The Company shall not be liable for any error, omission or inaccuracy in advertising material and/or any loss/damage incurred as a result of such dealings between the Users(s) and the Advertiser(s), or as a result of the presence of such Advertisement on EkThikana.
            </li>
            <br></br>
          </ol>
        </TextBody>
        <SpacingBlock></SpacingBlock>
        <TextBody>
          <TextHeading>Third Party Website, Services &#38; Products</TextHeading>
          <ol type="i">
            <li>
              EkThikana may contain links to other internet sites owned and operated by Third Parties. Users’ use of each of those sites is subject to the conditions, if any, posted by the said sites. The Company does not exercise any control over any internet site(s) apart from the Site, and cannot be held responsible for any Content available on any Third-Party internet website.
            </li>
            <br></br>
            <li>
              The Company’s inclusion of Third-Party Content or links to Third-Party internet websites is not an endorsement by the Company of such Third-Party internet websites.
            </li>
            <br></br>
            <li>
              The Company does not guarantee the accuracy, integrity and/or quality of the Content provided by Third Parties and such Content may not be relied upon by the Users in using or accessing EkThikana.
            </li>
            <br></br>
            <li>
              The User’s correspondence, transactions and usage or related activities with Third Parties, including payment providers and verification service providers, are solely between the User and that Third Party, and shall be subject to the terms and conditions, policies and other service terms adopted/implemented by such Third Party. The User shall be solely responsible for reviewing the same prior to transacting or availing of the services/offers of such third party. Any questions, complaints, or claims related to any Third-Party product or service should be directed to the appropriate vendor, and the Company shall have no role, responsibility and/or liability in that regard.
            </li>
            <br></br>
            <li>
              Without prejudice to the generality of this Clause, the User expressly agrees not to hold the Company responsible or liable where the Services are affected due to any action of any Third Party providing such service.
            </li>
            <br></br>
          </ol>
        </TextBody>
        <SpacingBlock></SpacingBlock>
        <TextBody>
          <TextHeading>Privacy Policy</TextHeading>
          <ol type="i">
            <li>
              All Information collected from Users, such as Content, Personal Information is subject to the Company’s Privacy Policy, which is available at <NavLink to="/privacy-policy" style={{color: "blue"}}>www.ekthikana.com/privacy-policy</NavLink>;
            </li>
            <br></br>
            <li>
              The <NavLink to="/privacy-policy" style={{color: "blue"}}>Privacy Policy</NavLink> is to be read as part and parcel of the Terms and Conditions.
            </li>
            <br></br>
            <li>
              The User has read, understood and agreed to the Terms and Conditions and the <NavLink to="/privacy-policy" style={{color: "blue"}}>Privacy Policy</NavLink>. The User is aware that the Company will handle their information in accordance therewith. By using any part of the Services provided by the Company, the User indicates their acceptance of the <NavLink to="/privacy-policy" style={{color: "blue"}}>Privacy Policy</NavLink>, and to the collection, Use and/or disclosure of their User Information in accordance therewith.
            </li>
            <br></br>
          </ol>
        </TextBody>
        <SpacingBlock></SpacingBlock>
        <TextBody>
          <TextHeading>Limitation of Liability and Indemnities</TextHeading>
          <ol type="i">
            <li>
              The User shall access the Services provided on EkThikana voluntarily and at their own risk. In consideration of the Company allowing the User to access EkThikana, to the maximum extent permissible under law, the User waives and releases each and every right, claim, cause of action, which the User may have against the Company, for any loss or damage suffered by the User on account of the use of EkThikana, more specifically detailed hereinafter.
            </li>
            <br></br>
            <li>
              The Company disclaims any responsibility for any interruption, suspension, termination or any other inaccessibility to EkThikana, that is caused due to any reason whatsoever, including, maintenance on the servers or the technology that underlies the Services, failures of the Company’s service providers (including telecommunications, hosting, and power providers), computer viruses, natural disasters or other destruction or damage of our facilities, acts of nature, war, civil disturbance, or any other cause beyond the reasonable control of the Company.
            </li>
            <br></br>
            <li>
              To the extent permitted under law, neither the Company nor its directors, employees, partners, associates or licensors shall be liable for any direct, indirect, incidental, special, or consequential damages arising out of the Use of or inability to Use EkThikana, under such circumstances, even if the Company has been advised on the possibilities of such damage. The User undertakes not to hold the Company liable for any deficiency in services under Applicable Law.
            </li>
            <br></br>
            <li>
              Any of the Services, which require specific permission from any Government Authority, any State or Central Government, or the Board of Directors, shall be deemed to be null and void, if such permission is either not obtained or denied, whether before or after the availability of the relevant Services, and the User shall not make claims against the Company in such cases.
            </li>
            <br></br>
            <li>
              The User shall be solely responsible for any loss, damage, harm or potential loss, damage or harm caused to a User or a Third Party, owing to the User’s reliance on the accuracy, reliability, authenticity and/or veracity of the Content (including Personal Information, listing details, market information, studies, analysis, value estimates, etc.) posted on the Site by any User and/or Third Party, or any misunderstanding in construing any such particulars. The User agrees not to hold the Company liable for any such any loss, damage, harm or potential loss, damage or harm caused to a User or a Third Party.
            </li>
            <br></br>
            <li>
              If there is any dispute between User(s) on EkThikana, it is agreed upon by the Users that the Company shall have no obligation whatsoever to be involved in any such dispute(s). The User(s) hereby undertake not to make any claims or demands against the Company in such a case and indemnify and hold harmless the Company, for any loss, damage, etc. arising from the same.
            </li>
            <br></br>
            <li>
              The User shall be solely responsible for all claims and liabilities or any other consequences, which may arise due to their Use of EkThikana. By using and accessing EkThikana, the User hereby releases from liability, unilaterally agrees to indemnify, defend and hold harmless, the Company, and/or any of its directors, employees, partners, affiliates, associates and licensors, from and against any Third Party claims, and all consequences thereof (whether direct, indirect, consequential or foreseeable), liability, cost, loss and/or any expense (including legal costs and expenses), resulting from an act or omission by any User, Third Party or any other Person, including, but not limited to the following:
            </li>
            <br></br>
            <ul style={{listStyleType: "disc"}}>
              <li>
                the User’s access and use of the Services;
              </li>
              <br></br>
              <li>
                the breach of the Terms and Conditions of the Agreement;
              </li>
              <br></br>
              <li>
                failure to comply with the <NavLink to="/privacy-policy" style={{color: "blue"}}>Privacy Policy</NavLink>;
              </li>
              <br></br>
              <li>
                actual or alleged gross negligence or willful misconduct in connection with the User’s use of EkThikana or this Agreement;
              </li>
              <br></br>
              <li>
                Third Party claims arising from an infringement or alleged infringement of such Third Party’s Intellectual Property;
              </li>
              <br></br>
              <li>
                claims made by any Government Authority due to the User’s violation of Applicable Law;
              </li>
              <br></br>
              <li>
                a fraudulent act committed by a User which results in loss or damage, suffered or incurred by any other User or by any Third Party; and/or
              </li>
              <br></br>
              <li>
                such other act(s) of omission or commission from User’s Account.
              </li>
              <br></br>
            </ul>
          </ol>
        </TextBody>
        <SpacingBlock></SpacingBlock>
        <TextBody>
          <TextHeading>Disclaimer of Warranties</TextHeading>
          <ol type="i">
            <li>
              The Company does not provide any warranty as to the Content, which is distributed on ‘as is, as available’ basis, and the Company is only an Intermediary, and an aggregator of Information, which is reproducing the Content generated or hosted on its Site by Users/Third Parties. The User acknowledges that their use of,  and access to, EkThikana is voluntary and at their risk.
            </li>
            <br></br>
            <li>
              The Services offered by the Company contain a combination of Content created by the Company or any of its partners, affiliates, associates, licensor, the Users and/or any Third-Party Service Providers. All rights and liabilities in Content related to the Services, including but not limited to software, Advertisement, written content, photographs, graphics, illustrations, trademarks, etc. are owned and controlled by the Company, and/or its partners, affiliates, associates, licensor, or the User to whom such User Content belongs and/or the Third-Party Service Provider, to whom such Third-Party Content belongs.
            </li>
            <br></br>
            <li>
              The Company shall not be responsible for the accuracy, reliability, authenticity and/or veracity of any Listing and/or Content (including Personal Information, listing details, market information, studies, analysis, value estimates, etc.) posted on the Site by any User and/or Third Party, or any misunderstanding in construing any such particulars. If any inaccurate or improper Content is sighted on EkThikana, kindly report the same to contact@ekthikana.com.
            </li>
            <br></br>
            <li>
              The Content on EkThikana is for general information only and does not amount to any advice of any kind (such as legal, financial, real estate), which the User should rely on. EkThikana hosts details of Listings of property(s) in good faith, and the User is advised to make independent inquiries and seek professional advice before making any decision. The Company does not make any representations, warranties, guarantees or endorsements, whether express or implied about the Listings or any other Content, available on EkThikana.
            </li>
            <br></br>
            <li>
              Nothing contained in any Listing posted by a Subscriber shall be construed to mean an invitation to any offer or an offer by the Company or any Subscriber. The Listings hosted on EkThikana are solely meant for the general information of the Users and the Users are responsible for verifying the genuineness of the same.
            </li>
            <br></br>
            <li>
              The Company shall not be responsible with regard to the bona fides of any User, Third-Party Service Provider or any other Person. The Company does not have the obligation to physically meet and/or conduct background check, interviews or police verification of such Users/Third-Party Service Providers.
            </li>
            <br></br>
            <li>
              Project developers or builders and Estate Agents are required to comply with rules, regulations and guidelines under RERA Act, and obtain necessary registration under the said Act, as well as, to disclose on their websites, all material and requisite Information as required under the Act. The Company does not guarantee that Project(s) and Estate Agents have registered under the RERA Act or are compliant with the same and shall not be liable for any claims arising as a consequence thereof. It is recommended and advised to the User to refer to respective RERA website(s) to see and obtain complete information with respect to any project/property, before making any decisions.
            </li>
            <br></br>
            <li>
              In case a User is desirous of purchasing/renting and/or visiting of any property from the Listings, it shall be the sole liability and duty of the Subscriber who has posted such Listing(s) to arrange for all necessary formalities for the same. The Company shall not be liable in any manner whatsoever, towards any liability and/or costs, arising out of the same, including but not limited to non-fulfillment by the Subscriber in this regard or any such other transactions.
            </li>
            <br></br>
            <li>
              Direct and indirect purchase of real property involves significant risk and investment, and the same may lose value and are not insured by any Government Authority or by the Company. It is the responsibility of the User to verify the integrity and authenticity of the information made available before making any investment.
            </li>
            <br></br>
            <li>
              If there is any dispute between User(s) on EkThikana, it is agreed upon by the Users that the Company shall have no obligation whatsoever to be involved in any such dispute(s). The User(s) hereby undertake not to make any claims or demands against the Company in such a case and indemnify and hold harmless the Company, for any loss, damage, etc. arising from the same.
            </li>
            <br></br>
            <li>
              The Company does not offer any guarantee/warranty that there would be satisfactory response, or any response at all, once the Listing is put on display.
            </li>
            <br></br>
            <li>
              The Company may display Content from Third-Party Service Providers on its Site, including Advertising, etc. Although the User may opt out of receiving such Content at any time, however, to the extent advertising technology is integrated into the Services, the User may still receive the same and related updates even if they choose to opt-out. The Company disclaims all warranties, express or implied, statutory or otherwise including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement of Third-Party rights, completeness or accuracy of the Information, update or correctness of the Information, freedom from computer viruses, other violation of rights regarding Services, products, material and Content available on EkThikana.
            </li>
            <br></br>
            <li>
              The Company neither has access to, nor does the Agreement govern the use of Cookies or other tracking technologies that may be placed by Third Party Service Providers on the Site, and the Company assumes no responsibility or liability whatsoever for the User’s receipt or use of such tailored Advertisements, including any Third Party fees, costs, charges or expenses incurred by the User for accessing the Site or availing the Services. The Company shall also not be liable in respect of any disputes or legal proceedings between the User and such Third-Party Service Provider for any reason whatsoever and any such disputes or proceedings shall be resolved outside the Site without any reference or recourse to the Company or EkThikana.
            </li>
            <br></br>
            <li>
              The Company shall make all possible endeavors to ensure that its Services are error free and secure. However, the Company does not make warranty or representation that:
            </li>
            <br></br>
            <ul style={{listStyleType: "disc"}}>
              <li>
                Services will meet the User’s expectation(s) or requirement(s);
              </li>
              <br></br>
              <li>
                Services will be uninterrupted, timely, secure and/or error free;
              </li>
              <br></br>
              <li>
                the results that may be obtained from the use of the Services will be accurate and reliable; and/or
              </li>
              <br></br>
              <li>
                the quality of any product(s), Information, or any other material that the User purchases or obtains through EkThikana will meet the User’s expectations
              </li>
              <br></br>
            </ul>
            <li>
              The Company disclaims any responsibility for any interruption, suspension, termination or any other inaccessibility to the Services, that may be caused due to any reason whatsoever, including, maintenance on the servers or the technology that underlies the Services, failure of the Company’s service providers (including telecommunications, hosting, and power providers), computer viruses, natural disasters or other destruction or damage of our facilities, acts of nature, war, civil disturbance, labor issues, or any other cause beyond the reasonable control of the Company.
            </li>
            <br></br>
            <li>
              To the extent permitted under law, neither the Company nor its directors, employees, partners, associates and licensors shall be liable for any direct, indirect, incidental, special, or consequential damages arising out of the use of or inability to use EkThikana, under such circumstances, even if the Company has been advised on the possibilities of such damage. The User undertakes not to hold the Company liable for any deficiency in services under Applicable Law, on account of the same pursuant to the User’s discretion, competence and acceptance of risk.
            </li>
            <br></br>
            <li>
              In case there is any loss, deletion, failure to store, mis-delivery, or the untimely delivery of any Information or material, caused due to any reason, whether as a result of any disruption of Service, suspension and/or termination of EkThikana, the Company or its parent/holding company, subsidiaries, affiliates, directors, officers, professional advisors and employees shall not be liable in any way for the same, to the extent permitted under law.
            </li>
            <br></br>
            <li>
              The Company shall not be held liable or responsible for indemnifying, refunding or reimbursing any User for any loss, damages or expenses suffered or incurred by the User as a consequence of the suspension or deactivation of the User’s Account.
            </li>
            <br></br>
            <li>
              No information contained on EkThikana shall constitute an invitation to offer or an offer to sell, solicit or make an offer to buy an investment interest in the Company or any of its affiliates.
            </li>
            <br></br>
            <li>
              The User shall ensure that while using the Services, all Applicable Laws, the Agreement and any other rules and regulations, directly or indirectly for the use of systems, Service or equipment shall at all times, be strictly complied with by the User and the Company shall not be liable in any manner whatsoever for default of any nature regarding the same, by the User.
            </li>
            <br></br>
            <li>
              The User acknowledges and agrees that the Company has absolute discretion to refuse, suspend, terminate and/or delete any Account, or amend, modify, and/or delete any Content such as artwork, materials, Information, which the Company believes, in its sole discretion, is contrary to the Applicable Laws or the Agreement, and in order to avoid infringing any Third Party’s rights, or any other rules, standards and codes of practices that may be applicable, etc.
            </li>
            <br></br>
            <li>
              EkThikana is controlled and operated from India and makes no representation that the Content is appropriate or will be made available for use in other parts of the world. The Services offered in one region may differ from those in other regions due to availability, local or regional laws or legal impediments and other considerations/factors. If EkThikana is used from outside India, the User shall be entirely responsible for compliance with all applicable local laws as well as international conventions and treaties.
            </li>
            <br></br>
          </ol>
        </TextBody>
        <SpacingBlock></SpacingBlock>
        <TextBody>
          <TextHeading>Miscellaneous</TextHeading>
          <ol type="i">
            <li>
              <strong>Entire Agreement:</strong> The Terms and Conditions, including all terms, conditions, and policies that are incorporated herein by reference (including but not limited to the <NavLink to="/privacy-policy" style={{color: "blue"}}>Privacy Policy</NavLink> constitute the entire Agreement between the User(s) and the Company, and shall govern the use of EkThikana, superseding any prior Agreement(s) that any User may have with the Company.
            </li>
            <br></br>
            <li>
              <strong>Severability:</strong> If any provision of this Agreement or the application thereof to any Person or circumstance shall be found to invalid or unenforceable to any extent, the remainder of this Agreement and the application of such provision to Persons or circumstances other than those as to which it is held invalid or unenforceable shall not be affected thereby.
            </li>
            <br></br>
            <li>
              <strong>Non-Waiver:</strong> The Company’s failure to exercise, and /or delay in exercising or enforcing any right or provision of the Agreement shall not constitute a waiver of such a right or the provision, or acceptance of any variation to the Agreement.
            </li>
            <br></br>
            <li>
              <strong>Reservation of Rights:</strong> No forbearance, indulgence, relaxation or inaction by the Company at any time to require performance of any of the provisions of the Agreement shall in any way affect, diminish or prejudice the right of the Company to require performance of that provision. Any waiver or acquiescence by the Company of any breach of any of the provisions of the Agreement shall not be construed as a waiver or acquiescence of any continuing or succeeding breach of such provisions, a waiver of any right under or arising out of the Agreement or acquiescence to or recognition of rights other than that expressly stipulated in the Agreement.
            </li>
            <br></br>
            <li>
              <strong>Cumulative Rights:</strong> All remedies of the Company under this Agreement whether provided herein or conferred by statute, civil law, common law, custom or trade usage, are cumulative and not alternative and may be enforced successively and/or concurrently.
            </li>
            <br></br>
            <li>
              <strong>Assignment:</strong> This Agreement and the rights and liabilities hereunder shall bind and inure to the benefit of the successors of the Company, without the prior written consent of the User.
            </li>
            <br></br>
            <li>
              <strong>Partnership or Agency:</strong> None of the provisions of the Agreement shall be deemed to constitute a partnership and/or an agency between the Company and the User, and the User shall have no authority to bind the Company in any form or manner whatsoever.
            </li>
            <br></br>
            <li>
              <strong>Amendments:</strong> No modification or amendment of the Agreement and no waiver of any of the Terms or Conditions thereof, shall be valid or binding unless made in writing and duly executed by the Company and accepted by the User.
            </li>
            <br></br>
            <li>
              <strong>Discretion of the Company:</strong> The Company reserves the right to moderate, restrict or ban the Use of EkThikana, specifically to any User, or generally, in accordance with the Company’s policies, as may be amended and updated from time to time, at its sole and absolute discretion and without any notice, and may also permanently close or temporarily suspend any Services (including any Contest(s)).
            </li>
            <br></br>
            <li>
              <strong>Period of Limitation:</strong> Users agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to Use of EkThikana or the Agreement must be filed within thirty (30) days of such claim or cause of action arising, or be forever barred.
            </li>
            <br></br>
            <li>
              <strong>Promotions &#38; Offers:</strong> The Company may from time-to-time conduct/organize promotions/offers on EkThikana. Any two or more promotions cannot be clubbed together with any other promotions that are running simultaneously on EkThikana.
            </li>
            <br></br>
            <br></br>
            <li>
              <strong>Notices:</strong> The Company may be required under certain legislations, to notify User(s) of certain events. User(s) hereby acknowledge and consent that such notices will be effective upon the Company posting them on EkThikana or delivering them to the User through the email address/phone number provided by the User at the time of registration. User(s) may update their email address/phone number by logging into their Account. If they do not provide the Company with accurate Information, the Company cannot be held liable for failure to notify the User.
            </li>
            <br></br>
            <li>
              <strong>Maintenance:</strong> The Company may at its sole discretion and without assigning any reason whatsoever, at any time deactivate/suspend the Services without notice, to carry out system maintenance, upgrading, testing, repairs or other related work, and it shall not be liable for the same.
            </li>
            <br></br>
            <li>
              <strong>Errors, Inaccuracies &#38; Omissions:</strong> Occasionally there may be Information that contains typographical errors, inaccuracies or omissions that may relate to product descriptions, pricing, promotions, offers, and availability. The Company reserves the right to correct any errors, inaccuracies or omissions, and to change or update Information at any time without prior notice, although the Company is under no obligation to update, amend or clarify Information on EkThikana, except as required by law.
            </li>
            <br></br>
            <li>
              <strong>Force Majeure:</strong> The Company shall not be liable for any delay or failure to perform resulting from causes outside its reasonable control, including but not limited to any failure to perform due to unforeseen circumstances or cause beyond the Company’s control such as acts of God, war, terrorism, riots, embargoes, acts of civil or military authorities, fire, floods, accidents, network infrastructure failures, strikes, or shortages of transportation facilities, fuel, energy, labor or materials.
            </li>
            <br></br>
          </ol>
        </TextBody>
        <SpacingBlock></SpacingBlock>
        <TextBody>
          <TextHeading>Governing Law, Dispute Resolution and Jurisdiction</TextHeading>
          <ol type="i">
            <li>
              All matters, pertaining to, and in relation to, the present Agreement, including interpretation, termination, disputes, etc., shall be governed by the laws of India, including the statutes, amendments thereto, rules, regulations, notifications, etc., as may be passed by the Central or State Government, from time to time.
            </li>
            <br></br>
            <li>
              The disputes between the User(s) and the Company shall be referred to a Sole Arbitrator, who shall be appointed at the sole and absolute discretion of the Company. The seat of arbitration shall be at Gurugram, Haryana and the arbitral proceedings shall be conducted in English.
            </li>
            <br></br>
            <li>
              Users agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to Use of the EkThikana or these Terms must be filed within thirty (30) days of such claim or cause of action arising or be forever barred.
            </li>
            <br></br>
            <li>
              Only the courts of Gurugram, Haryana shall have the jurisdiction to entertain and hear any disputes, which may arise between the parties, in relation to the Agreement.
            </li>
            <br></br>
          </ol>
        </TextBody>
      </Canvas>
        <Footer></Footer>
    </Wrapper>

  );
};

export default TermsScreen;
