import React, { useRef } from "react"
import styled from "styled-components"
import FilterButton from "./filterButton"
import { IFilters } from "../../../Hooks/useFilter"
import { colors } from "../../../Constants/colors"

const PrimaryColorSpan = styled.span`
  color: ${colors.primary};
`

const PricingRow = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
`

const StyledInput = styled.input`
  border: 1.5px solid ${colors.primary};
  border-radius: 2px;
  padding: 0.8em;
  outline: none;
  display: inline-block;
  width: 150px;
  font-weight: 600;
  color: ${colors.primary};
  margin-top: 2%;
  &:focus,
  &:hover {
    background: ${colors.lightprimary};
  }
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${colors.primary};
    opacity: 1; /* Firefox */
    font-weight: 500;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${colors.primary};
    font-weight: 500;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${colors.primary};
    font-weight: 500;
  }
`

const PricingLabel = styled.div`
  font-size: 1em;
  margin-top: 2%;
  margin-bottom: 2%;
`

const DoneButton = styled.button`
  font-size: 0.9em;
  padding: 0.5em;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
  width: 100%;
  background: ${colors.primary};
  border: 2px solid ${colors.primary};
  text-align: center;
  border-radius: 2px;
  cursor: pointer;
  color: ${colors.white};
  font-weight: 600;
`

const ResetButton = styled.button`
  font-size: 0.9em;
  padding: 0.5em;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2%;
  width: 100%;
  background: ${colors.white};
  border: 2px solid ${colors.primary};
  text-align: center;
  border-radius: 2px;
  cursor: pointer;
  color: ${colors.primary};
  font-weight: 600;

  &:hover {
    background: ${colors.lightprimary};
  }
`

interface IProps {
  filter: IFilters
}

interface IPricingProps {
  filter: IFilters
  closeDropdown?: () => void
}

const Pricing = (props: IPricingProps) => {
  const filter = props.filter

  const minInput: React.MutableRefObject<HTMLInputElement | null> = useRef(null)
  const maxInput: React.MutableRefObject<HTMLInputElement | null> = useRef(null)

  return (
    <>
      <PricingLabel>Price Range</PricingLabel>
      <PricingRow>
        <StyledInput
          ref={minInput}
          type="number"
          placeholder="Min"
          defaultValue={
            filter.pricing.value[0] === 0 ? undefined : filter.pricing.value[0]
          }
        ></StyledInput>
        <span style={{ margin: "0 5px" }}> - </span>
        <StyledInput
          ref={maxInput}
          type="number"
          placeholder="Max"
          defaultValue={
            filter.pricing.value[1] === -1 ? undefined : filter.pricing.value[1]
          }
        ></StyledInput>
      </PricingRow>
      <DoneButton
        onClick={() => {
          if (minInput.current && maxInput.current)
            filter.pricing.set([
              +minInput.current.value,
              +maxInput.current.value || -1,
            ])
          props.closeDropdown && props.closeDropdown()
        }}
      >
        Done
      </DoneButton>

      <ResetButton
        onClick={() => {
          filter.reset()
          props.closeDropdown && props.closeDropdown()
        }}
      >
        Reset
      </ResetButton>
    </>
  )
}

const PricingFilter: React.FC<IProps> = (props: IProps) => {
  const filter = props.filter

  return (
    <FilterButton collapsable={true}>
      {filter.pricing.value[0] === 0 && filter.pricing.value[1] === -1 ? (
        <span>Price</span>
      ) : (
        <span>
          <PrimaryColorSpan>₹</PrimaryColorSpan>
          {filter.pricing.value[0]}
          <span> - </span>
          {filter.pricing.value[1] === -1 ? (
            <span>any</span>
          ) : (
            <>
              <PrimaryColorSpan>₹</PrimaryColorSpan>
              <span>{filter.pricing.value[1]}</span>
            </>
          )}
        </span>
      )}
      <Pricing filter={filter}></Pricing>
    </FilterButton>
  )
}

export default PricingFilter
