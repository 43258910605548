export interface ILatLang {
  lon: number
  lat: number
}

export interface IServerListing {
  postID: string
  propertyType: number
  postType: number
  userID: string
  createdDate: string
  location: ILatLang
  address: string
  locality: string
  city: string
  state: string
  country: string
  monthlyRent: number
  securityDeposit: number
  squareFeet: number
  bedrooms: number
  bathrooms: number
  aboutTheProperty: string
  likes: number
  views: number
  furnishing: number
  facing: number
  status: number
  leaseSummary: string
  yearBuilt: string
  leaseDuration: string
  amenities: string[]
  amenities2: string
  dateAvailable: string
  price: number
  contentIDs?: string[]
}

export const mockImages: string[] = [
  // "https://thikanamedia.s3.ap-south-1.amazonaws.com/placeholder.png",
  // "https://thikanamedia.s3.ap-south-1.amazonaws.com/placeholder.png",
  // "https://thikanamedia.s3.ap-south-1.amazonaws.com/placeholder.png",
  "https://thikanamedia.s3.ap-south-1.amazonaws.com/placeholder.png"
]
