import ReactGA from "react-ga"

export const analyticsInit = () => {
  ReactGA.initialize("UA-191154979-1", {
    gaOptions: {
      siteSpeedSampleRate: 100,
    },
  })
}

export const identify = (userId: string) => {
  ReactGA.set({ userId })
}

export const track = (category: string, action: string) => {
  ReactGA.event({ category, action })
}

export const error = (description: string, fatal: boolean = false) => {
  ReactGA.exception({ description, fatal })
}
