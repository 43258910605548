import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import { ISearchIndex } from "../../../Constants/searchIndex"
import { colors } from "../../../Constants/colors"

const DropListItem = styled.div<{ active: boolean }>`
  background: ${colors.white};
  padding: 0.75em 0.75em;
  font-size: 0.9em;
  border-bottom: 0.5px solid ${colors.shadow};
  width: 100%;
  cursor: pointer;
  background: ${props => (props.active ? colors.primary : colors.white)};
  color: ${props => (props.active ? colors.white : colors.black)};
  display: flex;
  justify-content: space-between;
  justify-items: center;
  font-weight: 500;
  box-sizing: border-box;
`

interface IProps {
  matchedRecords: ISearchIndex[]
  handleTagSelection: (tag: ISearchIndex) => void
}

const Dropdown = (props: IProps) => {
  const [, changeActiveItem] = useState<number>(0)
  const activeRef = useRef(0)
  const activeRecords = useRef<ISearchIndex[]>([])

  // handle list traversal using keyboard
  const handleKeyboardInput = (e: any) => {
    if (e.key === "ArrowDown" && activeRef.current < 3) {
      e.preventDefault()
      // set the item below as active

      activeRef.current = activeRef.current + 1

      changeActiveItem(activeRef.current + 1)
    } else if (e.key === "ArrowUp" && activeRef.current > 0) {
      e.preventDefault()
      activeRef.current = activeRef.current - 1
      changeActiveItem(activeRef.current - 1)
    } else if (e.key === "Enter") {
      props.handleTagSelection(activeRecords.current[activeRef.current])
    }
  }

  useEffect(() => {
    changeActiveItem(0)
    activeRef.current = 0
    activeRecords.current = props.matchedRecords
  }, [props.matchedRecords])

  useEffect(() => {
    document.addEventListener("keydown", handleKeyboardInput)

    return () => {
      document.removeEventListener("keydown", handleKeyboardInput)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {props.matchedRecords.map((item: ISearchIndex, i: number) => {
        return (
          <DropListItem
            key={i}
            active={i === activeRef.current}
            onClick={() => {
              props.handleTagSelection(item)
            }}
            onMouseEnter={() => {
              activeRef.current = i
              changeActiveItem(i)
            }}
          >
            <span>{item.LocalityEnglish}</span>
            <span style={{ textAlign: "right" }}>{item.LocalityHindi}</span>
          </DropListItem>
        )
      })}
    </>
  )
}

export default Dropdown
