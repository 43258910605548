import React from "react";
import styled from "styled-components";
import { colors } from "../../Constants/colors";
import { size } from "../../Constants/sizesAndSpacing";
import { breakpoints } from "../../Constants/responsive";
import Footer from  "../Footer/footer";
import { useHistory } from "react-router-dom";


const Wrapper = styled.div`
  width: 100%;
  height: calc(100% - ${size.headerHeight + "px"});
  overflow-y: scroll;
`;

const CoverDiv = styled.div`
  position: relative;
`;

const CoverDivImage = styled.div`
  background: url("/img/about1.jpg");
  width: 100%;
  height: 0;
  padding-top: 41.43%;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
`;

const CoverHeading = styled.p`
  font-family: 'Shadows Into Light', cursive;
  color: ${colors.black};
  font-size: 7vw;
  position: absolute;
  text-align: center;
  top: 30%;
  font-weight: 600;
  right: 0;
  transform: translate(-50%, -50%);
  line-height: 1.2;
`;

const PageSection = styled.div`
  width: 90%;
  margin: auto;
`

const DivContainer = styled.div`
  display: block;
  justify-content: space-evenly;
  margin: auto;
  width: 100%;
  @media (min-width: ${breakpoints.tablet + "px"}) {
    display: flex;
    align-items: center;
  }
  @media (min-width: ${breakpoints.desktop + "px"}) {
    display: flex;
    align-items: center;
  }
`;

const SpacingBlock = styled.div`
  padding: 2%;
`;

const TextHeadingBorder = styled.div`
  width: 20%;
  border-bottom: 0.4em solid ${colors.primary};
`;

const TextHeading = styled.p`
  margin-top: 0.5%;
  margin-bottom: 0.1em;
  color: ${colors.secondary};
  font-weight: 600;
  font-size: 3em;

  @media only screen and (min-width: ${breakpoints.mobile + "px"}) {
    font-size: 3em;
  }
  @media only screen and (min-width: ${breakpoints.tablet + "px"}) {
    font-size: 3.25em;
  }
  @media only screen and (min-width: ${breakpoints.desktop + "px"}) {
    font-size: 3.5em;
  }
`;

const TextHeading2 = styled.p`
  padding-top: 0.5%;
  padding-bottom: 0.5%;
  color: ${colors.secondary};
  font-weight: 600;
  font-size: 2em;
  text-align: center;
  @media only screen and (min-width: ${breakpoints.mobile + "px"}) {
    font-size: 2em;
  }
  @media only screen and (min-width: ${breakpoints.tablet + "px"}) {
    font-size: 2.25em;
  }
  @media only screen and (min-width: ${breakpoints.desktop + "px"}) {
    font-size: 2.5em;
  }
`;

const TextBody = styled.p`
  margin-top: 5%;
  margin-bottom: 5%;
  color: ${colors.secondary};
  text-align: justify;
  font-size: 1em;
  @media only screen and (min-width: ${breakpoints.mobile + "px"}) {
    font-size: 1em;
  }
  @media only screen and (min-width: ${breakpoints.tablet + "px"}) {
    font-size: 1.275em;
  }
  @media only screen and (min-width: ${breakpoints.desktop + "px"}) {
    font-size: 1.5em;
  }
`;

const ViewMoreButton = styled.button`
  padding-left: 2em;
  padding-right: 2em;
  margin-top: 1%;
  margin-left: auto;
  margin-right: auto;
  font-weight: 600;
  align-items: center;
  text-align: center;
  display: block;
  cursor: pointer;
  background-color: ${colors.white};
  color: ${colors.primary};
  border-color: ${colors.primary};
  text-decoration: none;
  border-width: 3px;
  border-style: solid;
  border-radius: 3px;
  min-height: 3em;
  font-size: 1em;
  @media only screen and (min-width: ${breakpoints.mobile + "px"}) {
    font-size: 1em;
  }
  @media only screen and (min-width: ${breakpoints.tablet + "px"}) {
    font-size: 1.275em;
  }
  @media only screen and (min-width: ${breakpoints.desktop + "px"}) {
    font-size: 1.5em;
  }
`;

const LeftHalfDiv = styled.div`
  align-items: center;
  // padding-top: 2em;
  // padding-bottom: 2em;
  display: block;
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  @media (min-width: ${breakpoints.tablet + "px"}) {
  }
  @media (min-width: ${breakpoints.desktop + "px"}) {
  }
`;

const RightHalfDiv = styled.div`
  // padding: 2em;
  margin-left: 2%;
  margin-right: 2%;
  display: block;
  width: 100%;
  box-sizing: border-box;
  @media (min-width: ${breakpoints.tablet + "px"}) {
  }
  @media (min-width: ${breakpoints.desktop + "px"}) {
  }
`;

const PageImage = styled.img`
  width: 80%;
  height: auto;
  margin: auto;
  z-index: -100;
  background-size: cover;
  overflow: hidden;
`;

const AboutScreen = () => {

    const history = useHistory();

    // const isSafari = () => {
    //   const ua = navigator.userAgent.toLowerCase();
    //   return ua.indexOf("safari") > -1 && ua.indexOf("chrome") < 0;
    // }

    // const videoParentRef = useRef();
    // useEffect(() => {
    //   if(isSafari() && videoParentRef.current) {
    //     const player = videoParentRef.current.children[0];
    //     if (player) {
    //       player.controls = false;
    //       player.playsinline = true;
    //       player.muted = true;
    //       player.setAttribute("muted","");
    //       player.autoplay = true;
    //
    //       setTimeout(() => {
    //         const promise = player.play();
    //
    //         if (promise.then) {
    //           promise
    //           .then(() => {})
    //           .catch (() => {
    //             videoParentRef.current.style.display = "none";
    //             setShouldUseImage(true);
    //           })
    //         }
    //       }, 0);
    //     }
    //   }
    // }, []);

  return (
    <Wrapper>
      <CoverDiv>
        <CoverDivImage></CoverDivImage>
        <CoverHeading> <span style={{color: "#f46029", fontFamily: "inherit" }}>Your</span> home <br/> hunting <span style={{color: "#f46029", fontFamily: "inherit" }}>buddy</span> </CoverHeading>
      </CoverDiv>
      <PageSection>
          <SpacingBlock></SpacingBlock>
          <TextHeading>About us</TextHeading>
          <TextHeadingBorder></TextHeadingBorder>
          <TextBody>
              EkThikana is a no-fee real estate platform connecting property owners with buyers. We strive to simplify your home journey, turning dream to
              reality. Our Platform comprises of residential houses of various types fitting all budget, sizes, and facilities.<br/>
            <br/>
              Powered by Techlipi LLP, EkThikana was launched on March 1, 2021, with its headquarters in Gurugam, Haryana.
          </TextBody>
      </PageSection>
      <PageSection>
          <TextHeading2>Your next thikana just 2 steps away</TextHeading2>
          <DivContainer>
            <LeftHalfDiv>
              <div
                dangerouslySetInnerHTML={{
                  __html:`
                  <video autoplay loop muted playsinline style="width: 100%;
                  height: auto;
                  margin: auto;
                  z-index: -100;
                  background-size: cover;
                  overflow: hidden;
                  border-radius: 1em;
                  border: 3px solid ${colors.lightgrey};"
                  >
                      <source src="/video/about1.mp4" type="video/mp4" />
                  </video>
                  `
                }}
              />
            </LeftHalfDiv>

            <RightHalfDiv>
                <TextBody>
                  <b>1. Browse</b>
                  <SpacingBlock></SpacingBlock>
                    Start by entering neighborhood. Apply filters like Price, Home Type, or Beds and Baths to narrow your results. You can also save bookmark to a wishlist.
                </TextBody>
            </RightHalfDiv>
          </DivContainer>
          <SpacingBlock></SpacingBlock>
          <DivContainer>
            <LeftHalfDiv>
              <div
                dangerouslySetInnerHTML={{
                  __html:`
                  <video autoplay loop muted playsinline style="width: 100%;
                  height: auto;
                  margin: auto;
                  z-index: -100;
                  background-size: cover;
                  overflow: hidden;
                  border-radius: 1em;
                  border: 3px solid ${colors.lightgrey};"
                  >
                      <source src="/video/about2.mp4" type="video/mp4" />
                  </video>
                  `
                }}
              />
            </LeftHalfDiv>
            <RightHalfDiv>
                <TextBody>
                  <b>2. Send Interest</b>
                  <SpacingBlock></SpacingBlock>
                    Once you’ve found what you’re looking for, learn more about the property and amenities, then send interest in just a click.
                </TextBody>
            </RightHalfDiv>
          </DivContainer>
          <SpacingBlock></SpacingBlock>
          <DivContainer>
            <LeftHalfDiv>
              <PageImage src="/img/about2.png" />
            </LeftHalfDiv>
            <RightHalfDiv>
              <TextHeading2> Ready to start searching?</TextHeading2>
              <ViewMoreButton onClick={() => { history.push("/app"); }}> Get Started </ViewMoreButton>
            </RightHalfDiv>
          </DivContainer>
          <SpacingBlock></SpacingBlock>
      </PageSection>

      <Footer></Footer>
    </Wrapper>

);
};

export default AboutScreen;
