import React from "react"
import styled from "styled-components"
import MobileImageViewer from "./Image View/mobileImageViewer"
import DesktopImageViewer from "./Image View/desktopImageViewer"
import ExtendedListingDescription from "./extendedListingDescription"
import { IServerListing, mockImages } from "../../../mockListings"
import { breakpoints } from "../../../Constants/responsive"
import { size } from "../../../Constants/sizesAndSpacing"
import WideHeader from "./Header/wideHeader"

const MainWrapper = styled.div`
  width: 100%;
  height: calc(100% - ${size.headerHeight + "px"});
  overflow: scroll;

  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;

  @media (min-width: ${breakpoints.mobile + "px"}) {
    display: flex;
  }

  @media (min-width: ${breakpoints.tablet + "px"}) {
    height: 100%;
  }
`

interface IProps extends IServerListing {
  toggle: () => void
}

const ExtendedListingCard: React.FC<IProps> = (props: IProps) => {
  return (
    <>
      <WideHeader toggle={props.toggle}></WideHeader>
      <MainWrapper>
        <MobileImageViewer
          images={
            props.contentIDs && props.contentIDs.length > 0
              ? props.contentIDs
              : mockImages
          }
        ></MobileImageViewer>
        <DesktopImageViewer
          images={
            props.contentIDs && props.contentIDs.length > 0
              ? props.contentIDs
              : mockImages
          }
        ></DesktopImageViewer>
        <ExtendedListingDescription {...props}></ExtendedListingDescription>
      </MainWrapper>
    </>
  )
}

export default ExtendedListingCard
