import React, { useEffect } from "react"
import { createPortal } from "react-dom"
import { Link } from "react-router-dom"
import styled from "styled-components"
import { colors } from "../../Constants/colors"
import { breakpoints } from "../../Constants/responsive"
import { RouteComponentProps, withRouter, useHistory } from "react-router"

const ModalWrapper = styled.div<{ zIndex: number }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${props => props.zIndex};
  border-top: 1px solid ${colors.secondary};
`
const ModalCard = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 101;
  background: white;
  border-radius: 5px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);

  @media (min-width: ${breakpoints.tablet + "px"}) {
    width: 87%;
  }
`
const CloseButton = styled.button`
  display: none;
  position: absolute;
  top: 1%;
  right: 1%;
  border: none;
  background: transparent;
  width: 5%;
  height: 5%;
  &:hover {
    cursor: pointer;
  }
  z-index: 102;
  padding: none;
  color: white;
  font-size: 1.5em;

  @media (min-width: ${breakpoints.tablet + "px"}) {
    display: block;
  }
`

const CloseButtonLink = styled(Link)`
  display: none;
  position: absolute;
  top: 1%;
  right: 1%;
  border: none;
  background: transparent;
  width: 5%;
  height: 5%;
  &:hover {
    cursor: pointer;
  }
  z-index: 102;
  padding: none;
  color: white;
  font-size: 1.5em;

  @media (min-width: ${breakpoints.tablet + "px"}) {
    display: block;
  }
`

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: ${colors.black};
  opacity: 0.5;
  z-index: 100;
  cursor: pointer;
`

const Portal = ({ children }: { children: React.ReactNode }) => {
  const el = document.createElement("div")
  const modalRoot = document.getElementById("modal") as HTMLElement

  useEffect(() => {
    modalRoot.appendChild(el)
  }, [modalRoot, el])

  useEffect(() => {
    return () => {
      modalRoot.removeChild(el)
    }
  })

  return createPortal(children, el)
}

interface MatchParams {
  postId: string
}

interface IProps extends RouteComponentProps<MatchParams> {
  children: React.ReactNode
  toggle: () => void
  open: boolean
  zIndex?: number
  isLink?: boolean
}

const Modal: React.FC<IProps> = ({
  children,
  toggle,
  open,
  zIndex,
  isLink,
  match,
}: IProps) => {
  const history = useHistory()
  const overrideToggle = !isLink
    ? toggle
    : () => {
        history.push("/app")
        toggle()
      }
  return (
    <Portal>
      {open && (
        <ModalWrapper zIndex={zIndex ? zIndex : 100}>
          <ModalCard>{children}</ModalCard>

          <CloseButton onClick={overrideToggle}>
            <i className="fas fa-times"></i>
          </CloseButton>

          <Background onClick={overrideToggle} />
        </ModalWrapper>
      )}
    </Portal>
  )
}

export default React.memo(withRouter(Modal))
