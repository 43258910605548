import React, { useState, useEffect } from "react";
import { autoPlay } from "react-swipeable-views-utils";
import SwipeableViews from "react-swipeable-views";
import styled from "styled-components";
import { colors } from "../../../../Constants/colors";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 100px;
`;

const LeftButton = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 4em;
  background: linear-gradient(
    to right,
    ${colors.black} 5%,
    rgba(0, 0, 0, 0) 100%
  );
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10000;
`;

const RightButton = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 4em;
  background: linear-gradient(
    to left,
    ${colors.black} 5%,
    rgba(0, 0, 0, 0) 100%
  );
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10000;
`;

const ImgCount = styled.div`
  position: absolute;
  bottom: 1em;
  right: 1em;
  z-index: 100000;
  font-weight: 400;
`;

const SVGWrapper = styled.div`
  width: 1em;
  height: 1.5em;
  font-size: 2em;
  color: white;
`;

interface IProps {
  index?: number;
  children: React.ReactNode;
  autoPlay?: boolean;
}

const SwipeableViewWithControls: React.FC<IProps> = (props: IProps) => {
  const startIndex = props.index ? props.index : 0;
  const [index, changeIndex] = useState(startIndex);

  const maxIndex = React.Children.count(props.children) - 1;

  useEffect(() => {
    setTimeout(() => {
      if (startIndex !== maxIndex) changeIndex(startIndex + 1);
      else changeIndex(startIndex - 1);

      changeIndex(startIndex);
    }, 5);
  }, [maxIndex, startIndex]);

  const moveRight = () => {
    if (index === maxIndex) changeIndex(0);
    else changeIndex(index + 1);
  };

  const moveLeft = () => {
    if (index === 0) changeIndex(maxIndex);
    else changeIndex(index - 1);
  };

  const interval = props.autoPlay ? 10000 : 4500;

  return (
    <>
      <Wrapper>
        <LeftButton onClick={moveLeft}>
          <SVGWrapper>
            <i className="fas fa-chevron-left"></i>
          </SVGWrapper>
        </LeftButton>
        <RightButton onClick={moveRight}>
          <SVGWrapper>
            <i className="fas fa-chevron-right"></i>
          </SVGWrapper>
        </RightButton>
        <ImgCount>{`${index + 1}/${maxIndex + 1}`}</ImgCount>
        <AutoPlaySwipeableViews
          interval={interval}
          animateHeight={true}
          index={index}
          onChangeIndex={(newi: number) => {
            changeIndex(newi);
          }}
        >
          {props.children}
        </AutoPlaySwipeableViews>
      </Wrapper>
    </>
  );
};

export default SwipeableViewWithControls;
