export interface ISearchIndex {
  LocalityEnglish: string;
  LocalityHindi: string;
  Latitude: string;
  Longitude: string;
}

export const searchIndices = [
{
          LocalityEnglish: "Model Town",
          LocalityHindi: "मॉडल टाउन",
          Latitude: "28.4553669",
          Longitude: "77.0145786"
      },
      {
          LocalityEnglish: "Acharya Puri",
          LocalityHindi: "अचार्या पुरी",
          Latitude: "28.4682069",
          Longitude: "77.0090183"
      },
      {
          LocalityEnglish: "Adarsh Nagar",
          LocalityHindi: "आदर्श नगर",
          Latitude: "28.4642475",
          Longitude: "77.0293719"
      },
      {
          LocalityEnglish: "Ambedkar Nagar",
          LocalityHindi: "अंबेडकर नगर",
          Latitude: "28.4650234",
          Longitude: "77.0011988"
      },
      {
          LocalityEnglish: "Ambience Island",
          LocalityHindi: "अम्बिएंस आइलैंड",
          Latitude: "28.5029309",
          Longitude: "77.0917816"
      },
      {
          LocalityEnglish: "New Anaj Mandi",
          LocalityHindi: "न्यू अनाज मंडी",
          Latitude: "28.4466099",
          Longitude: "77.0128702"
      },
      {
          LocalityEnglish: "Anamika Enclave",
          LocalityHindi: "अनामिका एंक्लेव",
          Latitude: "28.4674209",
          Longitude: "77.0343434"
      },
      {
          LocalityEnglish: "Ardee City",
          LocalityHindi: "आरडी सिटी",
          Latitude: "28.4412022",
          Longitude: "77.0698096"
      },
      {
          LocalityEnglish: "Arjun Nagar",
          LocalityHindi: "अर्जुन नगर",
          Latitude: "28.4597655",
          Longitude: "77.0151082"
      },
      {
          LocalityEnglish: "Ashok Puri",
          LocalityHindi: "अशोक पुरी",
          Latitude: "28.4722865",
          Longitude: "77.0202693"
      },
      {
          LocalityEnglish: "Ashok Vihar",
          LocalityHindi: "अशोक विहार ",
          Latitude: "28.4868992",
          Longitude: "77.0066502"
      },
      {
          LocalityEnglish: "Ashok Vihar Phase I",
          LocalityHindi: "अशोक विहार फेस १",
          Latitude: "28.4795965",
          Longitude: "77.0054834"
      },
      {
          LocalityEnglish: "Ashok Vihar Phase II",
          LocalityHindi: "अशोक विहार फेस २",
          Latitude: "28.4846566",
          Longitude: "77.0173505"
      },
      {
          LocalityEnglish: "Ashok Vihar Phase III Extension",
          LocalityHindi: "अशोक विहार फेस ३ एक्सटेंशन",
          Latitude: "28.4941557",
          Longitude: "77.0167083"
      },
      {
          LocalityEnglish: "Badshapur",
          LocalityHindi: "बादशाहपुर",
          Latitude: "28.3979552",
          Longitude: "77.0465633"
      },
      {
          LocalityEnglish: "Baldev Nagar",
          LocalityHindi: "बलदेव नगर",
          Latitude: "28.4577954",
          Longitude: "77.0102736"
      },
      {
          LocalityEnglish: "Basai Village",
          LocalityHindi: "बसई गांव",
          Latitude: "28.4600182",
          Longitude: "76.9730687"
      },
      {
          LocalityEnglish: "Begampur Khatola",
          LocalityHindi: "बेगमपुर खटोला",
          Latitude: "28.4162793",
          Longitude: "77.0046717"
      },
      {
          LocalityEnglish: "Behrampur",
          LocalityHindi: "बेहरामपुर",
          Latitude: "28.4168298",
          Longitude: "77.0143052"
      },
      {
          LocalityEnglish: "Bhawani Enclave",
          LocalityHindi: "भवानी एंक्लेव",
          Latitude: "28.4604244",
          Longitude: "76.9897711"
      },
      {
          LocalityEnglish: "Bhim Nagar",
          LocalityHindi: "भीम नगर",
          Latitude: "28.4704349",
          Longitude: "77.0162512"
      },
      {
          LocalityEnglish: "Canal Colony",
          LocalityHindi: "कनाल कॉलोनी",
          Latitude: "28.4702089",
          Longitude: "77.0485343"
      },
      {
          LocalityEnglish: "Carterpuri Village",
          LocalityHindi: "कार्टरपुरी गाँव",
          Latitude: "28.5069364",
          Longitude: "77.0386366"
      },
      {
          LocalityEnglish: "Chakkarpur",
          LocalityHindi: "चक्करपुर",
          Latitude: "28.4737382",
          Longitude: "77.0791056"
      },
      {
          LocalityEnglish: "Chakkarpur Village",
          LocalityHindi: "चक्करपुर गाँव",
          Latitude: "28.473727",
          Longitude: "77.0879758"
      },
      {
          LocalityEnglish: "Chander Nagar",
          LocalityHindi: "चंदर नगर",
          Latitude: "28.464501",
          Longitude: "77.0501233"
      },
      {
          LocalityEnglish: "Civil Lines",
          LocalityHindi: "सिविल लाइंस",
          Latitude: "28.4531574",
          Longitude: "77.0240609"
      },
      {
          LocalityEnglish: "Dayanand Colony",
          LocalityHindi: "दयानंद कॉलोनी",
          Latitude: "28.4729069",
          Longitude: "77.0167011"
      },
      {
          LocalityEnglish: "Devilal Colony",
          LocalityHindi: "देवीलाल कॉलोनी",
          Latitude: "28.4634905",
          Longitude: "77.0027879"
      },
      {
          LocalityEnglish: "DLF Phase V",
          LocalityHindi: "डीएलएफ फेस ५",
          Latitude: "28.4430636",
          Longitude: "77.0877799"
      },
      {
          LocalityEnglish: "DLF Phase I",
          LocalityHindi: "डीएलएफ फेस १",
          Latitude: "28.4731287",
          Longitude: "77.0897112"
      },
      {
          LocalityEnglish: "DLF Phase II",
          LocalityHindi: "डीएलएफ फेस २",
          Latitude: "28.4891248",
          Longitude: "77.0659722"
      },
      {
          LocalityEnglish: "DLF Phase III",
          LocalityHindi: "डीएलएफ फेस ३",
          Latitude: "28.4941059",
          Longitude: "77.0858458"
      },
      {
          LocalityEnglish: "DLF Phase IV",
          LocalityHindi: "डीएलएफ फेस ४",
          Latitude: "28.4663046",
          Longitude: "77.0775651"
      },
      {
          LocalityEnglish: "Dundahera Village",
          LocalityHindi: "डुन्डाहेड़ा गाँव",
          Latitude: "28.5138554",
          Longitude: "77.075076"
      },
      {
          LocalityEnglish: "Fazilpur Jharsa",
          LocalityHindi: "फ़ाजिलपुर झरसा",
          Latitude: "28.4119584",
          Longitude: "77.0253366"
      },
      {
          LocalityEnglish: "Feroz Gandhi Colony",
          LocalityHindi: "फिरोज गांधी कॉलोनी",
          Latitude: "28.467477",
          Longitude: "77.0018763"
      },
      {
          LocalityEnglish: "Friends Colony",
          LocalityHindi: "फ्रेंड्स कॉलोनी",
          Latitude: "28.4805211",
          Longitude: "77.0569996"
      },
      {
          LocalityEnglish: "Gandhi Nagar",
          LocalityHindi: "गाँधी नगर",
          Latitude: "28.4527455",
          Longitude: "77.0096058"
      },
      {
          LocalityEnglish: "Garauli Kalan",
          LocalityHindi: "गाड़ौली कलां",
          Latitude: "28.448124",
          Longitude: "76.9799243"
      },
      {
          LocalityEnglish: "Garden Estate",
          LocalityHindi: "गार्डन एस्टेट",
          Latitude: "28.483643",
          Longitude: "77.1004514"
      },
      {
          LocalityEnglish: "Garoli Khurd",
          LocalityHindi: "गाड़ौली खुर्द",
          Latitude: "28.4407247",
          Longitude: "76.9786102"
      },
      {
          LocalityEnglish: "Ghasola",
          LocalityHindi: "घसोला",
          Latitude: "28.4079819",
          Longitude: "77.0507691"
      },
      {
          LocalityEnglish: "Ghata",
          LocalityHindi: "घाटा",
          Latitude: "28.419866",
          Longitude: "77.0829573"
      },
      {
          LocalityEnglish: "Gopal Nagar",
          LocalityHindi: "गोपाल नगर",
          Latitude: "28.4636547",
          Longitude: "77.0308006"
      },
      {
          LocalityEnglish: "Greenwood City",
          LocalityHindi: "ग्रीनवुड सिटी",
          Latitude: "28.4447682",
          Longitude: "77.0549272"
      },
      {
          LocalityEnglish: "Gurugram",
          LocalityHindi: "गुरुग्राम",
          Latitude: "28.4583576",
          Longitude: "77.0111112"
      },
      {
          LocalityEnglish: "Gurugram Village",
          LocalityHindi: "गुरुग्राम गांव",
          Latitude: "28.4754597",
          Longitude: "77.016949"
      },
      {
          LocalityEnglish: "Gwal Pahari",
          LocalityHindi: "ग्वाल पहाड़ी",
          Latitude: "28.4305778",
          Longitude: "77.1389902"
      },
      {
          LocalityEnglish: "Hans Enclave",
          LocalityHindi: "हंस एंक्लेव",
          Latitude: "28.4457715",
          Longitude: "77.0235659"
      },
      {
          LocalityEnglish: "Hari Nagar",
          LocalityHindi: "हरि नगर",
          Latitude: "28.4462053",
          Longitude: "77.011488"
      },
      {
          LocalityEnglish: "Garhi Harsaru",
          LocalityHindi: "गढ़ी हरसरू",
          Latitude: "28.4512579",
          Longitude: "76.9166503"
      },
      {
          LocalityEnglish: "Heera Nagar",
          LocalityHindi: "हीरा नगर",
          Latitude: "28.4509877",
          Longitude: "77.0145904"
      },
      {
          LocalityEnglish: "HVPNL Colony",
          LocalityHindi: "ह्व्प्न्ल कॉलोनी",
          Latitude: "28.4557795",
          Longitude: "77.0340703"
      },
      {
          LocalityEnglish: "Indira Colony I",
          LocalityHindi: "इंदिरा कॉलोनी १",
          Latitude: "28.4386719",
          Longitude: "77.0653741"
      },
      {
          LocalityEnglish: "Indira Colony II",
          LocalityHindi: "इंदिरा कॉलोनी २",
          Latitude: "28.4400324",
          Longitude: "77.0691286"
      },
      {
          LocalityEnglish: "Industrial Development Area",
          LocalityHindi: "इंडस्ट्रियल डेवेलपमेंट एरिया",
          Latitude: "28.4695049",
          Longitude: "77.0464636"
      },
      {
          LocalityEnglish: "Info Technology Park",
          LocalityHindi: "इन्फो टेक्नोलॉजी पार्क",
          Latitude: "28.43174",
          Longitude: "77.0126534"
      },
      {
          LocalityEnglish: "Islampur Village",
          LocalityHindi: "इस्लामपुर गाँव",
          Latitude: "28.4339487",
          Longitude: "77.0300331"
      },
      {
          LocalityEnglish: "Jacubpura",
          LocalityHindi: "जैकबपुरा",
          Latitude: "28.4624061",
          Longitude: "77.0233866"
      },
      {
          LocalityEnglish: "Jal Vihar Colony",
          LocalityHindi: "जल विहार कॉलोनी",
          Latitude: "28.4371899",
          Longitude: "77.0506151"
      },
      {
          LocalityEnglish: "Jal Vayu Vihar",
          LocalityHindi: "जल वायु विहार",
          Latitude: "28.45877",
          Longitude: "77.0498393"
      },
      {
          LocalityEnglish: "जवाहर नगर",
          LocalityHindi: "जवाहर नगर",
          Latitude: "28.4658726",
          Longitude: "77.0247037"
      },
      {
          LocalityEnglish: "Jharsa",
          LocalityHindi: "झाड़सा",
          Latitude: "28.446175",
          Longitude: "77.047244"
      },
      {
          LocalityEnglish: "Jyoti Park",
          LocalityHindi: "ज्योति पार्क",
          Latitude: "28.4633038",
          Longitude: "77.007557"
      },
      {
          LocalityEnglish: "Kadipur Industrial Area",
          LocalityHindi: "कादीपुर इंडस्ट्रियल एरिया",
          Latitude: "28.4486444",
          Longitude: "76.9880746"
      },
      {
          LocalityEnglish: "Kadipur Village",
          LocalityHindi: "कादीपुर गाँव",
          Latitude: "28.451093",
          Longitude: "76.9954166"
      },
      {
          LocalityEnglish: "Kanahi",
          LocalityHindi: "कनही",
          Latitude: "28.453138",
          Longitude: "77.0702135"
      },
      {
          LocalityEnglish: "Khandsa",
          LocalityHindi: "खान्डसा",
          Latitude: "28.4296115",
          Longitude: "76.9929971"
      },
      {
          LocalityEnglish: "Kherki Daula",
          LocalityHindi: "खेड़की दौला",
          Latitude: "28.4035748",
          Longitude: "76.9735537"
      },
      {
          LocalityEnglish: "Kirti Nagar",
          LocalityHindi: "किर्ति नगर",
          Latitude: "28.4494757",
          Longitude: "77.0324639"
      },
      {
          LocalityEnglish: "Krishna Colony",
          LocalityHindi: "कृष्णा कॉलोनी",
          Latitude: "28.4669005",
          Longitude: "77.008166"
      },
      {
          LocalityEnglish: "Krishna Nagar",
          LocalityHindi: "कृष्णा नगर",
          Latitude: "28.4540789",
          Longitude: "77.0028651"
      },
      {
          LocalityEnglish: "Lajpat Nagar",
          LocalityHindi: "लाजपत नगर",
          Latitude: "28.4675955",
          Longitude: "77.0264933"
      },
      {
          LocalityEnglish: "Laxman Vihar",
          LocalityHindi: "लक्ष्मण विहार",
          Latitude: "28.4809391",
          Longitude: "77.004301"
      },
      {
          LocalityEnglish: "Laxmi Garden",
          LocalityHindi: "लक्ष्मी गार्डन",
          Latitude: "28.4519965",
          Longitude: "77.0144223"
      },
      {
          LocalityEnglish: "Madanpuri",
          LocalityHindi: "मदनपुरी",
          Latitude: "28.4606314",
          Longitude: "77.0101691"
      },
      {
          LocalityEnglish: "Mahavir Pura",
          LocalityHindi: "महावीर पुरा",
          Latitude: "28.4696331",
          Longitude: "77.0227156"
      },
      {
          LocalityEnglish: "Malibu Town",
          LocalityHindi: "मालिबू टाउन",
          Latitude: "28.4224277",
          Longitude: "77.0377062"
      },
      {
          LocalityEnglish: "Manohar Nagar",
          LocalityHindi: "मनोहर नगर",
          Latitude: "28.4624728",
          Longitude: "77.0104887"
      },
      {
          LocalityEnglish: "Maruti Udyog",
          LocalityHindi: "मारुती उद्योग",
          Latitude: "28.4948033",
          Longitude: "77.0601437"
      },
      {
          LocalityEnglish: "Maruti Vihar",
          LocalityHindi: "मारुती विहार",
          Latitude: "28.4754813",
          Longitude: "77.0787319"
      },
      {
          LocalityEnglish: "Mayfield Garden",
          LocalityHindi: "मेफील्ड गार्डन",
          Latitude: "28.4252111",
          Longitude: "77.0530777"
      },
      {
          LocalityEnglish: "Medicity",
          LocalityHindi: "मेडिसिटी",
          Latitude: "28.4384977",
          Longitude: "77.0326843"
      },
      {
          LocalityEnglish: "Mianwali Colony",
          LocalityHindi: "मियांवाली कॉलोनी",
          Latitude: "28.4670206",
          Longitude: "77.0249303"
      },
      {
          LocalityEnglish: "Mohmmadpur Jharsa",
          LocalityHindi: "मोहम्मदपुर झाड़सा",
          Latitude: "28.4197888",
          Longitude: "76.9723604"
      },
      {
          LocalityEnglish: "Mohyal Colony",
          LocalityHindi: "मोह्यल कॉलोनी",
          Latitude: "28.4484335",
          Longitude: "77.0511078"
      },
      {
          LocalityEnglish: "Moti Vihar",
          LocalityHindi: "मोती विहार",
          Latitude: "28.4572839",
          Longitude: "77.0597983"
      },
      {
          LocalityEnglish: "Moulahera",
          LocalityHindi: "मुल्लाहेड़ा",
          Latitude: "28.5073189",
          Longitude: "77.0599322"
      },
      {
          LocalityEnglish: "Nahar Pur Rupa",
          LocalityHindi: "नहर पुर रूपा",
          Latitude: "28.4417895",
          Longitude: "77.0137886"
      },
      {
          LocalityEnglish: "New Basti",
          LocalityHindi: "न्यू बस्ती",
          Latitude: "28.4602809",
          Longitude: "77.0223533"
      },
      {
          LocalityEnglish: "Narsinghpur",
          LocalityHindi: "नर्सिंघ्पुर",
          Latitude: "28.4120089",
          Longitude: "76.9791604"
      },
      {
          LocalityEnglish: "Nathupur",
          LocalityHindi: "नाथूपुर",
          Latitude: "28.4874167",
          Longitude: "77.0908626"
      },
      {
          LocalityEnglish: "New Colony",
          LocalityHindi: "न्यू कॉलोनी",
          Latitude: "28.465525",
          Longitude: "77.0128021"
      },
      {
          LocalityEnglish: "New Palam Vihar Phase I",
          LocalityHindi: "न्यू पलाम विहार फेस १",
          Latitude: "28.5062902",
          Longitude: "77.0066783"
      },
      {
          LocalityEnglish: "New Palam Vihar Phase II",
          LocalityHindi: "न्यू पलाम विहार फेस २",
          Latitude: "28.5194229",
          Longitude: "77.0092232"
      },
      {
          LocalityEnglish: "New Palam Vihar Phase III",
          LocalityHindi: "न्यू पलाम विहार फेस ३",
          Latitude: "28.5143978",
          Longitude: "77.0216573"
      },
      {
          LocalityEnglish: "Nihali Colony",
          LocalityHindi: "निहाली कॉलोनी",
          Latitude: "28.5064641",
          Longitude: "77.0194044"
      },
      {
          LocalityEnglish: "Nirvana Country",
          LocalityHindi: "निरवाना कंट्री",
          Latitude: "28.4152787",
          Longitude: "77.0569709"
      },
      {
          LocalityEnglish: "Nitin Vihar",
          LocalityHindi: "नितिन विहार",
          Latitude: "28.4405875",
          Longitude: "77.0134898"
      },
      {
          LocalityEnglish: "Old DLF Colony",
          LocalityHindi: "ओल्ड डीएलएफ कॉलोनी",
          Latitude: "28.4706886",
          Longitude: "77.0425552"
      },
      {
          LocalityEnglish: "Om Nagar",
          LocalityHindi: "ओम नगर",
          Latitude: "28.4731262",
          Longitude: "76.9145028"
      },
      {
          LocalityEnglish: "Pace City I",
          LocalityHindi: "पेस सिटी १",
          Latitude: "28.439799",
          Longitude: "77.0061786"
      },
      {
          LocalityEnglish: "Pace City II",
          LocalityHindi: "पेस सिटी २",
          Latitude: "28.4304719",
          Longitude: "76.9900301"
      },
      {
          LocalityEnglish: "Pahari",
          LocalityHindi: "पहाड़ी",
          Latitude: "28.3064961",
          Longitude: "76.7055559"
      },
      {
          LocalityEnglish: "Palam Vihar",
          LocalityHindi: "पालम विहार",
          Latitude: "28.5107552",
          Longitude: "77.0158431"
      },
      {
          LocalityEnglish: "Palam Vihar Extension",
          LocalityHindi: "पालम विहार एक्सटेंशन",
          Latitude: "28.4982374",
          Longitude: "77.0307785"
      },
      {
          LocalityEnglish: "Patel Nagar",
          LocalityHindi: "पटेल नगर",
          Latitude: "28.4554002",
          Longitude: "77.030405"
      },
      {
          LocalityEnglish: "Pawala Khasrupur",
          LocalityHindi: "पावला खासरूपुर",
          Latitude: "28.5067995",
          Longitude: "76.99569"
      },
      {
          LocalityEnglish: "Police Lines",
          LocalityHindi: "पुलिस लाइंस",
          Latitude: "28.4624156",
          Longitude: "77.0322736"
      },
      {
          LocalityEnglish: "Pratap Nagar",
          LocalityHindi: "प्रताप नगर",
          Latitude: "28.464084",
          Longitude: "77.0168364"
      },
      {
          LocalityEnglish: "Prem Nagar",
          LocalityHindi: "प्रेम नगर",
          Latitude: "28.4672542",
          Longitude: "77.0311748"
      },
      {
          LocalityEnglish: "Prem Puri",
          LocalityHindi: "प्रेम पुरी",
          Latitude: "28.4483269",
          Longitude: "77.0397746"
      },
      {
          LocalityEnglish: "Raj Nagar",
          LocalityHindi: "राज नगर",
          Latitude: "28.4470758",
          Longitude: "77.0128616"
      },
      {
          LocalityEnglish: "Rajendra Park",
          LocalityHindi: "राजेन्द्र पार्क",
          Latitude: "28.4938373",
          Longitude: "76.9984327"
      },
      {
          LocalityEnglish: "Rajiv Colony",
          LocalityHindi: "राजीव कॉलोनी",
          Latitude: "28.4454344",
          Longitude: "77.0163696"
      },
      {
          LocalityEnglish: "West Rajiv Nagar",
          LocalityHindi: "वेस्ट राजीव नगर",
          Latitude: "28.4731693",
          Longitude: "77.0269903"
      },
      {
          LocalityEnglish: "Ram Nagar",
          LocalityHindi: "राम नगर",
          Latitude: "28.45871",
          Longitude: "77.0210523"
      },
      {
          LocalityEnglish: "Ram Vihar",
          LocalityHindi: "राम विहार",
          Latitude: "28.4787235",
          Longitude: "76.9938276"
      },
      {
          LocalityEnglish: "Ramgarh",
          LocalityHindi: "रामगढ़",
          Latitude: "28.3878738",
          Longitude: "77.0611356"
      },
      {
          LocalityEnglish: "Rattan Garden",
          LocalityHindi: "रत्तन गार्डन",
          Latitude: "28.4680985",
          Longitude: "77.0170504"
      },
      {
          LocalityEnglish: "Ravi Nagar",
          LocalityHindi: "रवि नगर",
          Latitude: "28.4598107",
          Longitude: "76.9996829"
      },
      {
          LocalityEnglish: "Rosewood City",
          LocalityHindi: "रोसवुड सिटी",
          Latitude: "28.4079819",
          Longitude: "77.0507691"
      },
      {
          LocalityEnglish: "Roshanpura",
          LocalityHindi: "रोशन पुरा",
          Latitude: "28.4601659",
          Longitude: "77.0274384"
      },
      {
          LocalityEnglish: "Saini Khera Village",
          LocalityHindi: "सैनी खेड़ा गाँव",
          Latitude: "28.4662584",
          Longitude: "77.0556668"
      },
      {
          LocalityEnglish: "Samaspur Village",
          LocalityHindi: "समसपुर गाँव",
          Latitude: "28.4271835",
          Longitude: "77.0609606"
      },
      {
          LocalityEnglish: "Sanjay Colony",
          LocalityHindi: "संजय कॉलोनी",
          Latitude: "28.4697654",
          Longitude: "77.0238049"
      },
      {
          LocalityEnglish: "Sarai Alawardi",
          LocalityHindi: "सराय अलावर्दी",
          Latitude: "28.5007501",
          Longitude: "77.0157945"
      },
      {
          LocalityEnglish: "Saraswati Enclave",
          LocalityHindi: "सरस्वती एंक्लेव",
          Latitude: "28.4452324",
          Longitude: "76.9909429"
      },
      {
          LocalityEnglish: "Saraswati Vihar",
          LocalityHindi: "सरस्वती विहार",
          Latitude: "28.4758317",
          Longitude: "77.0798275"
      },
      {
          LocalityEnglish: "Sarhol",
          LocalityHindi: "सरहौल",
          Latitude: "28.4844028",
          Longitude: "77.0676885"
      },
      {
          LocalityEnglish: "Sarhaul Abadi Village",
          LocalityHindi: "सरहौल आबादी गाँव",
          Latitude: "28.4875164",
          Longitude: "77.0652305"
      },
      {
          LocalityEnglish: "Sector 10",
          LocalityHindi: "सेक्टर १०",
          Latitude: "28.4529252",
          Longitude: "76.9929954"
      },
      {
          LocalityEnglish: "Sector 100",
          LocalityHindi: "सेक्टर १००",
          Latitude: "28.4606926",
          Longitude: "76.9627046"
      },
      {
          LocalityEnglish: "Sector 101",
          LocalityHindi: "सेक्टर १०१",
          Latitude: "28.4691267",
          Longitude: "76.9714581"
      },
      {
          LocalityEnglish: "Sector 102",
          LocalityHindi: "सेक्टर १०२",
          Latitude: "28.476311",
          Longitude: "76.9628548"
      },
      {
          LocalityEnglish: "Sector 103",
          LocalityHindi: "सेक्टर १०३",
          Latitude: "28.4952451",
          Longitude: "76.9678757"
      },
      {
          LocalityEnglish: "Sector 104",
          LocalityHindi: "सेक्टर १०४",
          Latitude: "28.4801984",
          Longitude: "76.9770024"
      },
      {
          LocalityEnglish: "Sector 106",
          LocalityHindi: "सेक्टर १०६",
          Latitude: "28.4998857",
          Longitude: "76.9915472"
      },
      {
          LocalityEnglish: "Sector 109",
          LocalityHindi: "सेक्टर १०९",
          Latitude: "28.5081372",
          Longitude: "77.0002102"
      },
      {
          LocalityEnglish: "Sector 10A",
          LocalityHindi: "सेक्टर १०ए",
          Latitude: "28.4458228",
          Longitude: "77.000562"
      },
      {
          LocalityEnglish: "Sector 111",
          LocalityHindi: "सेक्टर १११",
          Latitude: "28.5262612",
          Longitude: "77.0238043"
      },
      {
          LocalityEnglish: "Sector 112",
          LocalityHindi: "सेक्टर ११२",
          Latitude: "28.5301476",
          Longitude: "77.0076918"
      },
      {
          LocalityEnglish: "Sector 113",
          LocalityHindi: "सेक्टर ११३",
          Latitude: "28.5291392",
          Longitude: "77.0156627"
      },
      {
          LocalityEnglish: "Sector 14",
          LocalityHindi: "सेक्टर १४",
          Latitude: "28.4725793",
          Longitude: "77.035734"
      },
      {
          LocalityEnglish: "Sector 15",
          LocalityHindi: "सेक्टर १५",
          Latitude: "28.4611708",
          Longitude: "77.0371412"
      },
      {
          LocalityEnglish: "Sector 15 Part 1",
          LocalityHindi: "सेक्टर १५ भाग १",
          Latitude: "28.4508467",
          Longitude: "77.0292147"
      },
      {
          LocalityEnglish: "Sector 15 Part 2",
          LocalityHindi: "सेक्टर १५ भाग २",
          Latitude: "28.4600877",
          Longitude: "77.0361672"
      },
      {
          LocalityEnglish: "Sector 16",
          LocalityHindi: "सेक्टर १६",
          Latitude: "28.4678231",
          Longitude: "77.0433681"
      },
      {
          LocalityEnglish: "Sector 17",
          LocalityHindi: "सेक्टर १७",
          Latitude: "28.4789529",
          Longitude: "77.042341"
      },
      {
          LocalityEnglish: "Sector 18",
          LocalityHindi: "सेक्टर १८",
          Latitude: "28.4915083",
          Longitude: "77.0535268"
      },
      {
          LocalityEnglish: "Sector 21",
          LocalityHindi: "सेक्टर २१",
          Latitude: "28.5135657",
          Longitude: "77.0635688"
      },
      {
          LocalityEnglish: "Sector 22",
          LocalityHindi: "सेक्टर २२",
          Latitude: "28.5069834",
          Longitude: "77.0599766"
      },
      {
          LocalityEnglish: "Sector 23",
          LocalityHindi: "सेक्टर २३",
          Latitude: "28.5097757",
          Longitude: "77.0447764"
      },
      {
          LocalityEnglish: "Sector 23A",
          LocalityHindi: "सेक्टर २३ए",
          Latitude: "28.5062395",
          Longitude: "77.0387807"
      },
      {
          LocalityEnglish: "Sector 27",
          LocalityHindi: "सेक्टर २७",
          Latitude: "28.4653924",
          Longitude: "77.087005"
      },
      {
          LocalityEnglish: "Sector 28",
          LocalityHindi: "सेक्टर २८",
          Latitude: "28.4737331",
          Longitude: "77.0742847"
      },
      {
          LocalityEnglish: "Sector 29",
          LocalityHindi: "सेक्टर २९",
          Latitude: "28.4680117",
          Longitude: "77.0571802"
      },
      {
          LocalityEnglish: "Sector 30",
          LocalityHindi: "सेक्टर ३०",
          Latitude: "28.4623112",
          Longitude: "77.0480372"
      },
      {
          LocalityEnglish: "Sector 31",
          LocalityHindi: "सेक्टर ३१",
          Latitude: "28.4530507",
          Longitude: "77.0405882"
      },
      {
          LocalityEnglish: "Sector 31A",
          LocalityHindi: "सेक्टर ३१ए",
          Latitude: "28.4547575",
          Longitude: "77.0458157"
      },
      {
          LocalityEnglish: "Sector 32",
          LocalityHindi: "सेक्टर ३२",
          Latitude: "28.4461597",
          Longitude: "77.0325802"
      },
      {
          LocalityEnglish: "Sector 31 - 32A",
          LocalityHindi: "सेक्टर ३१ - ३२ए",
          Latitude: "28.4529737",
          Longitude: "77.038299"
      },
      {
          LocalityEnglish: "Sector 33",
          LocalityHindi: "सेक्टर ३३",
          Latitude: "28.4372898",
          Longitude: "77.0059953"
      },
      {
          LocalityEnglish: "Sector 34",
          LocalityHindi: "सेक्टर ३४",
          Latitude: "28.4287585",
          Longitude: "76.9989527"
      },
      {
          LocalityEnglish: "Sector 35",
          LocalityHindi: "सेक्टर ३५",
          Latitude: "28.4181572",
          Longitude: "76.9931747"
      },
      {
          LocalityEnglish: "Sector 36",
          LocalityHindi: "सेक्टर ३६",
          Latitude: "28.4183663",
          Longitude: "76.9701798"
      },
      {
          LocalityEnglish: "Sector 36A",
          LocalityHindi: "सेक्टर ३६ए",
          Latitude: "28.4140117",
          Longitude: "76.9686913"
      },
      {
          LocalityEnglish: "Sector 36B",
          LocalityHindi: "सेक्टर ३६बी",
          Latitude: "28.4160622",
          Longitude: "76.9683988"
      },
      {
          LocalityEnglish: "Sector 37",
          LocalityHindi: "सेक्टर ३७",
          Latitude: "28.4363554",
          Longitude: "76.9814689"
      },
      {
          LocalityEnglish: "Sector 37A",
          LocalityHindi: "सेक्टर ३७ए",
          Latitude: "28.4407645",
          Longitude: "76.9879651"
      },
      {
          LocalityEnglish: "Sector 37B",
          LocalityHindi: "सेक्टर ३७बी",
          Latitude: "28.4368702",
          Longitude: "76.9762828"
      },
      {
          LocalityEnglish: "Sector 37C",
          LocalityHindi: "सेक्टर ३७सी",
          Latitude: "28.44982",
          Longitude: "76.9788043"
      },
      {
          LocalityEnglish: "Sector 37D",
          LocalityHindi: "सेक्टर ३७डी",
          Latitude: "28.4460512",
          Longitude: "76.9598192"
      },
      {
          LocalityEnglish: "Sector 38",
          LocalityHindi: "सेक्टर ३८",
          Latitude: "28.4374112",
          Longitude: "77.0326843"
      },
      {
          LocalityEnglish: "Sector 39",
          LocalityHindi: "सेक्टर ३९",
          Latitude: "28.4424958",
          Longitude: "77.0412821"
      },
      {
          LocalityEnglish: "Sector 4",
          LocalityHindi: "सेक्टर ४",
          Latitude: "28.4739845",
          Longitude: "77.0012239"
      },
      {
          LocalityEnglish: "Sector 40",
          LocalityHindi: "सेक्टर ४०",
          Latitude: "28.449803",
          Longitude: "77.0471431"
      },
      {
          LocalityEnglish: "Sector 41",
          LocalityHindi: "सेक्टर ४१",
          Latitude: "28.4569402",
          Longitude: "77.0550257"
      },
      {
          LocalityEnglish: "Sector 42",
          LocalityHindi: "सेक्टर ४२",
          Latitude: "28.4584693",
          Longitude: "77.0998867"
      },
      {
          LocalityEnglish: "Sector 43",
          LocalityHindi: "सेक्टर ४३",
          Latitude: "28.4597107",
          Longitude: "77.0685102"
      },
      {
          LocalityEnglish: "Sector 44",
          LocalityHindi: "सेक्टर ४४",
          Latitude: "28.4493124",
          Longitude: "77.0712039"
      },
      {
          LocalityEnglish: "Sector 45",
          LocalityHindi: "सेक्टर ४५",
          Latitude: "28.4479239",
          Longitude: "77.0495268"
      },
      {
          LocalityEnglish: "Sector 46",
          LocalityHindi: "सेक्टर ४६",
          Latitude: "28.4358402",
          Longitude: "77.0495017"
      },
      {
          LocalityEnglish: "Sector 47",
          LocalityHindi: "सेक्टर ४७",
          Latitude: "28.4258673",
          Longitude: "77.0388188"
      },
      {
          LocalityEnglish: "Sector 48",
          LocalityHindi: "सेक्टर ४८",
          Latitude: "28.4148958",
          Longitude: "77.0183974"
      },
      {
          LocalityEnglish: "Sector 49",
          LocalityHindi: "सेक्टर ४९",
          Latitude: "28.4111858",
          Longitude: "77.0409179"
      },
      {
          LocalityEnglish: "Sector 5",
          LocalityHindi: "सेक्टर ५",
          Latitude: "28.480338",
          Longitude: "77.0111515"
      },
      {
          LocalityEnglish: "Sector 50",
          LocalityHindi: "सेक्टर ५०",
          Latitude: "28.4165545",
          Longitude: "77.0547047"
      },
      {
          LocalityEnglish: "Sector 51",
          LocalityHindi: "सेक्टर ५१",
          Latitude: "28.4287513",
          Longitude: "77.0595882"
      },
      {
          LocalityEnglish: "Sector 52",
          LocalityHindi: "सेक्टर ५२",
          Latitude: "28.4383792",
          Longitude: "77.0705457"
      },
      {
          LocalityEnglish: "Sector 52A",
          LocalityHindi: "सेक्टर ५२ए",
          Latitude: "28.4400013",
          Longitude: "77.0782707"
      },
      {
          LocalityEnglish: "Sector 53",
          LocalityHindi: "सेक्टर ५३",
          Latitude: "28.4406742",
          Longitude: "77.0878763"
      },
      {
          LocalityEnglish: "Sector 54",
          LocalityHindi: "सेक्टर ५४",
          Latitude: "28.4404333",
          Longitude: "77.1022201"
      },
      {
          LocalityEnglish: "Sector 55",
          LocalityHindi: "सेक्टर ५५",
          Latitude: "28.4253662",
          Longitude: "77.1034272"
      },
      {
          LocalityEnglish: "Sector 56",
          LocalityHindi: "सेक्टर ५६",
          Latitude: "28.424351",
          Longitude: "77.0918199"
      },
      {
          LocalityEnglish: "Sector 57",
          LocalityHindi: "सेक्टर ५७",
          Latitude: "28.4215538",
          Longitude: "77.0692993"
      },
      {
          LocalityEnglish: "Sector 58",
          LocalityHindi: "सेक्टर ५८",
          Latitude: "28.4180613",
          Longitude: "77.1015027"
      },
      {
          LocalityEnglish: "Sector 60",
          LocalityHindi: "सेक्टर ६०",
          Latitude: "28.4000662",
          Longitude: "77.0932587"
      },
      {
          LocalityEnglish: "Sector 61",
          LocalityHindi: "सेक्टर ६१",
          Latitude: "28.4102027",
          Longitude: "77.0879422"
      },
      {
          LocalityEnglish: "Sector 62",
          LocalityHindi: "सेक्टर ६२",
          Latitude: "28.4083615",
          Longitude: "77.0749221"
      },
      {
          LocalityEnglish: "Sector 65",
          LocalityHindi: "सेक्टर ६५",
          Latitude: "28.4031617",
          Longitude: "77.0610897"
      },
      {
          LocalityEnglish: "Sector 66",
          LocalityHindi: "सेक्टर ६६",
          Latitude: "28.3978128",
          Longitude: "77.0465633"
      },
      {
          LocalityEnglish: "Sector 67",
          LocalityHindi: "सेक्टर ६७",
          Latitude: "28.3866229",
          Longitude: "77.0515191"
      },
      {
          LocalityEnglish: "Sector 68",
          LocalityHindi: "सेक्टर ६८",
          Latitude: "28.3854498",
          Longitude: "77.0398235"
      },
      {
          LocalityEnglish: "Sector 69",
          LocalityHindi: "सेक्टर ६९",
          Latitude: "28.3976152",
          Longitude: "77.0295513"
      },
      {
          LocalityEnglish: "Sector 7",
          LocalityHindi: "सेक्टर ७",
          Latitude: "28.4645981",
          Longitude: "77.0036366"
      },
      {
          LocalityEnglish: "Sector 7 Extension",
          LocalityHindi: "सेक्टर ७ एक्सटेंशन",
          Latitude: "28.4641723",
          Longitude: "77.0035046"
      },
      {
          LocalityEnglish: "Sector 7 Housing Board",
          LocalityHindi: "सेक्टर ७ हाऊसिंग बोर्ड कॉलोनी",
          Latitude: "28.4660629",
          Longitude: "77.0046513"
      },
      {
          LocalityEnglish: "Sector 70",
          LocalityHindi: "सेक्टर ७०",
          Latitude: "28.3974945",
          Longitude: "77.0184382"
      },
      {
          LocalityEnglish: "Sector 71",
          LocalityHindi: "सेक्टर ७१",
          Latitude: "28.4087672",
          Longitude: "77.0141367"
      },
      {
          LocalityEnglish: "Sector 72",
          LocalityHindi: "सेक्टर ७२",
          Latitude: "28.4152314",
          Longitude: "77.0094532"
      },
      {
          LocalityEnglish: "Sector 72A",
          LocalityHindi: "सेक्टर ७२ए",
          Latitude: "28.4234758",
          Longitude: "77.0116491"
      },
      {
          LocalityEnglish: "Sector 73",
          LocalityHindi: "सेक्टर ७३",
          Latitude: "28.4098202",
          Longitude: "77.0045002"
      },
      {
          LocalityEnglish: "Sector 74",
          LocalityHindi: "सेक्टर ७४",
          Latitude: "28.4167997",
          Longitude: "77.0030727"
      },
      {
          LocalityEnglish: "Sector 74A",
          LocalityHindi: "सेक्टर ७४ए",
          Latitude: "28.4070842",
          Longitude: "76.989447"
      },
      {
          LocalityEnglish: "Sector 75",
          LocalityHindi: "सेक्टर ७५",
          Latitude: "28.3943687",
          Longitude: "76.9999797"
      },
      {
          LocalityEnglish: "Sector 75A",
          LocalityHindi: "सेक्टर ७५ए",
          Latitude: "28.3971867",
          Longitude: "76.9879561"
      },
      {
          LocalityEnglish: "Sector 76",
          LocalityHindi: "सेक्टर ७६",
          Latitude: "28.3932632",
          Longitude: "76.9812225"
      },
      {
          LocalityEnglish: "Sector 83",
          LocalityHindi: "सेक्टर ८३",
          Latitude: "28.3943067",
          Longitude: "76.9612936"
      },
      {
          LocalityEnglish: "Sector 84",
          LocalityHindi: "सेक्टर ८४",
          Latitude: "28.4028961",
          Longitude: "76.9609682"
      },
      {
          LocalityEnglish: "Sector 88",
          LocalityHindi: "सेक्टर ८८",
          Latitude: "28.4211832",
          Longitude: "76.9487142"
      },
      {
          LocalityEnglish: "Sector 9",
          LocalityHindi: "सेक्टर ९",
          Latitude: "28.4616012",
          Longitude: "76.9916909"
      },
      {
          LocalityEnglish: "Sector 9A",
          LocalityHindi: "सेक्टर ९ए",
          Latitude: "28.471442",
          Longitude: "76.9874568"
      },
      {
          LocalityEnglish: "Sector 9B",
          LocalityHindi: "सेक्टर ९बी",
          Latitude: "28.4604475",
          Longitude: "76.9730497"
      },
      {
          LocalityEnglish: "Shakti Nagar",
          LocalityHindi: "शक्ति नगर",
          Latitude: "28.4552975",
          Longitude: "77.0144422"
      },
      {
          LocalityEnglish: "Shakti Park Colony",
          LocalityHindi: "शक्ति पार्क कॉलोनी",
          Latitude: "28.4499964",
          Longitude: "77.0032998"
      },
      {
          LocalityEnglish: "Shanti Nagar",
          LocalityHindi: "शांति नगर",
          Latitude: "28.4550869",
          Longitude: "77.0099243"
      },
      {
          LocalityEnglish: "Sheetla Colony",
          LocalityHindi: "शीतला कॉलोनी",
          Latitude: "28.4838092",
          Longitude: "77.0192117"
      },
      {
          LocalityEnglish: "Shiv Colony",
          LocalityHindi: "शिव कॉलोनी",
          Latitude: "28.439432",
          Longitude: "77.0161908"
      },
      {
          LocalityEnglish: "Shiv Nagar",
          LocalityHindi: "शिव नगर",
          Latitude: "28.4514303",
          Longitude: "77.0070925"
      },
      {
          LocalityEnglish: "Shivaji Nagar",
          LocalityHindi: "शिवाजी नगर",
          Latitude: "28.4514022",
          Longitude: "77.0183427"
      },
      {
          LocalityEnglish: "Shivji Park Colony",
          LocalityHindi: "शिवजी पार्क कॉलोनी",
          Latitude: "28.4479759",
          Longitude: "77.0113443"
      },
      {
          LocalityEnglish: "Shivpuri",
          LocalityHindi: "शिवपुरी",
          Latitude: "28.4698409",
          Longitude: "77.0141413"
      },
      {
          LocalityEnglish: "Sihi",
          LocalityHindi: "सीही",
          Latitude: "28.406211",
          Longitude: "76.9651345"
      },
      {
          LocalityEnglish: "Sikanderpur Ghosi",
          LocalityHindi: "सिकंदरपुर घोसी",
          Latitude: "28.477544",
          Longitude: "77.085181"
      },
      {
          LocalityEnglish: "Silokhera",
          LocalityHindi: "सिलोखेरा",
          Latitude: "28.456836",
          Longitude: "77.0573708"
      },
      {
          LocalityEnglish: "South City I",
          LocalityHindi: "साउथ सिटी १",
          Latitude: "28.4570333",
          Longitude: "77.0465272"
      },
      {
          LocalityEnglish: "Subhash Nagar",
          LocalityHindi: "सुभाष नगर",
          Latitude: "28.4661895",
          Longitude: "77.0194952"
      },
      {
          LocalityEnglish: "Sukhrali",
          LocalityHindi: "सुखराली",
          Latitude: "28.4764268",
          Longitude: "77.0547701"
      },
      {
          LocalityEnglish: "Suncity",
          LocalityHindi: "सनसिटी",
          Latitude: "28.4364317",
          Longitude: "77.1033782"
      },
      {
          LocalityEnglish: "Surat Nagar Phase II",
          LocalityHindi: "सूरत नगर फेस २",
          Latitude: "28.4817874",
          Longitude: "76.9973031"
      },
      {
          LocalityEnglish: "Surya Vihar",
          LocalityHindi: "सूर्या विहार",
          Latitude: "28.4718232",
          Longitude: "76.9919184"
      },
      {
          LocalityEnglish: "Sushant Lok II",
          LocalityHindi: "सुशांत लोक २",
          Latitude: "28.4256367",
          Longitude: "77.1034272"
      },
      {
          LocalityEnglish: "Sushant Lok III",
          LocalityHindi: "सुशांत लोक ३",
          Latitude: "28.4203856",
          Longitude: "77.0699261"
      },
      {
          LocalityEnglish: "Sushant Lok III Extension",
          LocalityHindi: "सुशांत लोक ३ एक्सटेंशन",
          Latitude: "28.4180178",
          Longitude: "77.0673157"
      },
      {
          LocalityEnglish: "Sushant Lok I",
          LocalityHindi: "सुशांत लोक १",
          Latitude: "28.4633621",
          Longitude: "77.0638023"
      },
      {
          LocalityEnglish: "Tek Chand Nagar",
          LocalityHindi: "टेक चंद नगर",
          Latitude: "28.4812733",
          Longitude: "76.9886142"
      },
      {
          LocalityEnglish: "The National Media Centre",
          LocalityHindi: "द नेशनल मीडिया सेंटर",
          Latitude: "28.4995895",
          Longitude: "77.0892496"
      },
      {
          LocalityEnglish: "Tigra",
          LocalityHindi: "तिघरा",
          Latitude: "28.4182543",
          Longitude: "77.0615952"
      },
      {
          LocalityEnglish: "Tikri",
          LocalityHindi: "टिकरी",
          Latitude: "28.4199108",
          Longitude: "77.0313455"
      },
      {
          LocalityEnglish: "Udyog Vihar Phase I",
          LocalityHindi: "उद्योग विहार फेस १",
          Latitude: "28.5109251",
          Longitude: "77.07964"
      },
      {
          LocalityEnglish: "Udyog Vihar Phase II",
          LocalityHindi: "उद्योग विहार फेस २",
          Latitude: "28.5052735",
          Longitude: "77.0847016"
      },
      {
          LocalityEnglish: "Udyog Vihar Phase III",
          LocalityHindi: "उद्योग विहार फेस ३",
          Latitude: "28.5002224",
          Longitude: "77.0788914"
      },
      {
          LocalityEnglish: "Udyog Vihar Phase IV",
          LocalityHindi: "उद्योग विहार फेस ४",
          Latitude: "28.4972527",
          Longitude: "77.0665737"
      },
      {
          LocalityEnglish: "Udyog Vihar Phase V",
          LocalityHindi: "उद्योग विहार फेस ५",
          Latitude: "28.5015765",
          Longitude: "77.0775862"
      },
      {
          LocalityEnglish: "Udyog Vihar Phase VI",
          LocalityHindi: "उद्योग विहार फेस ६",
          Latitude: "28.4376007",
          Longitude: "76.9910523"
      },
      {
          LocalityEnglish: "Uppal Southend",
          LocalityHindi: "उप्पल साउथएंड",
          Latitude: "28.4087443",
          Longitude: "77.0406451"
      },
      {
          LocalityEnglish: "Veer Nagar",
          LocalityHindi: "वीर नगर",
          Latitude: "28.4583648",
          Longitude: "77.0156823"
      },
      {
          LocalityEnglish: "Vijay Vihar",
          LocalityHindi: "विजय विहार",
          Latitude: "28.459762",
          Longitude: "77.0530325"
      },
      {
          LocalityEnglish: "Vikas Nagar",
          LocalityHindi: "विकास नगर",
          Latitude: "28.4547613",
          Longitude: "76.9873132"
      },
      {
          LocalityEnglish: "Vishwakarma Colony",
          LocalityHindi: "विश्वकर्मा कॉलोनी",
          Latitude: "28.4587325",
          Longitude: "76.9971277"
      },
      {
          LocalityEnglish: "Wazirabad",
          LocalityHindi: "वज़ीराबाद",
          Latitude: "28.4365762",
          Longitude: "77.0748342"
      }
];
