import { INewListingJSONData } from "./addListingDescription"
import { IDropzoneFile } from "./dropzone"

export const LOCAL_STORAGE_NEW_THIKANA_SAVE_FLAG =
  "LOCAL_STORAGE_NEW_THIKANA_SAVE_FLAG"

export const resetLocalStorage = () => {
  // localStorage.removeItem(LOCAL_STORAGE_NEW_THIKANA_LOCATION_FLAG);
  localStorage.removeItem(LOCAL_STORAGE_NEW_THIKANA_SAVE_FLAG)
}

export const saveListingData = (data: INewListingJSONData) => {
  localStorage.setItem(
    LOCAL_STORAGE_NEW_THIKANA_SAVE_FLAG,
    JSON.stringify(data)
  )
}

export const getListingData = (): INewListingJSONData | {} => {
  const data = localStorage.getItem(LOCAL_STORAGE_NEW_THIKANA_SAVE_FLAG) as any

  if (!data) return {}
  return JSON.parse(data)
}

export const getLocation = () => {
  const savedData = localStorage.getItem(LOCAL_STORAGE_NEW_THIKANA_SAVE_FLAG)

  if (savedData) {
    const data = JSON.parse(savedData) as INewListingJSONData
    if (data.location) return data.location
    else return null
  } else return null
}

export const parseUrlToFile = (url: string): IDropzoneFile => {
  return {
    preview: url,
    url,
    thumbnailUrl: null,
    uploadFailed: false,
    uploadQueued: false,
    file: null,
    uploaded: true,
  }
}

export const getFiles = () => {
  const savedData = localStorage.getItem(LOCAL_STORAGE_NEW_THIKANA_SAVE_FLAG)

  if (savedData) {
    const data = JSON.parse(savedData) as INewListingJSONData
    if (data.contentIDs) {
      const files: IDropzoneFile[] = []
      data.contentIDs.forEach(url => {
        files.push(parseUrlToFile(url))
      })
      return files
    } else return []
  } else return []
}
