import React from "react";
import styled from "styled-components";
import { colors } from "../../Constants/colors";
import { size } from "../../Constants/sizesAndSpacing";
import { breakpoints } from "../../Constants/responsive";
import Footer from  "../Footer/footer";

const Wrapper = styled.div`
  width: 100%;
  height: calc(100% - ${size.headerHeight + "px"});
  overflow-y: scroll;
`;

const LeftHalfDiv = styled.div`
  align-items: center;
  // padding-top: 2em;
  // padding-bottom: 2em;
  display: block;
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  margin-right: 0;
  margin-left: auto;
  @media (min-width: ${breakpoints.tablet + "px"}) {
  }
  @media (min-width: ${breakpoints.desktop + "px"}) {
  }
`;

const RightHalfDiv = styled.div`
  // padding: 2em;
  margin-left: 2%;
  margin-right: 2%;
  display: block;
  width: 100%;
  box-sizing: border-box;
  @media (min-width: ${breakpoints.tablet + "px"}) {
  }
  @media (min-width: ${breakpoints.desktop + "px"}) {
  }
`;

const ContactImageAvt = styled.img`
  height: calc(80vh - ${size.headerHeight + "px"});
  width: auto;
  display: float;
  margin: auto;
`;

const TextHeading = styled.h2`
  color: ${colors.secondary};
  font-weight: 600;

  @media only screen and (min-width: ${breakpoints.mobile + "px"}) {
    font-size: 1.5em;
  }
  @media only screen and (min-width: ${breakpoints.tablet + "px"}) {
    font-size: 1.725em;
  }

  @media only screen and (min-width: ${breakpoints.desktop + "px"}) {
    font-size: 2em;
  }
`;

const TextBody = styled.p`
  color: ${colors.secondary};

  @media only screen and (min-width: ${breakpoints.mobile + "px"}) {
    font-size: 0.875em;
  }
  @media only screen and (min-width: ${breakpoints.tablet + "px"}) {
    font-size: 1em;
  }

  @media only screen and (min-width: ${breakpoints.desktop + "px"}) {
    font-size: 1.2em;
  }
`;

const SpacingBlock = styled.div`
  padding: 2%;
`;

const DivContainer = styled.div`
  display: block;
  justify-content: space-evenly;
  margin: auto;
  width: 100%;
  @media (min-width: ${breakpoints.tablet + "px"}) {
    display: flex;
    align-items: center;
    text-align: center;
  }
  @media (min-width: ${breakpoints.desktop + "px"}) {
    display: flex;
    align-items: center;
    text-align: center;
  }
`;

const ContactScreen = () => {
  return (
    <Wrapper>
      <SpacingBlock></SpacingBlock>
      <DivContainer>
      <LeftHalfDiv>
          <ContactImageAvt src="/img/contact.png"></ContactImageAvt>
      </LeftHalfDiv>

      <RightHalfDiv>
          <TextHeading> For customer and technical support</TextHeading>
          <TextBody>
            Contact us by email: contact@ekthikana.com
            <SpacingBlock></SpacingBlock>
            Address: <br/>
            EkThikana <br/>
            Attn: Customer Support <br/>
            766/4, Second Floor <br/>
            Urban Estate, Gurugram, <br/>
            Haryana 122001 <br/>
          </TextBody>
      </RightHalfDiv>
    </DivContainer>
      <Footer></Footer>
    </Wrapper>

);
};

export default ContactScreen;
