import React from "react";
import styled, { keyframes } from "styled-components";
import { breakpoints } from "../../Constants/responsive";
import { size, spacing } from "../../Constants/sizesAndSpacing";
import { colors } from "../../Constants/colors";

const shine = keyframes`
  0% {
    background-position: right;
  }
`;

const GreyLoaderBoxOutline = styled.div`
  flex: 1 0 ${size.listingMinWidth + 35 + "px"};
  margin: ${spacing.listingMargin / 2 + "px"};
  box-shadow: 0 0 5px ${colors.shadow};
  font-size: 1em;
  box-sizing: border-box;
  border-top: 3px solid rgba(0, 0, 0, 0.2);
  background: ${colors.shadow};
  height: 230px;
  opacity: 0.8;

  background-color: ${colors.light};
  background-image: linear-gradient(
    to right,
    transparent 33%,
    rgba(255, 255, 255, 0.3) 50%,
    transparent 66%
  );
  background-size: 300% 100%;
  animation: ${shine} 1s infinite;

  @media (min-width: ${breakpoints.mobile + "px"}) {
    flex: 1 0 ${size.listingMinWidth + "px"};
  }
`;

const Image = styled.div`
  width: 100%;
  height: 150px;
  background: rgba(0, 0, 0, 0.1);
  margin-bottom: 7px;
  opacity: 0.5;
`;

const Bar = styled.div<{ width: number }>`
  width: ${props => props.width + "%"};
  height: 1em;
  margin-bottom: 5px;
  background: rgba(0, 0, 0, 0.1);
  margin-left: 5%;
  opacity: 0.5;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 5%;
`;

const GreyLoaderBox = () => {
  return (
    <GreyLoaderBoxOutline>
      <Image></Image>
      <Row>
        <Bar width={30}></Bar>
        <Bar width={30}></Bar>
      </Row>

      <Bar width={50}></Bar>
      <Bar width={20}></Bar>
    </GreyLoaderBoxOutline>
  );
};

export default GreyLoaderBox;
