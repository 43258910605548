import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { breakpoints } from "../../Constants/responsive"
import { colors } from "../../Constants/colors"
import { resetLocalStorage, getListingData } from "./listingUtils"
import { size, spacing } from "../../Constants/sizesAndSpacing"
import { useHistory } from "react-router"
import Axios from "axios"
import { useAuth } from "../../Hooks/useAuth"
import { IServerListing, mockImages } from "../../mockListings"
import { INewListingJSONData } from "./addListingDescription"
import { ListingDescription } from "../Listings/ListingCard/listingCard"
import ListingImage from "../Listings/ListingCard/listingImage"
import { baseURL, clientURL } from "../../Constants/serverConfig"
import Loader from "../Loader/loader"
import Footer from "../Footer/footer"
import useModal from "../../Hooks/useModal"
import SmallModal from "../Modal/smallModal"

const PageContainer = styled.div`
  width: 100%;
  height: calc(100% - ${size.headerHeight + "px"});
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
  font-size: 0.875em;
  margin: auto;

  @media (min-width: ${breakpoints.mobile + "px"}) {
    font-size: 0.9375em;
  }
  @media (min-width: ${breakpoints.tablet + "px"}) {
    font-size: 1em;
  }
`

const Banner = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 1.5em;

  @media (min-width: ${breakpoints.mobile + "px"}) {
    padding: 2em;
  }
  @media (min-width: ${breakpoints.tablet + "px"}) {
    padding: 2.5em;
  }
`

const NewListing = styled.button`
  padding: 0.625em 1.75em;
  font-size: 0.875em;
  font-weight: 600;
  align-items: center;
  text-align: center;
  // display: block;
  box-sizing: border-box;
  width: auto;
  cursor: pointer;
  height: 2.625em;
  background-color: ${colors.primary};
  color: white;
  // margin-right: 0.5em;
  text-decoration: none;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  border: none;
  // position: absolute;
  // right: 25px;
  z-index: 10;
  opacity: 0.9;
  top: 25px;
  &:hover {
    opacity: 1;
  }
`

const MainLabel = styled.div`
  // padding: 1em;
  font-size: 1.6em;
`

const ListingCardWrapper = styled.div`
  flex: 1 0 ${size.listingMinWidth + 35 + "px"};
  margin: ${spacing.listingMargin / 2 + "px"};
  box-shadow: 0 0 2.5px ${colors.shadow};
  font-size: 1em;
  padding: 1em;
  box-sizing: border-box;
  // border-left: 3px solid ${colors.primary};
  position: relative;
  max-width: 350px;
  // @media (min-width: ${breakpoints.tablet + "px"}) {
  // max-width: 350px;
  // }
`

const ListingFlexBox = styled.div`
  display: flex;
  // top: 0;
  flex-flow: row wrap;
  // justify-content: space-evenly;
  // align-items: center;
  // align-content: center;
  // margin: ${"0 -" + spacing.listingMargin / 2 + "px"};
  // overflow-x: hidden;
  padding: 0 1.5em;

  @media (min-width: ${breakpoints.mobile + "px"}) {
    padding: 0 2em;
  }
  @media (min-width: ${breakpoints.tablet + "px"}) {
    padding: 0 2.5em;
  }
`

const ListingLineSpacing = styled.div`
  height: ${spacing.listingCardLineSpacing + "em"};
`

const ContinueEditingBoxMessage = styled.div`
  position: absolute;
  z-index: 10;
  bottom: 5px;
  right: 5px;
  font-size: 0.675em;
  padding: 0.2em 0.5em;
  background: ${colors.midprimary};
  color: ${colors.primary};
  border-radius: 5px;
  @media (min-width: ${breakpoints.mobile + "px"}) {
    bottom: 10px;
    right: 10px;
  }
  @media (min-width: ${breakpoints.tablet + "px"}) {
    bottom: 15px;
    right: 15px;
  }
`

// const ButtonRow = styled.div`
//   // position: absolute;
//   bottom: 0;
//   right: 0;
//   padding: 1em;
//   width: 100%;
//   // display: flex;
//   // justify-content: flex-end;
//   font-size: 0.8em;
// `

const ListingButton = styled.div`
  // background-color: ${colors.midprimary};
  color: ${colors.secondary};
  // padding: 0.5em;
  padding: 1em;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1em;
  margin-bottom: 1em;

  // margin: 5px 10px;
  cursor: pointer;
  // display: inline-block;
  // opacity: 0.8;
  width: 100%;
  font-size: 0.875em;
  font-weight: 600;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  text-decoration: none;
  // border-width: 1px;
  // border-style: solid;
  // border-radius: 3px;
  // border-color: ${colors.primary};
  opacity: 0.9;
  box-shadow: 0 0 2.5px ${colors.shadow};
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  &:hover {
    opacity: 1;
  }
`

const ListingButtonContinue = styled.div`
  // background-color: ${colors.midprimary};
  color: ${colors.secondary};
  // padding: 0.5em;
  padding: 1em;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1em;
  margin-bottom: 20px;


  cursor: pointer;
  // display: inline-block;
  // opacity: 0.8;
  width: 100%;
  font-size: 0.875em;
  font-weight: 600;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  text-decoration: none;
  // border-width: 1px;
  // border-style: solid;
  // border-radius: 3px;
  // border-color: ${colors.primary};
  opacity: 0.9;
  box-shadow: 0 0 2.5px ${colors.shadow};
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  &:hover {
    opacity: 1;
  }
`

const ListingStatusWrapper = styled.div`
  position: absolute;
  z-index: 10;
  top: 20px;
  left: 20px;
  font-size: 0.675em;
  padding: 0.2em 0.5em;
  background: ${colors.secondary};
  color: ${colors.white};
  border-radius: 5px;
`

const ListingStatusWrapperSuccess = styled.div`
  position: absolute;
  z-index: 10;
  top: 20px;
  left: 20px;
  font-size: 0.675em;
  padding: 0.2em 0.5em;
  background: ${colors.primary};
  color: ${colors.white};
  border-radius: 5px;
`

// const EditButton = styled.div`
//   background: ${colors.yellow};
//   color: ${colors.white};
//   border-radius: 5px;
//   padding: 0.5em;
//   margin-right: 10px;
//   cursor: pointer;
//   opacity: 0.8;
//   &:hover {
//     opacity: 1;
//   }
// `
// const DeleteButton = styled.div`
//   background: ${colors.red};
//   color: ${colors.white};
//   border-radius: 5px;
//   padding: 0.5em;
//   cursor: pointer;
//   opacity: 0.8;
//   &:hover {
//     opacity: 1;
//   }
// `

const NextButton = styled.i`
  background: ${colors.white};
  color: ${colors.primary};
  font-size: 1.375em;
`
const DelButton = styled.i`
  background: ${colors.white};
  color: ${colors.red};
  font-size: 1.2em;
`
const EditButton = styled.i`
  background: ${colors.white};
  color: ${colors.yellow};
  font-size: 1.2em;
`
const ViewButton = styled.i`
  background: ${colors.white};
  color: ${colors.primary};
  font-size: 1.2em;
`

const ListingDetail = styled.div`
  position: absolute;
  z-index: 10;
  bottom: 5px;
  left: 5px;
  font-size: 0.675em;
  padding: 0.2em 0.5em;
  background: ${colors.midprimary};
  color: ${colors.primary};
  border-radius: 5px;
  @media (min-width: ${breakpoints.mobile + "px"}) {
    bottom: 10px;
    left: 10px;
  }
  @media (min-width: ${breakpoints.tablet + "px"}) {
    bottom: 15px;
    left: 15px;
  }
`

const MyListings = () => {
  const history = useHistory()
  const auth = useAuth()

  const [loading, setLoading] = useState(false)

  const [myListings, setMyListings] = useState<IServerListing[]>([])
  const [continueEditing, setContinueEditing] = useState("")

  const [modal, toggle] = useModal()
  const [deleteId, setDeleteID] = useState("")

  useEffect(() => {
    fetchPosts(auth.user.userID)
  }, [auth.user.userID])

  const fetchPosts = async (data: string | null) => {
    try {
      setLoading(true)
      const res: any = await Axios.get(baseURL + "/myposts", {
        headers: {
          UserID: data,
        },
      })
      setLoading(false)
      setMyListings(res.data.posts)
    } catch (err) {
      console.error(err)
      setLoading(false)
    }
  }

  const deletePost = async (postId: string | null) => {
    console.log(auth.user.userID)
    Axios.get(`${baseURL}/delete/${postId}`, {
      headers: {
        UserID: auth.user.userID,
        Authorization: auth.user.token,
      },
    }).then(res => {
      setMyListings(myListings.filter(a => a.postID !== postId))
    })
  }

  useEffect(() => {
    const listingData = getListingData()
    if (listingData && listingData.hasOwnProperty("address"))
      setContinueEditing((listingData as INewListingJSONData).address)
  }, [])

  if (loading)
    return (
      <div style={{ marginTop: "200px" }}>
        <Loader></Loader>
      </div>
    )

  if (myListings.length == 0)
    return (
      <PageContainer>
        <Banner>
          <MainLabel>My Thikanas</MainLabel>
          <NewListing
            onClick={() => {
              resetLocalStorage()
              history.push("/newlisting")
            }}
          >
            <i className="fa fa-plus"></i> Add New Listing
          </NewListing>
        </Banner>
        <ListingFlexBox>
          {continueEditing != "" && (
            <ListingCardWrapper>
              <ListingStatusWrapper>Unpublished</ListingStatusWrapper>
              <ListingDetail>{continueEditing}</ListingDetail>
              <ContinueEditingBoxMessage>
                Saved locally
              </ContinueEditingBoxMessage>
              <ListingImage src={mockImages[0]}></ListingImage>
              <ListingLineSpacing></ListingLineSpacing>
              <ListingButtonContinue
                onClick={() => {
                  history.push("/newlisting")
                }}
              >
                Continue Editing
                <NextButton className="fa fa-chevron-circle-right"></NextButton>
              </ListingButtonContinue>
              <div style={{ marginBottom: "50px" }}></div>
            </ListingCardWrapper>
          )}
        </ListingFlexBox>
        <div style={{ textAlign: "center", width: "100%", paddingTop: "5%", paddingBottom: "5%" }}>
          No Listings published!
        </div>
        <Footer></Footer>
      </PageContainer>
    )

  return (
    <PageContainer>
      <Banner>
        <MainLabel>My Thikanas</MainLabel>
        <NewListing
          onClick={() => {
            resetLocalStorage()
            history.push("/newlisting")
          }}
        >
          <i className="fa fa-plus"></i> Add New Listing
        </NewListing>
      </Banner>
      <ListingFlexBox>
        {continueEditing != "" && (
          <ListingCardWrapper>
            <ListingStatusWrapper>Unpublished</ListingStatusWrapper>
            <ListingDetail>{continueEditing}</ListingDetail>
            <ContinueEditingBoxMessage>Saved locally</ContinueEditingBoxMessage>
            <ListingImage src={mockImages[0]}></ListingImage>
            <ListingLineSpacing></ListingLineSpacing>
            <ListingButtonContinue
              onClick={() => {
                history.push("/newlisting")
              }}
            >
              Continue Editing
              <NextButton className="fa fa-chevron-circle-right"></NextButton>
            </ListingButtonContinue>
          </ListingCardWrapper>
        )}
        {myListings.map(listing => (
          <ListingCardWrapper key={listing.postID}>
            <ListingStatusWrapperSuccess>Published</ListingStatusWrapperSuccess>
            <ListingImage
              src={
                (listing.contentIDs && listing.contentIDs[0]) || mockImages[0]
              }
            ></ListingImage>
            <ListingLineSpacing></ListingLineSpacing>
            <ListingDescription {...listing}></ListingDescription>
            <ListingButton
              onClick={() => {
                window.open("/app/" + listing.postID)
                // history.push("/app/" + listing.postID)
              }}
            >
              View Listing
              <ViewButton className="fas fa-eye"></ViewButton>
            </ListingButton>
            <ListingButton
              onClick={() => history.push("/editlisting/" + listing.postID)}
            >
              Edit Listing
              <EditButton className="fas fa-pencil-alt"></EditButton>
            </ListingButton>
            <ListingButton
              onClick={() => {
                setDeleteID(listing.postID)
                toggle()
              }}
            >
              {" "}
              Delete Listing
              <DelButton className="fas fa-trash-alt"></DelButton>
            </ListingButton>
          </ListingCardWrapper>
        ))}
        {modal && (
          <SmallModal
            open={modal}
            toggle={() => {
              toggle()
              setDeleteID("")
            }}
          >
            <ListingButton
              onClick={() => {
                if (deleteId) deletePost(deleteId)
                setDeleteID("")
                toggle()
              }}
            >
              {" "}
              Confirm Delete Listing
              <DelButton className="fas fa-trash-alt"></DelButton>
            </ListingButton>
          </SmallModal>
        )}
      </ListingFlexBox>
      <Footer></Footer>
    </PageContainer>
  )
}

export default MyListings
