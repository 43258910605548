import React from "react"
import styled from "styled-components"
import { breakpoints } from "../../Constants/responsive"
import { NavLink } from "react-router-dom"
import { colors } from "../../Constants/colors"


const FooterContainer = styled.div`
`

const FooterImage = styled.div`
  background: url("/img/footer.webp");
  background-position: center bottom;
  width: 100%;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 1em;
  margin-bottom: 1em;
  height: 5em;
  @media only screen and (min-width: ${breakpoints.mobile + "px"}) {
    height: 5em;
  }
  @media only screen and (min-width: ${breakpoints.tablet + "px"}) {
    height: 8em;
  }
  @media only screen and (min-width: ${breakpoints.desktop + "px"}) {
    height: 10em;
  }
`

const FooterMenu = styled.div`
  margin-top: 2%;
  // padding-top: 2%;
  // padding-bottom: 2%;
  border-top: 1px solid ${colors.lightgrey};
  border-bottom: 1px solid ${colors.lightgrey};
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`

const PlainButton = styled.div`
  cursor: pointer;
  font-size: 0.875em;
  outline: none;
  font-weight: 500;
  color: ${colors.secondary};
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-top: 10px;
  padding-bottom: 10px;

  @media only screen and (min-width: ${breakpoints.mobile + "px"}) {
    font-size: 0.875em;
  }
  @media only screen and (min-width: ${breakpoints.tablet + "px"}) {
    font-size: 0.9em;
  }
  @media only screen and (min-width: ${breakpoints.desktop + "px"}) {
    font-size: 1em;
  }
`

const FooterText = styled.p`
  width: 80%;
  font-size: 0.5em;
  margin: auto;
  padding-top: 1em;
  text-align: center;
  opacity: 0.9;
  color: ${colors.secondary};
  @media only screen and (min-width: ${breakpoints.mobile + "px"}) {
    font-size: 0.5em;
    width: 90%;
  }
  @media only screen and (min-width: ${breakpoints.tablet + "px"}) {
    font-size: 0.6em;
  }
  @media only screen and (min-width: ${breakpoints.desktop + "px"}) {
    font-size: 0.7em;
  }
`

const Footer = () => {
  return (
    <FooterContainer>
      <FooterMenu>
        <NavLink to="/about">
              <PlainButton>About</PlainButton>
          </NavLink>
        <NavLink to="/terms-and-conditions">
          <PlainButton>Terms and Conditions</PlainButton>
        </NavLink>
        <NavLink to="/privacy-policy">
          <PlainButton>Privacy Policy</PlainButton>
        </NavLink>
        <NavLink to="/cookie-policy">
          <PlainButton>Cookie Policy</PlainButton>
        </NavLink>
        <NavLink to="/contact">
          <PlainButton>Contact</PlainButton>
        </NavLink>
      </FooterMenu>
      <FooterText>EkThikana is a no-fee real estate platform connecting property owners with buyers. We strive to simplify your home journey, turning dream to reality.
        Our Platform comprises of residential houses of various types fitting all budget, sizes, and facilities.
        <br/>
        <br/>
        Powered by Techlipi LLP, EkThikana was launched on March 1, 2021, with its headquarters in Gurugam, Haryana.
      </FooterText>
      <FooterText>
        <a target="_blank" href="https://www.facebook.com/ekthikana/">
         <img alt="facebook-social-link" src="/img/socialLinks/facebook-icon.webp" style={{height: "30px", width: "auto", paddingRight: "10px"}} />
        </a>
        <a target="_blank" href="https://www.instagram.com/ekthikana/">
         <img alt="instagram-social-link" src="/img/socialLinks/instagram-icon.webp" style={{height: "30px", width: "auto", paddingRight: "10px"}} />
        </a>
        <a target="_blank" href="https://www.linkedin.com/company/ekthikana">
         <img alt="linkedin-social-link" src="/img/socialLinks/linkedin-icon.webp" style={{height: "30px", width: "auto", paddingRight: "10px"}} />
        </a>
        <a target="_blank" href="https://wa.me/message/FLES33ZKGBECL1">
         <img alt="whatsapp-social-link" src="/img/socialLinks/whatsapp-business.webp" style={{height: "30px", width: "auto"}} />
        </a>
      </FooterText>
      <FooterImage></FooterImage>
    </FooterContainer>
  )
}

export default Footer
