import React from "react";
import styled from "styled-components";
import { colors } from "../../Constants/colors";
import { breakpoints } from "../../Constants/responsive";
import { size } from "../../Constants/sizesAndSpacing";
import Footer from  "../Footer/footer";
import { NavLink } from "react-router-dom";

const Wrapper = styled.div`
  height: calc(100% - ${size.headerHeight + "px"});
  overflow-y: scroll;
`;
const Canvas = styled.div`
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 1%;
  margin-bottom: 1%;
`;

const MainHeading = styled.h1`
  color: ${colors.secondary};

  @media only screen and (min-width: ${breakpoints.mobile + "px"}) {
    font-size: 3em;

  }
  @media only screen and (min-width: ${breakpoints.tablet + "px"}) {
    font-size: 3.5em;
  }

  @media only screen and (min-width: ${breakpoints.desktop + "px"}) {
    font-size: 4em;
  }
`;

const TextHeading = styled.h2`
  margin-top: 0.5%;
  margin-bottom: 0.5%;
  color: ${colors.secondary};
  font-weight: 600;

  @media only screen and (min-width: ${breakpoints.mobile + "px"}) {
    font-size: 1.5em;

  }
  @media only screen and (min-width: ${breakpoints.tablet + "px"}) {
    font-size: 1.725em;
  }

  @media only screen and (min-width: ${breakpoints.desktop + "px"}) {
    font-size: 2em;
  }
`;

const TextBody = styled.p`
  margin-top: 1%;
  margin-bottom: 1%;
  color: ${colors.secondary};
  text-align: justify;

  @media only screen and (min-width: ${breakpoints.mobile + "px"}) {
    font-size: 0.875em;

  }
  @media only screen and (min-width: ${breakpoints.tablet + "px"}) {
    font-size: 1em;
  }

  @media only screen and (min-width: ${breakpoints.desktop + "px"}) {
    font-size: 1.2em;
  }
`;

const SpacingBlock = styled.div`
  padding: 2%;
`;

const PrivacyScreen = () => {
  return (
    <Wrapper>
        <Canvas>
          <MainHeading> Privacy Policy </MainHeading>
                      <TextBody><strong>Effective Date:</strong> January 1, 2021</TextBody>
                      <SpacingBlock></SpacingBlock>
                      <TextHeading>Introduction</TextHeading>
                      <TextBody>
                          Techlipi LLP and/or its affiliates, partners, successors, etc. <strong>(“Company”, “Us” or “We”)</strong> is
                          committed to ensuring that Your privacy is protected. Should We ask You to provide certain information by which You can
                          be identified when using this Site, then You can be assured that it will only be used in accordance with this Privacy Policy.
                      </TextBody>
                      <TextBody>
                          This <strong>‘Privacy Policy’</strong> sets out the type of information that the Company may collect from any Person <strong>
                          (“User”, “You” or “Your”)</strong> accessing EkThikana, and how the Company uses and protects any information that You give
                          the Company when You use EkThikana. For general information about the terms of use of EkThikana, please refer to the
                          <NavLink to="/terms-and-conditions" style={{color: "blue"}}> Terms and Conditions</NavLink>. The defined terms and phrases used
                          herein shall have the same meaning as assigned to them in the <NavLink to="/terms-and-conditions" style={{color: "blue"}}>Terms
                          and Conditions</NavLink>, and this Privacy Policy is to be read as part and parcel of the <NavLink to="/terms-and-conditions"
                          style={{color: "blue"}}>Terms and Conditions</NavLink>.
                      </TextBody>
                      <TextBody>
                          Please read this Privacy Policy carefully to understand the Company’s policies and practices regarding Your Information and how
                          the Company will treat it. By accessing and/or using EkThikana, You agree to this Privacy Policy and You are consenting to the
                          Company’s collection, Use, disclosure, retention, transfer and protection of Your User Information, for the purposes outlined in
                          this Privacy Policy and to the processing and maintenance of the Information. If You use EkThikana, it shall be deemed that You
                          have read and agreed to the Privacy Policy. While You have the option not to provide us with certain Information or withdraw
                          consent to collect certain Information, kindly note that in such an event, You may not be able to take full advantage of the
                          entire scope of features and Services offered to You, and We reserve the right not to provide You with the Services.
                      </TextBody>
                      <TextBody>
                          The Company may change this policy from time to time by updating this page. You should check this page from time to time to ensure
                          that You are up-to-date with any changes. This policy is effective from January 1, 2021.
                      </TextBody>
                      <TextBody>
                        Techlipi LLP operates EkThikana in India, which offers its Services through its Site <NavLink to="/homepage"
                        style={{color: "blue"}}>www.ekthikana.com</NavLink>, and associated partner website(s). Any Person accessing or utilizing EkThikana or
                        any of its Services, shall be bound by this Privacy Policy. Before the User submits any information to EkThikana, please read this
                        Privacy Policy for an explanation of how the Company will treat User Information.
                      </TextBody>
                      <SpacingBlock></SpacingBlock>
                      <TextHeading>What Information We Collect</TextHeading>
                      <TextBody>
                        To avail certain Services on EkThikana, Users would be required to provide certain Information for the registration process, and/or
                        any other purpose, as may be required from time to time by the Company, namely:
                        <ol type="i">
                          <li>
                            <strong>Account Information:</strong> Your full name, e-mail address, username, password, and any such other Information that
                            You may provide with Your Account, such as gender, mobile number, etc. The profile picture, that will be displayed publicly on Your
                            Account, shall also be collected. In case the sign-in is done through any Third-Party, such as Google, Facebook, etc., the Company
                            will fetch whatever information is made available to it through these Third-Party sign-in services.
                          </li>
                          <br></br>
                          <li>
                            <strong>Proof of Identification:</strong> In the course of providing You with access to the Services and the features offered through
                            EkThikana, and to verify Your identity, You may be required to furnish additional Information to the Company.
                          </li>
                          <br></br>
                          <li>
                            <strong>Content:</strong> The Company may collect Information that You provide during the course of accessing and Using EkThikana,
                            including reviews, photographs, comments, etc.
                          </li>
                          <br></br>
                          <li>
                            <strong>Usage Information:</strong> The Company collects information about how long You have used our Services and which features You
                            have used, such as system activity, ads You clicked on, etc.
                          </li>
                          <br></br>
                          <li>
                            <strong>Device Data:</strong> The Company may collect Data about the devices used to access the Services, including IP addresses,
                            operating systems, hardware models, software, preferred language, advertising identifiers, mobile network data, etc.
                          </li>
                          <br></br>
                          <li>
                            <strong>Communications Data:</strong> The Company may collect Data/Information about communications between You and other Users
                            through our Services; Your participation in any survey, poll, promotion schemes, etc.
                          </li>
                          <br></br>
                          <li>
                            <strong>Location Information:</strong> The Company may collect precise or accurate location data from a User’s device, if the same is
                            enabled. If You wish, You can disable the GPS service on Your device and stop sharing Your location with the Company. However, this
                            may affect some functionality available on the Services.
                          </li>
                          <br></br>
                          <li>
                            <strong>Transaction Information:</strong> If You make any transactions or purchases through Our Services, We may collect and store
                            information about You to process Your requests and automatically complete forms for future transactions, including (but not limited to)
                            Your Personal Information. The credit card/ payment information is submitted to us in an encrypted form by using industry standard practices.
                          </li>
                          <br></br>
                          <li>
                            <strong>Public Posts:</strong> You may also provide Information (such as ratings, reviews, tips, comments, photos, etc.) to be published or
                            displayed (collectively <strong>“Posts”</strong>) on publicly accessible areas of EkThikana and/or transmitted to Third Parties. Such Posts are displayed on
                            EkThikana and is transmitted to Third Parties at Your own risk. We cannot control the actions of the other Users, with whom You may choose
                            to share Your Posts. Therefore, We cannot guarantee that Your posts will not be viewed by unauthorized persons. We may display this information
                            on EkThikana, share it with businesses, and further distribute to a wider audience through Third Party websites and services. Therefore, You
                            should be careful about revealing sensitive details about Yourself in such posts.
                          </li>
                          <br></br>
                          <li>
                            <strong>Data from Other Sources:</strong> The Company may collect and store Information which the User posts, pertaining to User feedback,
                            ratings, etc. The Company may also store and collect Information which it receives as a result of the User participating in referral programs.
                          </li>
                        </ol>
                      </TextBody>
                      <SpacingBlock></SpacingBlock>
                      <TextHeading>What We do with the Information We collect</TextHeading>
                      <TextBody>
                        <ol type="i">
                          <li>
                            <strong>Enhance Service Experience</strong> We use the Information You provide to enhance the functionality, and improve the quality
                            of the Services, and to personalize Your experience while using the Services. The information as supplied by the User enables us to
                            improve the Services and provide the most user-friendly experience. We also use this information to display relevant advertising,
                            to provide support to You, communicate with You and/or to comply with our legal obligations.
                            <br></br>
                            <br></br>
                            All required information is specific and based on the kind of services the User wishes to participate in or access and will be utilized
                            for the purpose of providing Services, including but not limited to the services requested by the User.
                          </li>
                          <br></br>
                          <li>
                            <strong>Safety &#38; Security:</strong> The Company may use Your personal Data, including your Personal Information, to help maintain safety,
                            security and integrity of the Services and the Users. The Company may also Your information to detect, prevent and combat fraudulent and/or
                            unsafe activities or imminent harm, and to ensure the security of EkThikana.
                          </li>
                          <br></br>
                          <li>
                            <strong>For Maintaining Record:</strong> The Company may use the Information provided by You for internal record keeping.
                          </li>
                          <br></br>
                          <li>
                            <strong>For Customer Support:</strong> The Company may use the information collected and stored by it (such as recording calls by intimating
                              the User) to provide customer support, including directing questions to the appropriate customer support person, to investigate and address
                              concerns, and to monitor and improve customer service responses.
                          </li>
                          <br></br>
                          <li>
                            <strong>For Research &#38; Development:</strong> The Company may use the Data/Information collected by it for testing, research, analysis,
                            product development and machine learning, to improve the User experience, and to enhance the safety and security of the features and Services.
                          </li>
                          <br></br>
                          <li>
                            <strong>For Marketing:</strong> The Company may use the Data collected it to market Our Services to Our Users, including communications about
                            Services, features, promotions, studies, surveys, news, events and updates by the Company. The Company may also share such Information with
                            affiliates and Third Parties in limited circumstances. The Company may also periodically send promotional e-mails, special offers and information,
                            which We think You may find interesting, using the e-mail address and/or phone number, which You have provided.
                          </li>
                          <br></br>
                          <li>
                            <strong>For Legal Requirements:</strong> The Company may also share such Data/Information collected by it for the purpose complying with legal
                            processes.
                          </li>
                          <br></br>
                          <li>
                            <strong>For Automated Decision Making:</strong> The Company may use the Information to make automated decisions relating to the use of the
                            Services, such as deactivating Users who are identified as having engaged in fraud or activities that may harm the Company, etc.
                          </li>
                        </ol>
                      </TextBody>
                      <SpacingBlock></SpacingBlock>
                      <TextHeading>Disclosure/Sharing of Information</TextHeading>
                      <TextBody>
                          The Company may share Information as provided by You and the Data concerning usage of the Services with Third-Party Service Providers engaged
                          by the Company, for the purpose of data analytics, storage, improving the Services or other similar purposes.
                      </TextBody>
                      <TextBody>
                          Where We propose to Use User Information (that is, Information that may be used to identify the User and that is not otherwise publicly
                          available) for any other uses, We will ensure that We notify You first. You will also be given the opportunity to withhold or withdraw Your
                          consent for Use other than as listed above.
                      </TextBody>
                      <br></br>
                      <TextBody>
                          <strong>The Company may share Your information with the following:</strong>
                      </TextBody>
                      <TextBody>
                          <ul>
                            <li>
                              With contractors, commercial partners, advertisers, service providers, or outside vendors, who We use to support Our business and who are
                              contractually bound to keep User Information confidential. Some of our products, Services and databases are hosted by Third-Party Service
                              Providers, and We may also use them for other projects, for the purpose of facilitating User engagement, for surveys, marketing and promotions
                              and other related purposes. Thus, Your information may be shared with the above to enable them to serve You better, and enhance Your experience.
                            </li>
                            <br></br>
                            <li>
                              With Third Parties to market their products / services to You, if You have consented to receive promotional updates.
                            </li>
                            <br></br>
                            <li>
                              With companies, customers or other Users if the Company believes that such disclosure is necessary or appropriate to protect the rights, properties,
                              and/or safety of the Company, its User(s) or any other Person(s). This includes exchanging Information for the purposes of fraud protection and credit
                              risk reduction.
                            </li>
                            <br></br>
                            <li>
                              With law enforcement agencies, Government Authority, courts and/or other such bodies and organizations, on account of any statutory demands, warrants,
                              or order of a competent court or tribunal, to comply with the law. The Company may share Your Information, when it reasonably believes that the same
                              is necessary, in order to investigate, prevent and/or take action against illegal activities or to comply with legal process. We may also share Your
                              Information to investigate and address threats /potential threats to the physical safety of any Person, or to investigate and address violations of
                              the Agreement.
                            </li>
                            <br></br>
                            <li>
                              With social networks if You interact with the social media features of Our Services and/or if You use Your social media credentials to log in, to post
                              Content, etc. Your interactions with social media companies are governed by their respective privacy policies.
                            </li>
                          </ul>
                      </TextBody>
                      <SpacingBlock></SpacingBlock>
                      <TextHeading>Third Party Links &#38; Services</TextHeading>
                      <TextBody>
                          EkThikana also includes links to other/Third-Party websites. Such websites are governed by their respective privacy policies, which are beyond the Company’s
                          control, and the Company shall not be responsible for the same. Once the User leaves the Company’s servers (the User can tell where they are by checking the
                          URL in the location bar on the User’s browser), use of any Information provided by the User is governed by the privacy policy of the operator of the site which
                          the User is visiting, which policy may differ from the Company’s own Privacy Policy. If the User is unable to find the privacy policy of any of these sites via
                          a link from the site’s homepage, the User may contact the site directly for more information. The Company is not responsible for the privacy practices or the
                          content of such websites and We strongly encourage You to read the privacy policies of such Third Parties.
                      </TextBody>
                      <SpacingBlock></SpacingBlock>
                      <TextHeading>Analytics &#38; Advertising</TextHeading>
                      <TextBody>
                          To help us understand Your use of the Services, the Company may use Third-Party web analytics on its Services. The Information collected by this technology will
                          be disclosed to, or directly collected by, these analytic service providers.
                      </TextBody>
                      <TextBody>
                          When the Company presents information to its online advertisers -- to help them understand Our audience and confirm the value of advertising on EkThikana --
                          it is usually in the form of aggregated statistics on traffic to various pages within the Site. When You register with EkThikana, We may contact You from time
                          to time about updating Your Content, so as to provide features which We believe may benefit You.
                      </TextBody>
                      <SpacingBlock></SpacingBlock>
                      <TextHeading>Use of Cookies and other Electronic Tools</TextHeading>
                      <TextBody>
                          To improve the effectiveness and usability EkThikana for Our Users, We use Cookies, or such other similar electronic tools to collect and store information.
                          A “Cookie” is a text file that is stored in Your computer and enbales us to recognize when You use EkThikana, store Your settings and preferences, enhance Your
                          experience by delivering Content, advertise Content specific to Your interests, perform research and analytics, track Your use of Our Services, and to assist
                          with security and administrative functions. Cookies may be persistent or stored only during an individual session. To read and understand more about Cookies,
                          <NavLink to="/cookie-policy" style={{color: "blue"}}> click here</NavLink>.
                      </TextBody>
                      <SpacingBlock></SpacingBlock>
                      <TextHeading>Security Procedures</TextHeading>
                      <TextBody>
                          All information gathered on EkThikana is securely stored within the Company controlled database. The database is stored on servers secured behind a firewall;
                          access to such servers being password-protected and strictly limited on need-to-know basis. However, We understand that as effective as our security measures
                          are, no security system is impenetrable. Thus, We can neither guarantee the security of Our database, nor can We guarantee that the information You supply
                          will not be intercepted while being transmitted to Us over the Internet. By using EkThikana, You understand and agree that Your information may be used in,
                          or transferred to, countries other than India.
                      </TextBody>
                      <TextBody>
                          The Company does not assume any responsbility / liability for disclosure of Your Information due to errors in transmission, unauthorized Third-Party access or
                          other causes beyond its control.
                      </TextBody>
                      <SpacingBlock></SpacingBlock>
                      <TextHeading>Conditions of Use</TextHeading>
                      <TextBody>
                          The Company shall not be liable for any loss caused to You, inter alia, on account of breach of your User Information and Personal Information, or any other
                          information obtained from the Site, misrepresentation by any Third Party, a Subscriber or any other User and/or owing to Your own fault. You are therefore,
                          advised to maintain the secrecy and confidentiality of Your password, username or any other Information at all times.
                      </TextBody>
                      <TextBody>
                          Several deceptive emails, websites, blogs etc., claiming to be from, or associated with, the Company may be, or are, circulating on the Internet. These emails,
                          websites, blogs etc. often include our logo, photos, links, content or other information. Some emails, websites, blogs etc. call the User to provide login name,
                          password etc., state that the User has won a prize/ gift, provide a method to commit an illegal/ unauthorized act or deed, or request detailed User Information
                          or a payment of some kind. For Your own protection, We strongly recommend not responding to such emails or using websites, blogs etc. The Company does not take
                          any legal responsibility for any loss/damage caused to You on account of the same.
                      </TextBody>
                      <TextBody>
                          The Company shall also not be liable for any loss or damage of any kind, arising from the use of EkThikana, it’s servers, or email sent by it or on its behalf,
                          including, but not limited to compensatory, direct, indirect, incidental, punitive, special and consequential damages, loss of data, goodwill, business opportunity,
                          income or profit, loss of or damage to property and claims of Third Parties.
                      </TextBody>
                      <SpacingBlock></SpacingBlock>
                      <TextHeading>Accessing, Correcting &#38; Updating Your User Information</TextHeading>
                      <TextBody>
                          You shall furnish only correct and accurate User Information on EkThikana, and it is Your responsibility to update such information from time to time. The Company
                          shall take reasonable steps to accurately record Your User Information, or other subsequent updates that You provide.
                      </TextBody>
                      <TextBody>
                          We encourage You to review, update, modify and correct Your User Information that We maintain, and You may request the Company for deletion of any information that
                          is believed by You to be incomplete, inaccurate and/or in any manner, infriges Applicable Law or any other legal, statutory and/or regulatory requirements.
                      </TextBody>
                      <TextBody>
                          You will promptly notify the Company if there are any changes, updates or modifications to Your information. Further, You may also review, update or modify Your
                          information and User preferences by logging into Your Profile page on EkThikana.
                      </TextBody>
                      <TextBody>
                          If You would like to delete Your User Information, that We have collected from You, and/or permanently delete Your Account, please use the “Contact Us” link at
                          the bottom.
                      </TextBody>
                      <SpacingBlock></SpacingBlock>
                      <TextHeading>Retention of Data &#38; Account Termination</TextHeading>
                      <TextBody>
                          If Your Account is deleted on EkThikana, We will remove Your public Posts from view and/or disocciate them from Your Account, but We may retain the information
                          provided by You, for the purposes authorized under this Privacy Policy, including for business operations, for archival purposes, and/or to satisfy legal requirements,
                          only so long as it is necessary, until: (i) the relevant purposes for the use of Your information described in this Privacy Policy are no longer applicable; (ii) We
                          are no longer required by Applicable Law, regulations, contractual obligations or legitimate business purposes to retain Your User Information; and (iii) the retention
                          of Your User Information is not required for the establishment, exercise or defense of any legal claim.
                      </TextBody>
                      <SpacingBlock></SpacingBlock>
                      <TextHeading>Permissible Age</TextHeading>
                      <TextBody>
                          The use and access of EkThikana is not intended for anyone under the age of 18 years, unless permitted under the Applicable Laws. If We become aware that a Person submitting
                          User Information is under the age of 18 years, We shall delete the Account and any related Information as soon as possible.
                      </TextBody>
                      <SpacingBlock></SpacingBlock>
                      <TextHeading>Communication Choices</TextHeading>
                      <TextBody>
                          When You sign up for an Account on EkThikana, You are opting to receive e-mails / SMSs from the Company, as well as, its other Users and businesses. You can log into Your Account
                          to manage Your e-mail/SMS preferences and follow the “unsubscribe” instructions. However, You cannot opt out of receiving certain e-mails/SMSs, pertaining to administrative,
                          service and/or legal policies from the Company.
                      </TextBody>
                      <SpacingBlock></SpacingBlock>
                      <TextHeading>Applicable Law and Jurisdiction</TextHeading>
                      <TextBody>
                          By visiting EkThikana, You agree that the laws of the Republic of India, without regard to its conflict of laws principles, shall govern this Privacy Policy and any dispute arising
                          in respect hereof shall be subject to and governed by the dispute resolution process set out in the <NavLink to="/terms-and-conditions" style={{color: "blue"}}>Terms and Conditions</NavLink>.
                      </TextBody>
                      <SpacingBlock></SpacingBlock>
                      <TextHeading>Changes to this Privacy Policy</TextHeading>
                      <TextBody>
                          The Company believes that the internet is an ever-evolving medium. We may periodically review and change our Privacy Policy to incorporate such changes as may be considered appropriate,
                          without any notice to You. We reserve the right to amend the Privacy Policy to reflect changes in law, our data collection and usage practices, the features of our Services and/or advances
                          in technology. Use of Information is subject to the Privacy Policy in effect at the time such Information is used, regardless of what the new policy may be. If We make any material changes
                          to the Privacy Policy, We will post the changes here. Please check this page periodically for changes and review of any changes.
                      </TextBody>
                      <SpacingBlock></SpacingBlock>
                      <TextHeading>Grievance Redressal</TextHeading>
                      <TextBody>
                        Any queries, complaints, clarifications, or questions pertaining to this Policy or any complaints, comments, concerns, suggestions or feedback, may be sent to the Company at: contact@ekthikana.com
                        or by normal/physical mail addressed to:
                          <br></br>
                          <br></br>
                          Attn: EkThikana Team <br></br>
                          Techlipi LLP, <br></br>
                          H.No - 766, Sector-4, <br></br>
                          Urban Estate, <br></br>
                          Gurugram (Haryana), <br></br>
                          122006
                      </TextBody>
        </Canvas>
        <Footer></Footer>
    </Wrapper>

  );
};

export default PrivacyScreen;
