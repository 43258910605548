import React, {
  useState,
  useContext,
  createContext,
  ReactNode,
  useRef,
} from "react"
import { IServerListing, ILatLang } from "../mockListings"
import Axios, { CancelTokenSource } from "axios"
import distanceTo from "../Utils/distanceBetweenLatLang"
import { IMapConfig } from "../Components/Listings/ListingMap/listingMap"
import { baseURL as baseurl } from "../Constants/serverConfig"

const baseURL = baseurl + "/getposts/"

type TFetchData = (
  location: ILatLang,
  zoom: number,
  radius: number,
  listingType: number,
  sortType: number
) => void

export interface IServerError {
  message: string
}

interface IServerContext {
  posts: IServerListing[]
  fetchData?: TFetchData
  loading: boolean
  error: IServerError | null
}

const serverDataContext = createContext<IServerContext>({
  posts: [],
  loading: false,
  error: null,
})

export function ProvideServerData({ children }: { children: ReactNode }) {
  const serverData = useServerData()
  return (
    <serverDataContext.Provider value={serverData}>
      {children}
    </serverDataContext.Provider>
  )
}

// Hook for child components to get the server object ...
// ... and re-render when it changes.
export const useServer = () => {
  return useContext(serverDataContext)
}

//utils functions
const throttlePan = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
  radius: number
) => {
  // measure distance between old map center and new map center
  // then divide by radius, if answer comes less than 0.2, then
  // the map was panned for less than 20% view area, so do not
  // ask for new results from server
  const percentagePanned = (1000 * distanceTo(lat1, lon1, lat2, lon2)) / radius

  return percentagePanned < 0.2 ? true : false
}

export function useServerData() {
  // states for error, loading, and data from server (posts)
  const [error, setError] = useState<IServerError | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [posts, setPosts] = useState<IServerListing[]>([])

  // cancel token to cancel an api request (if a new request is intiated)
  const cancelToken = useRef<CancelTokenSource | null>(null)
  // ref to cache previous server request parameters (for comparison)
  const serverRequestParams = useRef<IMapConfig | null>(null)
  const serverRequestRentSellType = useRef(0)
  // ref to hold a timeout for debouncing
  const timeoutRef = useRef<number | null>(null)
  // ref to hold if curent request is first request since app loaded
  const firstRequest = useRef<boolean>(true)

  // function to fetch data. Parameters are:
  // location: latitude and longitude of center
  // radius: radius of posts to fetch from center (meters)
  // zoom: zoom level from leaflet map
  // lisitngType: 0 for rent, 1 for sell
  const fetchData = (
    location: ILatLang,
    zoom: number,
    radius: number,
    listingType: number,
    sortType: number
  ) => {
    // DECIDE IF WE WANT TO SEND THIS REQUEST

    // check if old request parameters are present
    if (serverRequestParams.current) {
      // cancel request if too low radius
      if (radius < 0.01) return
      // if zoom and map center both did not change, then cancel request
      // we compare old request params with current params here
      if (
        // if zoom did not change AND
        serverRequestParams.current.zoom <= zoom &&
        // if pan did not change by atleast a threshold value
        throttlePan(
          serverRequestParams.current.position.lat,
          serverRequestParams.current.position.lon,
          location.lat,
          location.lon,
          radius
        ) &&
        serverRequestRentSellType.current === listingType
      )
        // if neither zoom nor pan changed nor listing type changed, cancel request
        return
    }
    // if no serverRequestParams.current => no request has been sent till now, so send it

    // START REQUEST BODY

    setError(null)

    // copy request params in ref for comparison in future
    serverRequestParams.current = {
      position: { ...location },
      zoom,
      radius,
    }
    serverRequestRentSellType.current = listingType

    // debounced here
    // if timeoutRef has a timeout, it means a pending request is in queue
    // cancel the pending request, the current request will be sent in it's place
    if (timeoutRef.current) clearTimeout(timeoutRef.current)

    // Queue current request to be sent after some time
    // if no further request comes, it will be sent.
    // otherwise we will cancel the request in the queue and send only
    // updated request

    timeoutRef.current = setTimeout(
      async () => {
        // request started to be sent, set timeout to null
        timeoutRef.current = null
        setLoading(true)

        // if there is pending request, cancel it
        // this is to ensure that the pending request's data does not
        // overwrite the current request's data (handling race condition)
        if (cancelToken.current) {
          cancelToken.current.cancel()
        }
        // create new cancel token for this request
        cancelToken.current = Axios.CancelToken.source()

        // add params to url
        const reqURL =
          baseURL +
          (serverRequestParams.current as IMapConfig).position.lat +
          "/" +
          (serverRequestParams.current as IMapConfig).position.lon +
          "/" +
          (serverRequestParams.current as IMapConfig).radius +
          "/" +
          listingType +
          "/" +
          sortType // TODO Replace with sort type. 1 = Latest, 2 = Price (low > high), 3 = Price (High > low), 4 = area

        try {
          // fetch data
          const res = await Axios.get(reqURL, {
            cancelToken: cancelToken.current.token,
          })
          cancelToken.current = null

          // set the posts

          setPosts((res.data.posts as any) as IServerListing[])
          setTimeout(() => {
            setLoading(false)
          }, 250)
        } catch (err) {
          // check if request was cancelled
          if (Axios.isCancel(err)) console.warn("cancelled request")
          // if not cancelled, then error was thrown
          else {
            console.error(err)
            setLoading(false)
            setError({ message: "Something went wrong. Please try again" })
            cancelToken.current = null
          }
        }
      },
      // if first request, immediately send the request
      // else queue for 250 milliseconds
      firstRequest.current ? 0 : 250
    )

    // mark first request false, so now we will start debouncing further requests
    firstRequest.current = false

    // request sent
    return
  }

  // return posts, laoding state and error state along with fetchData function
  // this is hosted in the server context
  return {
    posts,
    fetchData,
    error,
    loading,
  }
}
