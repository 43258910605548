import React, { useRef, useState } from "react"
import styled from "styled-components"
import FilterButton from "./filterButton"
import { IFilters } from "../../../Hooks/useFilter"
import { colors } from "../../../Constants/colors"
import { breakpoints } from "../../../Constants/responsive"
import { ImCheckboxUnchecked, ImCheckboxChecked } from "react-icons/im"

const Shrink = styled.div`
  font-size: 0.8em;
  @media (min-width: ${breakpoints.mobile + "px"}) {
    font-size: 0.9em;
  }
`

// const StyledButton = styled.div`
//   font-size: 1em;
//   padding: 0.6em;
//   background: ${colors.primary};
//   color: ${colors.white};
//   text-align: center;
//   border-radius: 2px;
//   cursor: pointer;
//   max-width: 150px;
// `
//
// const StyledInverseButton = styled.div`
//   font-size: 1em;
//   padding: 0.6em;
//   background: ${colors.white};
//   color: ${colors.primary};
//   border: 1px solid ${colors.shadow};
//   text-align: center;
//   border-radius: 2px;
//   cursor: pointer;
//
//   &:hover {
//     border: 1px solid ${colors.primary};
//   }
// `

const DoneButton = styled.button`
  font-size: 0.9em;
  padding: 0.5em;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2%;
  width: 48%;
  background: ${colors.primary};
  border: 2px solid ${colors.primary};
  text-align: center;
  border-radius: 2px;
  cursor: pointer;
  color: ${colors.white};
  font-weight: 600;
`

const ResetButton = styled.button`
  font-size: 0.9em;
  padding: 0.5em;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2%;
  width: 48%;
  background: ${colors.white};
  border: 2px solid ${colors.primary};
  text-align: center;
  border-radius: 2px;
  cursor: pointer;
  color: ${colors.primary};
  font-weight: 600;
  &:hover {
    background: ${colors.lightprimary};
  }
`

const MoreFilterRow = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
`

const StyledInput = styled.input`
  border: 1.5px solid ${colors.primary};
  border-radius: 2px;
  padding: 0.8em;
  outline: none;
  display: inline-block;
  width: 100%;
  font-weight: 600;
  color: ${colors.primary};
  &:focus,
  &:hover {
    background: ${colors.lightprimary};
  }
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${colors.primary};
    opacity: 1; /* Firefox */
    font-weight: 500;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${colors.primary};
    font-weight: 500;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${colors.primary};
    font-weight: 500;
  }
`

const Label = styled.div`
  font-size: 1em;
  margin-top: 2%;
  margin-bottom: 2%;
`

const CheckBoxRow = styled.ul`
  font-weight: 500;
  padding-left: 0;
  max-width: 800px;
  font-size: 1em;
  display: flex;
  align-content: flex-start;
  min-width: 300px;
  li {
    list-style: none;
    color: ${colors.primary};
    display: flex;
    flex-wrap: nowrap;
    input:checked {
      display: inline;
      background: ${colors.black};
      color: ${colors.primary};
      &:hover {
        background-color: ${colors.primary};
      }
    }
  }
`

const ButtonRow = styled.div`
  width: 100%;
  padding: 0 1em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  box-sizing: border-box;
`

const MainLabel = styled.div`
  align-self: center;
  color: rgba(0, 0, 0, 0.6);
  font-size: 1.1em;
`
const LightRow = styled.hr`
  border-color: ${colors.shadow};
  opacity: 0.1;
  margin-bottom: 20px;
`

const OptionsRow = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: left;
  align-items: center;
  margin-bottom: 20px;
  border-radius: 2px;
  overflow: hidden;
  border: 1.5px solid ${colors.primary};
  border-right: none;
  width: 100%;
`

const PricingRow = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  // max-width: 800px;
  // margin-bottom: 20px;
`
const CheckBoxRowCol = styled.div`
  width: 33.33%;
  display: block;
  margin: auto;
`
const Option = styled.div<{ active: boolean }>`
  padding: 0.8em;
  text-align: center;
  font-size: 0.8em;
  font-weight: 600;
  background: ${props => (props.active ? colors.primary : colors.white)};
  color: ${props => (props.active ? colors.white : colors.primary)};
  border: 1.5px solid transparent;
  border-right: 1.5px solid ${colors.primary};
  flex-grow: 1;
  cursor: pointer;
  box-sizing: border-box;
  &:hover {
    border: 1.5px solid ${colors.primary};
    background: ${colors.primary};
    color: ${colors.white};
  }
`

interface IProps {
  filter: IFilters
}

interface IAllFiltersDropdownProps {
  filter: IFilters
  closeDropdown?: () => void
}

const AllFiltersDropdown: React.FC<IAllFiltersDropdownProps> = (
  props: IAllFiltersDropdownProps
) => {
  const filter = props.filter

  // Pricing
  const minPriceInput: React.MutableRefObject<HTMLInputElement | null> = useRef(
    null
  )
  const maxPriceInput: React.MutableRefObject<HTMLInputElement | null> = useRef(
    null
  )

  // Bed & Baths
  const options = [0, 1, 2, 3, 4]
  const [bedrooms, setBedrooms] = useState(filter.bedroomsBaths.value.bedrooms)
  const [baths, setBaths] = useState(filter.bedroomsBaths.value.baths)

  const minArea: React.MutableRefObject<HTMLInputElement | null> = useRef(null)
  const maxArea: React.MutableRefObject<HTMLInputElement | null> = useRef(null)
  const minYearBuilt: React.MutableRefObject<HTMLInputElement | null> = useRef(
    null
  )
  const maxYearBuilt: React.MutableRefObject<HTMLInputElement | null> = useRef(
    null
  )

  const settingCheck = (index: string) => {
    setFurnish((check: any) => {
      if (check.some((item: string) => item === index)) {
        return (check = check.filter((item: string) => item !== index))
      } else {
        return (check = [...check, index])
      }
    })
  }
  const settingOthers = (index: string) => {
    setOthers((others: any) => {
      if (others.some((item: string) => item === index)) {
        return (others = others.filter((item: string) => item !== index))
      } else {
        return (others = [...others, index])
      }
    })
  }
  const [furnish, setFurnish] = useState([...filter.furnishing.value])
  const [others, setOthers] = useState([...filter.otherAmenities.value])
  return (
    <Shrink>
      {/* <ButtonRow>
        <StyledInverseButton
          onClick={() => {
            filter.reset()
            props.closeDropdown && props.closeDropdown()
          }}
        >
          Reset
        </StyledInverseButton>
        <MainLabel>Filter Thikanas</MainLabel>
        <StyledButton
          onClick={() => {
            // set filters
            if (minPriceInput.current && maxPriceInput.current)
              filter.pricing.set([
                +minPriceInput.current.value,
                +maxPriceInput.current.value === 0
                  ? -1
                  : +maxPriceInput.current.value,
              ])
            filter.bedroomsBaths.set({ bedrooms, baths })
            props.closeDropdown && props.closeDropdown()
          }}
        >
          Done
        </StyledButton>
      </ButtonRow>
      <LightRow></LightRow> */}

      {/* Price Range */}
      <Label>Price Range</Label>
      <PricingRow>
        <StyledInput
          ref={minPriceInput}
          type="number"
          placeholder="Min"
          defaultValue={
            filter.pricing.value[0] === 0 ? undefined : filter.pricing.value[0]
          }
        ></StyledInput>
        <span style={{ margin: "0 5px" }}> - </span>
        <StyledInput
          ref={maxPriceInput}
          type="number"
          placeholder="Max"
          defaultValue={
            filter.pricing.value[1] === -1 ? undefined : filter.pricing.value[1]
          }
        ></StyledInput>
      </PricingRow>

      {/* Beds and Baths */}
      <Label>Bedrooms</Label>
      <OptionsRow>
        {options.map(opt => {
          return (
            <Option
              active={bedrooms === opt}
              onClick={() => {
                setBedrooms(opt)
              }}
            >
              {opt === 0 ? "Any" : opt + "+"}
            </Option>
          )
        })}
      </OptionsRow>

      <Label>Baths</Label>
      <OptionsRow>
        {options.map(opt => {
          return (
            <Option
              active={baths === opt}
              onClick={() => {
                setBaths(opt)
              }}
            >
              {opt === 0 ? "Any" : opt + "+"}
            </Option>
          )
        })}
      </OptionsRow>

      {/* Square Feet*/}
      <Label>Square Feet</Label>
      <PricingRow>
        <StyledInput
          ref={minArea}
          type="number"
          placeholder="Min"
          defaultValue={
            filter.squarefeet.value[0] === 0
              ? undefined
              : filter.squarefeet.value[0]
          }
        ></StyledInput>
        <span style={{ margin: "0 5px" }}> - </span>
        <StyledInput
          ref={maxArea}
          type="number"
          placeholder="Max"
          defaultValue={
            filter.squarefeet.value[1] === -1
              ? undefined
              : filter.squarefeet.value[1]
          }
        ></StyledInput>
      </PricingRow>

      {/* Year Built*/}

      <Label>Year Built</Label>
      <PricingRow>
        <StyledInput
          ref={minYearBuilt}
          type="number"
          placeholder="Min"
          defaultValue={
            filter.yearbuilt.value[0] === 0
              ? undefined
              : filter.yearbuilt.value[0]
          }
        ></StyledInput>
        <span style={{ margin: "0 5px" }}> - </span>
        <StyledInput
          ref={maxYearBuilt}
          type="number"
          placeholder="Max"
          defaultValue={
            filter.yearbuilt.value[1] === -1
              ? undefined
              : filter.yearbuilt.value[1]
          }
        ></StyledInput>
      </PricingRow>

      <Label>Furnishing</Label>
      <CheckBoxRow>
        {" "}
        <CheckBoxRowCol>
          <div
            style={{
              paddingBottom: "0.8em",
              cursor: "pointer",
            }}
            onClick={() => settingCheck("0")}
          >
            {furnish.some(item => item === "0") ? (
              <ImCheckboxChecked
                style={{
                  fontSize: "1.2em",
                  marginRight: "0.8em",
                  color: "#f46029",
                  cursor: "pointer",
                }}
              >
                {" "}
              </ImCheckboxChecked>
            ) : (
              <ImCheckboxUnchecked
                style={{
                  fontSize: "1.2em",
                  marginRight: "0.8em",
                  color: "#f46029",
                  cursor: "pointer",
                }}
              ></ImCheckboxUnchecked>
            )}
            Unfurnished
          </div>
        </CheckBoxRowCol>
        <CheckBoxRowCol>
          <div
            style={{
              paddingBottom: "0.8em",
              cursor: "pointer",
            }}
            onClick={() => settingCheck("1")}
          >
            {furnish.some(item => item === "1") ? (
              <ImCheckboxChecked
                style={{
                  fontSize: "1.2em",
                  marginRight: "0.8em",
                  color: "#f46029",
                  cursor: "pointer",
                }}
              >
                {" "}
              </ImCheckboxChecked>
            ) : (
              <ImCheckboxUnchecked
                style={{
                  fontSize: "1.2em",
                  marginRight: "0.8em",
                  color: "#f46029",
                  cursor: "pointer",
                }}
              ></ImCheckboxUnchecked>
            )}
            Semi-furnished
          </div>
        </CheckBoxRowCol>
        <CheckBoxRowCol>
          <div
            style={{
              paddingBottom: "0.8em",
              cursor: "pointer",
            }}
            onClick={() => settingCheck("2")}
          >
            {furnish.some(item => item === "2") ? (
              <ImCheckboxChecked
                style={{
                  fontSize: "1.2em",
                  marginRight: "0.8em",
                  color: "#f46029",
                  cursor: "pointer",
                }}
              >
                {" "}
              </ImCheckboxChecked>
            ) : (
              <ImCheckboxUnchecked
                style={{
                  fontSize: "1.2em",
                  marginRight: "0.8em",
                  color: "#f46029",
                  cursor: "pointer",
                }}
              ></ImCheckboxUnchecked>
            )}
            Furnished
          </div>
        </CheckBoxRowCol>
      </CheckBoxRow>

      <Label>Other Amenities</Label>
      <CheckBoxRow>
        <CheckBoxRowCol>
          <div
            style={{
              paddingBottom: "0.8em",
              cursor: "pointer",
            }}
            onClick={() => settingOthers("A/C")}
          >
            {others.some(item => item === "A/C") ? (
              <ImCheckboxChecked
                style={{
                  fontSize: "1.2em",
                  marginRight: "0.8em",
                  color: "#f46029",
                  cursor: "pointer",
                }}
              >
                {" "}
              </ImCheckboxChecked>
            ) : (
              <ImCheckboxUnchecked
                style={{
                  fontSize: "1.2em",
                  marginRight: "0.8em",
                  color: "#f46029",
                  cursor: "pointer",
                }}
              ></ImCheckboxUnchecked>
            )}
            AC
          </div>

          <div
            style={{
              paddingBottom: "0.8em",
              cursor: "pointer",
            }}
            onClick={() => settingOthers("Balcony")}
          >
            {others.some(item => item === "Balcony") ? (
              <ImCheckboxChecked
                style={{
                  fontSize: "1.2em",
                  marginRight: "0.8em",
                  color: "#f46029",
                  cursor: "pointer",
                }}
              >
                {" "}
              </ImCheckboxChecked>
            ) : (
              <ImCheckboxUnchecked
                style={{
                  fontSize: "1.2em",
                  marginRight: "0.8em",
                  color: "#f46029",
                  cursor: "pointer",
                }}
              ></ImCheckboxUnchecked>
            )}
            Balcony
          </div>

          <div
            style={{
              paddingBottom: "0.8em",
              cursor: "pointer",
            }}
            onClick={() => settingOthers("Club House")}
          >
            {others.some(item => item === "Club House") ? (
              <ImCheckboxChecked
                style={{
                  fontSize: "1.2em",
                  marginRight: "0.8em",
                  color: "#f46029",
                  cursor: "pointer",
                }}
              >
                {" "}
              </ImCheckboxChecked>
            ) : (
              <ImCheckboxUnchecked
                style={{
                  fontSize: "1.2em",
                  marginRight: "0.8em",
                  color: "#f46029",
                  cursor: "pointer",
                }}
              ></ImCheckboxUnchecked>
            )}
            Club House
          </div>
        </CheckBoxRowCol>

        <CheckBoxRowCol>
          <div
            style={{
              paddingBottom: "0.8em",
              cursor: "pointer",
            }}
            onClick={() => settingOthers("Gym")}
          >
            {others.some(item => item === "Gym") ? (
              <ImCheckboxChecked
                style={{
                  fontSize: "1.2em",
                  marginRight: "0.8em",
                  color: "#f46029",
                  cursor: "pointer",
                }}
              >
                {" "}
              </ImCheckboxChecked>
            ) : (
              <ImCheckboxUnchecked
                style={{
                  fontSize: "1.2em",
                  marginRight: "0.8em",
                  color: "#f46029",
                  cursor: "pointer",
                }}
              ></ImCheckboxUnchecked>
            )}
            Gym
          </div>

          <div
            style={{
              paddingBottom: "0.8em",
              cursor: "pointer",
            }}
            onClick={() => settingOthers("Lift")}
          >
            {others.some(item => item === "Lift") ? (
              <ImCheckboxChecked
                style={{
                  fontSize: "1.2em",
                  marginRight: "0.8em",
                  color: "#f46029",
                  cursor: "pointer",
                }}
              >
                {" "}
              </ImCheckboxChecked>
            ) : (
              <ImCheckboxUnchecked
                style={{
                  fontSize: "1.2em",
                  marginRight: "0.8em",
                  color: "#f46029",
                  cursor: "pointer",
                }}
              ></ImCheckboxUnchecked>
            )}
            Lift
          </div>

          <div
            style={{
              paddingBottom: "0.8em",
              cursor: "pointer",
            }}
            onClick={() => settingOthers("Park")}
          >
            {others.some(item => item === "Park") ? (
              <ImCheckboxChecked
                style={{
                  fontSize: "1.2em",
                  marginRight: "0.8em",
                  color: "#f46029",
                  cursor: "pointer",
                }}
              >
                {" "}
              </ImCheckboxChecked>
            ) : (
              <ImCheckboxUnchecked
                style={{
                  fontSize: "1.2em",
                  marginRight: "0.8em",
                  color: "#f46029",
                  cursor: "pointer",
                }}
              ></ImCheckboxUnchecked>
            )}
            Park
          </div>
        </CheckBoxRowCol>

        <CheckBoxRowCol>
          <div
            style={{
              paddingBottom: "0.8em",
              cursor: "pointer",
            }}
            onClick={() => settingOthers("Parking")}
          >
            {others.some(item => item === "Parking") ? (
              <ImCheckboxChecked
                style={{
                  fontSize: "1.2em",
                  marginRight: "0.8em",
                  color: "#f46029",
                  cursor: "pointer",
                }}
              >
                {" "}
              </ImCheckboxChecked>
            ) : (
              <ImCheckboxUnchecked
                style={{
                  fontSize: "1.2em",
                  marginRight: "0.8em",
                  color: "#f46029",
                  cursor: "pointer",
                }}
              ></ImCheckboxUnchecked>
            )}
            Parking
          </div>

          <div
            style={{
              paddingBottom: "0.8em",
              cursor: "pointer",
            }}
            onClick={() => settingOthers("Power Backup")}
          >
            {others.some(item => item === "Power Backup") ? (
              <ImCheckboxChecked
                style={{
                  fontSize: "1.2em",
                  marginRight: "0.8em",
                  color: "#f46029",
                  cursor: "pointer",
                }}
              >
                {" "}
              </ImCheckboxChecked>
            ) : (
              <ImCheckboxUnchecked
                style={{
                  fontSize: "1.2em",
                  marginRight: "0.8em",
                  color: "#f46029",
                  cursor: "pointer",
                }}
              ></ImCheckboxUnchecked>
            )}
            Power Backup
          </div>

          <div
            style={{
              paddingBottom: "0.8em",
              cursor: "pointer",
            }}
            onClick={() => settingOthers("Swimming Pool")}
          >
            {others.some(item => item === "Swimming Pool") ? (
              <ImCheckboxChecked
                style={{
                  fontSize: "1.2em",
                  marginRight: "0.8em",
                  color: "#f46029",
                  cursor: "pointer",
                }}
              >
                {" "}
              </ImCheckboxChecked>
            ) : (
              <ImCheckboxUnchecked
                style={{
                  fontSize: "1.2em",
                  marginRight: "0.8em",
                  color: "#f46029",
                  cursor: "pointer",
                }}
              ></ImCheckboxUnchecked>
            )}
            Swimming Pool
          </div>
        </CheckBoxRowCol>
      </CheckBoxRow>
      <ButtonRow>
        <ResetButton
          onClick={() => {
            filter.reset()
            props.closeDropdown && props.closeDropdown()
          }}
        >
          Reset
        </ResetButton>

        <DoneButton
          onClick={() => {
            // set filters
            if (minPriceInput.current && maxPriceInput.current)
              filter.pricing.set([
                +minPriceInput.current.value,
                +maxPriceInput.current.value === 0
                  ? -1
                  : +maxPriceInput.current.value,
              ])
            if (minArea.current && maxArea.current) {
              filter.squarefeet.set([
                +minArea.current.value,
                +maxArea.current.value,
              ])
            }
            if (minYearBuilt.current && maxYearBuilt.current) {
              filter.yearbuilt.set([
                +minYearBuilt.current.value,
                +maxYearBuilt.current.value,
              ])
            }
            if (furnish.length > 0) {
              filter.furnishing.set([...furnish])
            }
            if (others.length > 0) {
              filter.otherAmenities.set([...others])
            }
            filter.bedroomsBaths.set({ bedrooms, baths })
            props.closeDropdown && props.closeDropdown()
          }}
        >
          Done
        </DoneButton>
      </ButtonRow>
    </Shrink>
  )
}

const MoreFilter: React.FC<IProps> = (props: IProps) => {
  return (
    <FilterButton openRight={false} fullScreenDropdown={true}>
      <span>All Filters</span>
      <AllFiltersDropdown filter={props.filter}></AllFiltersDropdown>
    </FilterButton>
  )
}

export default MoreFilter
