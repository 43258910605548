import { useReducer } from "react";

const reducer = (state: boolean, action = true) => {
  return !state;
};

const useModal: () => [boolean, React.DispatchWithoutAction] = () => {
  // handles a modal's open/ close state
  const [open, dispatch] = useReducer(reducer, false);
  return [open, dispatch];
};

export default useModal;
