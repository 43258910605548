import React from "react";
import styled from "styled-components";
import ExtendedListingHeader from "./header";
import { breakpoints } from "../../../../Constants/responsive";

const WideHeaderWrapper = styled.div`
  display: block;

  @media (min-width: ${breakpoints.tablet + "px"}) {
    display: none;
  }
`;
interface IProps {
  toggle: () => void;
}

const WideHeader: React.FC<IProps> = (props: IProps) => {
   
  return (
    <WideHeaderWrapper>
   <ExtendedListingHeader
          showBackButton={true}
        toggle={props.toggle}
      ></ExtendedListingHeader>  
    </WideHeaderWrapper>
  );
};

export default WideHeader;
