import React, {
  useState,
  useContext,
  createContext,
  ReactNode,
  useEffect,
  useRef
} from "react";
import { IServerListing } from "../mockListings";
import { useServer } from "./useServer";
import usePagination, { IPagination } from "./usePagination";

//@ts-ignore
import filterWebWorker from "workerize-loader!../WebWorkers/filterWebWorker"; // eslint-disable-line import/no-webpack-loader-syntax

export enum SortOptions {
  Newest = "Newest",
  Price1 = "Price (Low to High)"
  // Price2 = "Price (High to Low)",
  // SquareFeet = "Square Feet"
}
export interface IFilters {
  pricing: {
    value: [number, number];
    set: React.Dispatch<React.SetStateAction<[number, number]>>;
  };
  rentSell: {
    value: 0 | 1;
    set: React.Dispatch<React.SetStateAction<number>>;
  };
  bedroomsBaths: {
    value: {
      bedrooms: number;
      baths: number;
    };
    set: React.Dispatch<
      React.SetStateAction<{
        bedrooms: number;
        baths: number;
      }>
    >;
  };
  hometype: {
    value: String[];
    set:React.Dispatch<React.SetStateAction<String[]>>;
  }
  squarefeet: {
    value: [number, number];
    set: React.Dispatch<React.SetStateAction<[number, number]>>;
  }
  yearbuilt: {
    value: [number, number];
    set: React.Dispatch<React.SetStateAction<[number, number]>>;

  }
  furnishing: {
    value: String[];
    set: React.Dispatch<React.SetStateAction<String[]>>;
  };
  otherAmenities: {
    value: String[];
    set: React.Dispatch<React.SetStateAction<String[]>>;
  }
  reset: () => void;
}

interface IFilterContext {
  posts: IServerListing[];
  paginatedPosts: IServerListing[];
  filters: IFilters | {};
  pagination: IPagination | {};
  viewMapMode: {
    set: React.Dispatch<React.SetStateAction<boolean>>;
    value: boolean;
  };
  sortBy: {
    set: React.Dispatch<React.SetStateAction<SortOptions>>;
    value: SortOptions;
  };
}

// Setup Filter Context

const filterDataContext = createContext<IFilterContext>({
  posts: [],
  paginatedPosts: [],
  filters: {},
  pagination: {},
  viewMapMode: {
    set: () => {},
    value: true
  },
  sortBy: {
    set: () => {},
    value: SortOptions.Newest
  }
});

export function ProvideFilterData({ children }: { children: ReactNode }) {
  const filterData = useFilterData();
  return (
    <filterDataContext.Provider value={filterData}>
      {children}
    </filterDataContext.Provider>
  );
}

export const useFilter = () => {
  return useContext(filterDataContext);
};

function useFilterData() {
  // to access up-to-date server data
  const server = useServer();
  // ref to store the web worker instance responsible for filtering
  const workerRef = useRef<Worker>();

  const [viewMapMode, setViewMapMode] = useState(true);

  // posts holds the filtered posts depending on currently applied filters
  const [posts, setPosts] = useState<IServerListing[]>([]);

  //////////
  // SORT
  //////////

  const [sortBy, setSortBy] = useState<SortOptions>(SortOptions.Newest);

  //////////
  // FILTERS
  //////////

  // pricing filter, in form of lower bound, upper bound
  const [pricingFilter, setPricingFilter] = useState<[number, number]>([0, -1]);
  // rent/ sell filter (this is filtered on server side, other properties are filtered client side)
  // rent: 0, sell: 1
  const [rentSellFilter, setRentSellFilter] = useState(0);
  const [homeType, setHomeType] = useState([])
  const [squareFeet, setSquareFeet] = useState<[number, number]>([0, -1])
  const [yearBuilt,setYearBuilt]=useState<[number, number]>([0, -1])
  // bed rooms filter, in form of #rooms > filter
  const [bedroomsBathsFilter, setBedRoomsBathsFilter] = useState<{
    bedrooms: number;
    baths: number;
  }>({ baths: 0, bedrooms: 0 });
  // furnishing filter, 0 = unfurnished, 1 = semi furnished, 2 = fully furnished
  const [furnishingFilter, setFurnishingFilter] = useState<number[]>([]);
  const [otherOptions,setOtherOptions]=useState<String[]>([])
  const reset = () => {
    setPricingFilter([0, -1]);
    setBedRoomsBathsFilter({ baths: 0, bedrooms: 0 });
    setFurnishingFilter([]);
    setHomeType([])
    setSquareFeet([0, -1])
    setYearBuilt([0, -1])
    setOtherOptions([])
  };

  // initialise the pagination logic, pass the posts state which will get paginated
  const pagination = usePagination(posts);

  // holds the paginated posts (only section of posts on active page)
  const [paginatedPosts, setPaginatedPosts] = useState<IServerListing[]>([]);

  // setup web worker
  useEffect(() => {
    const worker: Worker = filterWebWorker();
    workerRef.current = worker;
    worker.addEventListener("message", (msg: any) => {
      if (msg.data.filteredPosts) {
        // if recieved filteredPosts, set them in filteredposts state
        setPosts(msg.data.filteredPosts);
      }
    });
    // terminate worker on unmount
    return () => {
      worker.terminate();
    };
  }, []);

  useEffect(() => {
    // whenever the page changes, set new section of posts as paginated posts
    setPaginatedPosts(
      posts.slice(pagination.sliceIndices[0], pagination.sliceIndices[1])
    );
  }, [pagination.sliceIndices, posts]);

  useEffect(() => {
    if (workerRef.current)
      // apply filters whenever either posts or filter change
      // apply filters using a web worker, to ensure smooth UI
      //@ts-ignore
      workerRef.current.applyFilters(server.posts, {
        pricingFilter,
        homeType,
        bedroomsBathsFilter,
        furnishingFilter,
        sortBy,
        squareFeet,
        yearBuilt,
        otherOptions,


      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    server.posts,
      pricingFilter,
      homeType,
      squareFeet,
      yearBuilt,
    bedroomsBathsFilter,
      furnishingFilter,
    otherOptions,
    sortBy
  ]);

  // posts: all filtered posts
  // paginatedPosts: filtered posts on currently active page
  // filter: all filter's state
  // pagination: to hold pagination data in context
  return {
    posts,
    paginatedPosts,
    filters: {
      pricing: {
        value: pricingFilter,
        set: setPricingFilter
      },
      rentSell: {
        value: rentSellFilter,
        set: setRentSellFilter
      },
      bedroomsBaths: {
        value: bedroomsBathsFilter,
        set: setBedRoomsBathsFilter
      },
      furnishing: {
        value: furnishingFilter,
        set: setFurnishingFilter
      },
      otherAmenities: {
        value: otherOptions,
        set:setOtherOptions
      },
      hometype: {
        value: homeType,
        set:setHomeType
      },
      squarefeet: {
        value: squareFeet,
        set:setSquareFeet
      },
      yearbuilt: {
        value: yearBuilt,
        set:setYearBuilt
      }
      ,
      reset
    },
    pagination,
    viewMapMode: {
      set: setViewMapMode,
      value: viewMapMode
    },
    sortBy: {
      set: setSortBy,
      value: sortBy
    }
  };
}
