import React, { useState, useEffect } from "react"
import styled from "styled-components"
import FilterButton from "./filterButton"
import { IFilters } from "../../../Hooks/useFilter"
import { colors } from "../../../Constants/colors"

const Label = styled.div`
  font-size: 1em;
  margin-top: 2%;
  margin-bottom: 2%;
`

const OptionsRow = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: left;
  align-items: center;
  margin-bottom: 20px;
  border-radius: 2px;
  overflow: hidden;
  border: 1.5px solid ${colors.primary};
  border-right: none;
  width: 400px;
`

const Option = styled.div<{ active: boolean }>`
  padding: 0.8em;
  text-align: center;
  font-size: 0.8em;
  font-weight: 600;
  background: ${props => (props.active ? colors.primary : colors.white)};
  color: ${props => (props.active ? colors.white : colors.primary)};
  border: 1.5px solid transparent;
  border-right: 1.5px solid ${colors.primary};
  flex-grow: 1;
  cursor: pointer;
  box-sizing: border-box;
  &:hover {
    border: 1.5px solid ${colors.primary};
    background: ${colors.primary};
    color: ${colors.white};
  }
`

const DoneButton = styled.button`
  font-size: 0.9em;
  padding: 0.5em;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2%;
  width: 100%;
  background: ${colors.primary};
  border: 2px solid ${colors.primary};
  text-align: center;
  border-radius: 2px;
  cursor: pointer;
  color: ${colors.white};
  font-weight: 600;
`

const ResetButton = styled.button`
  font-size: 0.9em;
  padding: 0.5em;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2%;
  width: 100%;
  background: ${colors.white};
  border: 2px solid ${colors.primary};
  text-align: center;
  border-radius: 2px;
  cursor: pointer;
  color: ${colors.primary};
  font-weight: 600;
  &:hover {
    background: ${colors.lightprimary};
  }
`

interface IProps {
  filter: IFilters
}

interface IBedBathProps {
  filter: IFilters
  closeDropdown?: () => void
}

const BedBath = (props: IBedBathProps) => {
  const filter = props.filter

  useEffect(() => {
    setBedrooms(filter.bedroomsBaths.value.bedrooms)
    setBaths(filter.bedroomsBaths.value.baths)
  }, [filter.bedroomsBaths.value.bedrooms, filter.bedroomsBaths.value.baths])

  const options = [0, 1, 2, 3, 4]

  const [bedrooms, setBedrooms] = useState(filter.bedroomsBaths.value.bedrooms)
  const [baths, setBaths] = useState(filter.bedroomsBaths.value.baths)

  return (
    <>
      <Label>Bedrooms</Label>
      <OptionsRow>
        {options.map((opt, index) => {
          return (
            <Option
              key={index}
              active={bedrooms === opt}
              onClick={() => {
                setBedrooms(opt)
              }}
            >
              {opt === 0 ? "Any" : opt + "+"}
            </Option>
          )
        })}
      </OptionsRow>

      <Label>Baths</Label>
      <OptionsRow>
        {options.map((opt, index) => {
          return (
            <Option
              key={index}
              active={baths === opt}
              onClick={() => {
                setBaths(opt)
              }}
            >
              {opt === 0 ? "Any" : opt + "+"}
            </Option>
          )
        })}
      </OptionsRow>
      <DoneButton
        onClick={() => {
          filter.bedroomsBaths.set({ bedrooms, baths })
          props.closeDropdown && props.closeDropdown()
        }}
      >
        Done
      </DoneButton>
      <ResetButton
        onClick={() => {
          filter.reset()
          props.closeDropdown && props.closeDropdown()
        }}
      >
        Reset
      </ResetButton>
    </>
  )
}

const BedBathFilter: React.FC<IProps> = (props: IProps) => {
  const filter = props.filter

  return (
    <FilterButton openRight={false} collapsable={true}>
      {filter.bedroomsBaths.value.baths === 0 &&
      filter.bedroomsBaths.value.bedrooms === 0 ? (
        <span>Beds &#38; Baths</span>
      ) : (
        <span>
          {filter.bedroomsBaths.value.bedrooms +
            "+ bd, " +
            filter.bedroomsBaths.value.baths +
            "+ ba"}
        </span>
      )}
      <BedBath filter={filter}></BedBath>
    </FilterButton>
  )
}

export default BedBathFilter
