import React, { useEffect } from "react";
import { IListingMapControls } from "./listingMap";
import { useLeaflet } from "react-leaflet";

const ListingMapControls: React.FC<IListingMapControls> = (
  props: IListingMapControls
) => {
  // the component handles updating the mapConfig as user interacts with the leaflet map

  const { map } = useLeaflet();

  const getRadius = () => {
    if (!map) return 100;
    return map
      .getBounds()
      .getNorthEast()
      .distanceTo(map.getCenter());
  };

  const updatePosition = () => {
    if (!map) return;
    const pos = map.getCenter();
    const zoom = map.getZoom();
    const radius = getRadius();
    props.setMapConfig({
      position: {
        lat: pos.lat,
        lon: pos.lng
      },
      zoom,
      radius
    });
  };

  useEffect(() => {
    if (!map) return;
    map.on("moveend", updatePosition);

    return () => {
      map.off("moveend", updatePosition);
    };
  });

  return <span></span>;
};

export default ListingMapControls;
