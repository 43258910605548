import React, { useState } from "react";
import styled from "styled-components";
import ListingImage from "../../ListingCard/listingImage";
import { getScrollBarWidth } from "../../../../Utils/getScrollBarWidth";
import { breakpoints } from "../../../../Constants/responsive";
import Modal from "../../../Modal/modal";
import FullScreenImageViewer from "./fullScreenImageViewer";
import useModal from "../../../../Hooks/useModal";

const scrollBarWidth = getScrollBarWidth();

const MainWrapper = styled.div`
  height: 100%;
  flex: 1 0 0px;
  display: none;
  flex-flow: row wrap;
  align-items: flex-start;
  align-content: flex-start;
  overflow-y: scroll;
  margin-right: calc(-0.25em - ${scrollBarWidth + "px"});

  @media (min-width: ${breakpoints.mobile + "px"}) {
    display: flex;
  }
`;

const ImgWrapper = styled.div`
  flex: 1 0 40%;
  height: auto;
  margin: 0 0.25em 0.25em 0;

  @media (min-width: ${breakpoints.mobile + "px"}) {
    flex: 1 0 220px;
  }

  @media (min-width: 900px) {
    flex: 1 0 40%;
  }
`;

interface IProps {
  images: string[];
}

const DesktopImageViewer: React.FC<IProps> = ({ images }: IProps) => {
  const [open, toggle] = useModal();
  const [tappedOn, changeTappedOn] = useState(0);
  return (
    <>
      <MainWrapper>
        {images.map((src, i) => {
          return (
            <ImgWrapper
              onClick={() => {
                changeTappedOn(i);
                toggle();
              }}
              key={i}
            >
              <ListingImage src={src} paddingTop={100}></ListingImage>
            </ImgWrapper>
          );
        })}
      </MainWrapper>
      {open && (
        <Modal open={open} toggle={toggle} zIndex={10000}>
          <FullScreenImageViewer
            toggle={toggle}
            images={images}
            index={tappedOn}
          ></FullScreenImageViewer>
        </Modal>
      )}
    </>
  );
};

export default DesktopImageViewer;
