export const colors = {
  primary: "#f46029",
  midprimary: "#ffe5d9",
  lightprimary: "#fff6f2",
  secondary: "#2d3234",
  light: "#f7f7f7",
  dark: "#333399",
  white: "white",
  black: "#292b2c",
  red: "#d9534f",
  green: "#5cb85c",
  yellow: "#f0ad4e",
  shadow: "#c2c2c2",
  lightgrey:"#d1d1d5"
};
