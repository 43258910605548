import React, { useState, useEffect } from "react"
import styled from "styled-components"
import ListingsWrapper from "./Components/Listings/listingsWrapper"
import { IMapConfig } from "./Components/Listings/ListingMap/listingMap"
import { Route, Redirect, Switch } from "react-router"
import { BrowserRouter } from "react-router-dom"
import TopHeader from "./Components/Header/topHeader"
import SecondHeader from "./Components/Header/secondHeader"
import AboutScreen from "./Components/About/about"
import PrivacyScreen from "./Components/Privacy/privacy"
import ContactScreen from "./Components/Contact/contact"
import CookieScreen from "./Components/Cookie/cookiePolicy"
import TermsScreen from "./Components/TermsOfUse/termsOfUse"
import TermsListingScreen from "./Components/TermsOfUse/termsConditionListing"
// import HelpScreen from "./Components/Help/help"
// import HelpScreenArticle from "./Components/Help/helpArticle"
import AddListingScreen from "./Components/Add Listing/addListingDescription"
import { SavedThikanas } from "./Components/SavedThikanas/savedThikanas"
import Homepage from "./Components/Homepage/homepage"
import SuccessScreen from "./Components/Add Listing/success-page"
import MyListings from "./Components/Add Listing/myListings"
import { REACT_APP_GA_TRACKING_CODE } from "./Constants/serverConfig";
// import { analyticsInit } from "./Utils/analytics"
import ReactGA from 'react-ga';

ReactGA.initialize(REACT_APP_GA_TRACKING_CODE);

// interface propsInterface extends RouteComponentProps {
//   id: number
//   isPopular: boolean
//   shortHeading: string
//   description: string
//   heading: string
//   type: string
// }

const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
`

const startConfig: IMapConfig = {
  // gurgaon's location
  position: {
    lat: 28.4595,
    lon: 77.0266,
  },
  zoom: 13,
  // radius in meters, based on zoom of 13 in leaflet
  radius: 7225,
}

function App() {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  })

  const [mapStartConfig, setMapStartConfig] = useState<IMapConfig>(startConfig);

  return (
    <MainWrapper>
      <BrowserRouter>
        <TopHeader
          mapStartConfig={mapStartConfig}
          setMapStartConfig={setMapStartConfig}
        ></TopHeader>

        <Switch>
          <Route
            exact
            path="/"
            component={Homepage}
          ></Route>

          <Route path="/homepage"
          render={() => <Redirect to="/"></Redirect>}
          ></Route>

          <Route
            path="/app"
            exact
            render={() => (
              <>
                <SecondHeader
                  mapStartConfig={mapStartConfig}
                  setMapStartConfig={setMapStartConfig}
                ></SecondHeader>
                <ListingsWrapper
                  mapStartConfig={mapStartConfig}
                  setMapStartConfig={setMapStartConfig}
                ></ListingsWrapper>
              </>
            )}
          ></Route>

          {/* <Route path="/help" component={HelpScreen} exact></Route> */}

          {/* <Route
            path="/help/:category"
            render={routeProps => <HelpScreenArticle {...routeProps} />}
            exact
          ></Route> */}
          <Route
            exact
            path="/app/:postId"
            render={() => (
              <>
                <SecondHeader
                  mapStartConfig={mapStartConfig}
                  setMapStartConfig={setMapStartConfig}
                ></SecondHeader>
                <ListingsWrapper
                  mapStartConfig={mapStartConfig}
                  setMapStartConfig={setMapStartConfig}
                ></ListingsWrapper>
              </>
            )}
          ></Route>
          <Route path="/about" component={AboutScreen}></Route>
          <Route path="/privacy-policy" component={PrivacyScreen}></Route>
          <Route path="/cookie-policy" component={CookieScreen}></Route>
          <Route path="/terms-and-conditions" component={TermsScreen}></Route>
          <Route path="/listing-conditions" component={TermsListingScreen}></Route>
          <Route path="/success-page" component={SuccessScreen}></Route>
          <Route path="/savedThikanas" component={SavedThikanas}></Route>
          <Route path="/contact" component={ContactScreen}></Route>
          <Route path="/newlisting" component={AddListingScreen}></Route>
          <Route path="/editlisting/:postId" component={AddListingScreen}></Route>
          <Route path="/mylistings" component={MyListings}></Route>
          {/* <Route path="/profile" exact component={Profile}></Route> */}
        </Switch>
      </BrowserRouter>
    </MainWrapper>
  )
}

export default App;
