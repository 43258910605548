import React, { useEffect, useRef, useState } from "react"
import { useForm, useFieldArray } from "react-hook-form"
import styled from "styled-components"
import { colors } from "../../Constants/colors"
import { Map, Marker, TileLayer } from "react-leaflet"
import { breakpoints } from "../../Constants/responsive"
import { customMarkerIcon } from "../Listings/ListingMap/listingMap"
import { ILatLang, IServerListing } from "../../mockListings"
import AddNewListingLocation from "./addListingLocation"
import { Dropzone, IDropzoneFile } from "./dropzone"
import Axios from "axios"
import { generateUid } from "../../Utils/generateUid"
import { useHistory, useRouteMatch } from "react-router-dom"
import { useAuth } from "../../Hooks/useAuth"
import { size } from "../../Constants/sizesAndSpacing"
import {
  getListingData,
  getLocation,
  getFiles,
  saveListingData,
  resetLocalStorage,
  parseUrlToFile,
} from "./listingUtils"
import SuccessScreen from "./success-page"
import { baseURL } from "../../Constants/serverConfig"
import "./ToolTip.css"

const PageContainer = styled.div`
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  height: calc(100% - ${size.headerHeight + "px"});
  box-sizing: border-box;
  display: block;
  overflow: scroll;
  position: relative;
  font-size: 0.875em;
  padding: 1em 2em;

  @media (min-width: ${breakpoints.mobile + "px"}) {
    font-size: 0.9375em;
    padding: 1em 2em;
  }
  @media (min-width: ${breakpoints.tablet + "px"}) {
    font-size: 1em;
    padding: 1em 3em;
  }
`

const HiddenDiv = styled.div`
  display: none;
`

const SubPageContainer = styled.div`
  width: 100%;
  height: auto;
  box-sizing: border-box;
  display: block;
  border: 1px solid ${colors.lightgrey};
`

const FixedDiv = styled.div`
  padding-left: 10%;
  padding-right: 10%;
  width: 100%;
  background-color: white;
  box-sizing: border-box;
  border: 1px offset;
  height: 4em;
  position: fixed;
  bottom: 0;
  z-index: 10;
  left: -0.01em;
  box-shadow: 0px -1px 3px ${colors.lightgrey};
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (min-width: ${breakpoints.mobile + "px"}) {
    font-size: 0.8em;
  }
`

const MapContainer = styled.div`
  position: relative;
  height: 13em;
  width: 100%;
  cursor: pointer;
  background: black;
  opacity: 0.9;
`

const MapBox = styled.div`
  position: absolute;
  height: 13em;
  width: 100%;
  z-index: -2;
`

const FormWrapper = styled.div`
  display: table;
  box-sizing: border-box;
  width: 100%;
  padding-bottom: 2em;
  padding-left: 10%;
  padding-right: 10%;
  height: auto;
`

const FormHeader = styled.div`
  color: ${colors.secondary};
  font-size: 1.5em;
  font-weight: 600;
  display: block;
  box-sizing: border-box;
  padding-bottom: 0.5em;
  padding-top: 1em;
`

const FormSubLabel = styled.div`
  display: block;
  margin-bottom: 0.5em;
  font-size: 0.875em;
  font-weight: 500;
  letter-spacing: 0em;
  cursor: pointer;
  box-sizing: inherit;
  width: 90%;
  @media (min-width: ${breakpoints.mobile + "px"}) {
    width: 100%;
  }
`

const FormRowGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-around;
`

const FormRow = styled.div`
  box-sizing: border-box;
  min-width: 275px;
  flex: 1 0 0;
  margin: 0.5em;
  margin-right: auto;
`

const FormLabel = styled.div`
  display: block;
  margin-bottom: 0.5em;
  font-size: 0.875em;
  font-weight: 600;
  letter-spacing: 0em;
  cursor: pointer;
  box-sizing: inherit;
`

const FormDropDown = styled.select`
  cursor: pointer;
  box-shadow: none;
  font-weight: 400;
  font-size: 0.875em;
  letter-spacing: 0px;
  text-transform: none;
  box-sizing: border-box;
  width: 90%;
  padding-left: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-right: 3.75em;
  height: 2.5em;
  border: 1px solid ${colors.lightgrey};
  border-radius: 3px;
  vertical-align: middle;
  overflow: visible;
  display: inline-block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-size: 0.875em;
  background-position: calc(100% - 1.3em) center;
  background-repeat: no-repeat;
  @media (min-width: ${breakpoints.mobile + "px"}) {
    width: 100%;
  }
`

const FormDatePicker = styled.input`
  cursor: pointer;
  box-shadow: none;
  font-weight: 400;
  font-size: 0.875em;
  letter-spacing: 0px;
  text-transform: none;
  box-sizing: border-box;
  width: 90%;
  padding-left: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-right: 1em;
  height: 2.5em;
  border: 1px solid ${colors.lightgrey};
  border-radius: 3px;
  vertical-align: middle;
  overflow: visible;
  display: inline-block;
  ::-webkit-calendar-picker-indicator {
    cursor: pointer;
    outline: none;
    font-size: 1.2em;
    opacity: 0.9;
  }
  @media (min-width: ${breakpoints.mobile + "px"}) {
    width: 100%;
  }
`

const FormTextArea = styled.textarea`
  padding-right: 1em;
  padding-left: 1em;
  box-shadow: none;
  font-weight: 400;
  font-size: 0.875em;
  letter-spacing: 0px;
  text-transform: none;
  box-sizing: border-box;
  width: 90%;
  cursor: text;
  background-color: white;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  height: 10em;
  border: 1px solid ${colors.lightgrey};
  border-radius: 3px;
  vertical-align: middle;
  overflow: visible;
  display: block;
  margin-bottom: 1em;
  white-space: pre-wrap;
  @media (min-width: ${breakpoints.mobile + "px"}) {
    width: 100%;
  }
`

const FormTextAreaSmall = styled.textarea`
  padding-right: 1em;
  padding-left: 1em;
  box-shadow: none;
  font-weight: 400;
  font-size: 0.875em;
  letter-spacing: 0px;
  text-transform: none;
  box-sizing: border-box;
  white-space: pre-wrap;
  width: 90%;
  cursor: text;
  background-color: white;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  height: 5em;
  border: 1px solid ${colors.lightgrey};
  border-radius: 3px;
  vertical-align: middle;
  overflow: visible;
  display: block;
  margin-bottom: 1em;
  @media (min-width: ${breakpoints.mobile + "px"}) {
    width: 100%;
  }
`

const FormInput = styled.input`
  padding-right: 3.75em;
  padding-left: 1em;
  box-shadow: none;
  font-weight: 400;
  font-size: 0.875em;
  letter-spacing: 0px;
  text-transform: none;
  box-sizing: border-box;
  width: 90%;
  cursor: text;
  background-color: white;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  height: 2.5em;
  border: 1px solid ${colors.lightgrey};
  border-radius: 3px;
  vertical-align: middle;
  overflow: visible;
  display: inline-block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  @media (min-width: ${breakpoints.mobile + "px"}) {
    width: 100%;
  }
`

const FormFixedInput = styled.input`
  padding-right: 3.75em;
  padding-left: 1em;
  box-shadow: none;
  font-weight: 400;
  font-size: 0.875em;
  letter-spacing: 0px;
  text-transform: none;
  box-sizing: border-box;
  width: 90%;
  cursor: not-allowed;
  background-color: ${colors.light};
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  height: 2.5em;
  border: 1px solid ${colors.lightgrey};
  border-radius: 3px;
  vertical-align: middle;
  overflow: visible;
  display: inline-block;
  // -webkit-appearance: none;
  // -moz-appearance: none;
  appearance: none;

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${colors.secondary};
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${colors.secondary};
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${colors.secondary};
  }

  @media (min-width: ${breakpoints.mobile + "px"}) {
    width: 100%;
  }
`

const ErrorLog = styled.div`
  display: block;
  margin-top: 1em;
  margin-bottom: 0.5em;
  font-size: 0.875em;
  font-weight: 400;
  box-sizing: inherit;
  color: red;
`

const FixedDivContent = styled.h3`
  color: ${colors.secondary};
  display: flex;
  font-size: 1.2em;
  align-items: center;
  justify-content: center;
  font-weight: 500;
`

const SubmitButton = styled.button`
  padding: 0.5em 1em;
  font-size: 0.875em;
  font-weight: 600;
  align-items: center;
  text-align: center;
  display: block;
  box-sizing: border-box;
  width: auto;
  cursor: pointer;
  height: 2.625em;
  background-color: white;
  color: ${colors.primary};
  margin-right: 0.5em;
  text-decoration: none;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  border-color: ${colors.primary};
  position: relative;
  float: right;

  &:hover {
    background: ${colors.primary};
    color: ${colors.white};
  }
`

const CheckBoxInput = styled.input`
  margin: 0.6em;
`

const BackButton = styled.button`
  border: none;
  width: fit-content;
  background: none;
  color: ${colors.secondary};
  font-size: 0.875em;
  font-weight: 600;
  cursor: pointer;
  margin-bottom: 1em;
`
const TextIcon = styled.i`
  color: ${colors.secondary};
  margin: auto;
  padding-right: 0.5em;
`

export interface INewListingJSONData {
  postType: number
  postID: string
  propertyType: number
  monthlyRent: number
  securityDeposit: number
  bedrooms: number
  bathrooms: number
  squareFeet: number
  yearBuilt: number
  dateAvailable: Date
  leaseDuration: number
  furnishing: number
  aboutTheProperty: string
  leaseSummary: string
  amenities1: string[]
  amenities2: string
  contentIDs: string[]
  userID: string
  name: string
  email: string
  phone: number
  createdDate: Date
  address: string
  locality: string
  city: string
  state: string
  country: string
  location: ILatLang
  latitude: number
  longitude: number
  bookmarks: number
  views: number
  agentID: string
  postStatus: number
  propertyStatus: number
}

const isWhole = (monthlyRent: number) => monthlyRent % 1 === 0

const yearBuiltCheck = new Date().getFullYear()

const todayDate =
  new Date().getFullYear() +
  "-" +
  (new Date().getMonth() + 1) +
  "-" +
  (new Date().getDate() - 1)
const dateYearCheck =
  new Date().getFullYear() +
  1 +
  "-" +
  (new Date().getMonth() + 1) +
  "-" +
  new Date().getDate()

export const LOCAL_STORAGE_NEW_THIKANA_SAVE_FLAG =
  "LOCAL_STORAGE_NEW_THIKANA_SAVE_FLAG"

export interface IThikanaLocation {
  lat: number
  lon: number
}

interface IProps {}

export const AddNewListingDescription: React.FC<IProps> = (props: IProps) => {
  const history = useHistory()
  const auth = useAuth()

  const [submitted, setSubmitted] = useState(false)
  const [submitError, setSubmitError] = useState(false)

  const match = useRouteMatch({
    path: "/editlisting/:postId",
    strict: true,
    sensitive: true,
  })

  const {
    register,
    handleSubmit,
    errors,
    control,
    getValues,
    reset,
    setValue,
  } = useForm<INewListingJSONData>({
    defaultValues: getListingData(),
  })

  const getPostData = async (
    postId: string
  ): Promise<INewListingJSONData | null> => {
    const res = await Axios.get(baseURL + "/post/" + postId)
    if (res && res.data) {
      const data = res.data as IServerListing
      const media: IDropzoneFile[] = []
      data.contentIDs &&
        data.contentIDs.forEach(url => media.push(parseUrlToFile(url)))
      setFiles(media)
      // CHANGE HERE

      setThikanaLocation((data as any)["location"])
      const parsedForm: any[] = []
      Object.keys(data).forEach(key =>
        parsedForm.push({ [key]: (data as any)[key] })
      )
      if (data.amenities) parsedForm.push({ amenities1: data.amenities })
      setValue(parsedForm)
    }
    return null
  }

  useEffect(() => {
    if (match && match.params) {
      reset()
      getPostData((match.params as any).postId)
    }
  }, [])

  const prepareFormData = () => {
    const images = filesRef.current
      .map(file => file.url as string)
      .filter(val => !!val)
    const sub: INewListingJSONData = {
      ...(getValues() as any),
      location: locationRef.current,
      contentIDs: images,
      postID:
        match && match.params ? (match.params as any).postId : generateUid(),
    }

    return sub
  }

  const onSubmit = (data: INewListingJSONData) => {
    // do error handling for images being uploaded, no images being uploaded
    // no thikana selected
    submitListing(prepareFormData())
  }

  const [isLocationModalOpen, setLocationModalOpen] = useState(false)
  const [
    thikanaLocation,
    setThikanaLocation,
  ] = useState<IThikanaLocation | null>(getLocation())

  const locationRef: React.MutableRefObject<any> = useRef(getLocation())
  const filesRef: React.MutableRefObject<IDropzoneFile[]> = useRef(getFiles())

  useEffect(() => {
    if (thikanaLocation || (match && match.params)) {
    } else setLocationModalOpen(true)
    locationRef.current = thikanaLocation
  }, [thikanaLocation])

  const timer = useRef<any>(null)

  const [files, setFiles] = useState<IDropzoneFile[]>(getFiles())

  useEffect(() => {
    filesRef.current = files
  }, [files])

  const submitListing = async (data: INewListingJSONData) => {
    try {
      const res = await Axios.post(
        baseURL + "/share",
        {
          postType: data.postType,
          postID: data.postID,
          propertyType: data.propertyType,
          monthlyRent: data.monthlyRent,
          securityDeposit: Number(data.securityDeposit),
          bedrooms: data.bedrooms,
          bathrooms: data.bathrooms,
          squareFeet: Number(data.squareFeet),
          yearBuilt: Number(data.yearBuilt),
          createdDate: data.createdDate,
          dateAvailable: data.dateAvailable,
          leaseDuration: data.leaseDuration,
          furnishing: data.furnishing,
          aboutTheProperty: data.aboutTheProperty,
          leaseSummary: data.leaseSummary,
          name: data.name,
          // email: data.email,
          phone: data.phone,
          address: data.address,
          city: data.city,
          state: data.state,
          country: data.country,
          latitude: data.location.lat,
          longitude: data.location.lon,
          contentIDs: data.contentIDs,
          // bookmarks: data.bookmarks,
          // views: data.views,
          // agentID: data.agentID,
          // postStatus: data.postStatus,
          // propertyStatus: data.propertyStatus
          amenities: [...data.amenities1],
          amenities2: data.amenities2,
          locality: data.locality,
        },
        {
          headers: {
            userID: auth.user.userID,
            Authorization: auth.user.token,
          },
          params: {},
        }
      )
      setSubmitted(true)
      if (match && match.params && match.params.hasOwnProperty("postID")) {
      } else resetLocalStorage()
    } catch (err) {
      console.error(err)
      setSubmitError(true)
    }
  }

  useEffect(() => {
    timer.current = setInterval(() => {
      if (match && match.params) {
      } else saveListingData(prepareFormData())
    }, 5000)

    return () => {
      if (timer.current) clearInterval(timer.current)
    }
    // autosave every 5 seconds
  }, [])

  const { fields, append, remove } = useFieldArray({
    control,
    name: "amenities2",
  })

  if (fields.length === -1) {
    append({})
  }

  const amenitiesChoice = "A/C|Clubhouse|Balcony|Gym|Lift|Parking|Power Backup|Swimming Pool".split(
    "|"
  )

  const listingData = getListingData()

  if (submitted) return <SuccessScreen></SuccessScreen>

  return (
    <>
      <PageContainer>
        <BackButton
          onClick={() => {
            history.push("/mylistings")
          }}
        >
          <i className="fa fa-chevron-left"></i> My Thikanas
        </BackButton>

        <SubPageContainer>
          <form onSubmit={handleSubmit(onSubmit)}>
            {isLocationModalOpen && (
              <AddNewListingLocation
                setThikanaLocation={setThikanaLocation}
                thikanaLocation={thikanaLocation}
                closeModal={() => setLocationModalOpen(false)}
              ></AddNewListingLocation>
            )}
            <MapContainer onClick={() => setLocationModalOpen(true)}>
              {thikanaLocation && (
                <>
                  <MapBox>
                    <Map
                      style={{ height: "100%", zIndex: 1 }}
                      center={[thikanaLocation.lat, thikanaLocation.lon]}
                      zoom={16}
                      zoomControl={false}
                      scrollWheelZoom={false}
                      dragging={false}
                    >
                      <TileLayer
                        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      />
                      <Marker
                        icon={customMarkerIcon()}
                        position={[thikanaLocation.lat, thikanaLocation.lon]}
                      />
                    </Map>
                  </MapBox>
                  <HiddenDiv>
                    <input
                      type="text"
                      value={thikanaLocation.lat}
                      name="latitude"
                      ref={register({ required: true })}
                    />
                    <input
                      type="text"
                      value={thikanaLocation.lon}
                      name="longitude"
                      ref={register({ required: true })}
                    />
                  </HiddenDiv>
                  {/* <AddressWrapper>
                          <AddressBar>
                          {thikanaLocation.lat &&thikanaLocation.lon ?<>Lat:{thikanaLocation.lat} , Lon:{thikanaLocation.lon}</> :" Edit Thikana Location"}
                             <EditIcon className="fa fa-pen"></EditIcon>
                          </AddressBar>
                        </AddressWrapper> */}
                  {/* <SubAddressBar>
                        {props.thikanaLocation.city}, {props.thikanaLocation.state}
                      </SubAddressBar> */}
                </>
              )}
              {!thikanaLocation && (
                <>
                  <MapBox>
                    <Map
                      style={{ height: "100%", zIndex: 1 }}
                      center={[28.4479239, 77.0495268]}
                      zoom={16}
                      zoomControl={false}
                      scrollWheelZoom={false}
                    >
                      <TileLayer
                        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      />
                      <Marker
                        icon={customMarkerIcon()}
                        position={[28.4479239, 77.0495268]}
                      />
                    </Map>
                  </MapBox>
                  <HiddenDiv>
                    <input
                      type="text"
                      value={28.4479239}
                      name="latitude"
                      ref={register({ required: true })}
                    />
                    <input
                      type="text"
                      value={77.0495268}
                      name="longitude"
                      ref={register({ required: true })}
                    />
                  </HiddenDiv>
                </>
              )}
            </MapContainer>

            <FormWrapper>
              <ErrorLog>
                {submitError && (
                  <div className="error">
                    Something went wrong! Please try again in a moment
                  </div>
                )}
                {errors.latitude && errors.latitude.type === "required" && (
                  <div className="error">
                    Drag the marker to your thikana location{" "}
                  </div>
                )}

                {errors.longitude && errors.longitude.type === "required" && (
                  <div className="error">
                    Drag the marker to your thikana location{" "}
                  </div>
                )}

                {errors.monthlyRent &&
                  errors.monthlyRent.type === "required" && (
                    <div className="error">
                      Monthly rent must be entered to submit the listing{" "}
                    </div>
                  )}

                {errors.address && errors.address.type === "required" && (
                  <div className="error">
                    Address must be entered to submit the listing{" "}
                  </div>
                )}

                {errors.state && errors.state.type === "required" && (
                  <div className="error">
                    State must be entered to submit the listing{" "}
                  </div>
                )}

                {errors.city && errors.city.type === "required" && (
                  <div className="error">
                    City must be entered to submit the listing{" "}
                  </div>
                )}

                {errors.bedrooms && errors.bedrooms.type === "required" && (
                  <div className="error">
                    Please select Bedroom(s) to submit the listing{" "}
                  </div>
                )}

                {errors.bathrooms && errors.bathrooms.type === "required" && (
                  <div className="error">
                    Please select Bathroom(s) to submit the listing{" "}
                  </div>
                )}

                {errors.propertyType && errors.propertyType.type === "required" && (
                  <div className="error">
                    Property Type must be entered to submit the listing{" "}
                  </div>
                )}

                {errors.monthlyRent && errors.monthlyRent.type === "min" && (
                  <div className="error">
                    Monthly rent must be between ₹100 and ₹1,00,00,000
                  </div>
                )}

                {errors.monthlyRent && errors.monthlyRent.type === "max" && (
                  <div className="error">
                    Monthly rent must be between ₹100 and ₹1,00,00,000
                  </div>
                )}

                {errors.monthlyRent &&
                  errors.monthlyRent.type === "maxLength" && (
                    <div className="error">
                      Monthly rent must be between ₹100 and ₹1,00,00,000
                    </div>
                  )}

                {errors.monthlyRent &&
                  errors.monthlyRent.type === "validate" && (
                    <div className="error">
                      Monthly rent must be a whole number
                    </div>
                  )}

                {errors.securityDeposit &&
                  errors.securityDeposit.type === "min" && (
                    <div className="error">
                      Security deposit must be between ₹0 and ₹1,00,00,000
                    </div>
                  )}

                {errors.securityDeposit &&
                  errors.securityDeposit.type === "max" && (
                    <div className="error">
                      Security deposit must be between ₹0 and ₹1,00,00,000
                    </div>
                  )}

                {errors.securityDeposit &&
                  errors.securityDeposit.type === "maxLength" && (
                    <div className="error">
                      Security deposit must be between ₹10 and ₹1,00,00,000
                    </div>
                  )}

                {errors.securityDeposit &&
                  errors.securityDeposit.type === "validate" && (
                    <div className="error">
                      Security deposit must be a whole number
                    </div>
                  )}

                {errors.squareFeet && errors.squareFeet.type === "min" && (
                  <div className="error">
                    Square feet value must be between 10 and 10,00,000
                  </div>
                )}

                {errors.squareFeet && errors.squareFeet.type === "max" && (
                  <div className="error">
                    Square feet value must be between 10 and 10,00,000
                  </div>
                )}

                {errors.squareFeet &&
                  errors.squareFeet.type === "maxLength" && (
                    <div className="error">
                      Square feet value must be between 10 and 10,00,000
                    </div>
                  )}

                {errors.yearBuilt && errors.yearBuilt.type === "min" && (
                  <div className="error">Year value must be greater 1500</div>
                )}

                {errors.yearBuilt && errors.yearBuilt.type === "max" && (
                  <div className="error">
                    Year value must not be a future year
                  </div>
                )}

                {errors.yearBuilt && errors.yearBuilt.type === "maxLength" && (
                  <div className="error">Invalid year</div>
                )}

                {errors.dateAvailable &&
                  errors.dateAvailable.type === "min" && (
                    <div className="error">
                      Date selected must be a future Date
                    </div>
                  )}

                {errors.dateAvailable &&
                  errors.dateAvailable.type === "max" && (
                    <div className="error">
                      Date Available must be less than 1 year from today
                    </div>
                  )}

                {errors.aboutTheProperty &&
                  errors.aboutTheProperty.type === "maxLength" && (
                    <div className="error">
                      Description must not be greater than 1000 characters
                    </div>
                  )}

                {errors.leaseSummary &&
                  errors.leaseSummary.type === "maxLength" && (
                    <div className="error">
                      Lease Summary must not be greater than 500 characters
                    </div>
                  )}

                  {errors.amenities2 &&
                    errors.amenities2.type === "maxLength" && (
                      <div className="error">
                        Lease Summary must not be greater than 500 characters
                      </div>
                    )}

                {errors.name && errors.name.type === "required" && (
                  <div className="error">Contact name must be entered </div>
                )}

                {errors.name && errors.name.type === "maxLength" && (
                  <div className="error">
                    Contact Name must not be greater than 50 characters
                  </div>
                )}

                {errors.name && errors.name.type === "pattern" && (
                  <div className="error">Enter valid name characters</div>
                )}

                {errors.phone && errors.phone.type === "required" && (
                  <div className="error">Phone number must be entered </div>
                )}

                {errors.phone && errors.phone.type === "min" && (
                  <div className="error">Invalid phone number</div>
                )}

                {errors.phone && errors.phone.type === "max" && (
                  <div className="error">Invalid phone number</div>
                )}

                {errors.phone && errors.phone.type === "maxLength" && (
                  <div className="error">Invalid phone number</div>
                )}

                {errors.phone && errors.phone.type === "validate" && (
                  <div className="error">Invalid phone number</div>
                )}

                {errors.phone && errors.phone.type === "pattern" && (
                  <div className="error">Invalid phone number</div>
                )}
              </ErrorLog>

              <HiddenDiv>
                <FormRow>
                  <div className="field">
                    <FormLabel>
                      <label htmlFor="postType">Post Type</label>
                    </FormLabel>
                    <FormDropDown
                      name="postType"
                      ref={register({ required: true })}
                    >
                      <option value="0">Residential Rent</option>
                    </FormDropDown>
                  </div>
                </FormRow>
              </HiddenDiv>

              <FormHeader>Listing Information</FormHeader>
              <FormRowGroup>
                <FormRow>
                  <div className="field">
                    <FormLabel>
                      <label htmlFor="address">
                        Property Address
                        <span style={{ color: "red" }}>*</span>
                      </label>
                    </FormLabel>
                    <FormInput
                      type="text"
                      placeholder="Address"
                      name="address"
                      ref={register({
                        required: true,
                      })}
                    ></FormInput>
                  </div>
                </FormRow>
                <FormRow>
                  <div className="field">
                    <FormLabel>
                      <label htmlFor="state">
                        State
                        <span style={{ color: "red" }}>*</span>
                      </label>
                    </FormLabel>
                    <div className="tooltip">
                      <FormFixedInput
                        disabled
                        type="text"
                        name="state"
                        placeholder="Haryana"
                        value="Haryana"
                        ref={register({
                          required: true,
                        })}
                      ></FormFixedInput>
                      <span className="tooltiptext">
                        Currently serving only neighbourhood within Gurugram
                      </span>
                    </div>
                  </div>
                </FormRow>
                <FormRow>
                  <div className="field">
                    <FormLabel>
                      <label htmlFor="city">
                        City
                        <span style={{ color: "red" }}>*</span>
                      </label>
                    </FormLabel>
                    <div className="tooltip">
                      <FormFixedInput
                        disabled
                        type="text"
                        placeholder="Gurugram"
                        value="Gurugram"
                        name="city"
                        ref={register({
                          required: true,
                        })}
                      ></FormFixedInput>
                      <span className="tooltiptext">
                        Currently serving only neighbourhood within Gurugram
                      </span>
                    </div>
                  </div>
                </FormRow>
                <FormRow>
                  <div className="field">
                    <FormLabel>
                      <label htmlFor="propertyType">
                        Propery type
                        <span style={{ color: "red" }}>*</span>
                      </label>
                    </FormLabel>
                    <FormDropDown
                      name="propertyType"
                      ref={register({ required: true })}
                    >
                      <option value="" selected hidden>Select an option</option>
                      <option value="0">Apartment</option>
                      <option value="1">Farm House</option>
                      <option value="2">House/Villa</option>
                      <option value="3">PG/Roommate</option>
                      <option value="4">Other</option>
                    </FormDropDown>
                  </div>
                </FormRow>
                <FormRow>
                  <div className="field">
                    <FormLabel>
                      <label htmlFor="monthlyRent">
                        Monthly Rent
                        <span style={{ color: "red" }}>*</span>
                      </label>
                    </FormLabel>

                    <FormInput
                      type="number"
                      placeholder="Monthly Rent"
                      name="monthlyRent"
                      ref={register({
                        required: true,
                        max: 10000000,
                        min: 100,
                        maxLength: 7,
                        validate: isWhole,
                      })}
                    ></FormInput>
                  </div>
                </FormRow>

                <FormRow>
                  <div className="field">
                    <FormLabel>
                      <label htmlFor="securityDeposit">Security deposit</label>
                    </FormLabel>
                    <FormInput
                      type="number"
                      placeholder="Security deposit"
                      name="securityDeposit"
                      ref={register({
                        max: 10000000,
                        min: 0,
                        maxLength: 7,
                        validate: isWhole,
                      })}
                    />
                  </div>
                </FormRow>

                <FormRow>
                  <div className="field">
                    <FormLabel>
                      <label htmlFor="bedrooms">
                        Bedrooms
                        <span style={{ color: "red" }}>*</span>
                      </label>
                    </FormLabel>
                    <FormDropDown
                      name="bedrooms"
                      ref={register({ required: true })}
                    >
                      <option value="" selected hidden>Select an option</option>
                      <option value="0">Studio</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5+</option>
                    </FormDropDown>
                  </div>
                </FormRow>

                <FormRow>
                  <div className="field">
                    <FormLabel>
                      <label htmlFor="bathrooms">
                        Bathrooms
                        <span style={{ color: "red" }}>*</span>
                      </label>
                    </FormLabel>
                    <FormDropDown
                      name="bathrooms"
                      ref={register({ required: true })}
                    >
                      <option value="" selected hidden>Select an option</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5+</option>
                    </FormDropDown>
                  </div>
                </FormRow>

                <FormRow>
                  <div className="field">
                    <FormLabel>
                      <label htmlFor="squareFeet">Square Feet</label>
                    </FormLabel>

                    <FormInput
                      type="number"
                      placeholder="Square feet"
                      name="squareFeet"
                      ref={register({ max: 1000000, min: 10, maxLength: 6 })}
                    />
                  </div>
                </FormRow>

                <FormRow>
                  <div className="field">
                    <FormLabel>
                      <label htmlFor="yearBuilt">Year Built</label>
                    </FormLabel>

                    <FormInput
                      type="number"
                      placeholder="Year built"
                      name="yearBuilt"
                      ref={register({
                        min: 1500,
                        max: yearBuiltCheck,
                        maxLength: 4,
                      })}
                    />
                  </div>
                </FormRow>

                <FormRow>
                  <div className="field">
                    <FormLabel>
                      <label htmlFor="dateAvailable">Date Available</label>
                    </FormLabel>
                    <FormDatePicker
                      type="date"
                      name="dateAvailable"
                      ref={register({ min: todayDate, max: dateYearCheck })}
                    />
                  </div>
                </FormRow>

                <FormRow>
                  <div className="field">
                    <FormLabel>
                      <label htmlFor="leaseDuration">Lease Duration</label>
                    </FormLabel>
                    <FormDropDown name="leaseDuration" ref={register}>
                      <option value="0">1 month</option>
                      <option value="1">Less then 6 months</option>
                      <option value="2">Less than 1 Year</option>
                      <option value="3">Less than 2 Year</option>
                      <option value="4" selected>Flexible</option>
                    </FormDropDown>
                  </div>
                </FormRow>

                <FormRow>
                  <div className="field">
                    <FormLabel>
                      <label htmlFor="furnishing">Furnishing</label>
                    </FormLabel>
                    <FormDropDown name="furnishing" ref={register}>
                      <option value="0" selected>Unfurnished</option>
                      <option value="1">Semi-Furnished</option>
                      <option value="2">Furnished</option>
                    </FormDropDown>
                  </div>
                </FormRow>
              </FormRowGroup>

              <FormHeader>Detailed Description</FormHeader>
              <div className="field">
                <FormLabel>
                  <label htmlFor="aboutTheProperty">About the Property</label>
                </FormLabel>
                <FormSubLabel>
                  Good descriptions communicate a home’s features, along with
                  the benefits, which make it the most desirable choice. Making
                  a property stand out can help sell it faster.
                </FormSubLabel>

                <FormTextArea
                  placeholder="List area amenities – let folks know about the convenience of living near shopping areas, public transit, airports, highly-rated schools or that the neighborhood is within walking distance of those amenities."
                  name="aboutTheProperty"
                  ref={register({ maxLength: 1000 })}
                />
              </div>

              <div className="field">
                <FormLabel>
                  <label htmlFor="leaseSummary">Lease summary</label>
                </FormLabel>
                <FormSubLabel>
                  {" "}
                  Key details on deal-breakers or deal-makers.
                </FormSubLabel>

                <FormTextArea
                  placeholder="State the security deposit, instructions on how the renter will pay rent, collection date, late fee amount, and late fee grace period."
                  name="leaseSummary"
                  ref={register({ maxLength: 500 })}
                />
              </div>

              <FormHeader>Amenities</FormHeader>

              {amenitiesChoice.map((amenity, index) => (
                <>
                  <label key={amenity}>
                    <CheckBoxInput
                      type="checkbox"
                      value={amenity}
                      name="amenities1"
                      ref={register}
                    />
                    {amenity}
                  </label>
                  <br />
                </>
              ))}

              <br></br>
              <div className="field">
                <FormLabel>
                  <label htmlFor="amenities2">Additional Amenities</label>
                </FormLabel>
              <FormTextAreaSmall
                placeholder="Enter additional amenities seperated by comma"
                name="amenities2"
                ref={register({ maxLength: 100 })}
              />
            </div>

              {/* <FormLabel>
                <label>Additional amenities</label>
              </FormLabel>
              {fields.map((amenity: any, index: number) => (
                <div key={amenity.id}>
                  <div className="field">

                    <FormInput
                      style={{
                        width: "33.33%",
                        marginRight: "1em",
                        marginBottom: "1em",
                      }}
                      type="text"
                      placeholder="Describe Amenity"
                      name={`amenities2[${index}].name`}
                      ref={register()}
                      defaultValue="Describe Amennity"
                    />
                    <button onClick={() => remove(index)}>
                      <i className="fa fa-times" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
              ))}
              <AddMoreButton
                className="add"
                type="button"
                onClick={(e: React.MouseEvent) => append({})}
              >
                Add More
              </AddMoreButton>

              <RemoveButton
                className="remove"
                onClick={(e: React.MouseEvent) => {
                  e.preventDefault()
                  remove()
                }}
              >
                Clear all
              </RemoveButton> */}

              <FormHeader>Property media</FormHeader>
              <div className="field">
                <Dropzone files={files} setFiles={setFiles}></Dropzone>
              </div>

              <HiddenDiv>
                <FormRow>
                  <div className="field">
                    <FormLabel>
                      <label htmlFor="userID">User ID</label>
                    </FormLabel>

                    <FormInput
                      style={{ width: "50%", marginLeft: "0.5em" }}
                      type="text"
                      name="userID"
                      value={auth.user.userID || ""}
                      ref={register()}
                    />
                  </div>
                </FormRow>
              </HiddenDiv>

              <FormHeader>Your contact information</FormHeader>

              <div className="field">
                <FormLabel style={{ marginLeft: "0.5em" }}>
                  <label htmlFor="name">
                    Name
                    <span style={{ color: "red" }}>*</span>
                  </label>
                </FormLabel>

                <FormInput
                  style={{ width: "50%", marginLeft: "0.5em" }}
                  type="text"
                  name="name"
                  /* defaultValue={auth.user.name || ""} */
                  placeholder="Full Name"
                  ref={register({
                    required: true,
                    maxLength: 50,
                    pattern: /[a-zA-Z\s]+/i,
                  })}
                />
              </div>

              <ErrorLog>
                {errors.name && errors.name.type === "required" && (
                  <div className="error">Contact name must be entered </div>
                )}

                {errors.name && errors.name.type === "maxLength" && (
                  <div className="error">
                    Contact Name must not be greater than 50 characters
                  </div>
                )}

                {errors.name && errors.name.type === "pattern" && (
                  <div className="error">Enter valid name characters</div>
                )}
              </ErrorLog>

              <div className="field" style={{ marginBottom: "20px" }}>
                <FormLabel style={{ marginLeft: "0.5em" }}>
                  <label htmlFor="phone">
                    Phone
                    <span style={{ color: "red" }}>*</span>
                  </label>
                </FormLabel>

                <FormInput
                  type="tel"
                  name="phone"
                  style={{ width: "50%", marginLeft: "0.5em" }}
                  placeholder="Phone Number"
                  ref={register({
                    required: true,
                    max: 9999999999,
                    min: 6000000000,
                    maxLength: 10,
                    validate: isWhole,
                  })}
                ></FormInput>
              </div>

              <ErrorLog>
                {errors.phone && errors.phone.type === "required" && (
                  <div className="error">Phone number must be entered </div>
                )}

                {errors.phone && errors.phone.type === "min" && (
                  <div className="error">Invalid phone number</div>
                )}

                {errors.phone && errors.phone.type === "max" && (
                  <div className="error">Invalid phone number</div>
                )}

                {errors.phone && errors.phone.type === "maxLength" && (
                  <div className="error">Invalid phone number</div>
                )}

                {errors.phone && errors.phone.type === "validate" && (
                  <div className="error">Invalid phone number</div>
                )}

                {errors.phone && errors.phone.type === "pattern" && (
                  <div className="error">Invalid phone number</div>
                )}
                {submitError && (
                  <div className="error">
                    Something went wrong! Please try again in a moment
                  </div>
                )}
              </ErrorLog>
              <HiddenDiv>
                <input
                  type="datetime"
                  placeholder="Created Date"
                  name="createdDate"
                  value={Date.now()}
                  ref={register}
                />
                <input
                  type="datetime"
                  placeholder="Created Date"
                  name="createdDate"
                  value={Date.now()}
                  ref={register}
                />

                <input
                  type="text"
                  value="India"
                  placeholder="Country"
                  name="country"
                  ref={register}
                />
                <input
                  type="text"
                  placeholder="Bookmarks"
                  name="bookmarks"
                  ref={register}
                />
                <input
                  type="text"
                  placeholder="Views"
                  name="views"
                  ref={register}
                />
                <input
                  type="text"
                  placeholder="Agent ID"
                  name="agentID"
                  ref={register}
                />
                <input
                  type="number"
                  placeholder="Post Status"
                  name="postStatus"
                  ref={register}
                />
                <input
                  type="number"
                  placeholder="Property Status"
                  name="propertyStatus"
                  ref={register}
                />
              </HiddenDiv>
              <FixedDiv>
                <FixedDivContent> This listing is free! </FixedDivContent>
                <span>
                  <SubmitButton type="submit">Submit</SubmitButton>

                  <SubmitButton
                    type="button"
                    onClick={() => {
                      resetLocalStorage()
                      setThikanaLocation(null)
                      reset({})
                    }}
                  >
                    Reset
                  </SubmitButton>
                </span>
              </FixedDiv>
            </FormWrapper>
          </form>
        </SubPageContainer>
      </PageContainer>
    </>
  )
}

export default AddNewListingDescription
