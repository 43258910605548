import React, {
  useState,
  useContext,
  createContext,
  ReactNode,
  useEffect,
} from "react"
import { IServerListing } from "../mockListings"
import Axios from "axios"
import { baseURL } from "../Constants/serverConfig"

interface IBookmarksContext {
  bookmarks: { [postId: string]: IServerListing }
  addBookmark: (listing: IServerListing) => void
  removeBookmark: (postId: string) => void
  isBookmarked: (postId: string) => boolean
  refreshBookmarksData: () => void
}

const bookmarksContext = createContext<IBookmarksContext>({
  bookmarks: {},
  addBookmark: (i: any) => {},
  removeBookmark: (i: any) => {},
  isBookmarked: (i: string) => false,
  refreshBookmarksData: () => {},
})

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().

export function ProvideBookmarks({ children }: { children: ReactNode }) {
  const bookmarks = useProvideBookmarks()
  return (
    <bookmarksContext.Provider value={bookmarks}>
      {children}
    </bookmarksContext.Provider>
  )
}

const LOCAL_STORAGE_BOOKMARKS_FLAG = "LOCAL_STORAGE_BOOKMARKS_FLAG"
const refreshEndpoint = baseURL + "/post/"

// Hook for child components to get the bookmarks object ...
// ... and re-render when it changes.
export const useBookmarks = () => {
  return useContext(bookmarksContext)
}

const getLocalBookmarks = (): { [postId: string]: IServerListing } => {
  const localData = localStorage.getItem(LOCAL_STORAGE_BOOKMARKS_FLAG)

  if (!localData) return {}

  return JSON.parse(localData)
}

// Provider hook that creates auth object and handles state
function useProvideBookmarks() {
  const [bookmarks, setBookmarks] = useState<{
    [postId: string]: IServerListing
  }>(getLocalBookmarks())

  const refreshBookmarksData = async () => {
    const refreshData: { [postId: string]: IServerListing } = {}
    await Promise.all(
      Object.keys(bookmarks).map(async listing => {
        const res = await Axios.get(refreshEndpoint + listing)
        refreshData[listing] = res.data as any
      })
    )
    setBookmarks(refreshData)
  }

  useEffect(() => {
    localStorage.setItem(
      LOCAL_STORAGE_BOOKMARKS_FLAG,
      JSON.stringify(bookmarks)
    )
  }, [bookmarks])

  const addBookmark = (listing: IServerListing) => {
    setBookmarks({ ...bookmarks, [listing.postID]: listing })
  }

  const removeBookmark = (postId: string) => {
    const newbm = { ...bookmarks }
    delete newbm[postId]
    setBookmarks(newbm)
  }

  const isBookmarked = (postId: string) => {
    return bookmarks.hasOwnProperty(postId)
  }

  // Return the user object and auth methods
  return {
    bookmarks,
    addBookmark,
    removeBookmark,
    isBookmarked,
    refreshBookmarksData,
  }
}
