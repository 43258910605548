import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { colors } from "../../../Constants/colors"
import Axios from "axios"
import Loader from "../../Loader/loader"
import { baseURL } from "../../../Constants/serverConfig"

const FormWrapper = styled.div`
  display: table;
  box-sizing: border-box;
  width: 100% !important;
  // padding-bottom: 2em;
  // padding-left: 1em;
  height: auto;

`

const FormLabel = styled.div`
  display: block;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
  font-size: 0.875em;
  font-weight: 600;
  letter-spacing: 0em;
  cursor: pointer;
  box-sizing: inherit;
`

const FormInput = styled.input`
  padding-right: 3.75em;
  padding-left: 1em;
  box-shadow: none;
  font-weight: 400;
  font-size: 0.875em;
  letter-spacing: 0px;
  text-transform: none;
  box-sizing: border-box;
  width: 100% !important;
  cursor: text;
  background-color: ${colors.lightprimary};
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  height: 2.5em;
  border: 1px solid ${colors.lightgrey};
  border-radius: 3px;
  vertical-align: middle;
  overflow: visible;
  display: inline-block;
  // -webkit-appearance: none;
  // -moz-appearance: none;
  appearance: none;
`

const ErrorLog = styled.div`
  display: block;
  margin-top: 1em;
  margin-bottom: 0.5em;
  font-size: 0.875em;
  font-weight: 400;
  box-sizing: inherit;
  color: red;
`

const SubmitButton = styled.button`
  font-size: 0.875em;
  font-weight: 600;
  min-height: 2.75em;
  align-items: center;
  text-align: center;
  display: block;
  box-sizing: border-box;
  width: 100%;
  cursor: pointer;
  margin-top: 1.2em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  background-color: white;
  color: ${colors.primary};
  text-decoration: none;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  border-color: ${colors.primary};
  margin-top: 1em;
  margin-bottom: 1em;

  &:hover {
    background: ${colors.primary};
    color: ${colors.white};
  }
`

const TextIcon = styled.i`
  color: ${colors.green};
  font-size: 3em;
  align-items: center;
  text-align: center;
  display: block;
`

interface IShowInterestForm {
  name: string
  phone: string
}

interface IProps {
  postID: string
}

const VERIFIED_OTP_NUMBERS_FLAG = "VERIFIED_OTP_NUMBERS_FLAG"
const SHOW_INTEREST_FLAG = "SHOW_INTEREST_FLAG"

const USE_OTP_VERIFICATION = false

const ShowInterest = (props: IProps) => {
  const [verifyOtp, setVerifyOtp] = useState(false)
  const [otpSentTo, setOtpSentTo] = useState("")
  const [username, setUsername] = useState("")
  const [otp, setOtp] = useState("")
  const [status, setStatus] = useState(0)
  const [error, setError] = useState("")

  useEffect(() => {
    let res: any = localStorage.getItem(SHOW_INTEREST_FLAG)
    if (res) res = JSON.parse(res)

    if (res && res[props.postID]) setStatus(1)
  }, [props.postID])

  const showInterestVerified = async () => {
    try {
      setStatus(99)
      await Axios.post(baseURL + "/interest", {
        postID: props.postID,
        phone: otpSentTo,
        name: username,
      })
      setStatus(1)
      let res: any = localStorage.getItem(SHOW_INTEREST_FLAG)
      if (res) res = JSON.parse(res)
      else res = {}
      res[props.postID] = true
      localStorage.setItem(SHOW_INTEREST_FLAG, JSON.stringify(res))
    } catch (err) {
      setStatus(3)
    }
  }

  const verifyProcess = async () => {
    setVerifyOtp(true)

    try {
      setStatus(99)
      await Axios.post(baseURL + "/sendotp", {
        phone: otpSentTo,
      })

      setStatus(0)
    } catch (err) {
      setStatus(3)
    }
  }

  const verifyOTP = async () => {
    try {
      setStatus(99)
      const res = await Axios.post(baseURL + "/verify", {
        phone: otpSentTo,
        otp,
      })

      if (res.status === 200) {
        setStatus(1)
        let res: any = localStorage.getItem(VERIFIED_OTP_NUMBERS_FLAG)
        if (res) res = JSON.parse(res)
        else res = {}
        res[otpSentTo] = true
        localStorage.setItem(VERIFIED_OTP_NUMBERS_FLAG, JSON.stringify(res))

        showInterestVerified()
      } else {
        setStatus(2)
      }
    } catch (err) {
      setStatus(2)
    }
  }

  const onSubmit = () => {
    if (username.length == 0) {
      setError("Name not entered")
      return
    } else if (otpSentTo.length != 10 || isNaN(otpSentTo as any)) {
      setError("Invalid phone number entered")
      return
    }

    if (!USE_OTP_VERIFICATION) {
      showInterestVerified()
      return
    }

    const res = localStorage.getItem(VERIFIED_OTP_NUMBERS_FLAG)
    if (res) {
      const verified = JSON.parse(res) as { [phone: string]: true }
      if (verified[otpSentTo]) showInterestVerified()
      else {
        verifyProcess()
      }
    } else verifyProcess()
  }

  if (status === 1) {
    return (
      <FormWrapper>
        <div className="field">
          <TextIcon className="fas fa-check-circle"/>
          <div style={{ marginBottom: "5%" }} />
          <FormLabel style={{ alignItems: "center", textAlign: "center", display: "block" }}>
            <label htmlFor="interest">
              Thanks for showing interest! The owner will be in contact with you
              soon!
            </label>
          </FormLabel>
          <div style={{ marginBottom: "10%" }} />
        </div>
      </FormWrapper>
    )
  }

  if (status === 99) return <Loader></Loader>

  if (verifyOtp)
    return (
      <FormWrapper>
        <div className="field">
          <FormLabel>
            <label htmlFor="otp">
              Enter OTP sent at{" "}
              <span
                onClick={() => setVerifyOtp(false)}
                style={{
                  textDecoration: "underline",
                  color: "blue",
                  cursor: "pointer",
                }}
              >
                +91 {otpSentTo} (change){" "}
              </span>
              <span style={{ color: "red" }}>*</span>
            </label>
          </FormLabel>

          <ErrorLog>
            {status === 2 && <div className="error">Incorrect OTP entered</div>}
            {status === 3 && (
              <>
              <div style={{ marginBottom: "5%" }} />
              <div className="error">
                Something went wrong! Please try again in a few moments
              </div>
              <div style={{ marginBottom: "10%" }} />
            </>
            )}
          </ErrorLog>

          <FormInput
            type="text"
            name="otp"
            value={otp}
            onChange={e => setOtp(e.target.value)}
          />
          <SubmitButton onClick={verifyOTP}>Verify OTP</SubmitButton>
        </div>
      </FormWrapper>
    )

  return (
    <FormWrapper>
      <div className="field">
        <FormLabel>
          <label htmlFor="name">
            Full Name
            <span style={{ color: "red" }}>*</span>
          </label>
        </FormLabel>

        <FormInput
          type="text"
          name="name"
          value={username}
          onChange={e => setUsername(e.target.value)}
        />
      </div>

      <div className="field">
        <FormLabel>
          <label htmlFor="phone">
            Phone
            <span style={{ color: "red" }}>*</span>
          </label>
        </FormLabel>

        <FormInput
          type="tel"
          pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
          name="phone"
          value={otpSentTo}
          onChange={e => setOtpSentTo(e.target.value)}
        ></FormInput>
      </div>

      <ErrorLog>{error && <div className="error">{error}</div>}</ErrorLog>

      <SubmitButton onClick={onSubmit} type="submit">
        Send Interest
      </SubmitButton>
    </FormWrapper>
  )
}

export default ShowInterest
