import React, { useState, useContext, createContext, ReactNode } from "react"
import useModal from "./useModal"
import axios from "axios"
import { baseURL } from "../Constants/serverConfig"
import { useHistory } from "react-router"
import { identify } from "../Utils/analytics"

interface IUser {
  userID: null | string
  token: null | string
  name: null | string
  email: null | string
  image: null | string
  provider: null | string
}

interface IAuthContext {
  user: IUser
  signinGoogle: (response: any) => void
  signinFacebook: (response: any) => void
  signout: () => void
  authModal: {
    open: boolean
    toggleOpen: () => void
  }
  checkSignin: () => boolean
}

const authContext = createContext<IAuthContext>({
  user: {
    userID: null,
    token: null,
    name: null,
    image: null,
    email: null,
    provider: null,
  },
  signinGoogle: (response: any) => {},
  signinFacebook: (response: any) => {},
  signout: () => {},
  authModal: {
    open: false,
    toggleOpen: () => {},
  },
  checkSignin: () => false,
})

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().

export function ProvideAuth({ children }: { children: ReactNode }) {
  const auth = useProvideAuth()
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

const LOCAL_STORAGE_AUTH_FLAG = "LOCAL_STORAGE_AUTH_FLAG"

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext)
}

const getLocalUser = (): IUser => {
  const localData = localStorage.getItem(LOCAL_STORAGE_AUTH_FLAG)

  if (!localData)
    return {
      userID: null,
      name: null,
      token: null,
      image: null,
      provider: null,
      email: null,
    }
  const data = JSON.parse(localData) as IUser
  identify(data.userID || "guest")
  return data
}

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const history = useHistory()
  const [user, setUser] = useState<IUser>(getLocalUser())
  const [openAuthModal, toggleAuthModal] = useModal()

  const checkSignin = (): boolean => {
    if (!user.token) {
      toggleAuthModal()
      return false
    }

    return true
  }

  const signinGoogle = (response: any) => {
    var userData = {
      name: response.profileObj.name,
      email: response.profileObj.email,
      token: response.tokenObj.id_token,
      image: response.profileObj.imageUrl,
    }

    axios
      .post(`${baseURL}/register`, {
        name: userData.name,
        email: userData.email,
        imageUrl: userData.image,
        loginSource: "0",
      })
      .then(res => {
        const userInfo = {
          name: userData.name,
          email: userData.email,
          image: userData.image,
          userID: res.data.userID,
          token: res.data.token,
          provider: "0",
        }
        // history.push("/mylistings")
        identify(userInfo.userID)
        localStorage.setItem(LOCAL_STORAGE_AUTH_FLAG, JSON.stringify(userInfo))
        setUser(userInfo)
      })
  }

  const signinFacebook = (response: any) => {
    var userData = {
      name: response.name,
      email: response.email,
      token: response.accessToken,
      image: response.picture.data.url,
    }
    axios
      .post(`${baseURL}/register`, {
        name: userData.name,
        email: userData.email,
        imageUrl: userData.image,
        loginSource: "1",
      })
      .then(res => {
        const userInfo = {
          name: userData.name,
          email: userData.email,
          image: userData.image,
          userID: res.data.userID,
          token: res.data.token,
          provider: "1",
        }
        // history.push("/mylistings")
        identify(userInfo.userID)
        localStorage.setItem(LOCAL_STORAGE_AUTH_FLAG, JSON.stringify(userInfo))
        setUser(userInfo)
      })
  }

  const signout = () => {
    identify("guest")

    localStorage.removeItem(LOCAL_STORAGE_AUTH_FLAG)
    setUser({
      userID: null,
      token: null,
      name: null,
      email: null,
      image: null,
      provider: null,
    })
  }

  // Return the user object and auth methods
  return {
    user,
    authModal: {
      open: openAuthModal,
      toggleOpen: toggleAuthModal,
    },
    checkSignin,
    signinGoogle,
    signinFacebook,
    signout,
  }
}
