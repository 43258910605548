import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "../../Constants/colors";
import { getStaticListing } from "../../Constants/serverConfig";
import { breakpoints } from "../../Constants/responsive";
import ListingCard from "../Listings/ListingCard/listingCard";
import { IServerListing } from "../../mockListings";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../Hooks/useAuth";
import Footer from  "../Footer/footer";
import { size } from "../../Constants/sizesAndSpacing";

const Wrapper = styled.div`
  width: 100%;
  height: calc(100% - ${size.headerHeight + "px"});
  overflow-y: scroll;
  padding-bottom: 1%;
`;

const SearchHeader = styled.div`
  background: url("/img/homepage1.webp");
  background-position: center;
  width: 100%;
  height: 0;
  padding-top: 52.51%;
  margin-top: -7%;
  margin-bottom: -5%;
  background-repeat: no-repeat;
  background-size: contain;
`;

const ListWrapper = styled.div`
  display: block;
  position: relative;
  width: 100%;
`;


const ListingFlexBox = styled.div`
  display: flex;
  flex-flow: row;
  overflow-x: auto;
  &::-webkit-scrollbar {
  display: none;
}

`;

const ViewMoreButton = styled.button`
  padding-left: 2em;
  padding-right: 2em;
  margin-top: 1%;
  margin-left: auto;
  margin-right: auto;
  font-weight: 600;
  align-items: center;
  text-align: center;
  display: block;
  cursor: pointer;
  background-color: ${colors.white};
  color: ${colors.primary};
  border-color: ${colors.primary};
  text-decoration: none;
  border-width: 3px;
  border-style: solid;
  border-radius: 3px;
  min-height: 3em;
  font-size: 1em;
  @media only screen and (min-width: ${breakpoints.mobile + "px"}) {
    font-size: 1em;
  }
  @media only screen and (min-width: ${breakpoints.tablet + "px"}) {
    font-size: 1.275em;
  }
  @media only screen and (min-width: ${breakpoints.desktop + "px"}) {
    font-size: 1.5em;
  }
`;

const FlexBox = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1%;
  margin-top: 2%;
  min-height: 10em;
`;

const TextHeading1 = styled.h1`
  padding-top: 0.5%;
  color: ${colors.secondary};
  font-weight: 600;
  font-size: 2em;
  text-align: center;
  @media only screen and (min-width: ${breakpoints.mobile + "px"}) {
    font-size: 2em;
  }
  @media only screen and (min-width: ${breakpoints.tablet + "px"}) {
    font-size: 2.25em;
  }
  @media only screen and (min-width: ${breakpoints.desktop + "px"}) {
    font-size: 2.5em;
  }
`;

const TextHeading2 = styled.h2`
  padding-top: 0.5%;
  color: ${colors.secondary};
  font-weight: 600;
  font-size: 1.5em;
  text-align: left;
  @media only screen and (min-width: ${breakpoints.mobile + "px"}) {
    font-size: 1.5em;
  }
  @media only screen and (min-width: ${breakpoints.tablet + "px"}) {
    font-size: 1.75em;
  }
  @media only screen and (min-width: ${breakpoints.desktop + "px"}) {
    font-size: 2em;
  }
`;

const TextBody = styled.p`
  margin: 3%;
  color: ${colors.secondary};
  text-align: justify;
  font-size: 1em;
  @media only screen and (min-width: ${breakpoints.mobile + "px"}) {
    font-size: 1em;
  }
  @media only screen and (min-width: ${breakpoints.tablet + "px"}) {
    font-size: 1.275em;
  }
  @media only screen and (min-width: ${breakpoints.desktop + "px"}) {
    font-size: 1.5em;
  }
`;

const SpacingBlock = styled.div`
  padding: 2%;
`;

const DivContainer = styled.div`
  display: block;
  justify-content: space-between;
  margin: auto;
  width: 100%;
  @media (min-width: ${breakpoints.tablet + "px"}) {
    display: flex;
    align-items: center;
  }
  @media (min-width: ${breakpoints.desktop + "px"}) {
    display: flex;
    align-items: center;
  }
`;

const LeftHalfDiv = styled.div`
  align-items: center;
  // padding-top: 2em;
  // padding-bottom: 2em;
  display: block;
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  @media (min-width: ${breakpoints.tablet + "px"}) {
  }
  @media (min-width: ${breakpoints.desktop + "px"}) {
  }
`;

const RightHalfDiv = styled.div`
  // padding: 2em;
  margin-left: 2%;
  margin-right: 2%;
  display: block;
  width: 100%;
  box-sizing: border-box;
  @media (min-width: ${breakpoints.tablet + "px"}) {
  }
  @media (min-width: ${breakpoints.desktop + "px"}) {
  }
`;

const PageImage = styled.img`
  width: 80%;
  height: auto;
  margin: auto;
  z-index: -100;
  background-size: cover;
  overflow: hidden;
`;

const PageSection = styled.div`
  width: 90%;
  margin: auto;
`

const Homepage = () => {

  const [posts, setPosts] = useState<IServerListing[]>([]);
  const getPosts = async () => {
    const { data } = await axios.get(
      getStaticListing.url
    );
    setPosts(data.posts);
  };

  useEffect(() => {
    getPosts();
  }, []);

  const history = useHistory();
  const { checkSignin } = useAuth();

  return (
    <Wrapper>
      <SearchHeader></SearchHeader>
      <PageSection>
      <TextHeading1>Rental Properties in Gurgaon</TextHeading1>
      <TextBody>
        Searching for a house, apartment, or other residential real estate property for rent? Look no farther because EkThikana Real Estate Rental Platform
        is here to sort you out. We connect property owners with a buyer with absolutely no costs attached to our services.
      </TextBody>
      <SpacingBlock />
      <SpacingBlock />
      </PageSection>
      <PageSection>
      <DivContainer>
        <LeftHalfDiv>
          <TextBody>
            <TextHeading2>For Buyers</TextHeading2>
            <SpacingBlock />
            You are one click away from the property you can call your home. Get access to variety of properties and choose the most suitable.
            <SpacingBlock />
            There is absolutely no cost in using the EkThikana platform; thus, you have no reason to worry or get concerned about extra fees.
          </TextBody>
          <SpacingBlock />
          <SpacingBlock />
        </LeftHalfDiv>
        <RightHalfDiv>
          <PageImage src="/img/forBuyers.webp" alt="Property For Rent In Gurgaon"/>
          <SpacingBlock />
          <SpacingBlock />
        </RightHalfDiv>
      </DivContainer>
        <TextHeading1>Explore Thikana's around Gurugram</TextHeading1>
      <FlexBox>
        <ListWrapper id="ekThikanaScrollableListings">
            <ListingFlexBox>
              {Object.values(posts).map((listing) => {
                return (
                  <ListingCard key={listing.postID} {...listing}></ListingCard>
                );
              })}
            </ListingFlexBox>
        </ListWrapper>
      </FlexBox>
      <ViewMoreButton onClick={() => { history.push("/app"); }}> View More  </ViewMoreButton>
      <SpacingBlock />
      <SpacingBlock />
    </PageSection>
    <PageSection>
      <DivContainer>
        <LeftHalfDiv>
          <TextBody>
            <TextHeading2>For Owners</TextHeading2>
            <SpacingBlock />
            We offer you the platform to advertise and meet new buyers easily. Upload your property descriptions and locations, and buyers will flow through the
            best real estate in Gurugram, EkThikana.
          </TextBody>
          <SpacingBlock />
          <SpacingBlock />
          <ViewMoreButton
            onClick={() => {
              checkSignin() && history.push("/mylistings");
            }}
          >
            List Your Rental Property
          </ViewMoreButton>
          <SpacingBlock />
          <SpacingBlock />
        </LeftHalfDiv>
        <RightHalfDiv>
          <PageImage src="/img/addListing.webp" alt="Houses For Rent Near Me By Owner"/>
          <SpacingBlock />
          <SpacingBlock />
        </RightHalfDiv>
      </DivContainer>
      <SpacingBlock />
      <SpacingBlock />
    </PageSection>
      <Footer></Footer>
    </Wrapper>
  );
};

export default Homepage;
