import React, { useState, useEffect } from "react"
import { Map, Marker, TileLayer, Popup, ZoomControl } from "react-leaflet"
import { ILatLang, IServerListing } from "../../../mockListings"
import ListingMapControls from "./listingMapControls"
import { ListingDescription } from "../ListingCard/listingCard"
import useModal from "../../../Hooks/useModal"
import Modal from "../../Modal/modal"
import ExtendedListingCard from "../Extended Listing Card/extendedListingCard"
import { IServerError } from "../../../Hooks/useServer"
import { renderToStaticMarkup } from "react-dom/server"
import { divIcon } from "leaflet"
import styled from "styled-components"
import { colors } from "../../../Constants/colors"
import { RouteComponentProps, withRouter } from "react-router"
import Axios from "axios"
import { baseURL } from "../../../Constants/serverConfig"

const CustomMarker = styled.div`
  width: 20px;
  height: 20px;
  background: ${colors.primary};
  border: 2px solid ${colors.white};
  box-sizing: border-box;
  border-radius: 50%;
  opacity: 1;
`

export const customMarkerIcon = () => {
  const iconMarkup = renderToStaticMarkup(<CustomMarker></CustomMarker>)
  const customMarkerIcon = divIcon({ html: iconMarkup })
  return customMarkerIcon
}

const StyledPop = styled(Popup)`
  min-width: 200px;
  border-radius: 3px;
  box-shadow: 0 0 5px ${colors.primary};

  .leaflet-popup-content-wrapper {
    border-radius: 3px;
    background: ${colors.white};
    color: ${colors.secondary};
  }

  .leaflet-popup-tip {
    background: ${colors.lightprimary};
  }

  .leaflet-popup-close-button {
    display: none;
  }

  .leaflet-popup-content {
    margin: 0;
  }
`

export interface IMapConfig {
  position: ILatLang
  zoom: number
  radius: number
}

export interface IListingMapControls {
  setMapConfig: React.Dispatch<React.SetStateAction<IMapConfig>>
}

export interface IListingMap extends IListingMapControls {
  posts: IServerListing[]
  mapStartConfig: IMapConfig
  loading: boolean
  error: IServerError | null
}

interface MatchParams {
  postId: string
}

interface PropsItems extends RouteComponentProps<MatchParams> {
  setMapConfig: React.Dispatch<React.SetStateAction<IMapConfig>>
  posts: IServerListing[]
  mapStartConfig: IMapConfig
  loading: boolean
  error: IServerError | null
}
const ListingMap: React.FC<PropsItems> = (props: PropsItems) => {
  const [open, toggle] = useModal()
  const [fetchData, setFetchData] = useState<IServerListing>()
  // handle which post's  marker was clicked
  const [clickedPost, changeClickedPost] = useState<number>(0)
  const [isDirectLink, setIsDirectLink] = useState<boolean>(false)
  useEffect(() => {
    if (props.match.params.postId) {
      setIsDirectLink(true)
      if (!open) {
        Axios.get(`${baseURL}/post/${props.match.params.postId}`).then(res => {
          setFetchData(res.data)
          if (res.data) {
            toggle()
          }
        })
      }
    }
  }, [])

  return (
    <>
      <Map
        style={{ height: "100%", zIndex: 1 }}
        center={[
          props.mapStartConfig.position.lat,
          props.mapStartConfig.position.lon,
        ]}
        zoom={props.mapStartConfig.zoom}
        zoomControl={false}
      >
        <ZoomControl position="bottomright"></ZoomControl>
        <ListingMapControls
          setMapConfig={props.setMapConfig}
        ></ListingMapControls>
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {props.posts.map((post, i) => {
          return (
            <Marker
              icon={customMarkerIcon()}
              clickable={false}
              key={post.postID}
              position={[post.location.lat, post.location.lon]}
              onMouseOver={(e: any) => {
                e.target.openPopup()
              }}
              onMouseOut={(e: any) => {
                e.target.closePopup()
              }}
              onClick={(e: any) => {
                if (window.matchMedia("(hover:none)").matches) {
                  if (!e.target.getPopup().isOpen()) {
                    changeClickedPost(i)
                    toggle()
                  }
                } else {
                  changeClickedPost(i)
                  toggle()
                }
              }}
            >
              <StyledPop autoPan={false}>
                <div
                  onClick={() => {
                    changeClickedPost(i)
                    toggle()
                  }}
                >
                  <ListingDescription {...post}></ListingDescription>
                </div>
              </StyledPop>
            </Marker>
          )
        })}
      </Map>

      {fetchData ? (
        <Modal toggle={toggle} open={open} isLink={true}>
          <ExtendedListingCard
            toggle={toggle}
            {...fetchData}
          ></ExtendedListingCard>
        </Modal>
      ) : (
        <Modal toggle={toggle} open={open} isLink={true}>
          <ExtendedListingCard
            toggle={toggle}
            {...props.posts[clickedPost]}
          ></ExtendedListingCard>
        </Modal>
      )}
    </>
  )
}

export default React.memo(withRouter(ListingMap))
