import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { PaginationActions, IPagination } from "../../Hooks/usePagination";
import { colors } from "../../Constants/colors";

// number of nodes shown in paginator
const paginatorLength = 5;

// CSS

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
`;

const Container = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  max-width: 300px;
  width: 100%;
`;

const Page = styled.div<{ active: boolean }>`
  border-radius: 50%;
  font-size: 12px;
  font-weight: 600;
  height: 2em;
  width: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1.5px solid ${colors.primary};
  background: ${props => (props.active ? colors.primary : colors.white)};
  color: ${props => (props.active ? colors.white : colors.secondary)};
  opacity: ${props => (props.active ? 1 : 0.7)};
  cursor: pointer;
  box-sizing: border-box;

  &:hover {
    opacity: 1;
  }
`;

const PageNumber = styled.span`
  font-size: 0.9em;
`;

const PaginatorButton = styled.div`
  font-size: 0.9em;
  cursor: pointer;
  color: ${colors.primary};
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }
`;

const Ellipses = styled.div`
  font-size: 1em;
  font-weight: 600;
  height: 1em;
  width: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
`;

// function to handle scrolling to top on page changes
function scrollToTop() {
  const duration = 1000;
  const el = document.getElementById("ekThikanaScrollableListings");

  // cancel if already on top
  if (!el || el.scrollTop === 0) return;

  const cosParameter = el.scrollTop / 2;
  let scrollCount = 0,
    oldTimestamp: any = null;

  function step(newTimestamp: any) {
    if (oldTimestamp !== null) {
      // if duration is 0 scrollCount will be Infinity
      scrollCount += (Math.PI * (newTimestamp - oldTimestamp)) / duration;
      if (scrollCount >= Math.PI) return ((el as HTMLElement).scrollTop = 0);
      (el as HTMLElement).scrollTop =
        cosParameter + cosParameter * Math.cos(scrollCount);
    }
    oldTimestamp = newTimestamp;
    window.requestAnimationFrame(step);
  }
  window.requestAnimationFrame(step);
}

interface IProps {
  pagination: IPagination;
}

const Paginator: React.FC<IProps> = (props: IProps) => {
  // the component accepts pagination options from parent, where usePagination() was called
  // we dont call usePagination here as the usePagination hooks does not use context
  const { pagination } = props;

  // which pages to show (in form of numbers) in paginator (e.g. 1, 2, 3 ...)
  const [pagesArray, changePagesArray] = useState<number[]>([]);

  useEffect(() => {
    // update the pages shown in paginator whenever a page is changed

    // scroll to top
    scrollToTop();
    const newPageArray = [];

    // add first page if scrolled more than 5 pages
    if (pagination.page > 5) {
      newPageArray.push(0);
      // negative page number adds ellipses for UI
      newPageArray.push(-1);
    }

    // add previous page if not on first page
    if (pagination.page !== 0) newPageArray.push(pagination.page - 1);
    let pageToBeAdded = pagination.page;
    while (
      newPageArray.length < paginatorLength &&
      newPageArray.length < pagination.totalPages &&
      pageToBeAdded < pagination.totalPages
    ) {
      newPageArray.push(pageToBeAdded);
      pageToBeAdded++;
    }

    // add elipses
    if (pagination.page + 5 < pagination.totalPages) newPageArray.push(-2);

    // add last page
    newPageArray.push(pagination.totalPages);
    // update the pages
    changePagesArray(newPageArray);
  }, [pagination.page, pagination.totalPages]);

  return (
    <Wrapper>
      <Container>
        <PaginatorButton
          onClick={() => {
            pagination.changePage(PaginationActions.prev);
          }}
        >
          <i className="fas fa-chevron-left"></i>
        </PaginatorButton>
        {pagesArray.map((page, i) =>
          page > -1 ? (
            <Page
              key={page * 10 + i}
              active={pagination.page === page}
              onClick={() => {
                pagination.changePage(page);
              }}
            >
              <PageNumber>{page + 1}</PageNumber>
            </Page>
          ) : (
            <Ellipses key={i}>...</Ellipses>
          )
        )}
        <PaginatorButton
          onClick={() => {
            pagination.changePage(PaginationActions.next);
          }}
        >
          <i className="fas fa-chevron-right"></i>
        </PaginatorButton>
      </Container>
    </Wrapper>
  );
};

export default Paginator;
