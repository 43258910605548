import React from "react";
import styled from "styled-components";

const ImageWrapper = styled.div<{ paddingTop: number }>`
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  padding: ${props => props.paddingTop + "%"} 0 0 0;
  z-index: 5;
`;

const ImageDiv = styled.div<{ src: string }>`
  background-image: url(${props => props.src});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: block;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
`;

interface IProps {
  src: string;
  paddingTop?: number;
}
const ListingImage: React.FC<IProps> = (props: IProps) => {
  const paddingTop = props.paddingTop ? props.paddingTop : 41;

  return (
    <>
      <ImageWrapper paddingTop={paddingTop}>
        <ImageDiv src={props.src}></ImageDiv>
      </ImageWrapper>
    </>
  );
};

export default ListingImage;
