import React, { useState, useRef, useEffect } from "react"
import styled from "styled-components"
import Fuse from "fuse.js"
import { colors } from "../../../Constants/colors"
import { ISearchIndex, searchIndices } from "../../../Constants/searchIndex"
import Dropdown from "./dropdown"
import { IMapConfig } from "../../Listings/ListingMap/listingMap"

enum Constants {
  numberofResults = 4,
  matchDelay = 200,
}

// fuse options
const FuseOptions: Fuse.IFuseOptions<ISearchIndex> = {
  threshold: 0.6,
  location: 0,
  distance: 100,
  minMatchCharLength: 1,
  shouldSort: true,
  keys: ["LocalityEnglish"],
}

// fuse object
let fuse: Fuse<ISearchIndex, Fuse.IFuseOptions<ISearchIndex>> = new Fuse(
  searchIndices as ISearchIndex[],
  FuseOptions
)

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
`

const SearchIcon = styled.i`
  position: absolute;
  right: 5%;
  opacity: 1;
  color: ${colors.primary};
  font-size: 1.2em;
`

const NoteLine = styled.div`
  background: ${colors.light};
  padding: 0.75em 0.75em;
  font-size: 0.9em;
  border-bottom: 0.5px solid ${colors.shadow};
  width: 100%;
  color: ${colors.black};
  display: flex-start;
  font-weight: 500;
  box-sizing: border-box;
  font-weight: 600;
`

const DropListWrapper = styled.div`
  position: absolute;
  top: 90%;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  z-index: 20000;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 0 5px ${colors.shadow};
`

const Bar = styled.input`
  border: 1.5px solid ${colors.primary};
  outline: none;
  padding: 0.2em 0.5em;
  border-radius: 2px;
  width: 100%;
  height: 60%;
  box-sizing: border-box;
  font-weight: 500;
  color: ${colors.primary};
  font-weight: 600;
  &:hover,
  &:focus {
    border: 1.5px solid ${colors.primary};
    background: ${colors.lightprimary};
  }
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${colors.primary};
    opacity: 1; /* Firefox */
    font-weight: 500;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${colors.primary};
    font-weight: 500;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${colors.primary};
    font-weight: 500;
  }
`

const TextIcon = styled.i`
  color: ${colors.secondary};
  margin: auto;
  padding-right: 0.5em;
`

export interface IProps {
  mapStartConfig: IMapConfig
  setMapStartConfig: React.Dispatch<React.SetStateAction<IMapConfig>>
}

const SearchBar: React.FC<IProps> = (props: IProps) => {
  // state
  const [input, changeInput] = useState("")
  const [matchedRecords, changeMatchedRecords] = useState<ISearchIndex[]>([])
  const [turbo, changeTurbo] = useState(false)
  const [inFocus, changeInFocus] = useState(false)
  const [stopDelayMatchingTimeout, changeStopDelayMatchingTimeout] = useState(
    null
  )

  const inputRef = useRef(input)
  inputRef.current = input

  const inputBarRef: React.MutableRefObject<HTMLInputElement | null> = useRef(
    null
  )

  const handleTagSelection = (tag: ISearchIndex) => {
    changeInput(tag.LocalityEnglish)
    inputBarRef.current && inputBarRef.current.blur()
    props.setMapStartConfig({
      position: {
        lat: +tag.Latitude + 0.001,
        lon: +tag.Longitude + 0.009,
      },
      radius: 1000,
      zoom: 15,
    })
  }

  // helper functions

  //handle initial quick results for first keystroke, while also rendering the Dropdown
  const handleFirstType = () => {
    changeTurbo(true)
    changeInFocus(true)
  }

  //unmount DropDown by setting inFocus: false
  const handleBlur = () => {
    setTimeout(() => {
      changeInFocus(false)
    }, 200)
  }

  const handleChange = (e: any) => {
    var char = e.target.value
    changeInput(char)
  }

  const isArraySame = (arrayOne: ISearchIndex[], arrayTwo: ISearchIndex[]) => {
    if (arrayOne.length !== arrayTwo.length) return false

    let isSame = true

    arrayOne.forEach((item, index) => {
      if (item.LocalityEnglish !== arrayTwo[index].LocalityEnglish)
        isSame = false
    })

    return isSame
  }

  //handle fuzzy search with fuse.js
  //stopDelayMatchingTimeout ensures that only after "matchDelay" milliseconds, the dropdown is calculated
  const handleMatching = () => {
    var stopDelay = setTimeout(
      () => {
        // return if fuse object is not present
        if (!fuse) return

        //get matches for the last word
        var match = fuse
          .search(inputRef.current)
          .slice(0, Constants.numberofResults)

        const parsedMatches: ISearchIndex[] = []
        match.forEach(mat => parsedMatches.push(mat.item))

        //if match is an exact match, remove the dropdown results, without setting stopDelayMatchingTimeout for quick unmount

        // if new matches are found, set them as new options, else just set stopDelayMatchingTimeout: null to ensure
        //another match query can be made on the next keystroke
        if (
          !isArraySame((match as any) as ISearchIndex[], matchedRecords) &&
          inputRef.current !== ""
        ) {
          changeStopDelayMatchingTimeout(null)
          changeMatchedRecords(parsedMatches)
        } else {
          changeStopDelayMatchingTimeout(null)
        }
      },
      turbo ? 20 : Constants.matchDelay
    )
    //above ensure if turbo is on, only 20ms delay is done

    // set stopeInterval !== null to prevent more queries to match from keystrokes

    changeStopDelayMatchingTimeout(stopDelay as any)
  }

  // this executes when input changes, handles Matching input with FuseIndices
  useEffect(() => {
    // stopDelayMatchingTimeout -> if value exist => skip this input change, do not process this keystroke
    if (stopDelayMatchingTimeout === null && fuse) {
      handleMatching()
    }
    if (turbo) changeTurbo(false)
    // eslint-disable-next-line
  }, [input])

  return (
    <Wrapper>
      <Bar
        ref={inputBarRef}
        value={input}
        onChange={e => {
          handleChange(e)
        }}
        onFocus={handleFirstType}
        onBlur={handleBlur}
        autoComplete="off"
        placeholder="Search for Thikana around"
      ></Bar>
      <SearchIcon className="fa fa-search"></SearchIcon>
      {inFocus && (
        <DropListWrapper>
          <Dropdown
            matchedRecords={matchedRecords}
            handleTagSelection={handleTagSelection}
          ></Dropdown>

          <NoteLine>
            <TextIcon className="fa fa-bell" />
            Currently serving only neighbourhood within Gurugram area
          </NoteLine>
        </DropListWrapper>
      )}
    </Wrapper>
  )
}

export default SearchBar
