import React from "react"
import styled from "styled-components"
import { useHistory } from "react-router"
import { colors } from "../../Constants/colors"

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  padding-top: 10%;
  align-items: center;
  flex-direction: column;
`

const SuccessText = styled.h2`
  text-align: center;
`
const TextIcon = styled.i`
  color: ${colors.green};
  font-size: 5em;
  align-items: center;
  text-align: center;
  display: block;
`


const SuccessScreen = () => {
  const history = useHistory()

  return (
    <>
      <Wrapper
        onClick={() => {
          history.push("/mylistings")
        }}
      >
        <TextIcon className="fas fa-check-circle"/>
        <SuccessText>
          {" "}
          Submitted Successfully! <br/> <br/>Click here  to go back to your Listings{" "}
        </SuccessText>
      </Wrapper>
    </>
  )
}

export default SuccessScreen
