import React from "react";
import ReactDOM from "react-dom";
import { ProvideAuth } from "./Hooks/useAuth";
import App from "./App";
import { ProvideServerData } from "./Hooks/useServer";
import { ProvideFilterData } from "./Hooks/useFilter";
import { ProvideBookmarks } from "./Hooks/useBookmarks";

ReactDOM.render(
  <React.StrictMode>
    <ProvideAuth>
      <ProvideServerData>
        <ProvideFilterData>
          <ProvideBookmarks>
            <App/>
          </ProvideBookmarks>
        </ProvideFilterData>
      </ProvideServerData>
    </ProvideAuth>
  </React.StrictMode>,
  document.getElementById("root")
);
