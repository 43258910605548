import React from "react";
import styled from "styled-components";
import { colors } from "../../Constants/colors";
import { breakpoints } from "../../Constants/responsive";
import { size } from "../../Constants/sizesAndSpacing";
import Footer from  "../Footer/footer";
import { NavLink } from "react-router-dom";

const Wrapper = styled.div`
  height: calc(100% - ${size.headerHeight + "px"});
  overflow-y: scroll;
`;
const Canvas = styled.div`
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 1%;
  margin-bottom: 1%;
`;

const MainHeading = styled.h1`
  color: ${colors.secondary};

  @media only screen and (min-width: ${breakpoints.mobile + "px"}) {
    font-size: 3em;

  }
  @media only screen and (min-width: ${breakpoints.tablet + "px"}) {
    font-size: 3.5em;
  }

  @media only screen and (min-width: ${breakpoints.desktop + "px"}) {
    font-size: 4em;
  }
`;

const TextBody = styled.p`
  margin-top: 1%;
  margin-bottom: 1%;
  color: ${colors.secondary};
  text-align: justify;

  @media only screen and (min-width: ${breakpoints.mobile + "px"}) {
    font-size: 0.875em;

  }
  @media only screen and (min-width: ${breakpoints.tablet + "px"}) {
    font-size: 1em;
  }

  @media only screen and (min-width: ${breakpoints.desktop + "px"}) {
    font-size: 1.2em;
  }
`;

const SpacingBlock = styled.div`
  padding: 2%;
`;

const TermsListingScreen = () => {
  return (
    <Wrapper>
        <Canvas>
            <MainHeading> Listing Conditions</MainHeading>
            <TextBody><strong>Updated:</strong> January 1, 2021</TextBody>
            <SpacingBlock></SpacingBlock>
            <TextBody>
              <ol type="i">
                <li>
                  The defined terms and phrases used herein shall have the same meaning as assigned to them in the <NavLink to="/terms-and-conditions" style={{color: "blue"}}>Terms and Conditions</NavLink>.
                </li>
                <br></br>
                <li>
                  The Listing Conditions are to be read as part and parcel of the <NavLink to="/terms-and-conditions" style={{color: "blue"}}>Terms and Conditions</NavLink>, and in the event of any conflict and/or inconsistency, these Listing Conditions shall prevail.
                </li>
                <br></br>
                <li>
                  Only the Subscribers registered on EkThikana shall be eligible and entitled to post Listing of properties on EkThikana.
                </li>
                <br></br>
                <li>
                  Only the owner, power-of-attorney holder, or any Person who has acquired/received all necessary rights and authorizations from the
                  owner/ power-of-attorney holder in writing, may be permitted to post/publish any Listing on EkThikana. Any Listing by any unauthorized
                  Person(s) would be subject to deletion/removal and the Subscriber shall be subject to such action/penalty as the Company may deem appropriate,
                  in its sole discretion.
                </li>
                <br></br>
                <li>
                  The Subscriber further represents and warrants that it is the owner of, or licensee/assignee of any Content including from the photographer
                  and/or copyright owner of any photographs, to publish and advertise any Listing of property(s) on EkThikana.
                </li>
                <br></br>
                <li>
                  In order to post/publish the Listing of property(s), the Subscriber shall be required to fill the Listing Form, failing which the Company may decline to
                  accept such Listing. The Listing Form shall, inter alia, contain the following details:
                </li>
                <br></br>
                <ul style={{listStyleType: "disc"}}>
                  <li>
                    Property Location (Latitude And Longitude)
                  </li>
                  <li>
                    Address
                  </li>
                  <li>
                    City
                  </li>
                  <li>
                    State
                  </li>
                  <li>
                    Property Type
                  </li>
                  <li>
                    Property Description
                  </li>
                  <li>
                    Bedrooms, Bathrooms, Amenities
                  </li>
                  <li>
                    Year of Built
                  </li>
                  <li>
                    Square Footage
                  </li>
                  <li>
                    Furnishing
                  </li>
                  <li>
                    Lease Duration
                  </li>
                  <li>
                    Lease Summary
                  </li>
                  <li>
                    Security Deposit
                  </li>
                  <li>
                    Monthly Rent
                  </li>
                  <li>
                    Date of Availability
                  </li>
                  <li>
                    Property Photographs
                  </li>
                  <br></br>
                </ul>
                <li>
                  The Subscriber represents and warrants that it may only post a Listing on EkThikana, if the Subscriber is the owner, or the power-of-attorney holder on
                  behalf of the owner or has acquired/received all necessary rights and authorizations from the owner/ power-of-attorney holder in writing, of such property.
                  The Subscriber further represents and warrants that in the event the property in question is under joint ownership or has been licensed/assigned to any other
                  Person by the lawful owner, the Subscriber shall obtain the consent, in writing, of all owners/stakeholders/licensees/assignees of the property, as the case
                  may be, before posting/publishing the Listing.
                </li>
                <br></br>
                <li>
                  The Subscriber represents and warrants to ensure, and confirm, that all Information posted by them on the Listing Form is correct and authentic failing
                  which the Company may initiate legal proceedings against the Subscriber at their cost. The Company does not take any ownership, liability and/or
                  responsibility, either directly or indirectly, for the accuracy, genuineness or veracity of any Listing posted on EkThikana.
                </li>
                <br></br>
                <li>
                  The Subscriber further represents and warrants that in the event of any change in any particulars, or status of the property, the Subscriber will notify the
                  Company or update such Information/change in status in the Listing. The Subscriber is solely liable to ensure that the Listing is up to date and accurate and agrees to indemnify the Company against any/all claims, actions, liabilities or damages that may arise as a result of the Subscriber’s failure to update the same.
                </li>
                <br></br>
                <li>
                  The Subscriber warrants that any/all Information posted by them is in accordance with the Agreement. In case the Subscriber violates the same, the Company shall, in its sole discretion, terminate the Account of the Subscriber, and refuse Service to any Subscriber who repeatedly or intentionally violates the Agreement.
                </li>
                <br></br>
                <li>
                  The Subscriber represents and warrants that it has no objection to the Listing, or any part thereof, to be searched, displayed, accessed,
                  downloaded, copied, and otherwise referred to by the other Users of EkThikana.
                </li>
                <br></br>
                <li>
                  The Subscriber represents and warrants that the Company shall have the right to choose the manner in which any Listing will be searched, displayed,
                  accessed, downloaded, copied, and otherwise Used on the Site, including the right to modify the Listing in the exercise of its rights under these Listing Conditions.
                </li>
                <br></br>
                <li>
                  The Subscriber represents and warrants that in case it is covered under the provisions of the RERA Act, it shall have to ensure adherence of all necessary compliances, including but not limited to obtaining the necessary registrations etc.
                </li>
                <br></br>
                <li>
                  The Subscriber represents and warrants that it shall disclose all material information as required, in respect of their registrations under the RERA Act.
                </li>
                <br></br>
                <li>
                  The Subscriber represents and warrants that, while posting Listings, it shall submit all details of the property as required in the Listing Form, including the present status and nature of such property, and shall also disclose whether the property is free from all encumbrances or not.
                </li>
                <br></br>
                <li>
                  The Subscribers represents and warrants that in case the property in respect of which the Listing is posted, or sought to be posted, is under any dispute or claim with regards to right, title, ownership, possession or statutory dues; or there is any charge, lien, encumbrance or interest created on the property, the Subscriber shall furnish a brief background of such property in respect of title, ownership and possession, along with documents to support his/her claims as may be specified by it from time to time.
                </li>
                <br></br>
                <li>
                  The Subscriber represents and warrants that the Listing shall be in conformity with the Agreement and Applicable Laws, as may be amended from time to time, failing which, the Company may, at its sole and absolute discretion, take necessary remedial action, including but not limited to:
                </li>
                <br></br>
                <ul style={{listStyleType: "disc"}}>
                  <li>
                    modifying or deleting the Listing; and/or
                  </li>
                  <br></br>
                  <li>
                    deactivating or deleting the Account of the Subscriber and all related Information and Content associated with such Account; and/or
                  </li>
                  <br></br>
                  <li>
                    reporting any violation of any Applicable Law by the User to the Government Authority concerned.
                  </li>
                  <br></br>
                </ul>
                <li>
                  Users, who are buying/renting property/properties through EkThikana, may independently verify details the property/properties as well as, requesting for details/particulars with respect to right, title, ownership, lien etc. The Company shall not be liable for any costs/expenses incurred by either party while undertaking such an exercise.
                </li>
                <br></br>
                <li>
                  The Company reserves, in a manner consistent with reasonable commercial business practices, the right to remove all or any part of the Listings posted on EkThikana, without prior notice.
                </li>
                <br></br>
                <li>
                  EkThikana shall take all reasonable efforts for data backup and business resumption, but the Subscriber shall be solely responsible for retaining back-up copies of all information, photographs and other materials furnished/submitted to EkThikana.
                </li>
                <br></br>
              </ol>
            </TextBody>
        </Canvas>
        <Footer></Footer>
    </Wrapper>

  );
};

export default TermsListingScreen;
