import { useReducer, Dispatch, useEffect, useState } from "react"

import { IServerListing } from "../mockListings"

export interface IPagination {
  page: number
  changePage: Dispatch<PaginationActions | number>
  sliceIndices: [number, number]
  totalPages: number
}

const queriesPerPage = 26

export enum PaginationActions {
  next = "next",
  prev = "prev",
  start = "start",
}

const reducer = (state: number, action: PaginationActions | number) => {
  if (action === PaginationActions.next) return state + 1
  else if (action === PaginationActions.prev) return state === 0 ? 0 : state - 1
  else if (action === PaginationActions.start) return 0
  else if (typeof action === "number") return action

  return state
}

const usePagination: (posts: IServerListing[]) => IPagination = posts => {
  const [page, changePage] = useReducer(reducer, 0)
  const [totalPages, changeTotalPages] = useState(0)
  const [sliceIndices, changeSliceIndices] = useState<[number, number]>([0, 0])

  useEffect(() => {
    changeSliceIndices([
      page * queriesPerPage,
      (page + 1) * queriesPerPage > posts.length
        ? posts.length
        : (page + 1) * queriesPerPage,
    ])
  }, [page, posts])

  useEffect(() => {
    changeTotalPages(Math.floor(posts.length / queriesPerPage))
    changePage(PaginationActions.start)
  }, [posts])

  return {
    page,
    changePage,
    sliceIndices,
    totalPages,
  }
}

export default usePagination
