import React, { useState, useRef, useCallback } from "react"
import styled from "styled-components"
import { colors } from "../../../Constants/colors"
import useOnClickOutside from "../../../Hooks/useOnClickOutside"
import { breakpoints } from "../../../Constants/responsive"
import { size } from "../../../Constants/sizesAndSpacing"

const ClickWrapper = styled.div<{ collapsable: boolean }>`
  height: 100%;
  display: ${props => (props.collapsable ? "none" : "flex")};
  align-items: center;
  flex-grow: 1;
  max-width: 6.5em;
  @media (min-width: ${breakpoints.mobile + "px"}) {
    max-width: 7em;
  }
  @media (min-width: ${breakpoints.tablet + "px"}) {
    display: flex;
    max-width: 7.5em;
  }
`

const FilterButtonWrapper = styled.div<{ open: boolean }>`
  width: 100%;
  height: 60%;
  border: ${props =>
    props.open
      ? "1.5px solid " + colors.primary
      : "1px solid " + colors.primary};
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-right: 0.625em;
  box-sizing: border-box;
  @media (min-width: ${breakpoints.tablet + "px"}) {
    display: flex;
  }
  &:hover {
    background: ${colors.lightprimary};
  }
`

const FilterButtonSpan = styled.span`
  width: 100%;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  color: ${colors.primary};
`

const UpArrow = styled.div`
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid white;
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10002;
`

const DropDown = styled.div<{ openRight: boolean }>`
  cursor: default;
  position: absolute;
  background: white;
  top: 120%;
  left: ${props => (props.openRight ? 0 : "auto")};
  right: ${props => (props.openRight ? "auto" : 0)};
  z-index: 10000;
  border-radius: 3px;
  box-shadow: 0 0 5px ${colors.secondary};
  padding: 0.75em;
`

const FullScreenDropdown = styled.div<{ openRight: boolean }>`
  cursor: default;
  position: absolute;
  left: 7%;
  right: 7%;
  background: white;
  top: ${size.headerHeight * 2 + "px"};
  bottom: 0;
  overflow-y: auto;
  padding: 0.75em;
  z-index: 10000;
  border-radius: 3px;
  box-shadow: 0 0 5px ${colors.secondary};

  ::-webkit-scrollbar {
    width: 1.25em;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${colors.midprimary};
    border-radius: 1.25em;
    border: 6px solid transparent;
    background-clip: content-box;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: ${colors.primary};
}

`

interface IProps {
  children?: React.ReactNode
  openRight?: boolean
  collapsable?: boolean
  fullScreenDropdown?: boolean
}

const FilterButton: React.FC<IProps> = (props: IProps) => {
  const [open, setOpen] = useState(false)
  const ref: React.MutableRefObject<HTMLDivElement | null> = useRef(null)

  const closeDropdown = useCallback(() => {
    setOpen(false)
  }, [])

  useOnClickOutside(ref, closeDropdown)

  return (
    <ClickWrapper
      collapsable={props.collapsable === true ? true : false}
      ref={ref}
    >
      <FilterButtonWrapper open={open}>
        <FilterButtonSpan
          onClick={() => {
            setOpen(!open)
          }}
        >
          {props.children && (props.children as any)[0]}
        </FilterButtonSpan>
        {open && <UpArrow></UpArrow>}
        {open && !props.fullScreenDropdown && (
          <>
            <DropDown openRight={props.openRight === false ? false : true}>
              {props.children &&
                (props.children as any)[1] &&
                React.cloneElement((props.children as any)[1], {
                  closeDropdown,
                })}
            </DropDown>
          </>
        )}
      </FilterButtonWrapper>
      {open && props.fullScreenDropdown && (
        <FullScreenDropdown
          openRight={props.openRight === false ? false : true}
        >
          {props.children &&
            (props.children as any)[1] &&
            React.cloneElement((props.children as any)[1], {
              closeDropdown,
            })}
        </FullScreenDropdown>
      )}
    </ClickWrapper>
  )
}

export default FilterButton
