import React,{useState,useEffect} from "react";
import styled from "styled-components";
import { size } from "../../../../Constants/sizesAndSpacing";
import { colors } from "../../../../Constants/colors";
import Logo from "../../../logo";
import { useBookmarks } from "../../../../Hooks/useBookmarks"
 import { IServerListing } from "../../../../mockListings";
import { breakpoints } from "../../../../Constants/responsive";
import { baseURL, clientURL } from "../../../../Constants/serverConfig";
import { useRouteMatch, useHistory } from "react-router";

const HeaderWrapper = styled.div`
   height: ${size.headerHeight + "px"};
  background: ${colors.white};
  display: flex;

  justify-content: space-between;
  align-items: center;
  background: ${colors.white};
  border-bottom: 0.5px solid ${colors.shadow};
`;

const SmallHeaderWrapper = styled.div`
  display: none;
   @media (min-width: ${breakpoints.tablet + "px"}) {
    display: inline-block;
  }
`;

const BackButton = styled.div`
  cursor: pointer;
  font-size: 1.3em;
  margin: 1em;
  color: ${colors.secondary};
  position: relative;
`;

const Popup = styled.div`
  background: white;
  padding: 1em;
  border-radius: 5px;
  color: grey;
  box-shadow: 0 0 8px 3px grey;
  position: absolute;
  top: 2.3em;
  right: 0;
  width: 8em;
  font-size: 0.7em;
`

const LogoWrapper = styled.div`
  width: 100px;
  margin: 1em;
`;

const BookmarkButtonWrapper = styled.div<{ active: boolean }>`
   z-index: 10;
   height: 2em;
   width: 2em;
   margin: 1em;
   overflow: hidden;
   border-radius: 50%;
   cursor: pointer;
   background: url("/icon.png");
   background-position: center;
   background-size: cover;
   filter: ${props => (props.active ? "none" : "grayscale(100%)")};
`;

interface IProps {
  toggle: () => void;
  showBackButton: boolean;
  props?: IServerListing;
}

const ExtendedListingHeader: React.FC<IProps> = (props: IProps) => {
  const bookmark = useBookmarks();
  const [copiedMessage, setCopiedMessage] = useState(false);

  const [isBookmarked,setIsBookmarked]=useState(false)
    useEffect(()=>{
      if(props.props && props.props.postID)
      setIsBookmarked (bookmark.isBookmarked(props.props.postID))
    },[])

   const share = (postId: string) => {
    
    (navigator as any).permissions.query({name: "clipboard-write"}).then((result: any) => {
      if (result.state == "granted" || result.state == "prompt") {
        (navigator as any).clipboard.writeText(clientURL+"/app/"+postId);
      }
    });
    setCopiedMessage(true);
    setTimeout(() => {
      setCopiedMessage(false);
    }, 1500);
  }

  const match = useRouteMatch("/savedThikanas")
  const redirect = match && match.isExact ? false : true
  const history = useHistory();

  return (
    <HeaderWrapper>
       {props.showBackButton && (
        <BackButton onClick={() => {
          if (redirect) history.push("/app")
          props.toggle()
        }}>
          <i className="fas fa-chevron-left"></i>
        </BackButton>
      )}

 { props.props ? <BookmarkButtonWrapper
          onClick={e => {
            e.stopPropagation();
            if (isBookmarked) {
              bookmark.removeBookmark(props?.props?.postID||"");
              setIsBookmarked(false)
            } else {
              if(props.props)
                bookmark.addBookmark(props.props)
                setIsBookmarked(true)
            }
          }}
          active={isBookmarked}
      ></BookmarkButtonWrapper> : <></>}

<SmallHeaderWrapper> <LogoWrapper>
        <Logo></Logo>
      </LogoWrapper></SmallHeaderWrapper>

        { props.props ? <BackButton onClick={()=>share(props.props?.postID||"")}>
      <i className="fas fa-share"></i> {copiedMessage && <Popup>Link copied to clipboard!</Popup>}

      </BackButton>:<></>}
    </HeaderWrapper>
  );
};

export default ExtendedListingHeader;
