import React, { useEffect, useState, useRef } from "react"
import styled from "styled-components"
import Logo from "../logo"
import { colors } from "../../Constants/colors"
import { size } from "../../Constants/sizesAndSpacing"
import { breakpoints } from "../../Constants/responsive"
import SearchBar from "./searchBar/searchBar"
import { IMapConfig } from "../Listings/ListingMap/listingMap"
import { useFilter, IFilters } from "../../Hooks/useFilter"
import { NavLink, useHistory } from "react-router-dom"
import AuthModal from "../UserAuth/UserAuthModal"
import UserLogin from "./userLogin"
import { useAuth } from "../../Hooks/useAuth"
import { useBookmarks } from "../../Hooks/useBookmarks"

const HeaderBar = styled.div`
  width: 100%;
  background: ${colors.white};
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${size.headerHeight + "px"};
  border-bottom: 0.5px solid ${colors.shadow};
  box-sizing: border-box;
  padding: 0 10px;
  opacity: 1;
`

const SideBar = styled.div`
  position: absolute;
  display: none;
  transition: 0.3s all ease-out;
  top: ${size.headerHeight + "px"};
  z-index: 20000;

  @media (max-width: ${breakpoints.mobile + "px"}) {
    display: block;
    left: -80%;
    width: 50%;
    background: ${colors.white};
    height: ${window.screen.height - size.headerHeight + "px"};
  }
`

export const SideBarItem = styled.div`
  padding: 0.75em;
  background: transparent;
  border-bottom: 0.5px solid ${colors.shadow};
`

const DarkDrawerForSideBar = styled.div<{ open: boolean }>`
  opacity: 0.3;
  background: ${colors.black};
  display: ${props => (props.open ? "static" : "none")};
  position: absolute;
  top: ${size.headerHeight + "px"};
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 15000;
`

const CollapsableSearch = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  flex-grow: 1;
  max-width: 25em;

  @media (min-width: ${breakpoints.mobile + "px"}) {
    display: none;
  }
`

const CollapsableHamburger = styled.span`
  margin-right: 0.5em;
  font-size: 1.5em;
  opacity: 0.8;
  cursor: pointer;
  display: inline-block;

  @media (min-width: ${breakpoints.mobile + "px"}) {
    display: none;
  }
`

const CollapseOnMobile = styled.div`
  height: 100%;
  display: none;
  align-items: center;

  @media (min-width: ${breakpoints.mobile + "px"}) {
    display: flex;
  }
`

export const PlainButton = styled.div`
  cursor: pointer;
  padding: 0.5em;
  margin-right: 0.2em;
  opacity: 0.8;
  font-size: 1em;
  outline: none;
  font-weight: 500;

  &:hover {
    opacity: 1;
  }
`

const BookmarkButton = styled.div`
  cursor: pointer;
  display: inline-block;
  margin-left: 0.1em;
  margin-right: 0.1em;
  padding-left: 0.8em;
  padding-right: 0.8em;
  font-size: 0.8em;
  font-weight: 500;
  border: 1px solid;
  border-radius: 1em;
  color: ${colors.white};
  background-color: ${colors.primary};
`

const LogoHamburgerRow = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  margin-right: 1em;
`

const LogoWrapper = styled.div`
  width: 150px;
  padding: 1em;
  box-sizing: border-box;
  display: flex;
  align-items: center;
`

const HeaderWrapper = styled.div`
  display: block;
  width: 100%;
  z-index: 100000;
`

const AddListing = styled.div`
  display: flex;
  flex-grow: 1;
  max-width: 8em;
  padding: 0.4em;
  align-items: center;
  justify-content: center;
  height: 60%;
  border-radius: 3px;
  cursor: pointer;
  box-sizing: border-box;
  font-size: 0.9em;
  font-weight: 500;
  color: ${colors.white};
  margin-right: 5px;
  background: ${colors.primary};
`

const Collapsable = styled.div`
  height: 100%;
  align-items: center;
  margin-right: 0.625em;

  display: flex;
  margin-left: auto;
  @media (min-width: ${breakpoints.mobile + "px"}) {
    display: none;
  }
`

interface IProps {
  mapStartConfig: IMapConfig
  setMapStartConfig: React.Dispatch<React.SetStateAction<IMapConfig>>
}

const TopHeader: React.FC<IProps> = (props: IProps) => {
  const [sideBarOpen, setSideBarOpen] = useState(false)
  const sideBarRef = useRef(null)

  const setRentSell = (useFilter().filters as IFilters).rentSell.set

  // Bookmark values
  const bookmarks = useBookmarks().bookmarks

  const { authModal, user, checkSignin } = useAuth()

  const authModalOpen = authModal.open,
    toggleAuthModalOpen = authModal.toggleOpen

  const history = useHistory()
  const isAuth = !!user.token

  useEffect(() => {
    if (!isAuth) history.push("/")

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth])

  useEffect(() => {
    if (sideBarOpen) {
      if (sideBarRef && sideBarRef.current != null) {
        //@ts-ignore
        sideBarRef.current.style.left = "0"
      }
    } else {
      if (sideBarRef.current) {
        //@ts-ignore
        sideBarRef.current.style.left = "-60%"
      }
    }
  }, [sideBarOpen])

  return (
    <>
      <HeaderWrapper>
        <HeaderBar>
          <CollapseOnMobile>
            {/* <NavLink to="/app">
              <PlainButton onClick={() => setRentSell(1)}>Buy</PlainButton>
            </NavLink> */}
            <NavLink to="/app">
              <PlainButton onClick={() => setRentSell(0)}>Rent</PlainButton>
            </NavLink>
            {/* <PlainButton>Sell</PlainButton> */}
            <NavLink to="/savedThikanas">
              <PlainButton>
                Saved Thikanas
                <BookmarkButton>{Object.keys(bookmarks).length}</BookmarkButton>
              </PlainButton>
            </NavLink>
          </CollapseOnMobile>
          <LogoHamburgerRow>
            <CollapsableHamburger
              onClick={() => {
                setSideBarOpen(!sideBarOpen)
              }}
            >
              <i className="fas fa-bars"></i>
            </CollapsableHamburger>
            <NavLink to="/homepage">
              <LogoWrapper>
                <Logo></Logo>
              </LogoWrapper>
            </NavLink>
          </LogoHamburgerRow>
          <CollapseOnMobile>
            <UserLogin toggleAuthModalOpen={toggleAuthModalOpen}></UserLogin>
          </CollapseOnMobile>
          <Collapsable>
            <AddListing
              onClick={() => {
                checkSignin() && history.push("/mylistings")
              }}
            >
              <i
                style={{
                  marginRight: "4px",
                  fontSize: "0.875em",
                  transform: "translateY(1px)",
                }}
                className="fa fa-plus"
              ></i>
              Add Listing
            </AddListing>
          </Collapsable>
        </HeaderBar>

        <SideBar ref={sideBarRef}>
          <NavLink to="/app">
            <SideBarItem
              onClick={() => {
                setRentSell(0)
                setSideBarOpen(false)
              }}
            >
              Rent
            </SideBarItem>
          </NavLink>
          {/* <SideBarItem>Sell</SideBarItem> */}
          <NavLink onClick={() => setSideBarOpen(false)} to="/savedThikanas">
            {" "}
            <SideBarItem>
              Saved Thikanas
              <BookmarkButton>{Object.keys(bookmarks).length}</BookmarkButton>
            </SideBarItem>
          </NavLink>

          <UserLogin
            sideBar={true}
            setSideBarOpen={setSideBarOpen}
            toggleAuthModalOpen={toggleAuthModalOpen}
          ></UserLogin>
        </SideBar>
        <DarkDrawerForSideBar
          onClick={() => setSideBarOpen(false)}
          open={sideBarOpen}
        ></DarkDrawerForSideBar>
      </HeaderWrapper>
      {authModalOpen && (
        <AuthModal
          open={authModalOpen}
          toggle={toggleAuthModalOpen}
        ></AuthModal>
      )}
    </>
  )
}

export default TopHeader
