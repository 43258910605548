import React, { useState, useRef, useCallback } from "react";
import styled from "styled-components";
import { colors } from "../../Constants/colors";
import useOnClickOutside from "../../Hooks/useOnClickOutside";
import { SortOptions } from "../../Hooks/useFilter";

const Wrapper = styled.div`
  position: relative;
`;

const Dropdown = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  background: white;
  box-shadow: 0 0 5px ${colors.shadow};
  z-index: 100;
`;

const DropdownItem = styled.div`
  width: 150px;
  padding: 0.5em;
  cursor: pointer;
  background: ${colors.white};
  color: ${colors.black};
  font-size: 0.9em;

  &:hover {
    background: ${colors.primary};
    color: ${colors.white};
  }
`;

const SortText = styled.span`
  margin-right: 5px;
  color: ${colors.secondary};
  font-size: 12px;
`;

const Button = styled.div<{ disabled: boolean }>`
  border-radius: 2px;
  border: 1px solid
    ${props => (props.disabled ? colors.shadow : colors.primary)};
  padding: 0.3em 0.5em;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  cursor: pointer;
`;

const ButtonText = styled.div<{ disabled: boolean }>`
  font-size: 12px;
  font-weight: 600;
  color: ${props => (props.disabled ? colors.shadow : colors.primary)};
`;

const Chevron = styled.i<{ disabled: boolean }>`
  transform: translateY(1px);
  margin-left: 5px;
  color: ${props => (props.disabled ? colors.shadow : colors.primary)};
`;

interface IProps {
  sortBy: {
    set: React.Dispatch<React.SetStateAction<SortOptions>>;
    value: SortOptions;
  };
  disabled?: boolean;
}

const SortByDropdown: React.FC<IProps> = (props: IProps) => {
  const sortBy = props.sortBy;
  const disabled = props.disabled === true ? true : false;

  const [open, setOpen] = useState(false);

  const ref: React.MutableRefObject<HTMLDivElement | null> = useRef(null);
  const closeDropdown = useCallback(() => {
    setOpen(false);
  }, []);

  useOnClickOutside(ref, closeDropdown);

  return (
    <Wrapper ref={ref}>
      <SortText>Sort By: </SortText>
      <Button disabled={disabled} onClick={() => !disabled && setOpen(!open)}>
        <ButtonText disabled={disabled}>{sortBy.value}</ButtonText>
        {open ? (
          <Chevron disabled={disabled} className="fa fa-chevron-up"></Chevron>
        ) : (
          <Chevron disabled={disabled} className="fa fa-chevron-down"></Chevron>
        )}
      </Button>
      {open && (
        <Dropdown>
          {Object.values(SortOptions).map(opt => {
            return (
              <DropdownItem
                onClick={() => {
                  sortBy.set(opt as SortOptions);
                  setOpen(false);
                }}
              >
                {opt}
              </DropdownItem>
            );
          })}
        </Dropdown>
      )}
    </Wrapper>
  );
};

export default SortByDropdown;
