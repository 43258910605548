import React from "react";
import ExtendedListingHeader from "./header";
import { IServerListing } from "../../../../mockListings";

interface IProps {
  toggle: () => void;
  props: IServerListing;
}
const SmallHeader: React.FC<IProps> = (props: IProps) => {

  return (

      <ExtendedListingHeader
        {...props}
        showBackButton={false}
        toggle={props.toggle}
      ></ExtendedListingHeader>

  );
};

export default SmallHeader;
