export const size = {
  headerHeight: 60,
  listingMinWidth: 305,
  extendedListingDescriptionWidth: 460,
  NavHeaderWrapperHeight: 200
};

export const spacing = {
  listingMargin: 20,
  listingCardLineSpacing: 0.25
};
