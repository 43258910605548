import React, { useState } from "react"
import styled from "styled-components"
import { useAuth } from "../../Hooks/useAuth"
import { colors } from "../../Constants/colors"
import { PlainButton, SideBarItem } from "./topHeader"
import { GoogleLogout } from "react-google-login"
import { NavLink } from "react-router-dom"

const DropdownWrapper = styled.div`
  position: relative;
`

const DropDown = styled.div`
  cursor: default;
  position: absolute;
  background: white;
  top: 100%;
  right: 0;
  width: 300px;
  z-index: 10000;
  border-radius: 4px;
  box-shadow: 0 0 5px ${colors.shadow};
`

const DropdownItem = styled.div`
  padding: 1em;
  cursor: pointer;
  background: ${colors.white};
  color: ${colors.black};
  font-size: 1em;
  margin: auto;
  display: flex;
  border-bottom: 1px solid ${colors.shadow};

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`

const DropdownImage = styled.img`
  width: 20%;
  border-radius: 50%;
  // margin: auto;
`

const DropdownText = styled.p`
  display: inline-block;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 1em;
  margin-right: auto;
`

const BigChevron = styled.i`
  // transform: translateY(1px);
  // margin: auto;
  color: ${colors.secondary};
  font-size: 1.5em;
  padding-left: 0.5em;
`

const Chevron = styled.i`
  transform: translateY(1px);
  margin-left: 5px;
  color: ${colors.secondary};
`

interface IProps {
  toggleAuthModalOpen: () => void
  setSideBarOpen?: (flag: boolean) => void
  sideBar?: boolean
}

const UserLogin: React.FC<IProps> = (props: IProps) => {
  const auth = useAuth()

  const [open, setOpen] = useState(false)
  const [openSidebar, setOpenSidebar] = useState(false)

  const responseGoogleLogout = () => {
    auth.signout()
    props.setSideBarOpen && props.setSideBarOpen(false)
  }

  if (props.sideBar) {
    return (
      <>
        {auth.user.token && (
          <SideBarItem
            onClick={() => {
              if (openSidebar) setOpenSidebar(false)
              else setOpenSidebar(true)
            }}
          >
            {auth.user.name}
            {openSidebar ? (
              <Chevron className="fa fa-chevron-up"></Chevron>
            ) : (
              <Chevron className="fa fa-chevron-down"></Chevron>
            )}
          </SideBarItem>
        )}

        {!auth.user.token && (
          <SideBarItem
            onClick={() => {
              props.toggleAuthModalOpen()
              props.setSideBarOpen && props.setSideBarOpen(false)
            }}
          >
            Sign in
          </SideBarItem>
        )}
        {auth.user.token && openSidebar && (
          <div style={{ background: "rgba(0,0,0,0.1)" }}>
            <NavLink
              onClick={() =>
                props.setSideBarOpen && props.setSideBarOpen(false)
              }
              to="/mylistings"
            >
              <SideBarItem>
                <Chevron className="fas fa-th-list"></Chevron> My listings
              </SideBarItem>
            </NavLink>
            <GoogleLogout
              clientId="186530447367-irn6i31pu8ohcr98lk9nkf2o2atmcijf.apps.googleusercontent.com"
              render={renderProps => {
                return (
                  <SideBarItem
                    onClick={() => {
                      auth.signout()
                      renderProps.onClick()
                      props.setSideBarOpen && props.setSideBarOpen(false)
                    }}
                  >
                    <Chevron className="fas fa-sign-out-alt"></Chevron> Log out
                  </SideBarItem>
                )
              }}
              buttonText="Logout"
              onLogoutSuccess={responseGoogleLogout}
            />
          </div>
        )}
      </>
    )
  }

  return (
    <>
      {auth.user.token && (
        <DropdownWrapper
          onClick={() => setOpen(false)}
          onMouseLeave={() => setOpen(false)}
          onMouseEnter={() => setOpen(true)}
        >
          <PlainButton>
            My account
            <Chevron className="fa fa-chevron-down"></Chevron>
            {open && (
              <DropDown>
                <NavLink
                  onClick={() =>
                    props.setSideBarOpen && props.setSideBarOpen(false)
                  }
                  to="/mylistings"
                >
                  <DropdownItem>
                    <DropdownImage src={auth.user.image!} alt="helloworld" />
                    <DropdownText>
                      {" "}
                      <b> {auth.user.name} </b> <br /> See your listings{" "}
                    </DropdownText>
                  </DropdownItem>
                </NavLink>
                <GoogleLogout
                  clientId="186530447367-irn6i31pu8ohcr98lk9nkf2o2atmcijf.apps.googleusercontent.com"
                  render={renderProps => {
                    return (
                      <DropdownItem
                        onClick={() => {
                          auth.signout()
                          renderProps.onClick()
                        }}
                      >
                        <BigChevron className="fas fa-sign-out-alt"></BigChevron>
                        <DropdownText> Log out</DropdownText>
                      </DropdownItem>
                    )
                  }}
                  buttonText="Logout"
                  onLogoutSuccess={responseGoogleLogout}
                />
              </DropDown>
            )}
          </PlainButton>
        </DropdownWrapper>
      )}
      {!auth.user.token && (
        <PlainButton onClick={props.toggleAuthModalOpen}>Sign in</PlainButton>
      )}
    </>
  )
}

export default UserLogin
