import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import styled, { keyframes } from "styled-components";
import { colors } from "../../Constants/colors";
import { breakpoints } from "../../Constants/responsive";
import Logo from "../logo";
import { useAuth } from "../../Hooks/useAuth";
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import "./Button.css";
import { NavLink } from "react-router-dom";
import "./ToggleSwitch.css";
import { googleClientId, facebookClientId } from "../../Constants/serverConfig";

const fadeIn = keyframes`
  0% {
    opacity: 0;
    top: -25px;
  }

  100% {
    opacity: 1;
    top: 0px;
  }
`;

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 120;
`;

const ModalCard = styled.div`
  position: relative;
  width: 30%;
  min-width: 400px;
  z-index: 121;
  background: white;
  border-radius: 5px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
  animation: ${fadeIn} 350ms ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 1.5em;
  padding-bottom: 2em;
  padding-left: 1em;
  padding-right: 1em;
  box-sizing: border-box;
`;

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: ${colors.black};
  opacity: 0.5;
  z-index: 120;
  cursor: pointer;
`;

const LogoRow = styled.div`
  min-width: 100px;
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HeadingRow = styled.div`
  border-top: 1px solid ${colors.lightgrey};
  min-width: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.5em;
  padding-top: 0.5em;
  padding-bottom: 1em;
  vertical-align: baseline;
  font-size: 1.2em;
  font-weight: 600;
`;

const TextRow = styled.div`
  padding-bottom: 1em;
  font-size: 1em;
  text-align: justify;
`;

const LoginOptions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: transparent;
  &:hover {
    cursor: pointer;
  }
  z-index: 125;
  padding: none;
  color: ${colors.secondary};
  font-size: 1.2em;

  @media (min-width: ${breakpoints.tablet + "px"}) {
    display: block;
  }
`;

const Portal = ({ children }: { children: React.ReactNode }) => {
  const el = document.createElement("div");
  const modalRoot = document.getElementById("modal") as HTMLElement;

  useEffect(() => {
    modalRoot.appendChild(el);
  }, [modalRoot, el]);

  useEffect(() => {
    return () => {
      modalRoot.removeChild(el);
    };
  });

  return createPortal(children, el);
};

interface IProps {
  toggle: () => void;
  open: boolean;
}

const AuthModal: React.FC<IProps> = ({ toggle, open }: IProps) => {
  const auth = useAuth();

// const responseGoogl = response => {
//     this.setState({ userDetails: response.profileObj, isUserLoggedIn: true });
//   };

const responseGoogle = (response: any) => {
      auth.signinGoogle(response);
      toggle();
    }

const responseFacebook = (response: any) => {
  auth.signinFacebook(response);
  toggle();
}

const googleId=googleClientId;
const facebookId=facebookClientId;

const [disabled, setDisabled] = React.useState(false)
const [checked, setChecked] = React.useState(true)

// const [disabled, setDisabled] = React.useState(true)
// const [checked, setChecked] = React.useState(false)
// const handleClick = () => {
//   setChecked(!checked)
//   setDisabled(!disabled)
// }

  return (
    <Portal>
      {open && (
        <ModalWrapper>
          <ModalCard>
            <LogoRow>
              <Logo></Logo>
            </LogoRow>
            <CloseButton onClick={toggle}>
              <i className="fas fa-times"></i>
            </CloseButton>
            <HeadingRow>Welcome to ekThikana</HeadingRow>
            <TextRow>By logging in with my Facebook or Google account. I agree to the <strong><NavLink onClick={toggle} to="/terms-and-conditions" style={{color: "blue"}}> Terms and Conditions</NavLink></strong>. My personal data will be processed in accordance with the descriptions in the <strong><NavLink onClick={toggle} to="/privacy-policy" style={{color: "blue"}}>Privacy Policy</NavLink></strong>.</TextRow>
            {/* <label className="toggle-switch">
              <input type="checkbox" checked={checked} onChange={handleClick} />
              <span className="switch" />
            </label> */}
            <LoginOptions>
              {/* clientId="186530447367-irn6i31pu8ohcr98lk9nkf2o2atmcijf.apps.googleusercontent.com" */}
              <GoogleLogin
              clientId={googleId}
              buttonText="Continue with Google"
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
              theme="dark"
              className="signInButton"
              isSignedIn={true}
              disabled={disabled}
            />
            <br/>
            <FacebookLogin
              appId={facebookId} //APP ID NOT CREATED YET
              autoLoad={false}
              fields="name,email,picture.type(large)"
              size="medium"
              icon="fa fa-facebook-square fa-3x"
              textButton= "Continue with Facebook"
              callback={responseFacebook}
              isMobile={false}
              cssClass="btnFacebook"
              isDisabled={disabled}
            />
            {console.log(window.location.href)}
            </LoginOptions>
          </ModalCard>
          <Background onClick={toggle} />
        </ModalWrapper>
      )}
    </Portal>
  );
};

export default AuthModal;
