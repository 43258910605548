import React, { useState, useRef, useEffect } from "react"
import { IThikanaLocation } from "./addListingDescription"
import { Map, TileLayer, Marker, ZoomControl, useLeaflet } from "react-leaflet"
import { customMarkerIcon } from "../Listings/ListingMap/listingMap"
import { NavLink, useHistory } from "react-router-dom"
import styled from "styled-components"
import { colors } from "../../Constants/colors"
import { breakpoints } from "../../Constants/responsive"
import { ILatLang } from "../../mockListings"
import SearchBar from "../Header/searchBar/searchBar"
import { IMapConfig } from "../Listings/ListingMap/listingMap"

//CSS Code Starts HERE
const Backdrop = styled.div`
  cursor: pointer;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.5);
`

const HeadingRow = styled.div`
  border-bottom: 1px solid ${colors.lightgrey};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.5em;
  padding-bottom: 0.5em;
  vertical-align: baseline;
  font-size: 1.2em;
  font-weight: 600;
  color: ${colors.secondary};
`

const PageContainer = styled.div`
  z-index: 100000;
  position: fixed;
  top: 5%;
  bottom: 5%;
  left: 2%;
  right: 2%;
  border-radius: 3px;
  background: white;
  padding: 1%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  font-size: 0.875em;
  min-width: 50%;

  @media (min-width: ${breakpoints.mobile + "px"}) {
    font-size: 0.875em;
    left: 10%;
    right: 10%;
  }
  @media (min-width: ${breakpoints.tablet + "px"}) {
    font-size: 1em;
    left: 20%;
    right: 20%;
  }
`

const MapWrapper = styled.div`
  width: 100%;
  min-height: 120px;
  flex-grow: 1;
  box-sizing: border-box;
  padding: 0.5em 2em;

  @media (min-width: ${breakpoints.mobile + "px"}) {
    padding: 1em 2em;
  }
  @media (min-width: ${breakpoints.tablet + "px"}) {
    padding: 1em 3em;
  }
`

const SearchGroup = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  flex-direction: row;
  padding: 0em 2em;
  height: 40px;
  @media (min-width: ${breakpoints.mobile + "px"}) {
    padding: 0em 2em;
  }
  @media (min-width: ${breakpoints.tablet + "px"}) {
    padding: 0em 3em;
  }
`

const SearchBarWrapper = styled.div`
  width: 100%;
  min-height: 3.5em;
`

const FlexRow = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  justify-content: flex-end;
  padding: 0em 2em;
  @media (min-width: ${breakpoints.mobile + "px"}) {
    padding: 0em 2em;
  }
  @media (min-width: ${breakpoints.tablet + "px"}) {
    padding: 0em 3em;
  }
`

const Button = styled.button`
  font-size: 0.75em;
  box-sizing: border-box;
  cursor: pointer;
  height: 2.5em;
  color: white;
  background-color: ${colors.primary};
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  border-color: ${colors.primary};
  min-width: 100px;
  width: 100%;
  font-weight: 600;
  margin-bottom: 5px;

  @media (min-width: ${breakpoints.mobile + "px"}) {
    font-size: 0.75em;
  }
  @media (min-width: ${breakpoints.tablet + "px"}) {
    font-size: 0.875em;
  }
`

const InvButton = styled.button`
  font-size: 0.75em;
  box-sizing: border-box;
  cursor: pointer;
  height: 2.5em;
  color: ${colors.primary};
  background-color: ${colors.white};
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  border-color: ${colors.primary};
  min-width: 100px;
  width: 100%;
  font-weight: 600;

  @media (min-width: ${breakpoints.mobile + "px"}) {
    font-size: 0.75em;
  }
  @media (min-width: ${breakpoints.tablet + "px"}) {
    font-size: 0.875em;
  }
`

const Footnote = styled.div`
  display: inline;
  margin-top: 0.5em;
  font-size: 0.65em;
  color: ${colors.secondary};
  justify-content: center;
  align-items: center;
  padding: 1em 2em;
  @media (min-width: ${breakpoints.mobile + "px"}) {
    padding: 1em 2em;
  }
  @media (min-width: ${breakpoints.tablet + "px"}) {
    padding: 1em 3em;
  }
`
//CSS Code ends HERE

interface IListingMapControls {
  updatePos: (ele: IMapConfig) => void
}

const ListingMapControls: React.FC<IListingMapControls> = (
  props: IListingMapControls
) => {
  // the component handles updating the mapConfig as user interacts with the leaflet map

  const { map } = useLeaflet()

  const updatePosition = () => {
    if (!map) return
    const pos = map.getCenter()
    const zoom = map.getZoom()
    props.updatePos({
      position: {
        lat: pos.lat,
        lon: pos.lng,
      },
      zoom,
      radius: 7225,
    })
  }

  useEffect(() => {
    if (!map) return
    map.on("moveend", updatePosition)

    return () => {
      map.off("moveend", updatePosition)
    }
  })

  return <span></span>
}

const getLocation = async (): Promise<IThikanaLocation> => {
  const promise = new Promise<IThikanaLocation>((resolve, reject) => {
    const location: ILatLang = { lat: 0, lon: 0 }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        pos => {
          location.lat = pos.coords.latitude
          location.lon = pos.coords.longitude
          resolve(location)
        },
        () => {
          resolve({ lat: 28.4479239, lon: 77.0495268 })
        }
      )
    } else resolve({ lat: 28.4479239, lon: 77.0495268 })
  })

  return promise
}

interface IProps {
  closeModal: () => void
  setThikanaLocation: (location: IThikanaLocation) => void
  thikanaLocation: IThikanaLocation | null
}

const AddNewListingLocation: React.FC<IProps> = (props: IProps) => {
  const [coords, setCoords] = useState<{
    position: ILatLang
    zoom: number
    radius: number
  }>({
    position: {
      lat: props.thikanaLocation ? props.thikanaLocation.lat : 28.44792,
      lon: props.thikanaLocation ? props.thikanaLocation.lon : 77.0495,
    },
    zoom: 16,
    radius: 7,
  })

  const updateLocation = async () => {
    const loc = await getLocation()
    setCoords({
      position: loc,
      radius: 7225,
      zoom: 16,
    })
  }

  useEffect(() => {
    updateLocation()
  }, [])

  const refMarker = useRef<Marker>()

  const updateMarker = () => {
    const marker = refMarker.current

    if (marker) {
      const latlng = marker.leafletElement.getLatLng()
      setCoords({
        position: {
          lat: latlng.lat,
          lon: latlng.lng,
        },
        radius: 7225,
        zoom: coords.zoom,
      })
    }
  }

  const history = useHistory()

  return (
    <>
      <Backdrop onClick={() => props.closeModal()}></Backdrop>
      <PageContainer>
        <HeadingRow>Set property location</HeadingRow>
        <SearchGroup>
          <SearchBarWrapper>
            <SearchBar
              mapStartConfig={coords}
              setMapStartConfig={setCoords}
            ></SearchBar>
          </SearchBarWrapper>
        </SearchGroup>

        <MapWrapper>
          <Map
            style={{
              height: "100%",
              minHeight: "200px",
              zIndex: 1,
              boxShadow: "rgba(0, 0, 0, 0.4) 0px 0px 10px 0px",
            }}
            center={[coords.position.lat, coords.position.lon]}
            zoom={coords.zoom}
            zoomControl={false}
          >
            <ListingMapControls updatePos={setCoords}></ListingMapControls>
            <ZoomControl position="bottomright"></ZoomControl>
            <TileLayer
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker
              ref={refMarker as any}
              icon={customMarkerIcon()}
              position={[coords.position.lat, coords.position.lon]}
              draggable={true}
              onDragend={updateMarker}
            />
          </Map>
        </MapWrapper>

        <FlexRow>
          <Button
            onClick={() => {
              updateLocation()
            }}
          >
            Use my current location
          </Button>
        </FlexRow>
        <FlexRow>
          <InvButton
            onClick={() => {
              history.push("/mylistings")
            }}
          >
            {" "}
            Back{" "}
          </InvButton>
          <div style={{ width: "10px" }}></div>
          <Button
            onClick={() => {
              props.setThikanaLocation({
                lat: coords.position.lat,
                lon: coords.position.lon,
              })
              props.closeModal()
            }}
          >
            Continue
          </Button>
        </FlexRow>

        <Footnote>
          {" "}
          By clicking Continue, you agree to comply with our{" "}
          <NavLink to="/terms-and-conditions" style={{ color: "blue" }}>
            &nbsp;Terms and Conditions
          </NavLink>
          , and{" "}
          <NavLink to="/listing-conditions" style={{ color: "blue" }}>
            &nbsp;Listing Conditions
          </NavLink>
          .{" "}
        </Footnote>
      </PageContainer>
    </>
  )
}

export default AddNewListingLocation
