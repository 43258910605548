import React, { useEffect } from "react"
import { useDropzone } from "react-dropzone"
import styled from "styled-components"
import { generateUid } from "../../Utils/generateUid"
import Loader from "../Loader/loader"
import { colors } from "../../Constants/colors"
import Axios from "axios"
import { useAuth } from "../../Hooks/useAuth"
import imageCompression from "browser-image-compression"
import { baseURL, s3MediaURL } from "../../Constants/serverConfig"
import { breakpoints } from "../../Constants/responsive"

const signedUrl = baseURL + "/signurl"

const getColor = (props: any) => {
  if (props.isDragAccept) {
    return "#00e676"
  }
  if (props.isDragReject) {
    return "#ff1744"
  }
  if (props.isDragActive) {
    return "#2196f3"
  }
  return "#eeeeee"
}

const Container = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  width: 100%;
`

const RowWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

const PreviewRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: auto;
  padding: 1em;
  box-sizing: border-box;
`

const ImageMask = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  opacity: 1;
  text-align: center;
`

const PreviewItem = styled.div`
  position: relative;
  border-radius: 3px;
  width: 100px;
  height: 100px;
  display: inline-flex;
  justify-content: center;
  margin-bottom: 10px;
  margin-right: 10px;
  box-shadow: 0 0 5px 0 ${colors.lightgrey};

  &:hover ${ImageMask} {
    opacity: 0.5;
  }
`

const PreviewImage = styled.img`
  height: 100%;
`

const LoaderWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  font-size: 1.5em;
`

const LoaderWrapperInteractive = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  font-size: 1.5em;
  opacity: 0.7;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
`

const DeleteWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 1.5em;
  height: 1.5em;
  transform: translate(50%, -50%);
  border-radius: 50%;
  background: ${colors.primary};
  color: ${colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  opacity: 0.7;
  &:hover {
    opacity: 1;
  }
`

const Dropbox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`

export interface IDropzoneFile {
  preview: string
  uploaded: boolean
  uploadQueued: boolean
  uploadFailed: boolean
  file: File | null
  url: string | null
  thumbnailUrl: string | null
}

interface IProps {
  files: IDropzoneFile[]
  setFiles: (files: IDropzoneFile[]) => void
}

export const Dropzone: React.FC<IProps> = (props: IProps) => {
  const auth = useAuth()

  const handleUpload = async (file: IDropzoneFile) => {
    file.uploadQueued = true

    if (!file.file) return

    const compressedFile = await imageCompression(file.file, {
      maxSizeMB: 2,
    })

    const i = props.files.indexOf(file)

    const extension = (file.file as File).name.split(".").pop()
    const filename = generateUid() + "." + extension
    const newFile = new File([compressedFile], filename, {
      type: file.file.type,
    })

    try {
      const urls: {
        data: {
          signedURL: string
          thumbnailSignedURL: string
        }
      } = await Axios.post(
        signedUrl,
        {},
        {
          headers: {
            filename: filename,
            userID: auth.user.userID,
          },
        }
      )

      const res = await Axios.put(urls.data.signedURL, newFile, {})
      props.files[i] = {
        ...props.files[i],
        uploaded: true,
        url: s3MediaURL + filename,
        uploadQueued: false,
        preview: s3MediaURL + filename,
      }

      props.setFiles([...props.files])
    } catch (err) {
      props.files[i] = {
        ...props.files[i],
        uploadQueued: false,
        uploadFailed: true,
      }

      props.setFiles([...props.files])
      console.error(err)
    }
  }

  useEffect(() => {
    for (let i = 0; i < props.files.length; i++) {
      if (
        !props.files[i].uploadQueued &&
        !props.files[i].uploaded &&
        !props.files[i].uploadFailed
      ) {
        // start the upload operation
        handleUpload(props.files[i])
        break
      }
    }
  }, [props.files])

  const onDrop = (acceptedFiles: File[]) => {
    const fileArray: IDropzoneFile[] = []
    acceptedFiles.forEach(async file => {
      // get signedUrl
      if (fileArray.length < 10)
        fileArray.push({
          file,
          thumbnailUrl: null,
          url: null,
          uploaded: false,
          uploadQueued: false,
          uploadFailed: false,
          preview: URL.createObjectURL(file),
        })
    })

    props.setFiles([...props.files, ...fileArray])
  }

  const deleteImage = (i: number) => {
    const newFiles = [...props.files]
    const deletedFile = newFiles.splice(i, 1)[0]
    URL.revokeObjectURL(deletedFile.preview)
    // ALSO DELETE IT FROM S3 HERE !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    props.setFiles(newFiles)
  }

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop, accept: ["image/jpg", "image/jpeg", "image/png"] })

  return (
    <Container>
      <Dropbox {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the images here ...</p>
        ) : (
          <p>Drag 'n' drop up to 10 images here, or tap to select images</p>
        )}
      </Dropbox>

      {props.files.length !== 0 && (
        <RowWrapper>
          <PreviewRow>
            {props.files.map((file, i) => {
              return (
                <PreviewItem>
                  <ImageMask>
                    <PreviewImage src={file.preview}></PreviewImage>
                  </ImageMask>
                  {!file.uploaded && !file.uploadFailed && (
                    <LoaderWrapper>
                      <Loader></Loader>
                    </LoaderWrapper>
                  )}
                  {file.uploadFailed && (
                    <LoaderWrapperInteractive
                      onClick={() => {
                        if (!file.uploadFailed) return
                        props.files[i] = {
                          ...props.files[i],
                          uploadFailed: false,
                        }
                        props.setFiles([...props.files])
                        // handleUpload(file)
                      }}
                    >
                      <i
                        style={{ color: colors.primary, fontSize: "14px" }}
                        className="fa fa-cloud-upload-alt"
                      ></i>
                    </LoaderWrapperInteractive>
                  )}
                  {file.uploaded && (
                    <LoaderWrapper>
                      <i
                        style={{ color: colors.primary, fontSize: "14px" }}
                        className="fa fa-check-circle"
                      ></i>
                    </LoaderWrapper>
                  )}
                  <DeleteWrapper onClick={() => deleteImage(i)}>
                    <i className="fa fa-times"></i>
                  </DeleteWrapper>
                </PreviewItem>
              )
            })}
          </PreviewRow>
        </RowWrapper>
      )}
    </Container>
  )
}
