import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ListingCard from "./ListingCard/listingCard";
import { breakpoints } from "../../Constants/responsive";
import { size, spacing } from "../../Constants/sizesAndSpacing";
import { useServer } from "../../Hooks/useServer";
import { colors } from "../../Constants/colors";
import ListingMap, { IMapConfig } from "./ListingMap/listingMap";
import { useFilter, IFilters, SortOptions } from "../../Hooks/useFilter";
import Paginator from "../Paginator/paginator";
import { IPagination } from "../../Hooks/usePagination";
import SortByDropdown from "./sortByDropdown";
import GreyLoaderBox from "./greyLoaderBox";
import Footer from  "../Footer/footer";

/* CSS Starts */

const Wrapper = styled.div`
  width: 100%;
  height: calc(100% - ${2 * size.headerHeight + "px"});
  position: relative;
`;

const MapWrapper = styled.div<{ mobileMapMode: boolean }>`
  z-index: ${props => (props.mobileMapMode ? 2 : 1)};
  display: block;
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  @media (min-width: ${breakpoints.tablet + "px"}) {
    position: static;
    display: inline-block;
    width: 65%;
  }
  @media (min-width: ${breakpoints.desktop + "px"}) {
    width: 45%;
  }
`;

const ListWrapper = styled.div<{ mobileMapMode: boolean }>`
  z-index: ${props => (props.mobileMapMode ? 1 : 2)};
  background: white;
  display: block;
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  padding: 1em;
  height: 100%;
  overflow-y: scroll;
  box-shadow: none;
  font-size: 14px;
  @media (min-width: ${breakpoints.tablet + "px"}) {
    position: initial;
    display: inline-block;
    width: 35%;
    box-shadow: -10px 0 10px -10px lightgrey;
  }
  @media (min-width: ${breakpoints.desktop + "px"}) {
    width: 55%;
    font-size: 16px;
  }

  ::-webkit-scrollbar {
    width: 1.25em;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${colors.midprimary};
    border-radius: 1.25em;
    border: 6px solid transparent;
    background-clip: content-box;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: ${colors.primary};
}

`;

const ListingFlexBox = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  margin: ${"0 -" + spacing.listingMargin / 2 + "px"};
  overflow-x: hidden;
`;

const InfoBar = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  font-size: 0.85em;
`;

const InfoBarText = styled.span`
  text-align: center;
  padding: 1em 0;
  font-size: 12px;
  color: ${colors.secondary};
`;

const GreyText = styled.div`
  opacity: 0.6;
  text-align: center;
  padding: 1em;
  font-size: 12px;
  font-weight: 600;
`;

const SeoHeading = styled.h1`
  color: ${colors.secondary};
  font-size: 1.2em;
`;

const SeoHeading2 = styled.h2`
  color: ${colors.secondary};
  font-size: 12px;
  text-align: center;
  font-weight: 500;
`;

// const SwitchMobileMapMode = styled.div`
//   position: absolute;
//   bottom: 100px;
//   left: 50%;
//   transform: translateX(-50%);
//   background: #d6eeff;
//   border-radius: 3px;
//   border: 1px solid ${colors.shadow};
//   z-index: 50;
//   color: ${colors.black};
//   cursor: pointer;
//   padding: 0.75em;
//   font-size: 1.5em;
//   font-weight: 500;
//   display: block;

//   @media (min-width: ${breakpoints.tablet + "px"}) {
//     display: none;
//   }
// `;

/* CSS Ends */

const startConfig: IMapConfig = {
  // gurgaon's location
  position: {
    lat: 28.4595,
    lon: 77.0266
  },
  zoom: 13,
  // radius in meters, based on zoom of 13 in leaflet
  radius: 7225
};

interface IProps {
  mapStartConfig: IMapConfig;
  setMapStartConfig: React.Dispatch<React.SetStateAction<IMapConfig>>;
}

const ListingsWrapper: React.FC<IProps> = (props: IProps) => {
  // access server from context
  const server = useServer();
  // access filtered data from context (server data is fed and filtered)
  const filter = useFilter();

  // this holds map's current center and radius as user interacts with leaflet
  const [mapConfig, setMapConfig] = useState<IMapConfig>(startConfig);

  // this is bound to the map's center and radius, if we used above property to bind,
  // then map would rerender many ties unneccesarily, thus these values are only to provide
  // starting position to the leaflet map. Also, if user agrees to share info, we
  // update these properties then, the map automatically shifts to the user's position
  const { mapStartConfig, setMapStartConfig } = props;

  // get user's location
  const getLocation = () => {
    if (navigator.geolocation) {
      // if user gave permission, set map to new location
      navigator.geolocation.getCurrentPosition(pos => {
        setMapStartConfig({
          ...mapStartConfig,
          position: {
            lat: pos.coords.latitude,
            lon: pos.coords.longitude
          }
        });
        setMapConfig({
          ...mapStartConfig,
          position: {
            lat: pos.coords.latitude,
            lon: pos.coords.longitude
          }

        });
      });
    }
  };

  // app boot effect
  useEffect(() => {
    // get location only on startup
    getLocation();

    // hack to fix leaflet rendering issue. we are setting deafult viewMapMode as true
    // to render map to full height, then set it to false immediately. Map retains it's height
    // config this way
    filter.viewMapMode.set(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let sortValue = 0;
  if (filter.sortBy.value === SortOptions.Newest) {
    sortValue = 0;
  } else if (filter.sortBy.value === SortOptions.Price1) {
    sortValue = 1;
  }
  // else if (filter.sortBy.value === SortOptions.Price2) {
  //   sortValue = 2;
  // } else if (filter.sortBy.value === SortOptions.SquareFeet) {
  //   sortValue = 3;
  // }

  useEffect(() => {
    server.fetchData &&
      server.fetchData(
        mapConfig.position,
        mapConfig.zoom,
        mapConfig.radius,
        (filter.filters as IFilters).rentSell.value,
        sortValue
      );

    // this effect runs whenever the zoom, radius, or map center changes
    // as user interacts with leaflet

    // inside the fetch call, we decide if to use the new position data to request listings
    // or ignore if the changes were small (for optimisation)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    mapConfig.radius,
    mapConfig.position.lat,
    mapStartConfig.position.lon,
    mapConfig.zoom,
    //@ts-ignore
    filter.filters.rentSell.value
  ]);

  return (
    <Wrapper>
      <ListWrapper
        id="ekThikanaScrollableListings"
        mobileMapMode={filter.viewMapMode.value}
      >
        {server.loading && (
          <>
            <SeoHeading> Rental Properties in Gurgaon </SeoHeading>
            <InfoBar>
              <InfoBarText>Loading Thikanas...</InfoBarText>
              <SeoHeading2>Houses for Rent in Gurgaon</SeoHeading2>
              <SortByDropdown disabled sortBy={filter.sortBy}></SortByDropdown>
            </InfoBar>
            <ListingFlexBox>
              <GreyLoaderBox></GreyLoaderBox>
              <GreyLoaderBox></GreyLoaderBox>
              <GreyLoaderBox></GreyLoaderBox>
              <GreyLoaderBox></GreyLoaderBox>
            </ListingFlexBox>
          </>
        )}
        {server.error && (
          <ListingFlexBox>
            <GreyText style={{ color: colors.red }}>
              {server.error.message}
            </GreyText>
          </ListingFlexBox>
        )}
        {!server.loading && (
          <>
          <SeoHeading> Rental Properties in Gurgaon </SeoHeading>
          <InfoBar>
            <InfoBarText>
              <span style={{ color: colors.primary }}>
                <b>{filter.posts.length}</b>
              </span>{" "}
              thikana's found
            </InfoBarText>
            <SeoHeading2>Houses for Rent in Gurgaon</SeoHeading2>
            <SortByDropdown sortBy={filter.sortBy}></SortByDropdown>
          </InfoBar>
        </>
        )}
        <ListingFlexBox>
          {filter.paginatedPosts.map((listing) => {
            return (
               <ListingCard key={listing.postID} {...listing}></ListingCard>
             );
          })}
        </ListingFlexBox>
        {!server.error &&
          !server.loading &&
          (filter.pagination as IPagination).page ===
            (filter.pagination as IPagination).totalPages && (
            <ListingFlexBox>
              <GreyText>
                No more listings. Try expanding the search radius!
              </GreyText>
            </ListingFlexBox>
          )}
        {!server.loading &&
          filter.posts.length > filter.paginatedPosts.length && (
            <Paginator
              pagination={filter.pagination as IPagination}
            ></Paginator>
          )}
          <div style={{minHeight: "1em"}}></div>
          <Footer></Footer>
      </ListWrapper>

      <MapWrapper mobileMapMode={filter.viewMapMode.value}>
        <ListingMap
          loading={server.loading}
          error={server.error}
          mapStartConfig={mapStartConfig}
          setMapConfig={setMapConfig}
          posts={filter.posts}
        ></ListingMap>
      </MapWrapper>
    </Wrapper>
  );
};

export default ListingsWrapper;
