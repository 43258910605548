import React, { useState, useEffect } from "react"
import styled from "styled-components"
import ListingCard from "../Listings/ListingCard/listingCard"
import { breakpoints } from "../../Constants/responsive"
import { size, spacing } from "../../Constants/sizesAndSpacing"
import { colors } from "../../Constants/colors"
import SavedThikanasMap, { IMapConfig } from "./SavedThikanasMap"
import { useBookmarks } from "../../Hooks/useBookmarks"
import Footer from "../Footer/footer"

/* CSS Starts */

const Wrapper = styled.div`
  width: 100%;
  height: calc(100% - ${1 * size.headerHeight + "px"});
  position: relative;
`

const MapWrapper = styled.div<{ mobileMapMode: boolean }>`
  z-index: ${props => (props.mobileMapMode ? 1 : 0)};
  display: block;
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  @media (min-width: ${breakpoints.tablet + "px"}) {
    position: static;
    display: inline-block;
    width: 65%;
  }
  @media (min-width: ${breakpoints.desktop + "px"}) {
    width: 45%;
  }
`

const ListWrapper = styled.div<{ mobileMapMode: boolean }>`
  z-index: ${props => (props.mobileMapMode ? 2 : 3)};
  background: white;
  display: block;
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  padding: 1em;
  height: 100%;
  overflow-y: scroll;
  box-shadow: none;
  font-size: 14px;

  @media (min-width: ${breakpoints.tablet + "px"}) {
    position: initial;
    display: inline-block;
    width: 35%;
    box-shadow: -10px 0 10px -10px lightgrey;
  }
  @media (min-width: ${breakpoints.desktop + "px"}) {
    width: 55%;
    font-size: 16px;
  }

  ::-webkit-scrollbar {
    width: 1.25em;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${colors.midprimary};
    border-radius: 1.25em;
    border: 6px solid transparent;
    background-clip: content-box;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: ${colors.primary};
  }
`

const ListingFlexBox = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  margin: ${"0 -" + spacing.listingMargin / 2 + "px"};
  overflow-x: hidden;
`

const InfoBar = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  font-size: 0.85em;
`

const InfoBarText = styled.span`
  text-align: center;
  padding: 1em 0;
  font-size: 12px;
  color: ${colors.secondary};
`

const GreyText = styled.div`
  opacity: 0.6;
  text-align: center;
  padding: 1em;
  font-size: 12px;
  font-weight: 600;
`;

/* CSS Ends */

const startConfig: IMapConfig = {
  // gurgaon's location
  position: {
    lat: 28.4595,
    lon: 77.0266,
  },
  zoom: 13,
  // radius in meters, based on zoom of 13 in leaflet
  radius: 7225,
}

interface IProps {
  mapStartConfig: IMapConfig
  setMapStartConfig: React.Dispatch<React.SetStateAction<IMapConfig>>
}

export const SavedThikanas: React.FC<IProps> = (props: IProps) => {
  // Bookmark values
  const bookmarksHook = useBookmarks()
  const bookmarks = bookmarksHook.bookmarks

  useEffect(() => {
    setTimeout(() => {
      if (Object.keys(bookmarks).length === 0) return
      bookmarksHook.refreshBookmarksData()
    }, 500)
  }, [])

  // this holds map's current center and radius as user interacts with leaflet

  const [mapConfig, setMapConfig] = useState<IMapConfig>(startConfig)

  // this is bound to the map's center and radius, if we used above property to bind,
  // then map would rerender many ties unneccesarily, thus these values are only to provide
  // starting position to the leaflet map. Also, if user agrees to share info, we
  // update these properties then, the map automatically shifts to the user's position

  const { mapStartConfig, setMapStartConfig } = props

  return (
    <Wrapper>
      <ListWrapper id="ekThikanaScrollableListings" mobileMapMode={true}>
        <InfoBar>
          <InfoBarText>
            <span style={{ color: colors.primary }}>
              <b>{Object.keys(bookmarks).length}</b>
            </span>{" "}
            Saved Thikanas
          </InfoBarText>
        </InfoBar>

        <ListingFlexBox>
          {Object.keys(bookmarks).map((postId, index) => {
            return (
              <ListingCard key={index} {...bookmarks[postId]}></ListingCard>
            )
          })}
        </ListingFlexBox>
        <GreyText>
          No more saved Thikana's, to add click on the bookmark icon.
        </GreyText>

        <div style={{ minHeight: "1em" }}></div>
        <Footer></Footer>
      </ListWrapper>

      <MapWrapper mobileMapMode={true}>
        <SavedThikanasMap
          loading={false}
          error={null}
          mapStartConfig={mapStartConfig}
          setMapConfig={setMapConfig}
          posts={Object.values(bookmarks)}
        ></SavedThikanasMap>
      </MapWrapper>
    </Wrapper>
  )
}
